import { createTheme, ThemeProvider} from "@mui/material";

const h0fs = [60, 50, 25]
const h1fs = [45, 35, 18]
const h2fs = [40, 30, 15]
const h3fs = [30, 23, 12]
const h4fs = [20, 15, 10]
const pfs = [16, 13, 9]
const xsfs = [13, 10, 7]

const inputsHeight = '30px'
const inputsWidth = '350px'
const popupsWidth = '500px'
const popupsHeight = '200px'
const colors = {
    primary: '#ff9999',
    halftransparentprimary: "#f997",
    quartertransparentprimary: "#f993",
    secondary: '#ffcdcd',
    lightneutral: 'white',
    middleneutral: '#f8f8f8',
    darkmiddleneutral: '#e0e0e0',
    darkneutral: 'black',
    maroon: '#d48b70',
}
const theme = createTheme({
    palette: { primary: { main: colors.primary } },
    typography: { fontSize: pfs[0] },
    components: {
        MuiSelect: {
            styleOverrides : {
                root: {
                    height: inputsHeight,
                }
            }
        },
        MuiOutlinedInput: {
            styleOverrides : {
                root: {
                    height: inputsHeight,
                    border: '0px',
                    outline: 'none'
                }
            },
            variants: [
                {
                    props: { variant: 'authformrow' },
                    style: { width: '100%', height: '100%', backgroundColor: colors.secondary }
                },
                {
                    props: { variant: 'formrow' },
                    style:{
                        backgroundColor: colors.quartertransparentprimary,
                        height: '100%',
                    }
                },
                {
                    props: { variant: 'formrow2' },
                    style:{
                        backgroundColor: '#ece3fe',
                        height: '100%',
                    }
                },
                {
                    props: { variant: 'formrow3' },
                    style:{
                        backgroundColor: '#e3e8fe',
                        height: '100%',
                    }
                }
            ]
        },
        MuiToggleButton: {
            styleOverrides: {
                root: {
                    padding: '0',
                    border: "none"
                }
            }
        },
        MuiTypography: {
            styleOverrides: {
                root: ({theme}) => {return { fontSize: pfs[0],  [theme.breakpoints.down('md')]: { fontSize: pfs[1] }, [theme.breakpoints.down('sm')]: { fontSize: pfs[2] } }
            }},
            variants: [
                {
                    props: { variant: 'hometitle' },
                    style: ({theme}) => {return { fontSize: 70, [theme.breakpoints.down('md')]: { fontSize: 60 }, [theme.breakpoints.down('sm')]: { fontSize: 35 } }},
                }, 
                {
                    props: { variant: 'cbuttonttl' },
                    style: ({theme}) => {return { fontSize: 25, [theme.breakpoints.down('md')]: { fontSize: 20 }, [theme.breakpoints.down('sm')]: { fontSize: 11 } }},
                }, 
                {
                    props: { variant: 'title' },
                    style: ({theme}) => {return { fontSize: h4fs[0], [theme.breakpoints.down('md')]: { fontSize: h4fs[1] }, [theme.breakpoints.down('sm')]: { fontSize: h4fs[2] } }},
                },{
                    props: { variant: 'pfsbold'},
                    style: ({theme}) => {return { color: colors.darkneutral, fontWeight: 'bold' , fontSize: pfs[0], [theme.breakpoints.down('md')]: { fontSize: pfs[1] }, [theme.breakpoints.down('sm')]: { fontSize: pfs[2] } }},
                },
                {
                    props: { variant: 'h2' },
                    style: ({theme}) => {return { fontSize: h2fs[0], [theme.breakpoints.down('md')]: { fontSize: h2fs[1] }, [theme.breakpoints.down('sm')]: { fontSize: h2fs[2] } }},
                },
                {
                    props: { variant: 'h0' },
                    style: ({theme}) => {return { fontSize: h0fs[0], [theme.breakpoints.down('md')]: { fontSize: h0fs[1] }, [theme.breakpoints.down('sm')]: { fontSize: h0fs[2] } }},
                },
                {
                    props: { variant: 'h1' },
                    style: ({theme}) => {return { fontSize: h1fs[0], [theme.breakpoints.down('md')]: { fontSize: h1fs[1] }, [theme.breakpoints.down('sm')]: { fontSize: h1fs[2] } }},
                },{
                    props: { variant: 'pfsboldwhite'},
                    style: ({theme}) => {return { color: colors.lightneutral, fontWeight: 'bold' , fontSize: pfs[0], [theme.breakpoints.down('md')]: { fontSize: pfs[1] }, [theme.breakpoints.down('sm')]: { fontSize: pfs[2] } }},
                },{
                    props: { variant: 'h4' },
                    style: ({theme}) => {return { fontSize: h4fs[0], [theme.breakpoints.down('md')]: { fontSize: h4fs[1] }, [theme.breakpoints.down('sm')]: { fontSize: h4fs[2] } }},
                },
                {
                    props: { variant: 'h3' },
                    style: ({theme}) => {return { fontSize: h3fs[0], [theme.breakpoints.down('md')]: { fontSize: h3fs[1] }, [theme.breakpoints.down('sm')]: { fontSize: h3fs[2] } }},
                },{
                    props: { variant: 'whitetitle'},
                    style: ({theme}) => {return { color: colors.lightneutral, fontWeight: 'bold' , fontSize: h3fs[1], [theme.breakpoints.down('md')]: { fontSize: h1fs[2] }, [theme.breakpoints.down('sm')]: { fontSize: h3fs[2] } }},
                },{
                    props: { variant: 'blacktitle'},
                    style: ({theme}) => {return { color: colors.darkneutral, fontWeight: 'bold' , fontSize: h4fs[0], [theme.breakpoints.down('md')]: { fontSize: h4fs[1] }, [theme.breakpoints.down('sm')]: { fontSize: h4fs[2] } }},
                }
                ,{
                    props: { variant: 'white' },
                    style: { color: colors.lightneutral }
                },{
                    props: { variant: 'error' },
                    style: { color: '#f00' }
                },{
                    props: { variant: 'xs' },
                    style: ({theme}) => {return { color: colors.darkneutral, fontSize: xsfs[0], [theme.breakpoints.down('md')]: { fontSize: xsfs[1] }, [theme.breakpoints.down('sm')]: { fontSize: xsfs[2] } }},
                },{
                    props: { variant: 'xswhite' },
                    style: ({theme}) => {return { color: colors.lightneutral, fontSize: xsfs[0], [theme.breakpoints.down('md')]: { fontSize: xsfs[1] }, [theme.breakpoints.down('sm')]: { fontSize: xsfs[2] } }},
                }
            ]
        },
        MuiAccordionDetails: {
            variants: [
                {
                    props: { variant: 'gifsdetails' },
                    style: {
                        margin: "10px 0 0 20px" ,
                        display:"flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection:"row", 
                        cursor: "pointer", 
                        width: '100%', 
                        height: "10px", 
                        
                    }
                }
            ]
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    paddingLeft: '25px',
                    paddingRight: '25px',
                    boxShadow: 'none'
                }
            }
            ,variants: [
                {
                    props: { variant: 'login' },
                    style: {
                        color: 'white',
                        padding: '10px 40px',
                        border: '2px solid ' + colors.secondary,
                        transition: '.3s',
                        backgroundColor: '#63747a',
                        '&:hover':{
                            backgroundColor: '#63747a',
                            transform: 'scale(1.1)'
                        }
                    }
                },{
                    props: { variant: "formrow" },
                    style:{
                        color: colors.lightneutral,
                        backgroundColor: '#535353',
                        border: '1px solid #7e7e7e',
                        textTransform: 'capitalize',
                        transition: '.3s',
                        '&:hover': {
                            backgroundColor: '#535353',
                            transform: 'scale(1.05)'
                        }
                    }
                },{
                    props: { variant: "hover" },
                    style:{
                        transition: '.3s',
                        '&:hover': {
                            transform: 'scale(1.05)'
                        }
                    }
                }
            ]
        },
        MuiIconButton: {
            variants: [
                {
                    props: { variant: 'head' },
                    style: {
                        backgroundColor: colors.lightneutral,
                        color: colors.darkneutral,
                        transition: "0.5s"
                    }
                }
            ]
        },
        MuiGrid: {
            variants: [
                {
                    props: { variant: 'thead' },
                    style: ({theme}) => {return  { display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center', backgroundColor: colors.darkmiddleneutral, padding: '10px 0', fontSize: pfs[0],  [theme.breakpoints.down('md')]: { fontSize: pfs[1] }, [theme.breakpoints.down('sm')]: { fontSize: pfs[2] } }}
                },{
                    props: { variant: 'item' },
                    style: ({theme}) => {return  {  border: '1px solid ' + colors.darkmiddleneutral, padding: '10px 0', display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: pfs[0],  [theme.breakpoints.down('md')]: { fontSize: pfs[1] }, [theme.breakpoints.down('sm')]: { fontSize: pfs[2] } }}
                },{
                    props: { variant: "profcontitem1"},
                    style: { 
                        backgroundColor: '#6cc9ff', 
                        display: "flex", 
                        justifyContent: 'center', 
                        alignItems: 'center' ,
                        padding: '5px 0'
                    }
                },{
                    props: { variant: "profcontitem2"},
                    style: { backgroundColor: '#a5e2ff' }
                },
                ,{
                    props: { variant: 'maroon' },
                    style: { backgroundColor: colors.maroon, border: '2px solid #a7705b' }
                },{
                    props: { variant: 'primarybg' },
                    style: { backgroundColor: colors.primary }
                },{
                    props: { variant: 'yellowbg' },
                    style: { backgroundColor: "#fed699" }
                },{
                    props: { variant: 'halfyellowbg' },
                    style: { backgroundColor: "#ffecce" }
                },{
                    props: { variant: 'halftransparentprimarybg' },
                    style: { backgroundColor: colors.halftransparentprimary }
                },{
                    props: { variant: 'quartertransparentprimarybg' },
                    style: { backgroundColor: colors.quartertransparentprimary }
                }
            ]
        },
        MuiStack: {
            variants: [
                {
                    props: {variant: 'maroongradientbg'},
                    style: {
                        background:  'linear-gradient(to right, #e2b2a0,' +colors.lightneutral +')'
                    }
                },
                {
                    props: { variant: 'head'},
                    style: {
                        color: colors.lightneutral,
                        backgroundColor: colors.lightneutral,
                        position: 'sticky',
                        top: 0,
                        zIndex: 3,
                        padding: "10px 20px"
                    }
                }, {
                    props: { variant: "bordered" },
                    style: { 
                        border: "1px solid black ",
                        padding: "10px"
                    }
                },{
                    props: { variant: 'head-2' },
                    style: {
                        padding: '5px',
                        backgroundColor: colors.primary,
                        color: colors.lightneutral
                    }
                },{
                    props: { variant: 'profil'},
                    style: { backgroundColor: colors.lightneutral }
                },
                {
                    props: { variant: 'inverse' },
                    style: { backgroundColor: colors.primary, color: colors.lightneutral, padding: '20px 0' }
                },
                {
                    props: { variant: 'popup' },
                    style: { position:'fixed', top:'50%', left:'50%', transform: 'translate(-50%, -50%)' , backgroundColor: colors.primary, color: colors.lightneutral, width: popupsWidth, height: popupsHeight, display:'flex', alignItems:'center', justifyContent: 'space-evenly', borderRadius:'10px' }
                },{
                    props: { variant: 'pdp' },
                    style: {
                        backgroundColor: 'transparent',
                        overflow: "hidden"
                    }
                },{
                    props: { variant: 'profilelight' },
                    style: { 
                        backgroundColor: '#cd20c2', 
                        overflow: "hidden",
                        border: "3px solid " + colors.lightneutral,
                        boxShadow: "0px 0px 10px 7px #cd20c2",
                        padding:'2px',
                        transform: 'translateX(20%)' 
                    }
                }
            ]
        },
        MuiDivider: {
            variants: [
                {
                    props: { variant: "primarybold" },
                    style: { border: "none", borderTop: "5px solid " + colors.primary}
                }
            ]
        },
    }
})

const WithThemeProvider = ({children}) => {
    return (
        <ThemeProvider theme={theme}>
            {children}
        </ThemeProvider>
    )
}

export default WithThemeProvider