import React from "react";
import { Stack, Typography, Divider, Box, useMediaQuery, useTheme } from "@mui/material";
import useLanguage from "../hooks/useLanguage";
import CButton from "./CButton";

const Feat = () => {
    const { text } = useLanguage()

    const { breakpoints: { up } } =  useTheme()
    const mdUp = useMediaQuery(up("md"))

    const featsCount = 15
    const features = [
        { imageName: "1.png", backgroundColor: "#ffccab", background2Color: "#f5e4d9", title: text.ft1ttl, text: text.ft1txt, },
        { imageName: "2.png", backgroundColor: "#d6a7e4", background2Color: "#f8f8f8", title: text.ft2ttl, text: text.ft2txt, },
        { imageName: "3.png", backgroundColor: "#a8d2d4", background2Color: "#daf2f4", title: text.ft3ttl, text: text.ft3txt, },
        { imageName: "4.png", backgroundColor: "#efe6ab", background2Color: "#fdf7d0", title: text.ft4ttl, text: text.ft4txt, },
        { imageName: "5.png", backgroundColor: "#f3b2db", background2Color: "#ff4837", title: text.ft5ttl, text: text.ft5txt, },
        { imageName: "6.png", backgroundColor: "#ffb7b0", background2Color: "#ffdeda", title: text.ft6ttl, text: text.ft6txt, },
        { imageName: "7.png", backgroundColor: "#cdbdf6", background2Color: "#ede6ff", title: text.ft7ttl, text: text.ft7txt, },
        { imageName: "8.png", backgroundColor: "#c4dfb1", background2Color: "#e8f6df", title: text.ft8ttl, text: text.ft8txt, },
        { imageName: "9.png", backgroundColor: "#b6e2e8", background2Color: "#ddfbff", title: text.ft9ttl, text: text.ft9txt, },
        { imageName: "10.png", backgroundColor: "#ffdcad", background2Color: "#f0e7d5", title: text.ft10ttl, text: text.ft10txt, },
        { imageName: "11.png", backgroundColor: "#d48b70", background2Color: "#f1ada6", title: text.ft11ttl, text: text.ft11txt, },
        { imageName: "12.png", backgroundColor: "#a7d8f8", background2Color: "#b6e2e8", title: text.ft12ttl, text: text.ft12txt, },
        { imageName: "13.png", backgroundColor: "#c1ff72", background2Color: "#bfd9ac", title: text.ft13ttl, text: text.ft13txt, },
        { imageName: "14.png", backgroundColor: "#a6a6a6", background2Color: "#dad9d9", title: text.ft14ttl, text: text.ft14txt, },
        { imageName: "15.png", backgroundColor: "#ff5757", background2Color: "#f46043", title: text.ft15ttl, text: text.ft15txt,},
    ]

    const twoDigitsNumber = n => {
        if (n>9) return n.toString()
        return `0${n}`
    }

    return <Stack id="ftrs" spacing={5}>
        <Stack spacing={2}>
        <Stack flexDirection="row" alignItems="center" justifyContent="center" gap="5px">
            <Typography variant="hometitle" textAlign="right" fontWeight="bold" color="#fff" sx={{ WebkitTextStroke: "2px #d48b70", fontFamily: "PlayFairDisplay" }}>{featsCount}</Typography>
            <Typography variant="hometitle" textAlign="right" fontWeight="bold" color="#fff" sx={{ WebkitTextStroke: "2px #d48b70", fontFamily: "PlayFairDisplay" }}>{text.xftftm}</Typography>
        </Stack>
        <Stack width={{ xs: "100%", sm: "90%", md: "80%", lg: "70%", xl: "60%" }} alignSelf="center" gap={{ xs: "15px", md: "0px" }}>
        {
            features
            .map((feature, i) => <Stack key={i} sx={{ flexDirection: { xs: (i%2 === 0) ? "column" : "column-reverse", md: (i%2 ===  0) ? "row" : "row-reverse" }  }}>
                <Stack sx={{ width: { xs: "90%", md: "50%" }, position: "relative", margin: "7px 0", backgroundColor: feature.backgroundColor, flexDirection: (i%2 ===  0) ? "row" : "row-reverse", alignItems: "center", justifyContent: "space-between", padding: "10px", boxSizing: "border-box", borderRadius: (i%2 ===  0) ? "80px 0px 0px 80px" : "0px 80px 80px 0px" }}>
                    <Stack sx={{ bgcolor: feature.background2Color, borderRadius: "50%", padding: "3px", boxSizing: "border-box" }}>
                        <Box component="img" src={`/images/admin2/${feature.imageName}`} sx={{ height: '100px', aspectRatio: "1/1" }}/>
                    </Stack>
                    <Stack>
                        <Typography fontWeight="bold" variant="h3" textAlign={(i%2 === 0) ? "right" : "left"}>{twoDigitsNumber(i + 1)}.</Typography>
                        <Typography fontWeight="bold" variant="h3" textAlign={(i%2 === 0) ? "right" : "left"}>{feature.title}</Typography>
                    </Stack>
                    <Stack sx={{ position: "absolute", height: "100%", width: "12px", background: feature.background2Color, right: (i%2 === 0) ? "-12px" : "unset", left: (i%2 === 0) ? "unset" : "-12px", borderRadius: (i%2 ===  0) ? "0% 50% 50% 0%" : "50% 0% 0% 50%" }} />
                </Stack>
                { mdUp && <Divider orientation="vertical" sx={{ border: "2px dashed #777", zIndex: 2 }} flexItem/>}
                <Stack sx={{ width: { xs: "90%", md: "50%" } }}>
                    <Typography sx={(i%2 === 0) ? { paddingLeft: "10px" } : { paddingRight: "10px" }}>{feature.text}</Typography>
                </Stack>
            </Stack>)
        }
        </Stack>
        </Stack>
        <Stack alignItems="center">
            <CButton>{text.nsecrr}</CButton>
        </Stack>
    </Stack>
}

export default Feat