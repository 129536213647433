import React, { createContext, useState } from 'react';

const AuthStateContext = createContext(false);

const AuthStateProvider = ({children}) => {
    const [state, setState] = useState({})
    const setAuthenticated = (login, userId, userName, userRole, token, languageName, accessareas) =>{
        if (login){
            setState({ login, userId, userName, userRole, token, languageName, accessareas})
        }
        else{ // logout
            setState({})
        }
    }
    return (
        <AuthStateContext.Provider value={{
            authState: state,
            setAuthenticated,
        }}>
            {children}
        </AuthStateContext.Provider>
    )
}
export {AuthStateContext, AuthStateProvider}