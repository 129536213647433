import React, {useState, useEffect, useContext} from "react";
import { Stack, Typography, Button, Pagination} from "@mui/material";
import useLanguage from "../hooks/useLanguage";
import SearchFilter from "../tools/SearchFilter";
import useData from "../hooks/useData";
import TableRow from "../tools/TableRow";
import { GifsContext } from "../state/gifContext";
import axiosInstance from "../../axios";
import { AuthStateContext } from "../state/AuthStateContext";
import { formatDate2, limit2Nbr } from "../../helper/formatDate";
import { baseURL } from "../../helper/conf";
import { cData } from "../tools/Snackbars";

const InvoiceStatement = ({statement}) => {
    const { globalFilter } = useContext(GifsContext)
    const [filter, setFilter] = useState(() => ({
        ...globalFilter,
        country: '',
    }))

    const { text } = useLanguage()
    const { authState } = useContext(AuthStateContext)
    const { languagesList,getV, paymentList } = useData()

    // LIST INVOICE STATEMENT
    const [ datas, setDatas ] = useState()
    const [ total, setTotal ] = useState()
    const [ pagination, setPagination ] = useState()
    const [page, setPage] = useState(1);

    const handleChange = (event, value) => {
        setPage(value);
    };

    useEffect(()=>{
        axiosInstance.get(`/invoice?year=${filter?.year}&month=${filter?.month}&page=${page}&agent=${filter?.agents}&country=${filter?.country}&language=${filter?.language}`, {
            headers: {
                'Authorization': `Bearer ${authState.token}`,
            },
        })
        .then((response) => {
            setDatas(response.data.data)
            setTotal(response.data.total)
            setPagination(response.data.pagination)
        })
        .catch((error) => {
            console.log(error)
        })
    },[filter, page])

    useEffect(() => {
        if(statement){
            setDatas((prev) => [...prev, statement])
            setTotal(total + statement.totalPayment)
        }
    }, [statement])


    // GET PRINT PDF INVOICE STATEMENT
    const [ loading, setLoading ] = useState(false)
    const handlePrint = () => {
        const isPrint = true
        setLoading(true)
        axiosInstance.get(`/invoice?isPrint=${isPrint}&year=${filter?.year}&month=${filter?.month}&page=${page}&agent=${filter?.agents}&country=${filter?.country}&language=${filter?.language}&title=${cData(getV(5))}`, {
            headers: {
                'Authorization': `Bearer ${authState.token}`,
            },
        })
        .then((response) => {
            console.log(response.data.pdfName)
            const pdfName = response.data.pdfName;

            // Ouvrir le PDF dans un nouvel onglet
            const downloadUrl = `${baseURL}/print/${pdfName}`;
            window.open(downloadUrl, '_blank');

            setLoading(false)
        })
        .catch((error) => {
            console.log(error)
            setLoading(false)
        })
    }
    

    return (
        <Stack spacing={2} padding="20px 0">
            <SearchFilter payment
                onDateChange={{
                    year: y => setFilter({...filter, year: y}),
                    month: m => setFilter({...filter, month: m}),
                }}
                items= {[
                    { label: text.language, comp: 'Select', choices: languagesList, onChange: e => setFilter({ ...filter, language: e.target.value }) },
                    { label: text.agent, comp: 'TextInput', onChange: e => setFilter({...filter, agents: e.target.value}) },
                    { label: text.agcountry, comp: 'TextInput', onChange: e => setFilter({...filter, country: e.target.value}) },
                ]}
            />
            
            <Stack spacing={'10px'}>
                <TableRow header columns={[
                    { xs: 1.1,  text: "Date of Invoice"},
                    { xs: 1.1,  text: "Invoicing Period"},
                    { xs: 1.1,  text: "Invoice Number"},
                    { xs: 1.1,  text: "Agent Full Name"},
                    { xs: 1.1,  text: "Agent Full Address"},
                    { xs: 1.1,  text: "Agent Country of residency"},
                    { xs: 1.1,  text: text.language},
                    { xs: 1.1,  text: text.login},
                    { xs: 1.1,  text: text.paymeth},
                    { xs: 1.1,  text: "Total payment"},
                ]}/>
                { datas?.map((data, i) => (
                    <TableRow
                        key={i}
                        columns={[
                            { xs: 1.1, text: data.date },
                            { xs: 1.1, text: data.period },
                            { xs: 1.1, text: data.invoiceNumber },
                            { xs: 1.1, text: data.fullName },
                            { xs: 1.1, text: data.fullAddress },
                            { xs: 1.1, text: data.country },
                            { xs: 1.1, text: data.language },
                            { xs: 1.1,  text: data.login },
                            { xs: 1.1,  text: data.paymentMethod },
                            { xs: 1.1, text: `${limit2Nbr(data.totalPayment)} €` },
                        ]}
                    />
                ))}
                <TableRow
                    columns={[
                        { xs: 2.3,  text: "Gross Total"},
                        { xs: 1.1,  text: ""},
                        { xs: 1.1,  text: ""},
                        { xs: 1.1,  text: ""},
                        { xs: 1.1,  text: ""},
                        { xs: 1.1,  text: ""},
                        { xs: 1.1,  text: ""},
                        { xs: 2.3,  text: `${limit2Nbr(total)} €` },
                    ]}
                />
            </Stack>

            <Stack justifyItems="center" alignItems="center" >
                <Pagination
                    page={page}
                    onChange={handleChange}
                    count={pagination?.total_pages}
                    variant="outlined" 
                    shape="rounded" 
                    color="secondary"
                />
            </Stack>

            <Button 
                variant="formrow" 
                style={{ alignSelf:"flex-end"}}
                onClick={() => handlePrint()}
            >
                <Typography variant="pfsboldwhite" style={{textTransform: "capitalize"}}>
                    { loading ? "loading" : text.prstat }
                </Typography>
            </Button>

        </Stack>
    )
}

export default InvoiceStatement