import { Stack, Typography, Button, Alert, Snackbar, Grid, Box, DialogActions, DialogTitle } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import useLanguage from "../hooks/useLanguage";
import BigContainerAccordion from "../tools/BigContainerAccordion";
import SearchFilter from "../tools/SearchFilter";
import FormRow from "../tools/FormRow";
import {useForm} from 'react-hook-form'
import useData from "../hooks/useData";
import axiosInstance from "../../axios";
import { AuthStateContext } from "../state/AuthStateContext";
import { filterData } from "../../helper/filter";
import { GifsContext } from "../state/gifContext";
import CustomAlert from "../Admin/CustomAlert";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import SaveIcon from '@mui/icons-material/Save';
import CustomPopup from "../Admin/CustomPopup";
import Title from "../Admin2/Comps/Title";
import TableRow from "../tools/TableRow";
import Pusher from 'pusher-js'
import { CLUSTER, PUSHER_SECRET, apiURL, baseURL } from "../../helper/conf";

const Gifs = () =>{
    const { globalFilter} = useContext(GifsContext)
    const { gifTypes, languagesgifList, sexualgifList, gaygifList, lesbiangifList, transgendergifList, travestitegifList  } = useData()
    const {text} = useLanguage()
    const {languagesList} = useData()
    const { authState } = useContext(AuthStateContext)
    const [ gif, setGif ] = useState(null)
    const [platformSelect,setPlatformSelect] = useState([{value:'',innerHTML:''}])
    const [ alert, setAlert ] = useState()
    const [ open, setOpen ] = useState()
    const [ gifList, setGifList ] = useState()
    const [ gifTypeList, setGifTypeList ] = useState(languagesgifList)

    const handleClose = () => {
        setOpen(false);
      };

    const [filter, setFilter] = useState({
        year: new Date().getFullYear(),
        month: new Date().getMonth() + 1,
        date: '',
        startingDate: '',
        closingDate: '',
        platforms: '',
        giftype: '',
    })

    const { control, watch, reset, handleSubmit, formState: {errors} } = useForm()
    const values = watch()

    const getList = () => {
        axiosInstance.get('/listSelect', {
            headers: {
                'Authorization': `Bearer ${authState.token}`,
            },
        })
        .then(res=>setPlatformSelect(res.data))
        .catch(e => console.log(e))
        axiosInstance.get("/gif/list", {
            headers: { 
                'Authorization': `Bearer ${authState.token}`,
            },
        })
        .then((response) => {
            setGifList(response.data.gifs)
        })
        .catch((error) => {
            console.log(error)
        })
    }

    useEffect(() => {
        switch (values.giftype) {
          case "seg":
            setGifTypeList(sexualgifList);
            break;
          case "gag":
            setGifTypeList(gaygifList);
            break;
          case "leg":
            setGifTypeList(lesbiangifList);
            break;
          case "tsg":
            setGifTypeList(transgendergifList);
            break;
          case "tvg":
            setGifTypeList(travestitegifList);
            break;
          default:
            setGifTypeList(languagesgifList);
        }
    }, [values.giftype]);

    const newGif = data => {
        const formData = new FormData();
        formData.append("platformId", data.platform);
        formData.append("type", data.giftype);
        formData.append("typeoption", data.typeoption);
        formData.append("gifImage", gif);
        
        axiosInstance.post("/gif/add", formData, {
            headers: { 
                'Authorization': `Bearer ${authState.token}`,
                'Content-Type': 'multipart/form-data'
            },
            transformRequest: null
        })
        .then((response) => {
            setAlert(response.data.message)
            setOpen(true)
            getList()
            setGif(null)
            reset()
        })
        .catch((error) => {
            console.log(error)
        })
    }

    useEffect(() => {
        getList()
    }, [])


    // GET PHOTO CADEAU LIST
    const [ gifts, setGifts ] = useState()
    useEffect(()=>{
        axiosInstance.get('/photo_gifts', {
            headers: {
                'Authorization': `Bearer ${authState.token}`,
            },
        })
        .then((response) => {
            console.log(response.data.data)
            setGifts(response.data.data)
        })
        .catch((error) => {
            console.log(error)
        })
    },[])

    // LIST OF GIFTS REQUESTS
    const [ GiftsListes, setGiftsListes ] = useState([]);
    useEffect(()=>{
        axiosInstance.get('/giftsRequest/list', {
            headers: {
                'Authorization': `Bearer ${authState.token}`,
            },
        })
        .then((response) => {
            console.log(response.data.giftsRequests)
            setGiftsListes(response.data.giftsRequests)
        })
        .catch((error) => {
            console.log(error)
        })
    },[])

    // PUSHER RECEIVE GIFTS REQUESTS
    useEffect(()=>{
        const pusher = new Pusher(PUSHER_SECRET,{cluster:CLUSTER,channelAuthorization:{endpoint:`${apiURL}/pusher/user-auth`,transport:'jsonp'}})
        const channel = pusher.subscribe(`private-${authState.userId}`)
        channel.bind("add-gifts", (data) => {
            console.log(data)
            setGiftsListes((prev) => [ ...prev, data])
        })
        return()=>{
            channel.unbind()
            pusher.unsubscribe(`private-${authState.userId}`)
        }
    },[])

    // Uploid GIFTS
    const [ giftsToUploid, setGiftsToUploid ] = useState()
    const [ popPhoto, setPopPhoto ] = useState(false)
    const [ alertContenu, setAlertContenu ] = useState("")
    const [ alertOpen, setAlertOpen ] = useState(false)
    const [ loading, setLoading ] = useState(false)

    const [images, setImages] = useState([]);
    const [ imUrl, setImUrl ] = useState([])
    const handleImageUpload = (event) => {
        const files = Array.from(event.target.files);
        setImUrl(files.map((file) => URL.createObjectURL(file)));
        setImages((prev) => [...prev, ...event.target.files]);
    };

    const uploidPhoto = (isRequest) => {
        console.log(isRequest);
        setLoading(true)
        const formData = new FormData();
        images.forEach((image) => formData.append("gifts[]", image));
        formData.append("isRequest", isRequest);
        formData.append("requestId", isRequest ? giftsToUploid.request_id : null);

        axiosInstance.post("/photo_gifts", formData, {
            headers: { 
                'Authorization': `Bearer ${authState.token}`,
                'Content-Type': 'multipart/form-data'
            },
            transformRequest: null
        })
        .then((response) => {
            console.log(response.data.data)
            setGifts((prev)=>[ ...prev, ...response.data.data])
            if(isRequest){
                setGiftsListes((prev) => prev.filter((d) => d.request_id != response.data.dataRequest.request_id))
            }
            setAlertContenu(response.data.message)
            setAlertOpen(true)
            setLoading(false)
            setImUrl([])
            setImages([])
            setPopPhoto(false)
        })
        .catch((error) => {
            console.log(error)
        })
    }

    // Filtrage des données
    const filteredGif = filterData(gifList,filter)

    // DELETE GIFTS
    const [ openDelete, setOpenDelete ] = useState(false)
    const [ giftsToDelete, setGiftsToDelete ] = useState(false)
    const handleDeleteGifts = () => {
        setLoading(true)
        axiosInstance.delete(`/photo_gift/${giftsToDelete.id}`, {
            headers: { 
                'Authorization': `Bearer ${authState.token}`,
            },
        })
        .then((response) => {
            console.log(response.data)
            setGifts((prev) => prev.filter((d) => d.id != response.data.data.id))
            setLoading(false)
            setOpenDelete(false)
        })
        .catch((error) => {
            console.log(error)
            setLoading(false)
        })
    }

    // DELETE GIFS
    const [ gifsToDelete, setGifsToDelete ] = useState(false)
    const [ openDeleteG, setOpenDeleteG ] = useState(false)
    const handleDeleteGifs = () => {
        setLoading(true)
        axiosInstance.delete(`/gifs_delete/${gifsToDelete.id}`, {
            headers: { 
                'Authorization': `Bearer ${authState.token}`,
            },
        })
        .then((response) => {
            console.log(response.data)
            setGifList((prev) => prev.filter((d) => d.id != response.data.data.id))
            setLoading(false)
            setOpenDeleteG(false)
        })
        .catch((error) => {
            console.log(error)
            setLoading(false)
        })
    }
    

    return(
        <Stack spacing={2} style={{ overflowY: "scroll" }}>
            <CustomPopup open={popPhoto} onClose={() => setPopPhoto(false)}>
                <Box sx={{ py: "20px", px: "25px"}}>
                    <Box sx={{ mb: "10px"}}>
                        <Typography sx={{ fontWeight: "900", mb: "10px", fontSize: "20px"}}>
                            {text.addpht} :
                        </Typography>
                        <Stack sx={{ flexDirection: "row", gap: "6px"}}>
                            <Typography sx={{ fontWeight: "900", mb: "10px"}}>
                                {text.descri}:
                            </Typography>
                            <Typography sx={{ fontWeight: "700", color: "#757575"}}>
                                {giftsToUploid?.image_type}
                            </Typography>
                        </Stack>
                    </Box>
                    <Stack sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                        <Button variant="contained" component="label">
                            <CloudUploadIcon
                                style={{
                                    color: 'green', 
                                    width: '30px', 
                                    height: '30px',
                                    marginRight: '8px'
                                }}
                            />
                            <p style={{ fontWeight: '800'}}>
                                {text.uploadimage}
                            </p>
                            <input type="file" hidden multiple onChange={handleImageUpload} />
                        </Button>
                    </Stack>
                    <Stack sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                        <Stack sx={{ maxWidth: '500px'}}>
                            <Grid container spacing={2}>
                                {imUrl.map((image, index) => (
                                    <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
                                        <Box
                                            component="img"
                                            src={image}
                                            alt={`Uploaded ${index}`}
                                            sx={{ width: '100px', height: '100px' }}
                                        />
                                    </Grid>
                                ))}
                            </Grid>
                        </Stack>
                    </Stack>
                    <Stack sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                        <Stack sx={{ mt: "40px", flexDirection: "row", gap: "20px"}}>
                            <Button 
                                variant='contained' 
                                sx={{ 
                                    fontWeight: "600", 
                                    bgcolor: "error.main",
                                    color: "white",
                                    "&:hover": {
                                        bgcolor: "error.dark",
                                    }
                                }}
                                onClick={() => { setImUrl([]); setImages([]); setPopPhoto(false) }}
                            >
                                {text.restfn}
                            </Button>
                            <Button 
                                variant='contained' 
                                sx={{ 
                                    fontWeight: "700", 
                                    bgcolor: "success.main", 
                                    color: "white",
                                    "&:hover": {
                                        bgcolor: "success.dark",
                                    } 
                                }} 
                                onClick={() => uploidPhoto(true)}
                            >
                                <SaveIcon
                                    style={{
                                        color: 'white', 
                                        width: '20px', 
                                        height: '20px',
                                        marginRight: '6px'
                                    }}
                                />
                                {loading ? text.phload : text.valider}
                            </Button>
                        </Stack>
                    </Stack>
                </Box>
            </CustomPopup>

            <CustomPopup open={openDeleteG} onClose={() => setOpenDeleteG(false)}>
                <div style={{ padding: "18px 30px"}}>

                    <DialogTitle id="alert-dialog-title" sx={{textAlign: "center"}}>
                        <p style={{ fontWeight: "800", fontSize: "22px"}}>
                            Voulez-vous vraiment supprimer cette GIF ?
                        </p>
                        <img
                            src={`${baseURL}/gifs/${gifsToDelete.name}`}
                            style={{ width: '100px', height: '100px' }}
                            alt="gifs"
                        />
                    </DialogTitle>
                    <DialogActions>
                        <Button
                            variant='contained' 
                            sx={{ 
                                fontWeight: "700", 
                                bgcolor: "error.main",
                                color: "white",
                                "&:hover": {
                                    bgcolor: "error.dark",
                                } 
                            }} 
                            onClick={() => setOpenDeleteG(false)}
                        >
                            {text.annuler}
                        </Button>
                        <Button 
                            variant='contained' 
                            sx={{ 
                                fontWeight: "700", 
                                bgcolor: "success.main", 
                                color: "white",
                                "&:hover": {
                                    bgcolor: "success.dark",
                                } 
                            }} 
                            onClick={()=> handleDeleteGifs()}
                        >
                            {loading ? text.phload : text.vld}
                        </Button>
                    </DialogActions>
                </div>
            </CustomPopup>
            {/* pop up delete */}
            <CustomPopup open={openDelete} onClose={() => setOpenDelete(false)}>
                <div style={{ padding: "18px 30px"}}>

                    <DialogTitle id="alert-dialog-title" sx={{textAlign: "center"}}>
                        <p style={{ fontWeight: "800", fontSize: "22px"}}>
                            Voulez-vous vraiment supprimer cette GIFTS ?
                        </p>
                        <img
                            src={`${baseURL}/photorequests/${giftsToDelete.photoName}`}
                            style={{ width: '100px', height: '100px' }}
                            alt="gift"
                        />
                    </DialogTitle>
                    <DialogActions>
                        <Button
                            variant='contained' 
                            sx={{ 
                                fontWeight: "700", 
                                bgcolor: "error.main",
                                color: "white",
                                "&:hover": {
                                    bgcolor: "error.dark",
                                } 
                            }} 
                            onClick={() => setOpenDelete(false)}
                        >
                            {text.annuler}
                        </Button>
                        <Button 
                            variant='contained' 
                            sx={{ 
                                fontWeight: "700", 
                                bgcolor: "success.main", 
                                color: "white",
                                "&:hover": {
                                    bgcolor: "success.dark",
                                } 
                            }} 
                            onClick={()=> handleDeleteGifts()}
                        >
                            {loading ? text.phload : text.vld}
                        </Button>
                    </DialogActions>
                </div>
            </CustomPopup>
            <Snackbar
                open={open}
                autoHideDuration={3000}
                onClose={handleClose}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
                <Alert onClose={handleClose} severity="success">
                    {alert}
                </Alert>
            </Snackbar>
            <CustomAlert
                open={alertOpen}
                autoHideDuration={1500}
                onClose={() => setAlertOpen(false)}
                severity="success"
                message={alertContenu}
            />
            <Stack style={{display: 'flex', alignItems: 'center', gap: '15px', justifyContent: 'center', flexDirection: 'row'}}>
                {/* <Typography variant="h1" alignSelf='center' style={{fontFamily: 'Fascinate Regular', fontWeight: 'bold', color: '#ffc50f', textShadow: '3px 3px 3px #8adcb4'}}>
                    Gifts and GIFs
                </Typography> */}
                <Title>{text.gftgtfman}</Title>
                {/* <img className="card-img-resize-2" src={"/images/supervisor/12gi.png"} alt="" height='50px' width='50px'/> */}
            </Stack>

            {/* List of gifts requests */}
            <BigContainerAccordion 
                transparent
                summaryBg="#4caf50"
                summary={"List of requests Gifts"}
                details={
                    <Stack spacing={2}>
                        <SearchFilter
                            selectedDate={filter.date}
                            onDateChange={{
                                year: y => setFilter({...filter, year: y}),
                                month: m => setFilter({...filter, month: m}),
                                date: d => setFilter({...filter, date: d}),
                            }}
                            items= {[
                                { label: text.startingdate , comp: 'DatePicker', selected: filter.startingDate, onChange: d => setFilter({...filter, startingDate: d})},
                                { label: text.closingdate , comp: 'DatePicker', selected: filter.closingDate, onChange: d => setFilter({...filter, closingDate: d})},
                                { label: text.platform , comp: 'TextInput', onChange: e => setFilter({...filter, platforms: e.target.value}) },
                                { label: text.language, comp: 'Select', choices: languagesList, onChange: e => setFilter({ ...filter, language: e.target.value }) },
                                { label: text.phttp, comp: 'TextInput', onChange: e => setFilter({...filter, phototype: e.target.value}) }
                            ]}
                        />
                        <Stack spacing={2}>
                            <TableRow header columns={[
                                { xs: 1.4,  text: text.date},
                                { xs: 1.4,  text: text.platform},
                                { xs: 1.4,  text: text.idrq},
                                { xs: 1.4,  text: "Gifts type"},
                                { xs: 1.4,  text: text.fp},
                                { xs: 1.4,  text: text.rp},
                                { xs: 1.4,  text: text.agent},
                                { xs: 1.4,  text: "Gifts"},
                                // { xs: 1.2,  text: text.actions},
                            ]}/>
                            {
                                GiftsListes.map((pr, i) =>
                                    <TableRow key={`pr${i}`} columns={[
                                        { xs: 1.4,  text: pr.created_at},
                                        { xs: 1.4,  text: pr.platform.name},
                                        { xs: 1.4,  text: pr.request_id},
                                        { xs: 1.4,  text: pr.image_type},
                                        { xs: 1.4,  text: pr.fake_profil},
                                        { xs: 1.4,  text: pr.real_profil},
                                        { xs: 1.4,  text: pr.agent},
                                        {
                                            xs: 1.4,
                                            customContent: true,
                                            content: <Stack style={{width: '100%', height: '100%', textTransform: 'capitalize', color: '#000'}}>
                                                <Button onClick={() => { setGiftsToUploid(pr); setPopPhoto(true); }}>
                                                    <Typography style={{fontFamily: 'Delm'}}>{text.upld}</Typography>
                                                </Button>
                                            </Stack>
                                        },
                                        // {
                                        //     xs: 1.2,
                                        //     customContent: true,
                                        //     content: <Stack style={{width: '100%'}}>
                                        //         <Button style={{width: '100%', height: '100%', textTransform: 'capitalize', color: '#000'}}><Typography style={{fontFamily: 'Delm'}}>{text.edit}</Typography></Button>
                                        //         <Button onClick={() => { setDeleteData(pr); handleClickOpen() }} style={{width: '100%', height: '100%', textTransform: 'capitalize', color: '#000'}}><Typography style={{fontFamily: 'Delm'}}>{text.dlt}</Typography></Button>
                                        //         {/* <Button style={{width: '100%', height: '100%', textTransform: 'capitalize', color: '#000'}}><Typography style={{fontFamily: 'Delm'}}>{text.snd}</Typography></Button> */}
                                        //     </Stack>
                                        // },
                                    ]}/>
                                )
                            }
                        </Stack>
                    </Stack>
                }
            />

            {/* List of gifts */}
            <BigContainerAccordion 
                transparent
                summaryBg="#ff5722"
                summary={"List of Gifts"}
                details={
                    <Stack spacing={2}>
                        <SearchFilter
                            selectedDate={filter.date}
                            onDateChange={{
                                year: y => setFilter({...filter, year: y}),
                                month: m => setFilter({...filter, month: m}),
                                date: d => setFilter({...filter, date: d}),
                            }}
                            items= {[
                                { label: text.startingdate , comp: 'DatePicker', selected: filter.startingDate, onChange: d => setFilter({...filter, startingDate: d})},
                                { label: text.closingdate , comp: 'DatePicker', selected: filter.closingDate, onChange: d => setFilter({...filter, closingDate: d})},
                                { label: text.platform , comp: 'Select', choices:platformSelect, onChange: e => setFilter({...filter, platforms: e.target.value}) },
                                { label: text.language, comp: 'Select', choices: languagesList, onChange: e => setFilter({ ...filter, language: e.target.value }) },
                                { label: text.gftp , comp: 'Select', choices: gifTypes, onChange: g => setFilter({...filter, giftype: g}) },
                            ]}
                        />
                        <Grid container spacing={2}>
                            {gifts?.map((gift) => {
                                return (
                                    <Grid item xs={6} sm={4} md={3} lg={2} key={gift.id}>
                                        <div style={{ padding: '2px', position: 'relative', width: '100px', height: '100px' }}>
                                            <img
                                                src={`${baseURL}/photorequests/${gift.photoName}`}
                                                style={{ width: '100px', height: '100px' }}
                                                alt="gift"
                                            />
                                            <button
                                                style={{
                                                    position: 'absolute',
                                                    top: '0',
                                                    right: '0',
                                                    color: 'black',
                                                    border: 'none',
                                                    cursor: 'pointer',
                                                    fontWeight: '700'
                                                }}
                                                onClick={() => {setOpenDelete(true); setGiftsToDelete(gift)}}
                                            >
                                                X
                                            </button>
                                        </div>
                                    </Grid>
                                )
                            })}
                        </Grid>
                    </Stack>
                }
            />
            {/* Add new gifts */}
            <BigContainerAccordion 
                transparent
                summaryBg="#4527a0"
                summary={"Add new Gifts"}
                details={
                    <Stack spacing={2}>
                        <Stack sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                            <Button variant="contained" component="label">
                                <CloudUploadIcon
                                    style={{
                                        color: 'green', 
                                        width: '30px', 
                                        height: '30px',
                                        marginRight: '8px'
                                    }}
                                />
                                <p style={{ fontWeight: '800'}}>
                                    {text.uploadimage}
                                </p>
                                <input type="file" hidden multiple onChange={handleImageUpload} />
                            </Button>
                        </Stack>
                        <Stack sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                            <Stack sx={{ maxWidth: '500px'}}>
                                <Grid container spacing={2}>
                                    {imUrl.map((image, index) => (
                                        <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
                                            <Box
                                                component="img"
                                                src={image}
                                                alt={`Uploaded ${index}`}
                                                sx={{ width: '100px', height: '100px' }}
                                            />
                                        </Grid>
                                    ))}
                                </Grid>
                            </Stack>
                        </Stack>
                        <Stack sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                            <Stack sx={{ mt: "40px", flexDirection: "row", gap: "20px"}}>
                                <Button 
                                    variant='contained' 
                                    sx={{ 
                                        fontWeight: "600", 
                                        bgcolor: "error.main",
                                        color: "white",
                                        "&:hover": {
                                            bgcolor: "error.dark",
                                        }
                                    }}
                                    onClick={() => { setImUrl([]); setImages([]) }}
                                >
                                    {text.restfn}
                                </Button>
                                <Button 
                                    variant='contained' 
                                    sx={{ 
                                        fontWeight: "700", 
                                        bgcolor: "success.main", 
                                        color: "white",
                                        "&:hover": {
                                            bgcolor: "success.dark",
                                        } 
                                    }} 
                                    onClick={() => uploidPhoto(false)}
                                >
                                    <SaveIcon
                                        style={{
                                            color: 'white', 
                                            width: '20px', 
                                            height: '20px',
                                            marginRight: '6px'
                                        }}
                                    />
                                    {loading ? text.phload : text.valider}
                                </Button>
                            </Stack>
                        </Stack>
                    </Stack>
                }
            />

            {/* List of gifs */}
            <BigContainerAccordion 
                transparent
                summaryBg="#1e3d58"
                summary={text.lstgfs}
                details={
                    <Stack spacing={2}>
                        <SearchFilter
                            selectedDate={filter.date}
                            onDateChange={{
                                year: y => setFilter({...filter, year: y}),
                                month: m => setFilter({...filter, month: m}),
                                date: d => setFilter({...filter, date: d}),
                            }}
                            items= {[
                                { label: text.startingdate , comp: 'DatePicker', selected: filter.startingDate, onChange: d => setFilter({...filter, startingDate: d})},
                                { label: text.closingdate , comp: 'DatePicker', selected: filter.closingDate, onChange: d => setFilter({...filter, closingDate: d})},
                                { label: text.platform , comp: 'Select', choices:platformSelect, onChange: e => setFilter({...filter, platforms: e.target.value}) },
                                { label: text.language, comp: 'Select', choices: languagesList, onChange: e => setFilter({ ...filter, language: e.target.value }) },
                                { label: text.gftp , comp: 'Select', choices: gifTypes, onChange: g => setFilter({...filter, giftype: g}) },
                            ]}
                        />
                        <BigContainerAccordion 
                            transparent
                            summaryBg="#de5b6d"
                            summary={text.gftp.toUpperCase()}
                            level={2}
                            details={
                                <Stack>
                                    <BigContainerAccordion 
                transparent
                                        level={3}
                                        summaryBg="#63747a"
                                        summary={text.wiseg}
                                        style={{ border: '1px solid #000' }}
                                        details={
                                            <Stack>
                                                <BigContainerAccordion 
                transparent
                                                    level={4}
                                                    summaryBg="#004aad"
                                                    summary={text.french}
                                                    details={
                                                        <Grid container spacing={2}>
                                                            {filteredGif?.map((gif) => {
                                                                if(gif.type === "wig" && gif.typeoption === "fr"){
                                                                    return (
                                                                        <Grid item xs={6} sm={4} md={3} lg={2} key={gif.id}>
                                                                            <div style={{ padding: '0px', position: 'relative', fontWeight: "600", width: '100px'}}>
                                                                                <img src={ `${baseURL}/gifs/${gif.name}`} style={{ width: "100px", height: "100px"}} alt="gif"/>
                                                                                <button
                                                                                    style={{
                                                                                        position: 'absolute',
                                                                                        top: '0',
                                                                                        right: '0',
                                                                                        color: 'black',
                                                                                        border: 'none',
                                                                                        cursor: 'pointer',
                                                                                        fontWeight: '700'
                                                                                    }}
                                                                                    onClick={() => {setOpenDeleteG(true); setGifsToDelete(gif)}}
                                                                                >
                                                                                    X
                                                                                </button>
                                                                            </div>
                                                                        </Grid>
                                                                    )
                                                                }
                                                            })}
                                                        </Grid>
                                                    }
                                                />
                                                <BigContainerAccordion 
                transparent
                                                    level={4}
                                                    summaryBg="#ff3131"
                                                    summary={text.english}
                                                    details={
                                                        <Grid container spacing={2}>
                                                            {filteredGif?.map((gif) => {
                                                                if(gif.type === "wig" && gif.typeoption === "en"){
                                                                    return (
                                                                        <Grid item xs={6} sm={4} md={3} lg={2} key={gif.id}>
                                                                            <div style={{ padding: '0px', position: 'relative', fontWeight: "600", width: '100px'}}>
                                                                                <img src={ `${baseURL}/gifs/${gif.name}`} style={{ width: "100px", height: "100px"}} alt="gif"/>
                                                                                <button
                                                                                    style={{
                                                                                        position: 'absolute',
                                                                                        top: '0',
                                                                                        right: '0',
                                                                                        color: 'black',
                                                                                        border: 'none',
                                                                                        cursor: 'pointer',
                                                                                        fontWeight: '700'
                                                                                    }}
                                                                                    onClick={() => {setOpenDeleteG(true); setGifsToDelete(gif)}}
                                                                                >
                                                                                    X
                                                                                </button>
                                                                            </div>
                                                                        </Grid>
                                                                    )
                                                                }
                                                            })}
                                                        </Grid>
                                                    }
                                                />
                                                <BigContainerAccordion 
                transparent
                                                    level={4}
                                                    summaryBg="#1cb743"
                                                    summary={text.italian}
                                                    details={
                                                        <Grid container spacing={2}>
                                                            {filteredGif?.map((gif) => {
                                                                if(gif.type === "wig" && gif.typeoption === "it"){
                                                                    return (
                                                                        <Grid item xs={6} sm={4} md={3} lg={2} key={gif.id}>
                                                                            <div style={{ padding: '0px', position: 'relative', fontWeight: "600", width: '100px'}}>
                                                                                <img src={ `${baseURL}/gifs/${gif.name}`} style={{ width: "100px", height: "100px"}} alt="gif"/>
                                                                                <button
                                                                                    style={{
                                                                                        position: 'absolute',
                                                                                        top: '0',
                                                                                        right: '0',
                                                                                        color: 'black',
                                                                                        border: 'none',
                                                                                        cursor: 'pointer',
                                                                                        fontWeight: '700'
                                                                                    }}
                                                                                    onClick={() => {setOpenDeleteG(true); setGifsToDelete(gif)}}
                                                                                >
                                                                                    X
                                                                                </button>
                                                                            </div>
                                                                        </Grid>
                                                                    )
                                                                }
                                                            })}
                                                        </Grid>
                                                    }
                                                />
                                                <BigContainerAccordion 
                transparent
                                                    level={4}
                                                    summaryBg="#de5b6d"
                                                    summary={text.dutch}
                                                    details={
                                                        <Grid container spacing={2}>
                                                            {filteredGif?.map((gif) => {
                                                                if(gif.type === "wig" && gif.typeoption === "nl"){
                                                                    return (
                                                                        <Grid item xs={6} sm={4} md={3} lg={2} key={gif.id}>
                                                                            <div style={{ padding: '0px', position: 'relative', fontWeight: "600", width: '100px'}}>
                                                                                <img src={ `${baseURL}/gifs/${gif.name}`} style={{ width: "100px", height: "100px"}} alt="gif"/>
                                                                                <button
                                                                                    style={{
                                                                                        position: 'absolute',
                                                                                        top: '0',
                                                                                        right: '0',
                                                                                        color: 'black',
                                                                                        border: 'none',
                                                                                        cursor: 'pointer',
                                                                                        fontWeight: '700'
                                                                                    }}
                                                                                    onClick={() => {setOpenDeleteG(true); setGifsToDelete(gif)}}
                                                                                >
                                                                                    X
                                                                                </button>
                                                                            </div>
                                                                        </Grid>
                                                                    )
                                                                }
                                                            })}
                                                        </Grid>
                                                    }
                                                />
                                                <BigContainerAccordion 
                transparent
                                                    level={4}
                                                    summaryBg="#f4a100"
                                                    summary={text.spanish}
                                                    details={
                                                        <Grid container spacing={2}>
                                                            {filteredGif?.map((gif) => {
                                                                if(gif.type === "wig" && gif.typeoption === "es"){
                                                                    return (
                                                                        <Grid item xs={6} sm={4} md={3} lg={2} key={gif.id}>
                                                                            <div style={{ padding: '0px', position: 'relative', fontWeight: "600", width: '100px'}}>
                                                                                <img src={ `${baseURL}/gifs/${gif.name}`} style={{ width: "100px", height: "100px"}} alt="gif"/>
                                                                                <button
                                                                                    style={{
                                                                                        position: 'absolute',
                                                                                        top: '0',
                                                                                        right: '0',
                                                                                        color: 'black',
                                                                                        border: 'none',
                                                                                        cursor: 'pointer',
                                                                                        fontWeight: '700'
                                                                                    }}
                                                                                    onClick={() => {setOpenDeleteG(true); setGifsToDelete(gif)}}
                                                                                >
                                                                                    X
                                                                                </button>
                                                                            </div>
                                                                        </Grid>
                                                                    )
                                                                }
                                                            })}
                                                        </Grid>
                                                    }
                                                />
                                                <BigContainerAccordion transparent
                                                    level={4}
                                                    summaryBg="#000000"
                                                    summary={text.german}
                                                    details={
                                                        <Grid container spacing={2}>
                                                            {filteredGif?.map((gif) => {
                                                                if(gif.type === "wig" && gif.typeoption === "de"){
                                                                    return (
                                                                        <Grid item xs={6} sm={4} md={3} lg={2} key={gif.id}>
                                                                            <div style={{ padding: '0px', position: 'relative', fontWeight: "600", width: '100px'}}>
                                                                                <img src={ `${baseURL}/gifs/${gif.name}`} style={{ width: "100px", height: "100px"}} alt="gif"/>
                                                                                <button
                                                                                    style={{
                                                                                        position: 'absolute',
                                                                                        top: '0',
                                                                                        right: '0',
                                                                                        color: 'black',
                                                                                        border: 'none',
                                                                                        cursor: 'pointer',
                                                                                        fontWeight: '700'
                                                                                    }}
                                                                                    onClick={() => {setOpenDeleteG(true); setGifsToDelete(gif)}}
                                                                                >
                                                                                    X
                                                                                </button>
                                                                            </div>
                                                                        </Grid>
                                                                    )
                                                                }
                                                            })}
                                                        </Grid>
                                                    }
                                                />
                                            </Stack>
                                        }
                                    />
                                    <BigContainerAccordion transparent
                                        level={3}
                                        summaryBg="#ff3131"
                                        summary={text.sexg}
                                        details={
                                            <Stack>
                                                <BigContainerAccordion transparent
                                                    level={4}
                                                    summaryBg="#004aad"
                                                    summary={text.bdsm}
                                                    details={
                                                        <Grid container spacing={2}>
                                                            {filteredGif?.map((gif) => {
                                                                if(gif.type === "seg" && gif.typeoption === "bdsm"){
                                                                    return (
                                                                        <Grid item xs={6} sm={4} md={3} lg={2} key={gif.id}>
                                                                            <div style={{ padding: '0px', position: 'relative', fontWeight: "600", width: '100px'}}>
                                                                                <img src={ `${baseURL}/gifs/${gif.name}`} style={{ width: "100px", height: "100px"}} alt="gif"/>
                                                                                <button
                                                                                    style={{
                                                                                        position: 'absolute',
                                                                                        top: '0',
                                                                                        right: '0',
                                                                                        color: 'black',
                                                                                        border: 'none',
                                                                                        cursor: 'pointer',
                                                                                        fontWeight: '700'
                                                                                    }}
                                                                                    onClick={() => {setOpenDeleteG(true); setGifsToDelete(gif)}}
                                                                                >
                                                                                    X
                                                                                </button>
                                                                            </div>
                                                                        </Grid>
                                                                    )
                                                                }
                                                            })}
                                                        </Grid>
                                                    }
                                                />
                                                <BigContainerAccordion transparent
                                                    level={4}
                                                    summaryBg="#ff3131"
                                                    summary={text.dont}
                                                    details={
                                                        <Grid container spacing={2}>
                                                            {filteredGif?.map((gif) => {
                                                                if(gif.type === "seg" && gif.typeoption === "dont"){
                                                                    return (
                                                                        <Grid item xs={6} sm={4} md={3} lg={2} key={gif.id}>
                                                                            <div style={{ padding: '0px', position: 'relative', fontWeight: "600", width: '100px'}}>
                                                                                <img src={ `${baseURL}/gifs/${gif.name}`} style={{ width: "100px", height: "100px"}} alt="gif"/>
                                                                                <button
                                                                                    style={{
                                                                                        position: 'absolute',
                                                                                        top: '0',
                                                                                        right: '0',
                                                                                        color: 'black',
                                                                                        border: 'none',
                                                                                        cursor: 'pointer',
                                                                                        fontWeight: '700'
                                                                                    }}
                                                                                    onClick={() => {setOpenDeleteG(true); setGifsToDelete(gif)}}
                                                                                >
                                                                                    X
                                                                                </button>
                                                                            </div>
                                                                        </Grid>
                                                                    )
                                                                }
                                                            })}
                                                        </Grid>
                                                    }
                                                />
                                                <BigContainerAccordion transparent
                                                    level={4}
                                                    summaryBg="#1cb743"
                                                    summary={text.blj}
                                                    details={
                                                        <Grid container spacing={2}>
                                                            {filteredGif?.map((gif) => {
                                                                if(gif.type === "seg" && gif.typeoption === "blj"){
                                                                    return (
                                                                        <Grid item xs={6} sm={4} md={3} lg={2} key={gif.id}>
                                                                            <div style={{ padding: '0px', position: 'relative', fontWeight: "600", width: '100px'}}>
                                                                                <img src={ `${baseURL}/gifs/${gif.name}`} style={{ width: "100px", height: "100px"}} alt="gif"/>
                                                                                <button
                                                                                    style={{
                                                                                        position: 'absolute',
                                                                                        top: '0',
                                                                                        right: '0',
                                                                                        color: 'black',
                                                                                        border: 'none',
                                                                                        cursor: 'pointer',
                                                                                        fontWeight: '700'
                                                                                    }}
                                                                                    onClick={() => {setOpenDeleteG(true); setGifsToDelete(gif)}}
                                                                                >
                                                                                    X
                                                                                </button>
                                                                            </div>
                                                                        </Grid>
                                                                    )
                                                                }
                                                            })}
                                                        </Grid>
                                                    }
                                                />
                                                <BigContainerAccordion transparent
                                                    level={4}
                                                    summaryBg="#de5b6d"
                                                    summary={text.org}
                                                    details={
                                                        <Grid container spacing={2}>
                                                            {filteredGif?.map((gif) => {
                                                                if(gif.type === "seg" && gif.typeoption === "org"){
                                                                    return (
                                                                        <Grid item xs={6} sm={4} md={3} lg={2} key={gif.id}>
                                                                            <div style={{ padding: '0px', position: 'relative', fontWeight: "600", width: '100px'}}>
                                                                                <img src={ `${baseURL}/gifs/${gif.name}`} style={{ width: "100px", height: "100px"}} alt="gif"/>
                                                                                <button
                                                                                    style={{
                                                                                        position: 'absolute',
                                                                                        top: '0',
                                                                                        right: '0',
                                                                                        color: 'black',
                                                                                        border: 'none',
                                                                                        cursor: 'pointer',
                                                                                        fontWeight: '700'
                                                                                    }}
                                                                                    onClick={() => {setOpenDeleteG(true); setGifsToDelete(gif)}}
                                                                                >
                                                                                    X
                                                                                </button>
                                                                            </div>
                                                                        </Grid>
                                                                    )
                                                                }
                                                            })}
                                                        </Grid>
                                                    }
                                                />
                                                <BigContainerAccordion transparent 
                                                    level={4}
                                                    summaryBg="#f4a100"
                                                    summary={text.vagpen}
                                                    details={
                                                        <Grid container spacing={2}>
                                                            {filteredGif?.map((gif) => {
                                                                if(gif.type === "seg" && gif.typeoption === "vagpen"){
                                                                    return (
                                                                        <Grid item xs={6} sm={4} md={3} lg={2} key={gif.id}>
                                                                            <div style={{ padding: '0px', position: 'relative', fontWeight: "600", width: '100px'}}>
                                                                                <img src={ `${baseURL}/gifs/${gif.name}`} style={{ width: "100px", height: "100px"}} alt="gif"/>
                                                                                <button
                                                                                    style={{
                                                                                        position: 'absolute',
                                                                                        top: '0',
                                                                                        right: '0',
                                                                                        color: 'black',
                                                                                        border: 'none',
                                                                                        cursor: 'pointer',
                                                                                        fontWeight: '700'
                                                                                    }}
                                                                                    onClick={() => {setOpenDeleteG(true); setGifsToDelete(gif)}}
                                                                                >
                                                                                    X
                                                                                </button>
                                                                            </div>
                                                                        </Grid>
                                                                    )
                                                                }
                                                            })}
                                                        </Grid>
                                                    }
                                                />
                                                <BigContainerAccordion transparent 
                                                    level={4}
                                                    summaryBg="#000000"
                                                    summary={text.sod}
                                                    details={
                                                        <Grid container spacing={2}>
                                                            {filteredGif?.map((gif) => {
                                                                if(gif.type === "seg" && gif.typeoption === "sod"){
                                                                    return (
                                                                        <Grid item xs={6} sm={4} md={3} lg={2} key={gif.id}>
                                                                            <div style={{ padding: '0px', position: 'relative', fontWeight: "600", width: '100px'}}>
                                                                                <img src={ `${baseURL}/gifs/${gif.name}`} style={{ width: "100px", height: "100px"}} alt="gif"/>
                                                                                <button
                                                                                    style={{
                                                                                        position: 'absolute',
                                                                                        top: '0',
                                                                                        right: '0',
                                                                                        color: 'black',
                                                                                        border: 'none',
                                                                                        cursor: 'pointer',
                                                                                        fontWeight: '700'
                                                                                    }}
                                                                                    onClick={() => {setOpenDeleteG(true); setGifsToDelete(gif)}}
                                                                                >
                                                                                    X
                                                                                </button>
                                                                            </div>
                                                                        </Grid>
                                                                    )
                                                                }
                                                            })}
                                                        </Grid>
                                                    }
                                                />
                                                <BigContainerAccordion transparent 
                                                    level={4}
                                                    summaryBg="#64483b"
                                                    summary={text.titss}
                                                    details={
                                                        <Grid container spacing={2}>
                                                            {filteredGif?.map((gif) => {
                                                                if(gif.type === "seg" && gif.typeoption === "titss"){
                                                                    return (
                                                                        <Grid item xs={6} sm={4} md={3} lg={2} key={gif.id}>
                                                                            <div style={{ padding: '0px', position: 'relative', fontWeight: "600", width: '100px'}}>
                                                                                <img src={ `${baseURL}/gifs/${gif.name}`} style={{ width: "100px", height: "100px"}} alt="gif"/>
                                                                                <button
                                                                                    style={{
                                                                                        position: 'absolute',
                                                                                        top: '0',
                                                                                        right: '0',
                                                                                        color: 'black',
                                                                                        border: 'none',
                                                                                        cursor: 'pointer',
                                                                                        fontWeight: '700'
                                                                                    }}
                                                                                    onClick={() => {setOpenDeleteG(true); setGifsToDelete(gif)}}
                                                                                >
                                                                                    X
                                                                                </button>
                                                                            </div>
                                                                        </Grid>
                                                                    )
                                                                }
                                                            })}
                                                        </Grid>
                                                    }
                                                />
                                            </Stack>
                                        }
                                    />
                                    <BigContainerAccordion transparent 
                                        level={3}
                                        summaryBg="#5271ff"
                                        summary={text.gayg}
                                        details={
                                            <Stack>
                                                <BigContainerAccordion transparent 
                                                    level={4}
                                                    summaryBg="#004aad"
                                                    summary={text.bdsmg}
                                                    details={
                                                        <Grid container spacing={2}>
                                                            {filteredGif?.map((gif) => {
                                                                if(gif.type === "gag" && gif.typeoption === "bdsmg"){
                                                                    return (
                                                                        <Grid item xs={6} sm={4} md={3} lg={2} key={gif.id}>
                                                                            <div style={{ padding: '0px', position: 'relative', fontWeight: "600", width: '100px'}}>
                                                                                <img src={ `${baseURL}/gifs/${gif.name}`} style={{ width: "100px", height: "100px"}} alt="gif"/>
                                                                                <button
                                                                                    style={{
                                                                                        position: 'absolute',
                                                                                        top: '0',
                                                                                        right: '0',
                                                                                        color: 'black',
                                                                                        border: 'none',
                                                                                        cursor: 'pointer',
                                                                                        fontWeight: '700'
                                                                                    }}
                                                                                    onClick={() => {setOpenDeleteG(true); setGifsToDelete(gif)}}
                                                                                >
                                                                                    X
                                                                                </button>
                                                                            </div>
                                                                        </Grid>
                                                                    )
                                                                }
                                                            })}
                                                        </Grid>
                                                    }
                                                />
                                                <BigContainerAccordion transparent 
                                                    level={4}
                                                    summaryBg="#ff3131"
                                                    summary={text.wisegg}
                                                    details={
                                                        <Grid container spacing={2}>
                                                            {filteredGif?.map((gif) => {
                                                                if(gif.type === "gag" && gif.typeoption === "wisegg"){
                                                                    return (
                                                                        <Grid item xs={6} sm={4} md={3} lg={2} key={gif.id}>
                                                                            <div style={{ padding: '0px', position: 'relative', fontWeight: "600", width: '100px'}}>
                                                                                <img src={ `${baseURL}/gifs/${gif.name}`} style={{ width: "100px", height: "100px"}} alt="gif"/>
                                                                                <button
                                                                                    style={{
                                                                                        position: 'absolute',
                                                                                        top: '0',
                                                                                        right: '0',
                                                                                        color: 'black',
                                                                                        border: 'none',
                                                                                        cursor: 'pointer',
                                                                                        fontWeight: '700'
                                                                                    }}
                                                                                    onClick={() => {setOpenDeleteG(true); setGifsToDelete(gif)}}
                                                                                >
                                                                                    X
                                                                                </button>
                                                                            </div>
                                                                        </Grid>
                                                                    )
                                                                }
                                                            })}
                                                        </Grid>
                                                    }
                                                />
                                                <BigContainerAccordion transparent 
                                                    level={4}
                                                    summaryBg="#1cb743"
                                                    summary={text.sexgg}
                                                    details={
                                                        <Grid container spacing={2}>
                                                            {filteredGif?.map((gif) => {
                                                                if(gif.type === "gag" && gif.typeoption === "sexgg"){
                                                                    return (
                                                                        <Grid item xs={6} sm={4} md={3} lg={2} key={gif.id}>
                                                                            <div style={{ padding: '0px', position: 'relative', fontWeight: "600", width: '100px'}}>
                                                                                <img src={ `${baseURL}/gifs/${gif.name}`} style={{ width: "100px", height: "100px"}} alt="gif"/>
                                                                                <button
                                                                                    style={{
                                                                                        position: 'absolute',
                                                                                        top: '0',
                                                                                        right: '0',
                                                                                        color: 'black',
                                                                                        border: 'none',
                                                                                        cursor: 'pointer',
                                                                                        fontWeight: '700'
                                                                                    }}
                                                                                    onClick={() => {setOpenDeleteG(true); setGifsToDelete(gif)}}
                                                                                >
                                                                                    X
                                                                                </button>
                                                                            </div>
                                                                        </Grid>
                                                                    )
                                                                }
                                                            })}
                                                        </Grid>
                                                    }
                                                />                                            
                                            </Stack>
                                        }
                                    />
                                    <BigContainerAccordion transparent 
                                        level={3}
                                        summaryBg="linear-gradient(to right, #ffdc58, #ff924d)"
                                        summary={text.Lesbg}
                                        details={
                                            <Stack>
                                                <BigContainerAccordion transparent 
                                                    level={4}
                                                    summaryBg="#f4a100"
                                                    summary={text.bdsml}
                                                    details={
                                                        <Grid container spacing={2}>
                                                            {filteredGif?.map((gif) => {
                                                                if(gif.type === "leg" && gif.typeoption === "bdsml"){
                                                                    return (
                                                                        <Grid item xs={6} sm={4} md={3} lg={2} key={gif.id}>
                                                                            <div style={{ padding: '0px', position: 'relative', fontWeight: "600", width: '100px'}}>
                                                                                <img src={ `${baseURL}/gifs/${gif.name}`} style={{ width: "100px", height: "100px"}} alt="gif"/>
                                                                                <button
                                                                                    style={{
                                                                                        position: 'absolute',
                                                                                        top: '0',
                                                                                        right: '0',
                                                                                        color: 'black',
                                                                                        border: 'none',
                                                                                        cursor: 'pointer',
                                                                                        fontWeight: '700'
                                                                                    }}
                                                                                    onClick={() => {setOpenDeleteG(true); setGifsToDelete(gif)}}
                                                                                >
                                                                                    X
                                                                                </button>
                                                                            </div>
                                                                        </Grid>
                                                                    )
                                                                }
                                                            })}
                                                        </Grid>
                                                    }
                                                />
                                                <BigContainerAccordion transparent 
                                                    level={4}
                                                    summaryBg="#000000"
                                                    summary={text.wiselg}
                                                    details={
                                                        <Grid container spacing={2}>
                                                            {filteredGif?.map((gif) => {
                                                                if(gif.type === "leg" && gif.typeoption === "wiselg"){
                                                                    return (
                                                                        <Grid item xs={6} sm={4} md={3} lg={2} key={gif.id}>
                                                                            <div style={{ padding: '0px', position: 'relative', fontWeight: "600", width: '100px'}}>
                                                                                <img src={ `${baseURL}/gifs/${gif.name}`} style={{ width: "100px", height: "100px"}} alt="gif"/>
                                                                                <button
                                                                                    style={{
                                                                                        position: 'absolute',
                                                                                        top: '0',
                                                                                        right: '0',
                                                                                        color: 'black',
                                                                                        border: 'none',
                                                                                        cursor: 'pointer',
                                                                                        fontWeight: '700'
                                                                                    }}
                                                                                    onClick={() => {setOpenDeleteG(true); setGifsToDelete(gif)}}
                                                                                >
                                                                                    X
                                                                                </button>
                                                                            </div>
                                                                        </Grid>
                                                                    )
                                                                }
                                                            })}
                                                        </Grid>
                                                    }
                                                />
                                                <BigContainerAccordion transparent 
                                                    level={4}
                                                    summaryBg="#64483b"
                                                    summary={text.sexlg}
                                                    details={
                                                        <Grid container spacing={2}>
                                                            {filteredGif?.map((gif) => {
                                                                if(gif.type === "leg" && gif.typeoption === "sexlg"){
                                                                    return (
                                                                        <Grid item xs={6} sm={4} md={3} lg={2} key={gif.id}>
                                                                            <div style={{ padding: '0px', position: 'relative', fontWeight: "600", width: '100px'}}>
                                                                                <img src={ `${baseURL}/gifs/${gif.name}`} style={{ width: "100px", height: "100px"}} alt="gif"/>
                                                                                <button
                                                                                    style={{
                                                                                        position: 'absolute',
                                                                                        top: '0',
                                                                                        right: '0',
                                                                                        color: 'black',
                                                                                        border: 'none',
                                                                                        cursor: 'pointer',
                                                                                        fontWeight: '700'
                                                                                    }}
                                                                                    onClick={() => {setOpenDeleteG(true); setGifsToDelete(gif)}}
                                                                                >
                                                                                    X
                                                                                </button>
                                                                            </div>
                                                                        </Grid>
                                                                    )
                                                                }
                                                            })}
                                                        </Grid>
                                                    }
                                                />
                                            </Stack>
                                        }
                                    />
                                    <BigContainerAccordion transparent 
                                        level={3}
                                        summaryBg="#ff924d"
                                        summary={text.transg}
                                        details={
                                            <Stack>
                                                <BigContainerAccordion transparent 
                                                    level={4}
                                                    summaryBg="#004aad"
                                                    summary={text.maletrans}
                                                    details={
                                                        <Grid container spacing={2}>
                                                            {filteredGif?.map((gif) => {
                                                                if(gif.type === "tsg" && gif.typeoption === "maletrans"){
                                                                    return (
                                                                        <Grid item xs={6} sm={4} md={3} lg={2} key={gif.id}>
                                                                            <div style={{ padding: '0px', position: 'relative', fontWeight: "600", width: '100px'}}>
                                                                                <img src={ `${baseURL}/gifs/${gif.name}`} style={{ width: "100px", height: "100px"}} alt="gif"/>
                                                                                <button
                                                                                    style={{
                                                                                        position: 'absolute',
                                                                                        top: '0',
                                                                                        right: '0',
                                                                                        color: 'black',
                                                                                        border: 'none',
                                                                                        cursor: 'pointer',
                                                                                        fontWeight: '700'
                                                                                    }}
                                                                                    onClick={() => {setOpenDeleteG(true); setGifsToDelete(gif)}}
                                                                                >
                                                                                    X
                                                                                </button>
                                                                            </div>
                                                                        </Grid>
                                                                    )
                                                                }
                                                            })}
                                                        </Grid>
                                                    }
                                                />
                                                <BigContainerAccordion transparent 
                                                    level={4}
                                                    summaryBg="#ff3131"
                                                    summary={text.femaletrans}
                                                    details={
                                                        <Grid container spacing={2}>
                                                            {filteredGif?.map((gif) => {
                                                                if(gif.type === "tsg" && gif.typeoption === "femaletrans"){
                                                                    return (
                                                                        <Grid item xs={6} sm={4} md={3} lg={2} key={gif.id}>
                                                                            <div style={{ padding: '0px', position: 'relative', fontWeight: "600", width: '100px'}}>
                                                                                <img src={ `${baseURL}/gifs/${gif.name}`} style={{ width: "100px", height: "100px"}} alt="gif"/>
                                                                                <button
                                                                                    style={{
                                                                                        position: 'absolute',
                                                                                        top: '0',
                                                                                        right: '0',
                                                                                        color: 'black',
                                                                                        border: 'none',
                                                                                        cursor: 'pointer',
                                                                                        fontWeight: '700'
                                                                                    }}
                                                                                    onClick={() => {setOpenDeleteG(true); setGifsToDelete(gif)}}
                                                                                >
                                                                                    X
                                                                                </button>
                                                                            </div>
                                                                        </Grid>
                                                                    )
                                                                }
                                                            })}
                                                        </Grid>
                                                    }
                                                />
                                            </Stack>
                                        }
                                    />
                                    <BigContainerAccordion transparent 
                                        level={3}
                                        summaryBg="#7ed957"
                                        summary={text.travg}
                                        details={
                                            <Grid container spacing={2}>
                                                {filteredGif?.map((gif) => {
                                                    if(gif.type === "tvg" && gif.typeoption === "femaletranst"){
                                                        return (
                                                            <Grid item xs={6} sm={4} md={3} lg={2} key={gif.id}>
                                                                <div style={{ padding: '0px', position: 'relative', fontWeight: "600", width: '100px'}}>
                                                                    <img src={ `${baseURL}/gifs/${gif.name}`} style={{ width: "100px", height: "100px"}} alt="gif"/>
                                                                    <button
                                                                        style={{
                                                                            position: 'absolute',
                                                                            top: '0',
                                                                            right: '0',
                                                                            color: 'black',
                                                                            border: 'none',
                                                                            cursor: 'pointer',
                                                                            fontWeight: '700'
                                                                        }}
                                                                        onClick={() => {setOpenDeleteG(true); setGifsToDelete(gif)}}
                                                                    >
                                                                        X
                                                                    </button>
                                                                </div>
                                                            </Grid>
                                                        )
                                                    }
                                                })}
                                            </Grid>
                                        }
                                    />
                                </Stack>
                            }
                        />
                    </Stack>
                }
            />

            {/* Add gif */}
            <BigContainerAccordion 
                transparent
                summaryBg="#057dcd"
                summary={text.ddgfs}
                details={
                    <Stack spacing={2}>
                        <FormRow label={text.platform} comp='Select' options={platformSelect} control={control} name="platform" error={errors.platform}/>
                        <FormRow label={text.gftp} comp="Select" options={gifTypes} control={control} name="giftype" error={errors.giftype} />
                        <FormRow label={text.option} comp="Select" options={gifTypeList} control={control} name="typeoption" error={errors.language} />
                        <FormRow label={text.gfimg} comp="Gif" control={control} name="gifimage" error={errors.gifimage} onExportFile={setGif}/>                      
                        { gif?.name ? <Box component='img' sx={{height: '200px', width: '200px'}} src={URL.createObjectURL(gif)}/> : <></>}
                        <Stack style={{width: '85%', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end'}}>
                            <Button onClick={handleSubmit(newGif)} variant="formrow">
                                <Typography>
                                    {text.save}
                                </Typography>
                            </Button>
                        </Stack>
                    </Stack>
                }
            />
        </Stack>
    )
}

export default Gifs