import React, { useState, useEffect } from "react";
import { Stack, Typography, Button, Checkbox } from "@mui/material";
import useAuth from "../hooks/useAuth";
import useLanguage from "../hooks/useLanguage";
import { useForm, useWatch } from 'react-hook-form'
import AuthFormRow from "../tools/AuthFormRow";
import useData from "../hooks/useData";
import { Link } from "react-router-dom";
import RoutesList from "../../helper/routes"
import { useNavigate } from 'react-router-dom'
import Pusher from 'pusher-js'
import { PUSHER_SECRET, CLUSTER, apiURL } from "../../helper/conf";

const Auth = () => {
    const { error, login } = useAuth()
    const { control, handleSubmit, formState: { errors }} = useForm()
    const values = useWatch({control})
    const { text } = useLanguage()
    const { roleList } = useData()
    const navigate = useNavigate()

    const [pp, setPP] = useState(false)
    const [ppError, setPPError] = useState(false)

    const onSubmit = data => {
        setPPError(!pp)
        if (pp) login(data)
    }
    
    return (
        <Stack alignItems={'center'} display={'flex'} justifyContent={'center'}
            variant="maroongradientbg" height='100vh'>

            {/* <Button onClick={()=>buttonTrigger()}>Trigger</Button> */}
            <img src="/images/HeaderImage/logo.png" alt="" height='150px' width='150px' />
            <Stack alignItems={'center'} spacing={3} className="width-resize">
                <Typography>{error}</Typography>
                <AuthFormRow label={text.role} comp="Select" options={roleList} name='role' control={control} error={errors.role} />
                <AuthFormRow label={text.login} name='mail' control={control} error={errors.mail} />
                {/* <AuthFormRow label="mailAdmin" name='mailAdmin' control={control} error={errors.mail} /> */}
                <AuthFormRow label={text.password} comp="Password" name='password' control={control} error={errors.password} />
                <Stack flexDirection='row'>
                    <Checkbox onClick={() => setPP(!pp)} sx={{ color: ppError ? '#c00' : '#555' }} />
                    {
                        (values?.role === "administrator")
                        ? <Typography>{text.ppaccept1adm} <Link to={RoutesList.ppAdmin} target="blank" className="link1">{text.ppadm}</Link> {text.ppaccept2adm}</Typography>
                        : <Typography>{text.ppaccept1} <Link to={RoutesList.pp} target="blank" className="link1">{text.pp}</Link> {text.ppaccept2}</Typography>
                    }
                </Stack>
                <Button variant="login" onClick={handleSubmit(onSubmit)}>{text.logIn}</Button>
                {/* <Stack alignItems={'center'} flexDirection={'row'}>
                    <Button sx={{ fontFamily: '' }} onClick={() => { navigate(RoutesList.register) }}>{text.enreg[0] + ' ' + text.enreg[1]}</Button>
                </Stack> */}
            </Stack>
            
            <Typography position='absolute' bottom='5px'>{text.copyright}</Typography>
        </Stack>
    )
}

export default Auth;