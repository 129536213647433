import React, { useEffect, useReducer } from "react";
import RoutesList from "../../helper/routes"
import { useLocation } from 'react-router-dom';
import useLanguage from '../hooks/useLanguage'

const useDynamicTitle = ()=>{
    const location = useLocation()
    const { currentLanguage, text } = useLanguage()
    const suffix = ' - TextModing'

    const reducer = (state , action) =>{
        const { login,reg, operatorpage, supervisorpage, adminpage, qualitycontroller, accountant, stat, pp } = text
        switch ( action.type || location.pathname){
            case RoutesList.auth + currentLanguage: return login + suffix
            case RoutesList.register + currentLanguage : return reg + suffix
            case RoutesList.operator + currentLanguage: return operatorpage + suffix
            case RoutesList.supervisor + currentLanguage: return supervisorpage + suffix
            case RoutesList.admin + currentLanguage: return adminpage + suffix
            case RoutesList.adminNew + currentLanguage: return adminpage + suffix
            case RoutesList.accountant + currentLanguage: return accountant + suffix
            case RoutesList.qualitycontroller + currentLanguage: return qualitycontroller + suffix
            case RoutesList.statistics + currentLanguage: return stat + suffix
            case RoutesList.pp + currentLanguage: return pp[0].toUpperCase() + pp.slice(1) + suffix
            case RoutesList.ppAdmin + currentLanguage: return pp[0].toUpperCase() + pp.slice(1) + suffix
            case RoutesList.pplanding + currentLanguage: return pp[0].toUpperCase() + pp.slice(1) + suffix
            default: return 'TextModing'
        }
    }
    
    const [title, dispatch] = useReducer(reducer, '.')
    useEffect( () => dispatch({}), [location])
    
    return title
}
export default useDynamicTitle