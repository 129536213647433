import Snackbar from '@mui/material/Snackbar'
import MuiAlert from '@mui/material/Alert'
import { MessageContext } from '../state/MessageContext'
import { memo, useCallback, useContext, useEffect, useRef, useState } from 'react'
import { Accordion, AccordionSummary, Badge, Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Fade, Grid, ListItemButton, ListItemText, Menu, Paper, Popper, Stack, Typography } from '@mui/material'
import useLanguage from '../hooks/useLanguage'
import axiosInstance from '../../axios'
import { AuthStateContext } from '../state/AuthStateContext'
import { MeetStateContext, MeetStateProvider } from '../state/MeetingContext'
import BigContainerAccordion from './BigContainerAccordion'
import Profile from '../Operator/Profile'
import Message from '../Operator/Message'
import NotificationsIcon from '@mui/icons-material/Notifications';
import { format } from "date-fns";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CustomPopup from '../Admin/CustomPopup'
import useMessage from '../hooks/useMessage'
import { useForm } from 'react-hook-form'
import InputEmoji from 'react-input-emoji'
import DeleteIcon from '@mui/icons-material/Delete';
import { CheckBox, ConstructionOutlined, DeleteOutline } from '@mui/icons-material'
import useData from '../hooks/useData'
import { StatContext } from '../state/StatContext'
import incoming from '../../assets/audio/notification1.mp3'
import FormRow from './FormRow'
import ReactDatePicker from 'react-datepicker'
import AuthFormRow from './AuthFormRow'

export const SendMessage = memo(({ photo, gifL }) => {
    const { control, handleSubmit } = useForm()
    const { text } = useLanguage();
    const { messageState,setOpen } = useContext(MessageContext)
    const {meetState} = useContext(MeetStateContext)
    const [message, setMessage] = useState('')
    const { sendMessage } = useMessage()
    const [messageVP, setMessageVP] = useState(null)
    const messLengthVP = ((message.length * 100) / messageVP).toFixed(2)
    const [msgLengthNotEnough, setMsgLengthNotEnough] = useState(true)
    const [canSend, setCanSend] = useState(false)
    const onEdit = e => {
        setMessage(e)
    }
    useEffect(() => {
        let max = 0
        messageState.message_data.map(p => p.message_id == null && max < p.content.length ? max = p.content.length : 0)
        setMessageVP(max)
    }, [])
    useEffect(() => {
        if (/ {2,}/.test(message)) {
            if (!canSend) setCanSend(true)
        } else if (canSend) setCanSend(false)
        if (message.length > 119 && messLengthVP > 75) setMsgLengthNotEnough(false)
        else if (!msgLengthNotEnough) setMsgLengthNotEnough(true)
    }, [message])
    const send = async (data) => {
        const meet = meetState.find(p => !p.esquive) ? true :false
        if(meet) setOpen(8)
        if (!msgLengthNotEnough && !meet) {
            if (!canSend) sendMessage(true, message, photo, gifL)
            setOpen(1)
        }
    }
    return (
        <>
            <Stack style={{ backgroundColor: '#e5f3ff', borderRadius: '5px', position: 'relative' }}>
                <Stack style={{ opacity: canSend ? 1 : 0, marginX: '5px', backgroundColor: '#ed7f7f', borderRadius: '5px', zIndex: 10, bottom: '-1.8em', position: 'absolute', width: '100%', transition: 'opacity .3s ease', height: '1.5em' }}>
                    <Typography style={{ textAlign: 'center', color: '#fff', fontWeight: 'bold', fontSize: '.9rem' }}>{text.dbspace}</Typography>
                </Stack>
                <InputEmoji
                    value={message}
                    borderRadius={0}
                    keepOpened
                    onChange={onEdit}
                    // cleanOnEnter
                    onEnter={send}
                    placeholder={text.writemessage}
                />
                <Stack display='flex' flexDirection="row" justifyContent="space-between" style={{ padding: '5px 10px', }}>
                    <Stack>
                        <Typography variant="xs">[{text.mincharlen}]</Typography>
                        <Typography variant="xs">[{message.length} {(message.length > 1) ? text.chars : text.char}]</Typography>
                        {(messageState.type_mess === 'message') && <Typography variant="xs">[{text.perc1} {messLengthVP}% {text.perc2}]</Typography>}
                    </Stack>
                    <Button variant="hover" style={{ width: '11em', height: '3.5em', backgroundColor: canSend ? '#ed7f7f' : msgLengthNotEnough ? "#a5afb2" : "#2db96c" }} disabled={msgLengthNotEnough} onClick={handleSubmit(send)}><Typography variant="pfsboldwhite" style={{ textTransform: "capitalize" }}>{text.sendMessage}</Typography></Button>
                </Stack>
            </Stack>
        </>
    )
})

export const getCountH = async(stat) => {
    if(stat){
        let count = 0
        Object.keys(stat.stat.conversations).map(lang => Object.keys(stat.stat.conversations[lang]).map(plat => count += stat.stat.conversations[lang][plat].treated))
        return count
    }
    return 0
}
export const addAll = (n) => {
    let platform = [{value:0,innerHTML:'All'}]
    n.forEach(p => platform.push({value:p.id,innerHTML:p.name}))
    return platform
}
export const DialogNote = memo(({editNote,platform,setEditNote}) => {
    const { stat,setStat } = useContext(StatContext);
    const {text} = useLanguage()
    const {qualityControllerOpt1} = useData()
    const {authState} = useContext(AuthStateContext)
    const { control, handleSubmit, formState: { errors } } = useForm()
    const adminL = stat.adminlist[editNote]
    const strToDate = (dt) => new Date([dt[1],dt[0],dt[2]].join('/'))
    const defaultDate = strToDate(adminL.accessvalidity.split('/'))
    const [plat,setPlat] = useState(adminL.tmFolder)
    const [opt,setOpt] = useState(adminL.accessarea)
    const [date,setDate] = useState(defaultDate)
    const closeEdit = () => setEditNote(null)
    const handleEdit = async({name,validity,company}) => {
        const dataT = {id:adminL.id,name,company,tmFolder:cData(plat),accessarea:cData(opt),accessvalidity:validity}
        await axiosInstance.post('/adminUpdate',dataT,{headers:{'Authorization':authState.token}}).then(()=>{
            const data = {name:name,company:company,tmFolder:plat,accessvalidity:format(new Date(validity),'dd/MM/yyyy'),accessarea:opt}
            setStat({...stat, adminlist:stat.adminlist.map(p => p.id === adminL.id ? {...p,...data} : p)})
            setEditNote(null)
        }).catch(e=>console.log(e))
    }
    const changePlat = (val) => {
        const allPlat = () => {
            let platf = []
            platform.forEach(p => platf.push(p.value))
            return platf
        }
        if(val.length) setPlat((val[val.length-1] === 0 || (val[val.length-1] !== 0 && !plat.includes(0) && val.length === platform.length-1)) ? allPlat() : val.filter(f => f !== 0))
    }
    return(
        <Dialog open={editNote !== null ? true : false} onClose={closeEdit}>

            <Stack style={{padding:'2em 2em',width:'33em'}}>
            <DialogTitle fontWeight='bold' fontSize='2em' style={{padding:'.7em'}}>Edition Access ID : {adminL.id}</DialogTitle>
                <DialogContent sx={{ gap: 1, display: 'flex', flexDirection: 'column' }}>
                    <FormRow defaultValue={adminL.name} label={text.nomd} control={control} name="name" error={errors.event} />
                    <FormRow defaultValue={adminL.company} label={text.cmpn} control={control} name="company" error={errors.event} />
                    <FormRow control={control} label={toNameCase(text.tmfldr.split(' ')[1])} comp="Select1" name='' options={platform} error={errors.folder} opt={plat} setOpt={changePlat} variant="formrow3"/>
                    <FormRow control={control} label={text.accssar} comp="Select1" options={qualityControllerOpt1} name="accessarea" error={errors.accessarea} variant="formrow3" opt={opt} setOpt={setOpt}/>
                    <FormRow control={control} dateFormat='dd/MM/yyyy' defaultValue={date} topEnd={true} label={text.valdt} name="validity" comp="DatePicker" minDate={new Date()} error={errors.validity}/>
                </DialogContent>
                <DialogActions style={{ justifyContent: 'space-around' }}>
                    <Button variant='contained' style={{padding:'1em',fondWeight:'bold'}} onClick={closeEdit}><Typography>{text.annuler}</Typography></Button>
                    <Button variant="contained" style={{padding:'1em',fondWeight:'bold'}} onClick={handleSubmit(handleEdit)} color='success' type="submit"><Typography>{text.editer}</Typography></Button>
                </DialogActions>
            </Stack>
        </Dialog>
    )
})
export const funcObj = (n) => Object.keys(n)
export const SendNote = () => {
    const noteRef = useRef(null)
    const { authState } = useContext(AuthStateContext)
    const { text } = useLanguage();
    const [loading, setLoading] = useState(false)

    const sendNote = () => {
        const content = noteRef.current?.value
        if (content) {
            const data = { event: content, platform: null, languageName: null, validity: null }
            setLoading(true)
            axiosInstance.post('/Note/Add', data, { headers: { 'Authorization': `Bearer ${authState.token}` } }).then(res => {
                setLoading(false)
                setNoteStates(false)
                // setOpen(7)
            }).catch(e => console.log(e))
        }

    }

    const [noteStates, setNoteStates] = useState(false)
    return (
        <>
            <CustomPopup open={noteStates} onClose={() => setNoteStates(false)}>
                <div style={{ padding: "18px 30px", width: '30em' }}>
                    <DialogTitle id="alert-dialog-title" sx={{ textAlign: "center" }}>
                        <Stack fontWeight={'bold'} fontSize={'2em'}>{text.ntsup}</Stack>
                    </DialogTitle>
                    <DialogContent style={{ padding: 0, width: '100%' }}>
                        <textarea ref={noteRef} style={{ minHeight: '8em', outline: 'none', padding: '4px', width: '98%' }} placeholder={text.nthr}></textarea>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            variant='contained'
                            sx={{
                                fontWeight: "700",
                                bgcolor: "error.main",
                                color: "white",
                                "&:hover": {
                                    bgcolor: "error.dark",
                                }
                            }}
                            onClick={() => setNoteStates(false)}
                        >
                            {text.delrefuse}
                        </Button>
                        <Button
                            variant='contained'
                            sx={{
                                fontWeight: "700",
                                bgcolor: "success.main",
                                color: "white",
                                "&:hover": {
                                    bgcolor: "success.dark",
                                }
                            }}
                            onClick={sendNote}
                        >
                            {loading ? text.phload : text.delaccept}
                        </Button>
                    </DialogActions>
                </div>
            </CustomPopup>
            <img src='/images/HeaderImage/send.png' alt='' style={{ position: 'relative', top: '.7em', width: '3.5em', cursor: 'pointer', zIndex: 20 }} onClick={() => setNoteStates(true)} />
        </>
    )
}
const getRoleName = (role, text) => {
    switch (role) {
        case 'administrator': return text.administrator
        case 'operator': return text.operator
        case 'supervisor': return text.supervisor
        default: return 'default'
    }
}

const checkOpen = (p) => {
    switch (p.type) {
        case 'note': return 4
        case 'panic': return 3
        case 'report': return 9
        case 'Gif Request': return 12
        case 'Photo Request': return 8
    }
}
const NotifBox = memo(({ i, p, notifClick, notifSuppr, openComp }) => {
    const { text } = useLanguage();
    const [mouseEnter, setMouseEnter] = useState(false)
    const {qualityControllerOpt,critereQCOpt,appCntrlQC} = useData()
    const split = p.type === 'qc1' ? p.role.split('-') : null
    const {messageCnt,qc_appreciate} = split ? {qc_appreciate:parseInt(split[0]),messageCnt:parseInt(split[1])} : {messageCnt:null,qc_appreciate:null}
    const clickN = (i,p) => {
        if(!p.checked) notifClick(i,p)
        openComp && openComp(checkOpen(p))
    }
    const ListQCResult = ({data}) => critereQCOpt.map((k,j) => <span key={j} style={{padding:'4px',display:'flex',flexDirection:'row'}}>
        <Typography style={{ fontFamily: 'cursive', fontWeight: 'bold', fontSize: '.8em' }}>{k.name} :</Typography>
        <Typography style={{ fontFamily: 'cursive', fontSize: '.8em', fontWeight: 'bold', color: j === 1 || j === 6 || j === 7 ? '#ff2b36' : '#00bb00' }}>{data.content[j]}</Typography>
    </span>)
    return (
        <Box key={i}>
            <ListItemButton sx={{ gap: '1em' }} style={{ backgroundColor: p.checked ? '' : '#b3d3f5' }}>
                <Stack style={{ gap: '1em', flexDirection: 'row', placeItems: 'center', width: '100%' }} onClick={() => clickN(i, p)}>
                    <Stack component='span' sx={{ fontWeight: 'bold' }}>{i + 1}</Stack>
                    <Divider variant="middle" sx={{ height: '2em', borderColor: 'rgb(99 177 219)' }} orientation="vertical" component='span' />
                    <ListItemText
                        primary={
                            <Stack sx={{ flexDirection: 'row', fontWeight: 'bold', gap: 1, placeItems: 'center', justifyContent: 'space-between' }}>
                                {/* {`${p.messageCnt ? '' : text.unNouv1} ${p.type} ${p.role ? `${text.from} ${p.messageCnt ? getRoleName(p.role, text) : p.sender}` : ''}`} */}
                                {(p.type === 'qc' || p.type === 'qc1') ? 'QC' : `${toNameCase(p.type)} ${p.role ? `${toNameLCase(text.from)} ${p.messageCnt ? getRoleName(p.role, text) : p.sender}` : ''}`}
                                <Stack style={{ gap: 2, flexDirection: 'row', placeItems: 'center' }}>
                                    <Typography sx={{ color: '#858585', fontSize: '.8rem' }}>
                                        {`${format(new Date(p.date), 'dd/MM/yyyy hh:mm')}`}
                                    </Typography>
                                </Stack>
                            </Stack>
                        }
                        secondary={
                            <Typography
                                component="span"
                                variant="body2"
                                sx={{ display: 'flex', flexDirection: 'column', color: '#616161', gap: .5 }}
                            >
                                <>
                                    {(p.messageCnt || p.type === 'qc1') && 
                                    <Accordion style={{ backgroundColor: "transparent" }}>
                                        <AccordionSummary style={{ minHeight: '1.5em', height: '2em', color: '#616161', fontWeight: 'bold', fontSize: '.9em' }} expandIcon={<ExpandMoreIcon style={{ color: '#616161' }} />}>
                                            {p.type === 'qc1' ? <span>Result QC : {messageCnt !== null ? messageCnt : 0}</span> : <span>{text.messageto} <span>{p.sender}</span></span>}
                                        </AccordionSummary>
                                        {p.type === 'qc1' ? <ListQCResult data={p}/>:
                                        <Typography style={{ padding: 3, fontSize: '.8em',overflowX:'auto' }}>
                                            {p.messageCnt}
                                        </Typography>}
                                    </Accordion>
                                }
                                    {p.content && p.content.length ? <span style={{ fontWeight: 'bold',display:'flex',flexWrap:'wrap',gap:2 }}>{text.contenu} <span style={{ fontWeight: 'normal',display:'flex',gap:4,flexWrap:'wrap' }}>- {p.type === 'qc' ? p.content.map(k => <span key={k} style={{color:qualityControllerOpt[k].color}}>{qualityControllerOpt[k].name}</span>) : p.type === 'qc1' ? qc_appreciate !== null && <span style={{font:'bold',fontFamily:'cursive',color:appCntrlQC[qc_appreciate].color}}>{appCntrlQC[qc_appreciate].name}</span> : p.content}</span></span>:<></>}
                                </>
                            </Typography>
                        }
                    />
                </Stack>
                <Box onClick={() => notifSuppr(p, i)} onMouseEnter={() => setMouseEnter(true)} onMouseLeave={() => setMouseEnter(false)} style={{ zIndex: 10, color: '' }} >{mouseEnter ? <DeleteIcon /> : <DeleteOutline />}</Box>
            </ListItemButton>
            <Divider variant="middle" component="li" />
        </Box>
    )
})
export const toNameCase = (name) => name.charAt(0).toUpperCase() + name.slice(1)
export const toNameLCase = (name) => name.charAt(0).toLowerCase() + name.slice(1)
export const Notification = ({setValue=null}) => {
    const [notif, setNotifs] = useState({ data: [], nbrChecked: 0 })
    const [notifEl, setNotifEl] = useState(null)
    const { text } = useLanguage();
    const { authState: { token } } = useContext(AuthStateContext)
    const { CustomScrollList, chan1 } = useContext(MessageContext)
    const notifOpen = Boolean(notifEl)

    useEffect(() => {
        if(!chan1 || chan1 && !notif.data.length)
        axiosInstance.get('/Notifications/get', { 
            headers: { 'Authorization': `Bearer ${token}` } 
        }).then(res => {
            if (res.status === 200) {
                if (res.data.data.length) {
                    setNotifs(res.data)
                }
            }
        }).catch(e => console.log(e))
        if (chan1) chan1.bind('notification', data => {
            const audio = new Audio(incoming)
            audio.play()
            setNotifs(p => ({data:[...p.data,data],nbrChecked:p.nbrChecked+1}))})
        return () => {
            chan1 && chan1.unbind()}
    }, [chan1])
    const notifSuppr = useCallback((p, i) => {
        axiosInstance.put(`/Notifications/Delete/${p.id}`).then(() =>
            setNotifs(k => ({ data: k.data.filter((_, j) => i !== j), nbrChecked: !p.checked ? k.nbrChecked - 1 : k.nbrChecked } ))
        ).catch(e => console.log(e))
    }, [])
    const notifClick = useCallback((n, d) => {
        setNotifs(p => ({ data: p.data.map((k, i) => i === n ? { ...k, checked: true } : k), nbrChecked: p.nbrChecked - 1 }))
        axiosInstance.put(`/Notifications/check/${d.id}`,{
            "test": "teste"
        }, {
            headers: { 'Authorization': `Bearer ${token}` } 
        })
        .then((response) => {
            console.log(response)
        })
        .catch((e) => {
            console.log(e)
        })
            //  setOpen(5)
    }, [])

    return (
        <>
            <Stack className="usericon" style={{ cursor: 'pointer' }} onClick={(e) => setNotifEl(notifEl ? null : e.currentTarget)}>
                <Badge color='info' badgeContent={notif.data.length ? notif.nbrChecked : 0}>
                    <NotificationsIcon style={{ color: '#ffbf4a', fontSize: '2.5rem' }} />
                </Badge>
            </Stack>
            <Menu
                anchorEl={notifEl}
                open={notifOpen}
                onClose={() => setNotifEl(null)}
                anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                transformOrigin={{ vertical: "top", horizontal: "left" }}
                slotProps={{ paper: { style: { backgroundColor: "#f7ead2", boxShadow: "none", color: '#616161', zIndex: 20 } } }}>
                <Typography sx={{ fontWeight: 'bold', fontSize: '1.3rem', paddingX: '5px' }}>{text.notifs}</Typography>
                {!notif.data.length ? <Typography sx={{ paddingX: '1.5em' }}>{text.nonotif}</Typography> :
                    <CustomScrollList sx={{ minWidth: 450, maxWidth: 500, maxHeight: 180, width: '100%' }}>
                        {notif.data.sort((a, b) => b.id - a.id).map((p, i) => <NotifBox key={i} i={i} p={p} notifSuppr={notifSuppr} notifClick={notifClick} openComp={setValue ? setValue : null} />)}
                    </CustomScrollList>
                }
            </Menu>
        </>
    )
}
export const AccessArea = ({ admin }) => {
    const {qualityControllerOpt1} = useData()
    const { stat } = useContext(StatContext);
    const accessarea = (admin) => admin.accessarea
    const accessarea2 = (admin) => accessarea(admin) && accessarea(admin).length !== qualityControllerOpt1.length
    const [pop, setPop] = useState(false)
    const [anchorEl, setAnchorEl] = useState(null);
    const [placement,setPlacement] = useState();
    const { text } = useLanguage()
    const handleShow = (e) => {
        if(!accessarea2(admin)) return;
        setAnchorEl(e.currentTarget)
        // setPlacement(newPlacement)
        setPop(true)   
    }
    return (
        <Stack onMouseEnter={handleShow} onMouseLeave={() => setPop(false)} style={{ width: '100%', height: '100%', cursor: 'pointer', justifyContent: 'center', placeItems: 'center',fontWeight:'bold',color:'#d5d5d5',backgroundColor:!accessarea2(admin) ? 'mediumseagreen':'indianred' }}>
            <PopperS types={2} pop={pop} anchorEl={anchorEl} text={text} placement={placement} profil={accessarea(admin) ? accessarea(admin) : [...Array(qualityControllerOpt1.length)].map((_,i)=>i)} quality={qualityControllerOpt1} />
            {accessarea2(admin) ? text.limite : text.total}
        </Stack>
    )
}
export const PopperS = ({pop,anchorEl,placement,profil,text,types=0,quality}) => {
    return(
        <Popper
        sx={{ zIndex: 1200 }}
        open={pop}
        anchorEl={anchorEl}
        placement={placement}
        transition
    >
        {({ TransitionProps }) => (
            <Fade {...TransitionProps} timeout={200}>
                <Paper>
                    {profil && types === 2 ? <Box style={{padding:'5px',PointerEvents:'none',fontWeight:'bold'}}>{quality.map((p,i)=>
                        <p key={i} style={{ margin:'3px',textDecorationColor:'red',textDecoration:profil.indexOf(i) > -1 ? '':'line-through' }}>{p.innerHTML}</p>)}</Box>
                     : types === 1 ? ((!profil.type ?
                    <Box style={{ fontFamily: 'Delm', fontWeight: 800, padding: '10px' }}>
                        <p style={{ fontSize: '1em' }}>{text.nomd} : {profil.content.Name}</p>
                        <p style={{ fontSize: '1em' }}>{text.ag} : {profil.content.Age}</p>
                        <p style={{ fontSize: '1em' }}>{text.sexe} : {profil.content.Sex}</p>
                        <p style={{ fontSize: '1em' }}>{text.sexor} : {profil.content.SO}</p>
                        <p style={{ fontSize: '1em' }}>{text.situation} : {profil.content.MS}</p>
                        <p style={{ fontSize: '1em' }}>{text.ville} : {profil.content.City}</p>
                        <p style={{ fontSize: '1em' }}>{text.pays} : {profil.content.Country}</p>
                        <p style={{ fontSize: '1em' }}>{text.search} : {profil.content.Search}</p>
                        <p style={{ fontSize: '1em', maxWidth:'15em' }}>{text.descri} : {profil.content.Descri}</p>
                    </Box>:
                    <Box style={{ fontFamily: 'Delm', fontWeight: 800, padding: '10px' }}>
                        <p style={{fontSize:'1.5em'}}>{text.mess}</p>
                        <p>{profil.content}</p>
                    </Box>)):<></>
                        }
                </Paper>
            </Fade>
        )}
    </Popper>
    )
}
export const EditAdmin = memo(({token,countriesList,languagesgifList,qualityControllerOpt1,text,platform,bases,editNote,setBases,setEditNote}) => {
    const { control, reset, handleSubmit, formState: { errors } } = useForm()
    const [opt,setOpt] = useState(editNote.accessarea)
    const [plat,setPlat] = useState(editNote.tmFolder)
    const [showPass,setShowPass] = useState(false)
    const changePlat = (val) => {
        const allPlat = () => {
            let platf = []
            platform.forEach(p => platf.push(p.value))
            return platf
        }
        if(val.length) setPlat((val[val.length-1] === 0 || (val[val.length-1] !== 0 && !plat.includes(0) && val.length === platform.length-1)) ? allPlat() : val.filter(f => f !== 0))
    }
    const handleSub = async({name,mail,validity,company,password}) => {
        const dts = {name,mail,accessvalidity:format(new Date(validity),'yyyy-MM-dd'),company,accessarea:opt,tmFolder:plat,password}
        const dts1 = {...dts,id:editNote.id,accessarea:cData(opt),tmFolder:cData(plat),mail:editNote.email,chang:null}
        let dataB
        await axiosInstance.post('/adminUpdate',dts1).then(async res => {
            dataB = res.data.dataB
            setBases(p => p.map(d => d.id === editNote.id ? {...d,...dts}:d))
            setEditNote(null)
        }).catch(e=>console.log(e))
        axiosInstance.post('/adminUpdate',{...dts1,chang:dataB}).catch(e=>console.log(e))
    }
    return (
        <Stack style={{width:'800px',overflow:'hidden'}}>
            <Stack flexDirection={'row'} gap={15} style={{placeItems:'center'}}>
                <DialogTitle style={{fontFamily:'Delm',fontSize:'2em',fontWeight:'600'}}>Edition Admin : {editNote.id}</DialogTitle>
                <label style={{fontFamily:'Delm',fontSize:'1.5em',display:'flex',placeItems:'center',gap:2}}><input type={'checkbox'} value={showPass} style={{width:'1.5em',height:'1.5em',color:'black'}} onChange={()=>setShowPass(!showPass)}></input>{text.chngmdp}</label>
            </Stack>
            <DialogContent>
                <Stack spacing={1} style={{width:'80%'}}>
                    <FormRow control={control} defaultValue={editNote.name} label={text.nm} name="name" error={errors.name} bgCol="#9aacff" variant="formrow3"/>
                    <FormRow control={control} defaultValue={editNote.email} label={text.mladd} name="mail" error={errors.mail} bgCol="#9aacff" variant="formrow3"/>
                    <FormRow control={control} defaultValue={editNote.company} label={text.cmpnnm} name="company" error={errors.company} bgCol="#9aacff" variant="formrow3"/>
                    <FormRow control={control} label={text.tmfldr} comp="Select1" name='' options={platform} bgCol="#9aacff" inpCol='#e3e8fe' error={errors.folder} opt={plat} setOpt={changePlat} variant="formrow3"/>
                    <FormRow control={control} label={text.accssar} comp="Select1" options={qualityControllerOpt1} name="accessarea" error={errors.accessarea} bgCol="#9aacff" variant="formrow3" inpCol='#e3e8fe' opt={opt} setOpt={setOpt}/>
                    {showPass && <FormRow control={control} error={errors.password} label={text.password} name="password" bgCol="#9aacff" variant="formrow3"/>}
                    <FormRow control={control} dateFormat='dd/MM/yyyy' defaultValue={new Date(editNote.accessvalidity)} label={text.valdt} name="validity" comp="DatePicker" minDate = {new Date()} error={errors.validity} bgCol="#9aacff" className/>
                </Stack>
            </DialogContent>
            <DialogActions style={{width:'90%',justifyContent:'center',gap:4}}>
                <Button variant={'outlined'}>Close</Button>
                <Button variant={'contained'} onClick={handleSubmit(handleSub)}>Editer</Button>
            </DialogActions>
        </Stack>
    )
})
export const cData = (n) => JSON.stringify(n)
export const TextSC = memo(() => {
    const { chan1 } = useContext(MessageContext)
    const [eventTab, setEventTab] = useState(null)
    const { authState } = useContext(AuthStateContext)

    useEffect(() => {
        if (chan1) {
            chan1.bind('note-' + authState.languageName, data => {
            switch (data.type) {
                case 1: return setEventTab(p => p ? [...p,{id:data.id,event:data.event}]:[{id:data.id,event:data.event}])
                case 2: return setEventTab(p => p.map(k => k.id === data.id ? {...k,event:data.event}:k))
                default : return setEventTab(p => p.filter(k => k.id !== data.id))
            }
        })
    }
        else if (!eventTab)
            axiosInstance.get('/getNote', { headers: { 'Authorization': `Bearer ${authState.token}` } }).then(rep => {
                const noteData = rep.data
                if (noteData && noteData.length)
                    setEventTab(eventTab ? [...eventTab] : noteData)
            }).catch(e => console.log(e))
    }, [chan1])
    return eventTab?.map((p, i) => <span key={i} style={{}}>- {p.event}</span>)
})
export const Discussion = memo(({ messageState }) => {
    const { text } = useLanguage()
    const [photoSeen, setPhotoSeen] = useState()
    const { authState } = useContext(AuthStateContext)
    const { setImage } = useContext(MessageContext)

    const getDatas = async () => {
        await axiosInstance.get(`/photoAttachment/list/${messageState?.convId}`, {headers: {'Authorization': `Bearer ${authState.token}`,},}).then(rep => setImage(rep.data.photos)).catch((error) => {console.log(error)})
        await axiosInstance.get(`/photoRequestSent/${messageState?.convId}`, {
            headers: {
                'Authorization': `Bearer ${authState.token}`,
            },
        })
            .then((response) => {
                setPhotoSeen(response.data.photos)
            })
            .catch((error) => {
                console.log(error)
            })
    }
    useEffect(() => {
        getDatas()
    }, [])
    return (
        <Box className="wrap-content" style={{ paddingTop: '6em' }} >
            <Snackbars />
            <MeetStateProvider>
                <Box className="profil-display" >
                    <BigContainerAccordion
                        summaryBg="#d48b70"
                        summary={text.userprofil}
                        details={
                            <Profile
                                user={messageState?.fake_profil}
                                type="fake_profil"
                            />
                        }
                    />
                </Box>
                <Box className="profil-display" >
                    <BigContainerAccordion
                        summaryBg="#ffa781"
                        summary={text.botprofil}
                        details={
                            <Profile
                                user={messageState?.real_profil}
                                type="real_profil"
                            />
                        }
                    />
                </Box>
                <Box className="profil-display-inv" width="25%">
                    <Profile
                        info={messageState.info}
                        meet={messageState.meeting}
                        user={messageState?.real_profil}
                        type="real_profil"
                    />
                </Box>
                <Stack width="50%">
                    <Message
                        photoSeen={photoSeen}
                    />
                </Stack>
                <Box className="profil-display-inv" width="25%">
                    <Profile
                        info={messageState.info}
                        meet={messageState.meeting}
                        user={messageState?.fake_profil}
                        type="fake_profil"
                    />
                </Box>
            </MeetStateProvider>
        </Box>
    )
})
const Snackbars = () => {
    const { text } = useLanguage()
    const { open, setOpen } = useContext(MessageContext)
    const notiF = () => {
    switch (open) {
        case 1 : return <MuiAlert elevation={6} variant="outlined" severity='success'>Message envoye</MuiAlert>
        case 2 : return <MuiAlert elevation={6} variant="filled" severity='error'>Vous n'avez pas repondu</MuiAlert>
        case 3 : return <MuiAlert elevation={6} variant="filled" severity='success'>Vous avez un nouveau Message</MuiAlert>
        case 4 : return <MuiAlert elevation={6} variant="filled" severity='warning'>Vous avez une nouvelle notification</MuiAlert>
        case 5 : return <MuiAlert elevation={6} variant="outlined" severity='success'>Notification lue</MuiAlert>
        case 6 : return <MuiAlert elevation={6} variant="outlined" severity='success'>Nouveau report</MuiAlert>
        case 7 : return <MuiAlert elevation={6} variant="filled" severity='success'>Note envoyer</MuiAlert>
        default : return <MuiAlert elevation={6} variant="filled" severity='warning'>{text.esqmq}</MuiAlert>
    }
}
    return (
        <Snackbar open={open ? true : false} autoHideDuration={3000} onClose={() => setOpen(false)}>
            {notiF()}
        </Snackbar>
    )
}
export default Snackbars