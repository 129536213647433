import React from 'react'
import { Dialog, Stack, Typography } from '@mui/material'
import RecentActorsIcon from '@mui/icons-material/RecentActors';
import FolderCopyIcon from '@mui/icons-material/FolderCopy';
import SwitchAccountIcon from '@mui/icons-material/SwitchAccount';
import CircularProgress from '@mui/material/CircularProgress';
import CheckIcon from '@mui/icons-material/Check';
import { green } from '@mui/material/colors';
import Fab from '@mui/material/Fab';

const FolderPop = ({ loading, setLoading }) => {

  return (
    <div>
      <Dialog
        open={loading}
        onClose={() => setLoading(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Stack style={{ padding: "50px 40px"}}>
          <Stack sx={{ marginBottom: "30px"}}>
            <Typography sx={{ fontSize: "24px", fontWeight: "700"}}>
              Création d'un nouvel compte administrateur:
            </Typography>
            <Typography sx={{ fontSize: "16px", fontWeight: "600", color: "gray"}}>
              ( Cela peut prendre entre 05 à 10 sec )
            </Typography>
          </Stack>
          <Stack sx={{ gap: "20px"}}>
            <Stack direction="row" justifyContent="space-between" alignItems="center">
              <Stack direction="row" gap="5px" alignItems="center">
                <Stack>
                  <SwitchAccountIcon sx={{ color: "#482880"}} />
                </Stack>
                <Typography sx={{ fontSize: "18px", fontWeight: "600"}}>
                  Création des utilisateurs.
                </Typography>
              </Stack>
              <Stack>
                  { loading ?
                    <div>
                      <CircularProgress
                        size={26}
                        sx={{
                          color: green[800],
                          marginLeft: "10px"
                        }}
                      />
                    </div>
                    :
                    <div>
                      <Fab
                        aria-label="save"
                        color="primary"
                        sx={{ 
                          bgcolor: green[500],
                          '&:hover': {
                            bgcolor: green[700],
                          },
                          width: "30px",
                          maxHeight: "30px",
                        }}
                      >
                        <CheckIcon sx={{ fontSize: "16px"}} />
                      </Fab>
                    </div>
                  }
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </Dialog>
    </div>
  )
}

export default FolderPop