import React from "react";
import { Stack, Typography, Box } from "@mui/material";
import useLanguage from "../hooks/useLanguage";
import CButton from "./CButton";

const Item = ({ rank, title, content }) => {
    return <Stack flexDirection="row" gap="15px">
        <Stack sx={{ borderRadius: "50%", border: "8px solid #f5af19", padding: "12px", height: "fit-content", aspectRatio: "1/1", background: "#fff" }}>
            <Typography color="#f5af19" fontWeight="bold" variant="h3" sx={{ display: "flex", alignItems: "center", justifyContent: "center", height: "fit-content", aspectRatio: "1/1" }}>{rank}</Typography>
        </Stack>
        <Stack spacing={0}>
            <Typography variant="h4" fontWeight="bold" color="#d6801c">{title}</Typography>
            <Typography>{content}</Typography>
        </Stack>
    </Stack>
}

const Cle = () => {
    const { text } = useLanguage()
    
    const cles = [
        { title: text.cldrssttl1, content: text.cldrsstxt1 },
        { title: text.cldrssttl2, content: text.cldrsstxt2 },
        { title: text.cldrssttl3, content: text.cldrsstxt3 },
        { title: text.cldrssttl4, content: text.cldrsstxt4 },
        { title: text.cldrssttl5, content: text.cldrsstxt5 },
        { title: text.cldrssttl6, content: text.cldrsstxt6 },
    ]
    return <Stack spacing={5} alignItems="center" marginTop="35px">
        <Typography variant="hometitle" textAlign="center" fontWeight="bold" color="#fff" sx={{ WebkitTextStroke: "2px #d48b70", fontFamily: "PlayFairDisplay", zIndex: 2, paddingRight: "25px" }}>{cles.length} {text.xcldrssttm}</Typography>
        <Stack sx={{ flexDirection: { xs: "column", md: "row" }, padding: "0 10px" }}>
            <Stack sx={{ width: { xs: "100%", md: "45%" } }}>
                <Box component="img" src="/images/landing/cdr.png" sx={{ width: "90%" }}/>
            </Stack>
            <Stack id="cldrsst" sx={{ width: { xs: "100%", md: "55%" } }} spacing={3}>
                { cles.map((data, i) => <Item rank={i+1} key={i} title={data.title} content={data.content}/>)}
            </Stack>
        </Stack>
        <CButton>{text.nsecrr}</CButton>
    </Stack>
}

export default Cle