import React, { useState, createContext } from "react";
import { Stack } from "@mui/material";
import LandingHeader from "./Header";
import Home from "./1Home";
import Apr from "./2Apr";
import Feat from "./3Feat";
import Cqdiff from "./4Cqdiff";
import Cle from "./5Cle";
import Prod from "./6Prod";
import Footer from "./Footer";
import Contacts from "./Contacts";

const ContactContext = createContext(null)
const Landing = () => {
    const [contactShow, setContactShow] = useState(false)
    return <ContactContext.Provider value={{ contactShow, setContactShow }}>
        <Stack bgcolor="#f6e7de" sx={{ overflowX: "hidden" }}>
            <LandingHeader/>
            <Home/>
            <Apr/>
            <Feat/>
            <Cqdiff/>
            <Cle/>
            <Prod/>
            <Footer/>
            <Contacts/>
        </Stack>
    </ContactContext.Provider>
}
export { ContactContext }
export default Landing