import React, { useState,useEffect, useContext } from "react";
import { Stack, Typography, Button } from "@mui/material";
import useLanguage from "../hooks/useLanguage";
import { useForm } from 'react-hook-form'
import AuthFormRow from "../tools/AuthFormRow";
import useData from "../hooks/useData";
import { useNavigate } from 'react-router-dom'
import RoutesList from "../../helper/routes"
import axiosInstance from "../../axios"
import Pusher from 'pusher-js'
import { PUSHER_SECRET,CLUSTER } from "../../helper/conf";
import { AuthStateContext } from "../state/AuthStateContext";

const Register = () => {
    const { authState } = useContext(AuthStateContext)
    const { control, handleSubmit, formState: {errors} } = useForm()
    const navigate = useNavigate()
    const { text } = useLanguage()
    const [error,setError] = useState('')
    const [pp, setPP] = useState(false)
    const [ppError, setPPError] = useState(false)
    const { roleList,languagesgifList } = useData()

    const onSubmit = async({role,mail,password,passwordConfirm,name,country,languageName}) =>{
        if(error) setError('')
            setPPError(!pp)
        if(password === passwordConfirm){
            try{
                const rep = await axiosInstance.post('/register',{mail,password,role,name,country,languageName}, {
                    headers: {
                        'Authorization': `Bearer ${authState.token}`,
                    },
                })
                if(rep.status === 200) navigate(RoutesList.auth)
            }catch(e){
                setError(e.response.data.errors[0])
            }
        } else {
            setError(text.passdiff)
        }
    }
    
    return(
        <Stack alignItems={'center'} display={'flex'} justifyContent={'center'}
        variant="maroongradientbg" height='100vh'>
          <img src="/images/HeaderImage/logo.png" alt="" height='150px' width='150px'/>
        <Stack alignItems={'center'} spacing={1} className="width-resize">
           <Typography>{error}</Typography>
            <AuthFormRow label={text.role} comp="Select" options={roleList} name='role' control={control} error={errors.role}/>
            <AuthFormRow label={text.mail} name='mail' control={control} error={errors.mail}/>
            <AuthFormRow label='Name' name='name' control={control} error={errors.mail}/>
            <AuthFormRow label='Country' name='country' control={control} error={errors.mail}/>
            <AuthFormRow label='Language' comp="Select" options={languagesgifList} name='languageName' control={control} error={errors.role}/>
            <AuthFormRow label={text.password} comp="Password" name='password' control={control} error={errors.password}/>
            <AuthFormRow label={text.confirmpass} comp="Password" name='passwordConfirm' control={control} error={errors.password}/>
            {/* <Button variant="login" onClick={handleSubmit(onSubmit)}>{text.reg}</Button>
            <Button onClick={()=>{navigate(RoutesList.auth)}}>{text.alreadyreg}</Button> */}
        </Stack>
        <Typography position='absolute' bottom='5px'>{text.copyright}</Typography>
    </Stack>
    )
}
export default Register