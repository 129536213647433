import React, { useState } from 'react'
import axiosInstance from '../../axios'
import { Stack } from '@mui/material'
import { useNavigate } from 'react-router-dom';
import LogoutIcon from '@mui/icons-material/Logout';

const Logout = () => {
  const navigate = useNavigate();
  const token = localStorage.getItem('token');
  const [ loading, setLoading ] = useState(false)
  
  const logout = () => {
    axiosInstance.post('/interface/logout', {
      'token' : token,
    },{
      headers: {
          'Authorization': `Bearer ${token}`,
      },
    })
    .then((response) => {
      console.log(response)
      navigate('/login');
      localStorage.removeItem('token');
      localStorage.removeItem('userId');
    })
    .catch((error) => {
      console.log(error)
    })
  }

  return (
    <div>
        <Stack onClick={() => logout()} sx={{ cursor: "pointer"}} >
            <LogoutIcon />
        </Stack>
    </div>
  )
}

export default Logout