import React, {useContext, useEffect, useMemo, useState} from "react";
import { Stack, Button, Typography } from "@mui/material";
import useLanguage from "../../hooks/useLanguage";
import Title from "./Title";
import TableRow from "../../tools/TableRow";
import { StatContext } from "../../state/StatContext";
import { limit2Nbr } from "../../../helper/formatDate";

export default ({onDetails}) => {
    const { text } = useLanguage()
    const { stat, totalAccount } = useContext(StatContext);
    const [ total, setTotal ] = useState({ wireTransfer: 0, paypal: 0, wise: 0, yoursafe: 0, total: 0 })

    const languages = Object.keys(stat?.accountant ?? {});

    useEffect(() => {
        setTotal({ wireTransfer: 0, paypal: 0, wise: 0, yoursafe: 0, total: 0 })
        languages.forEach(l =>{
            const platforms = Object.keys(stat?.accountant[l] ?? {})
            platforms.forEach(platform =>{
                setTotal(t => ({...t, wireTransfer: t.wireTransfer + stat?.accountant[l][platform].wireTransfer}))
                setTotal(t => ({...t, paypal: t.paypal + stat?.accountant[l][platform].paypal}))
                setTotal(t => ({...t, wise: t.wise + stat?.accountant[l][platform].wise}))
                setTotal(t => ({...t, yoursafe: t.yoursafe + stat?.accountant[l][platform].yoursafe}))
                setTotal(t => ({...t, total: t.total + stat?.accountant[l][platform].total}))
            })
        })
    }, [])

    useEffect(() => {
        if(totalAccount) {
            setTotal(t => ({...t, [totalAccount.field]: t[totalAccount.field] + totalAccount.value }))
        }
    }, [totalAccount])

    const StatAccountTab = (language) => {
        const platforms = Object.keys(stat?.accountant[language] ?? {});
        return (
            <TableRow columns={[
                { xs: 1.62,
                    customContent: true,
                    content: <Stack>{language.toUpperCase()}</Stack>
                },
                { xs: 1.62,
                    customContent: true,
                    content: <Stack>
                        {platforms.map(platform => (
                            <Typography key={platform}>
                                {platform.charAt(0).toUpperCase() + platform.slice(1)}
                            </Typography>
                        ))}

                    </Stack>
                },
                { xs: 1.62,
                    customContent: true,
                    content: <Stack>
                        {platforms.map(platform => (
                            <Typography  key={platform}>
                                {limit2Nbr(stat?.accountant[language][platform]?.wireTransfer)}
                            </Typography>
                        ))}
                    </Stack>
                },
                { xs: 1.62,
                    customContent: true,
                    content: <Stack>
                        {platforms.map(platform => (
                            <Typography key={platform}>
                                {limit2Nbr(stat?.accountant[language][platform]?.paypal)}
                            </Typography>
                        ))}
                    </Stack>
                },
                { xs: 1.62,
                    customContent: true,
                    content: <Stack>
                        {platforms.map(platform => (
                            <Typography key={platform}>
                                {limit2Nbr(stat?.accountant[language][platform]?.wise)}
                            </Typography>
                        ))}
                    </Stack>
                },
                { xs: 1.62,
                    customContent: true,
                    content: <Stack>
                        {platforms.map(platform => (
                            <Typography key={platform}>
                                {limit2Nbr(stat?.accountant[language][platform]?.yoursafe)}
                            </Typography>
                        ))}
                    </Stack>
                },
                { xs: 1.62,
                    customContent: true,
                    content: <Stack>
                        {platforms.map(platform => (
                            <Typography key={platform}>
                                {limit2Nbr(stat?.accountant[language][platform]?.total)}
                            </Typography>
                        ))}
                    </Stack>
                },
            ]}/>
        );
    };



    return <Stack padding="2%" spacing={3} height="100%" style={{ overflowY: "scroll" }}>
        <Title>{text.acc}</Title>
        <Stack spacing={1}>
            <TableRow header columns={[
                { xs: 11.85,  text: text.paymnts},
            ]}/>
            <TableRow header columns={[
                { xs: 1.62,  text: text.language},
                { xs: 1.62,  text: text.platform},
                { xs: 1.62,  text: `${text.bnk} €`},
                { xs: 1.62,  text: `${text.ppl} €`},
                { xs: 1.62,  text: `${text.ws} €`},
                { xs: 1.62,  text: `${text.yrsf} €`},
                { xs: 1.62,  text: `${text.total} €`},
            ]}/>
            {languages.map((language) => (
                <Stack key={language}>{StatAccountTab(language)}</Stack>
            ))}
            <TableRow footer columns={[
                { xs: 3.33,  text: `${text.grotot} €`},
                { xs: 1.62,  text: limit2Nbr(total.wireTransfer)},
                { xs: 1.62,  text: limit2Nbr(total.paypal)},
                { xs: 1.62,  text: limit2Nbr(total.wise)},
                { xs: 1.62,  text: limit2Nbr(total.yoursafe)},
                { xs: 1.62,  text: limit2Nbr(total.total)},
            ]}/>
        </Stack>
        
        <Button onClick={onDetails} style={{ backgroundColor: '#feba3b', textTransform: 'capitalize', color: '#000', borderRadius: '15px', border: '2px solid #fff', width: 'fit-content', alignSelf: 'center'}}><Typography style={{fontFamily: 'Chewy Pro'}}>{ text.details}</Typography></Button>
    </Stack>
}

