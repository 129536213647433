const RoutesList = {
    landing: '/',
    lang: '/lang/',
    auth: '/auth/',
    register: '/register/',
    operator: '/operator/',
    supervisor: '/supervisor/',
    admin: '/admin/',
    qualitycontroller: '/quality-controller/',
    accountant: '/accountant/',
    statistics: '/statistics/',
    pp: '/privacy-policy/',
    ppAdmin: '/privacy-policy/administrator/',
    adminNew: '/admin_new/',
    pplanding: "/privacypolicy/",
    
    landingFinal: '/:lang',
    authFinal: '/auth/:lang',
    registerFinal:'/register/:lang',
    operatorFinal: '/operator/:lang',
    adminFinal: '/admin/:lang',
    supervisorFinal: '/supervisor/:lang',
    qualitycontrollerFinal: '/quality-controller/:lang',
    accountantFinal: '/accountant/:lang',
    statisticsFinal: '/statistics/:lang',
    ppFinal: '/privacy-policy/:lang',
    ppAdminFinal: '/privacy-policy/administrator/:lang',
    adminNewFinal: '/admin_new/:lang',
    pplandingFinal: "/privacypolicy/:lang",
}
export default RoutesList