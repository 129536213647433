import React from "react";
import { Stack, Typography, Box, Divider } from "@mui/material";
import CButton from "./CButton";
import useLanguage from "../hooks/useLanguage";
import { keyframes } from "@emotion/react"

const Home = () => {
    const { text } = useLanguage()
    const datas = {
        dailyFlows: "1,5M+",
        features: "15+"
    }

    const Flag = ({imageName, index}) => {

        const rotate = keyframes`
            from{transform: rotate(${360 * index/6}deg);}
            to{transform: rotate(${360 +360 * index/6}deg);}
        `

        return <Stack sx={{ position: "absolute", width: "80%", height: "80%", alignItems: "center", zIndex: 2, animation: `${rotate} 10s infinite linear` }}>
            <Box component="img" src={`/images/landing/${imageName}`} sx={{ height: "75px", aspectRatio: "1/1" }}/>
        </Stack>
    }

    return <Stack height="100vh" sx={{ flexDirection: { xs: "column", md: "row" } }}>
        <Stack id="home" sx={{ width: { xs: "100%", md: "40%" } }}>
            <Stack sx={{ height: "100%", gap: "30px", alignItems: "center", justifyContent: "center", padding: "10px", boxSizing: "border-box" }}>
                <Stack spacing={2}>
                    <Typography fontWeight="bold" variant="h1">{text.orpltfrm1}</Typography>
                    <Typography fontWeight="bold" variant="h1" color="#f2a491">{text.orpltfrm2}</Typography>
                    <Typography>{text.orpltfrmtxt}</Typography>
                </Stack>
                <CButton>{text.rqstdmo}</CButton>
            </Stack>
        </Stack>
        <Stack sx={{ position: "relative", width: { xs: "100%", md: "60%" }, height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
            <Stack sx={{ background: "#473426", position: "absolute", height: "31%", aspectRatio: "1/1", borderRadius: "50%", left: "0%", bottom: "0%", zIndex: 0 }}/>
            <Stack sx={{ border: "3px solid #473426", position: "absolute", height: "29%", aspectRatio: "1/1", borderRadius: "50%", left: "calc(0% - 10px)", bottom: "calc(0% - 5px)", zIndex: 0 }}/>
            <Stack sx={{ border: "3px solid #473426", position: "absolute", height: "29%", aspectRatio: "1/1", borderRadius: "50%", left: "1%", bottom: "3%", zIndex: 0 }}/>
            <Box component="img" src="/images/landing/home.png" sx={{ height: "100%", zIndex: 0}}/>
            {
                ["fr.png", "en.png", "it.png", "nl.png", "es.png", "de.png"]
                .map((imageName, i) => <Flag key={i} imageName={imageName} index={i}/>)
            }
            <Stack sx={{ position: "absolute", gap: "10px", flexDirection: "row", padding: "20px 35px", top: "65%", right: "50%", background: "#ffffff", borderRadius: "10px" }}>
                <Stack spacing={1}>
                    <Typography>{text.flxqtdns}</Typography>
                    <Typography color="#d78529" textAlign="center" fontWeight="bold" variant="h3">{datas.dailyFlows}</Typography>
                </Stack>
                <Divider orientation="vertical" flexItem/>
                <Stack spacing={1}>
                    <Typography>{text.ftrs}</Typography>
                    <Typography color="#d48b70" textAlign="center" fontWeight="bold" variant="h3">{datas.features}</Typography>
                </Stack>
            </Stack>
        </Stack>

    </Stack> 
}

export default Home