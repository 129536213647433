import React from "react";
import { Stack, Typography, Grid, Box, Divider } from "@mui/material";
import useLanguage from "../hooks/useLanguage";
import CButton from "./CButton";

const Item = ({ data:{title, imageName, content, bg} }) => {
    return <>
        <Grid item xs={12} sm={6} sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
            <Box component="img" src={`/images/landing/${imageName}`} sx={{ width: "90%", }}/>
        </Grid>
        <Grid item xs={12} sm={6}>
            <Stack spacing={2}>
                <Typography variant="h3" textAlign="center" fontWeight="bold">{title}</Typography>
                <Divider/>
                <Typography>{content}</Typography>
            </Stack>
        </Grid>
    </>
}

const Prod = () => {
    const { text, currentLanguage } = useLanguage()
    const products = [
        { title: text.prdtttl1, imageName: `test_${currentLanguage}.jpg`, content: text.prdttxt1 },
        { title: text.prdtttl2, imageName: `admi_${currentLanguage}.jpg`, content: text.prdttxt2 },
        { title: text.prdtttl3, imageName: `oper_${currentLanguage}.jpg`, content: text.prdttxt3 },
    ]

    return <Stack id="nsprdts" marginTop="35px" spacing={5}>
        <Typography variant="hometitle" textAlign="center" fontWeight="bold" color="#fff" sx={{ WebkitTextStroke: "2px #d48b70", fontFamily: "PlayFairDisplay", zIndex: 2, paddingRight: "25px" }}>{text.prdtstm}</Typography>
        <Grid container sx={{ rowGap: "30px", }}>
            { products.map((data, i) => <Item key={i} data={data}/>)}
        </Grid>
        <Typography fontWeight="bold" paddingLeft="15px">*{text.prdindpdts}</Typography>
        <Stack alignItems="center">
            <CButton>{text.rqstdmo}</CButton>
        </Stack>
    </Stack>
}

export default Prod