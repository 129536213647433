import React, { useState,useEffect, useContext } from "react";
import { Stack, Typography, Button } from "@mui/material";
import SupervisorHeader from "../Header/SupervisorHeader";
import ConvOnHold from "./ConvOnHold";
import useLanguage from "../hooks/useLanguage";
import CurrentComponentSuper from "../tools/CurrentComponentSuper";
import Snackbars from '../tools/Snackbars'
import { AuthStateContext } from "../state/AuthStateContext";
import Pusher from 'pusher-js'
import { MessageContext } from "../state/MessageContext";
import { CLUSTER, PUSHER_SECRET, apiURL } from "../../helper/conf";
import axiosInstance from "../../axios";
import axios from "axios";

const Supervisor = () =>{
    const {text} = useLanguage()
    const [state, setState] = useState(0)
    const {authState} = useContext(AuthStateContext)
    const {setChan1} = useContext(MessageContext)
    const [open,setOpen] = useState(false)
    const [ stat, setStat ] = useState()

    useEffect(()=>{
        console.log(authState.token)
        const pusher = new Pusher(PUSHER_SECRET,{cluster:CLUSTER,channelAuthorization:{endpoint:`${apiURL}/pusher/user-auth`,transport:'jsonp'}})
        const chan = pusher.subscribe('private-'+authState.userId.toString())
        setChan1(chan)

        return ()=> {
            chan.unbind()
            pusher.unsubscribe('private-'+authState.userId.toString())
        }
    },[])
 
    return(
        <Stack spacing={3} marginBottom='50px'>
            <SupervisorHeader setOpen={setOpen} setState={setState} />
           <Snackbars open={open} setOpen={setOpen}/>
            <Stack spacing={3}>
                <ConvOnHold stat={stat} inverseDisplay/>
                <Stack className="height-resize-sup" style={{display: 'flex', flexDirection: 'row', gap: '0'}} alignSelf='center'>
                    <img src="/images/supervisor/dash_int_left.png" alt="" height='100%' />
                    <Typography className="horizon-regular" textAlign='center' variant="h2" style={{height: '100%', color: '#fec0a7', WebkitTextStroke: '1px #9a5c43', padding: '0 5px', display: 'flex', alignItems: 'center', backgroundColor: '#bc6079', fontFamily: 'Horizon Regular'}}>{text.supdash.toUpperCase()}</Typography>
                    <img src="/images/supervisor/dash_int_right.png" alt="" height='100%'/>
                </Stack>
            </Stack>
            <CurrentComponentSuper stat={stat} setStat={setStat} state={state} setState={setState}/>
            {
                (state != 0) ?
                <Button onClick={()=>setState(0)} style={{backgroundColor: '#feba3b', alignSelf: 'center', textTransform: 'capitalize', color: '#000', borderRadius: '15px', border: '2px solid #fff'}}><Typography>{text.back}</Typography></Button>
                : <></>
            }
        </Stack>
    )
}

export default Supervisor