import React from "react";
import { Box, Stack, Typography, Button } from "@mui/material";
import { Link } from "react-scroll";
import useLanguage from "../hooks/useLanguage";

const sx = { display: "flex", flexDirection: "column", alignItems: "center", padding: "0", "& .MuiTouchRipple-root": { display: "none" }, "&:hover": { backgroundColor: "transparent" } }

const NavItem = ({label, to, customContent}) => {
    return <Button sx={sx}>
        <Link to={to} spy={true} smooth={true} offset={-85} duration={500}>
            {
                customContent ?? <Typography color="#000" sx={{ WebkitTextStroke: "1px #d58f78" }} textTransform="none" fontWeight="bold">{label}</Typography>
            }
        </Link>
    </Button>
}

const LandingHeader = () => {
    const { text } = useLanguage()
    return <Stack sx={{ position: 'fixed', top: '0', zIndex: 5, padding: "10px 5px", flexDirection: "row", gap: "15px", background: "#f6e7de", paddingRight: "20px", borderBottomRightRadius: "10px"}}>
        <NavItem to="home" customContent={<>
            <Box component="img" src="/images/landing/logo.png" sx={{ height: "50px", width: "50px" }}/>
            <Typography color="#d58f78" textTransform="none" fontWeight="bold">TextModing</Typography>
        </>}/>
        <Stack>
            <Typography textAlign="center" color="#fff" variant="h3" sx={{ textShadow: "0px 0px 4px #ff4400", fontFamily: "Gladiola", fontStyle: "italic", fontWeight: "bold" }}>{text.intrcteffcctprfrmnc}</Typography>
            <Stack flexDirection="row" gap="15px">
                <NavItem label={text.apr} to="apr"/>
                <NavItem label={text.ftrs} to="ftrs"/>
                <NavItem label={text.avntgs} to="avntgs"/>
                <NavItem label={text.cldrsst} to="cldrsst"/>
                <NavItem label={text.nsprdts} to="nsprdts"/>
            </Stack>
        </Stack>
    </Stack>
}

export default LandingHeader