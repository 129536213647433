import React from "react";
import { Box, Grid, Stack, Typography } from "@mui/material";
import useLanguage from "../hooks/useLanguage";
import CButton from "./CButton";

const Item = ({ data:{title, imageName, content, bg} }) => {
    return <Grid item xs={12} sm={6}>
        <Stack flexDirection="row" gap="15px">
            <Stack sx={{ background: bg, borderRadius: "50%", marginTop: "20px", padding: "7px", height: "50px", boxSizing: "border-box" }}>
                <Box component="img" src={`/images/landing/${imageName}`} sx={{ height: "100%", aspectRatio: "1/1" }}/>
            </Stack>
            <Stack spacing={1}>
                <Typography variant="h4" fontWeight="bold" color="#d68432">{title}</Typography>
                <Typography>{content}</Typography>
            </Stack>
        </Stack>
    </Grid>
}

const Cqdiff = () => {
    const { text } = useLanguage()
    const cqdiff = [
        { title: text.cqdffttl1, imageName: "cqd1.png", content: text.cqdfftxt1, bg: "#fe9800" },
        { title: text.cqdffttl2, imageName: "cqd2.png", content: text.cqdfftxt2, bg: "#fe9800" },
        { title: text.cqdffttl3, imageName: "cqd3.png", content: text.cqdfftxt3, bg: "#f5af19" },
        { title: text.cqdffttl4, imageName: "cqd4.png", content: text.cqdfftxt4, bg: "#f5af19" },
    ]

    return <Stack spacing={5} alignItems="center" minHeight="100vh" justifyContent="flex-end" paddingBottom="35px" boxSizing="border-box">
        <Typography variant="hometitle" textAlign="center" fontWeight="bold" color="#fff" sx={{ WebkitTextStroke: "2px #d48b70", fontFamily: "PlayFairDisplay", zIndex: 2, paddingRight: "25px" }}>{text.cqdfftm}</Typography>
        <Stack sx={{ flexDirection: { xs: "column", md: "row" }, padding: "0 10px" }}>
            <Stack sx={{ width: { xs: "100%", md: "35%" } }}>
                <Box component="img" src="/images/landing/cqd.png" sx={{ width: "90%" }}/>
            </Stack>
            <Stack id="avntgs" sx={{ width: { xs: "100%", md: "65%" } }} spacing={3}>
                <Typography>{text.cqdffttl}</Typography>
                <Grid container rowGap="30px">
                    { cqdiff.map((data, i) => <Item key={i} data={data}/>)}
                </Grid>
            </Stack>
        </Stack>
        <CButton>{text.rqstdmo}</CButton>
    </Stack>
}

export default Cqdiff