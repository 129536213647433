import { Stack, Typography, Button, DialogTitle, DialogContent, Box, DialogActions, Pagination } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import useLanguage from "../hooks/useLanguage";
import BigContainerAccordion from "../tools/BigContainerAccordion";
import TableRow from "../tools/TableRow";
import useData from "../hooks/useData";
import SearchFilter from "../tools/SearchFilter";
import { AuthStateContext } from "../state/AuthStateContext";
import axiosInstance from "../../axios";
import CustomPopup from "../Admin/CustomPopup";
import CustomAlert from "../Admin/CustomAlert";
import Pusher from 'pusher-js'
import { CLUSTER, PUSHER_SECRET, apiURL } from "../../helper/conf";
import { formatDate } from "../../helper/formatDate";
import { GifsContext } from "../state/gifContext";
import Title from "../Admin2/Comps/Title";

const LogBook = () =>{
    const { globalFilter } = useContext(GifsContext)
    const {text} = useLanguage()
    const { logBooks, missingLogBooks,languagesList } = useData()
    const { authState } = useContext(AuthStateContext)
    const [ loading, setLoading ] = useState()
    const [ alert, setAlert ] = useState()
    const [ alertContenu, setAlertContenu ] = useState()

    const [filter, setFilter] = useState(() => ({
        ...globalFilter,
        nature: '',
        content: ''
    }))

    const [filter2, setFilter2] = useState(() => ({
        ...globalFilter,
        object: ''
    }))


    // LIST ALL INFORMATION
    const [ page, setPage] = useState(1)
    const [ pagination, setPagination ] = useState()
    const [ info, setInfo] = useState()
    const handleChange = (event, value) => {
        setPage(value);
    };
    useEffect(()=>{ 
        console.log(filter)
        axiosInstance.get(`/information/AllList?year=${filter?.year}&month=${filter?.month}&date=${formatDate(filter?.date).split('-')[0]}&startingDate=${formatDate(filter?.startingDate)}&closingDate=${formatDate(filter?.closingDate)}&platforms=${filter?.platforms}&language=${filter?.language}&agents=${filter?.agents}&content=${filter?.content}&nature=${filter?.nature}&page=${page}`, {
            headers: {
                'Authorization': `Bearer ${authState.token}`
            }
        })
        .then((response)=>{
            console.log(response.data.data)
            setInfo(response.data.data)
            setPagination(response.data.pagination)
        })
        .catch((error)=>{
            console.log(error)
        })
    },[page, filter])
    
    // LIST ALL MEETING
    const [ pageMeet, setPageMeet] = useState(1)
    const [ paginationMeet, setPaginationMeet ] = useState()
    const [ meet, setMeet] = useState()
    const handleChangeMeet = (event, value) => {
        setPageMeet(value);
    };
    useEffect(()=>{
        axiosInstance.get(`/meeting/AllList?year=${filter2?.year}&month=${filter2?.month}&date=${formatDate(filter2?.date).split('-')[0]}&startingDate=${formatDate(filter2?.startingDate)}&closingDate=${formatDate(filter2?.closingDate)}&platforms=${filter2?.platforms}&language=${filter2?.language}&agents=${filter2?.agents}&object=${filter2?.object}&page=${pageMeet}`, {
            headers: {
                'Authorization': `Bearer ${authState.token}`
            }
        })
        .then((response)=>{
            console.log(response.data.data)
            setMeet(response.data.data)
            setPaginationMeet(response.data.pagination)
        })
        .catch((error)=>{
            console.log(error)
        })
    },[pageMeet, filter2])

    // DELETE MEETING
    const [ meetToDelete, setMeetToDelete ] = useState()
    const [ openDelete, setOpenDelete ] = useState(false)
    const handleDelete = () => {
        setLoading(true)
        axiosInstance.delete(`/meeting/delete/${meetToDelete?.id}`, {
            headers: {
                'Authorization': `Bearer ${authState.token}`
            }
        })
        .then((response)=>{
            console.log(response.data)
            setAlertContenu(text.delalert)
            setAlert(true)
            setMeet((last)=>last.filter((a)=>a.id !== meetToDelete?.id))
            setLoading(false)
            setOpenDelete(false)
        })
        .catch((error)=>{
            console.log(error)
            setLoading(false)
        })
    }

    // DELETE PERMANENT
    const [ infoToDelete, setInfoToDelete ] = useState()
    const [ openInfoDelete, setInfoOpenDelete ] = useState(false)
    const handleInfoDelete = () => {
        setLoading(true)
        axiosInstance.delete(`/information/delete/${infoToDelete?.id}`, {
            headers: {
                'Authorization': `Bearer ${authState.token}`
            }
        })
        .then((response)=>{
            console.log(response.data)
            setAlertContenu(text.delalert)
            setAlert(true)
            setInfo((last)=>last.filter((a)=>a.id !== infoToDelete?.id))
            setLoading(false)
            setInfoOpenDelete(false)
        })
        .catch((error)=>{
            console.log(error)
            setLoading(false)
        })
    }

    //PUSHER LOGBOOK
    useEffect(()=>{
        const pusher = new Pusher(PUSHER_SECRET,{cluster:CLUSTER,channelAuthorization:{endpoint:`${apiURL}/pusher/user-auth`,transport:'jsonp'}})
        const channel = pusher.subscribe('private-logbook')
        channel.bind(`add-infos-${authState.userId}`, (data)=> {
            console.log("info :", data)
            setInfo((prev)=>[data, ...prev])
        })
        channel.bind(`add-meeting-${authState.userId}`, (data)=> {
            console.log("meeting :", data)
            setMeet((prev)=>[data, ...prev])
        })
        return()=>{
            channel.unbind()
            pusher.unsubscribe('private-logbook')
        }
    },[])

    return(
        <Stack spacing={2} style={{ overflowY: "scroll" }}>
            <CustomAlert
                open={alert}
                autoHideDuration={1500}
                onClose={() => setAlert(false)}
                severity="success"
                message={alertContenu}
            />

            {/* pop up delete information */}
            <CustomPopup open={openInfoDelete} onClose={() => setInfoOpenDelete(false)}>
                <div style={{ padding: "18px 30px"}}>
                    <DialogTitle id="alert-dialog-title" sx={{textAlign: "center"}}>
                        <p style={{ fontWeight: "800", fontSize: "22px"}}>
                            {text.dltinfo}
                        </p>
                    </DialogTitle>
                    <DialogContent>
                        <Box>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <Stack sx={{ mb: "8px", fontWeight: "700", fontSize: "20px", mr: "6px" }}>
                                    {text.idd} :
                                </Stack>
                                <Stack sx={{ mb: "8px", fontWeight: "700", fontSize: "20px", color: "#757575" }}>
                                    {infoToDelete?.id}
                                </Stack>
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <Stack sx={{ mb: "8px", fontWeight: "700", fontSize: "20px", mr: "6px" }}>
                                    {text.contenu} :
                                </Stack>
                                <Stack sx={{ mb: "8px", fontWeight: "700", fontSize: "20px", color: "#757575" }}>
                                    {infoToDelete?.content}
                                </Stack>
                            </div>
                        </Box>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            variant='contained' 
                            sx={{ 
                                fontWeight: "700", 
                                bgcolor: "error.main",
                                color: "white",
                                "&:hover": {
                                    bgcolor: "error.dark",
                                } 
                            }} 
                            onClick={() => setInfoOpenDelete(false)}
                        >
                            {text.annuler}
                        </Button>
                        <Button 
                            variant='contained' 
                            sx={{ 
                                fontWeight: "700", 
                                bgcolor: "success.main", 
                                color: "white",
                                "&:hover": {
                                    bgcolor: "success.dark",
                                } 
                            }} 
                            onClick={()=> handleInfoDelete()}
                        >
                            {loading ? text.phload : text.cnfrm}
                        </Button>
                    </DialogActions>
                </div>
            </CustomPopup>

            {/* pop up delete meeting */}
            <CustomPopup open={openDelete} onClose={() => setOpenDelete(false)}>
                <div style={{ padding: "18px 30px"}}>
                    <DialogTitle id="alert-dialog-title" sx={{textAlign: "center"}}>
                        <p style={{ fontWeight: "800", fontSize: "22px"}}>
                            {text.dltrdv}
                        </p>
                    </DialogTitle>
                    <DialogContent>
                        <Box>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <Stack sx={{ mb: "8px", fontWeight: "700", fontSize: "20px", mr: "6px" }}>
                                    {text.obj} :
                                </Stack>
                                <Stack sx={{ mb: "8px", fontWeight: "700", fontSize: "20px", color: "#757575" }}>
                                    {meetToDelete?.objet}
                                </Stack>
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <Stack sx={{ mb: "8px", fontWeight: "700", fontSize: "20px", mr: "6px" }}>
                                    {text.date} :
                                </Stack>
                                <Stack sx={{ mb: "8px", fontWeight: "700", fontSize: "20px", color: "#757575" }}>
                                    {meetToDelete?.date.slice(0,10)}
                                </Stack>
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <Stack sx={{ mb: "8px", fontWeight: "700", fontSize: "20px", mr: "6px" }}>
                                    {text.plc} :
                                </Stack>
                                <Stack sx={{ mb: "8px", fontWeight: "700", fontSize: "20px", color: "#757575" }}>
                                    {meetToDelete?.place}
                                </Stack>
                            </div>
                        </Box>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            variant='contained' 
                            sx={{ 
                                fontWeight: "700", 
                                bgcolor: "error.main",
                                color: "white",
                                "&:hover": {
                                    bgcolor: "error.dark",
                                } 
                            }} 
                            onClick={() => setOpenDelete(false)}
                        >
                            {text.annuler}
                        </Button>
                        <Button 
                            variant='contained' 
                            sx={{ 
                                fontWeight: "700", 
                                bgcolor: "success.main", 
                                color: "white",
                                "&:hover": {
                                    bgcolor: "success.dark",
                                } 
                            }} 
                            onClick={()=> handleDelete()}
                        >
                            {loading ? text.phload : text.cnfrm}
                        </Button>
                    </DialogActions>
                </div>
            </CustomPopup>
            <Stack style={{display: 'flex', alignItems: 'center', gap: '15px', justifyContent: 'center', flexDirection: 'row'}}>
                {/* <Typography variant="h1" alignSelf='center' style={{fontFamily: 'Fascinate Regular', fontWeight: 'bold', color: '#842426', WebkitTextStroke: '2px #400102'}}
                >{text.logbook}</Typography> */}
                <Title>{text.logbook}</Title>
                {/* <img className="card-img-resize-2" src={"/images/supervisor/10lo.png"} alt="" height='50px' width='50px'/> */}
            </Stack>

            {/* Meeting */}
            <BigContainerAccordion 
                transparent
                summaryBg="#9370DB" 
                // summary={text.mtng}
                summary={text.otherimp1}
                details={
                    <Stack spacing={2}>
                        <SearchFilter
                            selectedDate={filter2.date}
                            onDateChange={{
                                year: y => setFilter2({...filter2, year: y}),
                                month: m => setFilter2({...filter2, month: m}),
                                date: d => setFilter2({...filter2, date: d}),
                            }}
                            items= {[
                                { label: text.startingdate , comp: 'DatePicker', selected: filter2.startingDate, onChange: d => setFilter2({...filter2, startingDate: d})},
                                { label: text.closingdate , comp: 'DatePicker', selected: filter2.closingDate, onChange: d => setFilter2({...filter2, closingDate: d})},
                                { label: text.platform , comp: 'TextInput', onChange: e => setFilter2({...filter2, platforms: e.target.value}) },
                                { label: text.language, comp: 'Select', choices: languagesList, onChange: e => setFilter2({ ...filter2, language: e.target.value }) },
                                { label: text.obj , comp: 'TextInput', onChange: e => setFilter2({...filter2, object: e.target.value}) },
                                { label: text.agent , comp: 'TextInput', onChange: e => setFilter2({...filter2, agents: e.target.value}) },
                            ]}
                        />
                        <Stack spacing={'10px'}>
                            <TableRow header columns={[
                                { xs: .7,  text: text.idlgbk},
                                { xs: 1.0, text: text.plc},
                                { xs: .8,  text: text.date},
                                { xs: .8,  text: text.time},
                                { xs: 1.0,  text: text.obj},
                                { xs: .8,  text: text.platform},
                                { xs: 1.0,  text: text.agent},
                                { xs: 1.0,  text: text.rp},
                                { xs: 1.0,  text: text.fp},
                                { xs: .7,  text: text.language},
                                { xs: 1.0, text: text.esqv},
                                { xs: 1.0,  text: text.actions},
                            ]}/>
                            {
                                meet?.map((l, i) =>
                                    <TableRow key={`l${i}`} columns={[
                                        { xs: .7,  text: l.id},
                                        { xs: 1.0,  text: l.place},
                                        { xs: .8, text: l.date},
                                        { xs: .8,  text: l.time},
                                        { xs: 1.0,  text: l.object},
                                        { xs: .8,  text: l.platform},
                                        { xs: 1.0,  text: l.agent},
                                        { xs: 1.0,  text: l.sender_profil.Name},
                                        { xs: 1.0,  text: l.receiver_profil.Name},
                                        { xs: .7,  text: l.language},
                                        { xs: 1, text:l.dodge ? l.dodge : ''},
                                        {
                                            xs: 1.0,
                                            customContent: true,
                                            content: <Stack>
                                                <Button style={{width: '100%', height: '100%', textTransform: 'capitalize', color: '#000'}}>
                                                    <Typography style={{fontFamily: 'Delm'}}>
                                                        {text.edit}
                                                    </Typography>
                                                </Button>                       
                                                <Button 
                                                    style={{width: '100%', height: '100%', textTransform: 'capitalize', color: '#000'}}
                                                    onClick= {() => {setMeetToDelete(l); setOpenDelete(true)}}
                                                >
                                                    <Typography style={{fontFamily: 'Delm'}}>
                                                        {text.dlt}
                                                    </Typography>
                                                </Button>                       
                                            </Stack>
                                        },
                                    ]} number={l} stat={10} ind={i > -1 ? true:false}/>
                                )
                            }
                        </Stack>
                        <Stack justifyItems="center" alignItems="center" >
                            <Pagination 
                                page={pageMeet}
                                onChange={handleChangeMeet}
                                count={paginationMeet?.total_pages}
                                variant="outlined"
                                shape="rounded" 
                                color="secondary"
                            />
                        </Stack>
                    </Stack>
                }
            />

            {/* Information */}
            <BigContainerAccordion 
                transparent
                summaryBg="#45ce9d"
                summary={text.infopernoper}
                details={
                    <Stack spacing={2}>
                        <SearchFilter
                            selectedDate={filter.date}
                            onDateChange={{
                                year: y => setFilter({...filter, year: y}),
                                month: m => setFilter({...filter, month: m}),
                                date: d => setFilter({...filter, date: d}),
                            }}
                            items= {[
                                { label: text.startingdate , comp: 'DatePicker', selected: filter.startingDate, onChange: d => setFilter({...filter, startingDate: d})},
                                { label: text.closingdate , comp: 'DatePicker', selected: filter.closingDate, onChange: d => setFilter({...filter, closingDate: d})},
                                { label: text.platform , comp: 'TextInput', onChange: e => setFilter({...filter, platforms: e.target.value}) },
                                { label: text.language, comp: 'Select', choices: languagesList, onChange: e => setFilter({ ...filter, language: e.target.value }) },
                                { label: text.nat , comp: 'TextInput', onChange: e => setFilter({...filter, nature: e.target.value}) },
                                { label: text.agent , comp: 'TextInput', onChange: e => setFilter({...filter, agents: e.target.value}) },
                                { label: text.contenu , comp: 'TextInput', onChange: e => setFilter({...filter, content: e.target.value}) },
                            ]}
                        />
                        <Stack spacing={'10px'}>
                            <TableRow header columns={[
                                { xs: 1.0,  text: text.date},
                                { xs: 1.0,  text: text.platform},
                                { xs: 1.0, text: text.language },
                                { xs: 1.0,  text: text.idlgbk},
                                { xs: 1.0,  text: text.nat},
                                { xs: 1.0,  text: text.infs},
                                { xs: 1.0,  text: text.agent},
                                { xs: 1.0,  text: text.rp},
                                { xs: 1.0,  text: text.fp},
                                { xs: 1.0,  text: text.lvlofimprt},
                                { xs: 1.0,  text: text.actions},
                            ]}/>
                            {
                                info?.map((l, i) =>
                                    <TableRow key={`l${i}`} columns={[
                                        { xs: 1.0,  text: l.createdAt},
                                        { xs: 1.0,  text: l.platform},
                                        { xs: 1.0, text: l.language},
                                        { xs: 1.0,  text: l.id},
                                        { xs: 1.0,  text: l.nature},
                                        { xs: 1.0,  text: l.content},
                                        { xs: 1.0,  text: l.agent},
                                        { xs: 1.0,  text: l.vp.name},
                                        { xs: 1.0,  text: l.fp.name},
                                        { xs: 1.0,  text: l.permanent ? text.prmnnt : text.ntprmnnt, color: l.permanent ? '#0d51af' : '#36bc55'},
                                        {
                                            xs: 1.0,
                                            customContent: true,
                                            content: <Stack>
                                                <Button 
                                                    style={{width: '100%', height: '100%', textTransform: 'capitalize', color: '#000'}}
                                                >
                                                    <Typography style={{fontFamily: 'Delm'}}>
                                                        {text.edit}
                                                    </Typography>
                                                </Button>                       
                                                <Button 
                                                    style={{width: '100%', height: '100%', textTransform: 'capitalize', color: '#000'}}
                                                    onClick= {() => {setInfoToDelete(l); setInfoOpenDelete(true)}}
                                                >
                                                    <Typography style={{fontFamily: 'Delm'}}>
                                                        {text.dlt}
                                                    </Typography>
                                                </Button>                       
                                            </Stack>
                                        },
                                    ]}/>
                                )
                            }
                        </Stack>
                        <Stack justifyItems="center" alignItems="center" >
                            <Pagination 
                                page={page}
                                onChange={handleChange}
                                count={pagination?.total_pages}
                                variant="outlined" 
                                shape="rounded" 
                                color="secondary"
                            />
                        </Stack>
                    </Stack>
                }
            />
            <BigContainerAccordion 
                transparent
                summaryBg="#ff3333"
                summary={text.mssnglgbk}
                details={
                    <Stack spacing={2}>
                        <SearchFilter
                            selectedDate={filter2.date}
                            onDateChange={{
                                year: y => setFilter2({...filter2, year: y}),
                                month: m => setFilter2({...filter2, month: m}),
                                date: d => setFilter2({...filter2, date: d}),
                            }}
                            items= {[
                                { label: text.startingdate , comp: 'DatePicker', selected: filter2.startingDate, onChange: d => setFilter2({...filter2, startingDate: d})},
                                { label: text.closingdate , comp: 'DatePicker', selected: filter2.closingDate, onChange: d => setFilter2({...filter2, closingDate: d})},
                                { label: text.platform , comp: 'TextInput', onChange: e => setFilter2({...filter2, platforms: e.target.value}) },
                                { label: text.language, comp: 'Select', choices: languagesList, onChange: e => setFilter({ ...filter, language: e.target.value }) },
                                { label: text.lgbktp , comp: 'TextInput', onChange: e => setFilter2({...filter2, logbooktype: e.target.value}) },
                                { label: text.agent , comp: 'TextInput', onChange: e => setFilter({...filter, agent: e.target.value}) },
                            ]}
                        />
                        <Stack spacing={'10px'}>
                            <TableRow header columns={[
                                { xs: 1.2,  text: text.date},
                                { xs: 1.2,  text: text.platform},
                                { xs: 1.2, text: text.language },
                                { xs: 1.2,  text: text.idconv},
                                { xs: 1.2,  text: text.idmess},
                                { xs: 1.2,  text: text.mssnglgbk},
                                { xs: 1.2,  text: text.agent},
                                { xs: 1.2,  text: text.lvlofimprt},
                                { xs: 1.2,  text: text.actions},
                            ]}/>
                            {
                                missingLogBooks.map((l, i) =>
                                    <TableRow key={`l${i}`} columns={[
                                        { xs: 1.2,  text: l.date},
                                        { xs: 1.2,  text: l.platform},
                                        { xs: 1.2, text: l.language ? l.language.charAt(0).toUpperCase() + l.language.charAt(1) : 'Fr' },
                                        { xs: 1.2,  text: l.idconv},
                                        { xs: 1.2,  text: l.idmess},
                                        { xs: 1.2,  text: l.missingLogBook},
                                        { xs: 1.2,  text: l.agent},
                                        { xs: 1.2,  text: l.permanent ? text.prmnnt : text.ntprmnnt, color: l.permanent ? '#0d51af' : '#36bc55'},
                                        {
                                            xs: 1.2,
                                            customContent: true,
                                            content: <Stack>
                                                <Button style={{width: '100%', height: '100%', textTransform: 'capitalize', color: '#000'}}><Typography style={{fontFamily: 'Delm'}}>{text.mrklgbk}</Typography></Button>                       
                                                <Button style={{width: '100%', height: '100%', textTransform: 'capitalize', color: '#000'}}><Typography style={{fontFamily: 'Delm'}}>{text.infrmagnt}</Typography></Button>                       
                                            </Stack>
                                        },
                                    ]}/>
                                )
                            }
                        </Stack>
                    </Stack>
                }
            />
        </Stack>
    )
}

export default LogBook