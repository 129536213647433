import { Typography, Box, Stack, Accordion, AccordionDetails, AccordionSummary, IconButton, DialogActions, Button, DialogTitle, DialogContent, TextField, Badge, styled, MenuItem, Select, FormControl, Grid } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import useLanguage from "../../hooks/useLanguage";
import TabOtherInfo1 from "./TabOtherInfo1";
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import useData from "../../hooks/useData";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import CustomAlert from "../../Admin/CustomAlert";
import { AuthStateContext } from "../../state/AuthStateContext";
import CustomPopup from "../../Admin/CustomPopup";
import axiosInstance from "../../../axios.js";
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { useForm } from "react-hook-form";
import AuthFormRow from "../../tools/AuthFormRow.jsx";
import { formatDate } from "../../../helper/formatDate";
import NotificationsIcon from '@mui/icons-material/Notifications';
import { MessageContext } from "../../state/MessageContext.jsx";
import { MeetStateContext } from "../../state/MeetingContext.jsx";
import useMessage from "../../hooks/useMessage.jsx";
import BorderColorIcon from '@mui/icons-material/BorderColor';
import FormRow from "../../tools/FormRow.jsx";
import { format } from "date-fns";

const YellowNotificationsIcon = styled(NotificationsIcon)(({ theme }) => ({
    color: '#FFAA00',
}));

const Meetings = ({ type, meet }) => {
    // const { control, reset, handleSubmit, formState: { errors } } = useForm()
    const { authState } = useContext(AuthStateContext)
    const { messageState } = useContext(MessageContext)
    const { setMeet } = useContext(MeetStateContext)
    const { text } = useLanguage()
    const { typeMeet,typeMeet2 } = useData()

    const [loading, setLoading] = useState()
    const [alert, setAlert] = useState()
    const [alertContenu, setAlertContenu] = useState()
    const [meeting, setMeeting] = useState()
    const [isVp, setIsVp] = useState()

    useEffect(() => {
        if (type === "fake_profil") {
            setMeeting(meet?.fp);
            setIsVp(false)
        } else {
            console.log(meet?.vp)
            setMeeting(meet?.vp);
            setMeet(meet?.vp)
            setIsVp(true)
        }
    }, [type, meet]);

    // ADD MEETING
    const [meetToAdd, setMeetToAdd] = useState()
    const [openAdd, setOpenAdd] = useState(null)

    // DELETE MEETING
    const [meetToDelete, setMeetToDelete] = useState()
    const [openDelete, setOpenDelete] = useState(false)
    const handleDelete = () => {
        setLoading(true)
        axiosInstance.delete(`/meeting/delete/${meetToDelete?.id}`, {
            headers: {
                'Authorization': `Bearer ${authState.token}`
            }
        })
            .then((response) => {
                console.log(response.data)
                setAlertContenu(text.delalert)
                setAlert(true)
                const meet = meeting.filter((a) => a.id !== meetToDelete?.id)
                setMeeting(meet)
                setMeet(meet)
                setLoading(false)
                setOpenDelete(false)
            })
            .catch((error) => {
                console.log(error)
                setLoading(false)
            })
    }
const getTrad = (name) => {
    switch (name) {
        case 'Réunion': return text.meeting
        case 'Vacances': return text.holiday
        case 'Autres' : return text.other
        default: return text.rdv
    }
}
const openDel = (mt) => {
    setMeetToDelete(mt); setOpenDelete(true)
}
const MeetingC = () => {
    const canAdd = openAdd && openAdd.type
    console.log('obj ',openAdd)
    const dateSS = canAdd ? {date:openAdd.obj.date.split('-'),time:openAdd.obj.time.split(':')} : ''
    const dateS = canAdd ? new Date(parseInt(dateSS.date[0]),parseInt(dateSS.date[1])-1,parseInt(dateSS.date[2])):new Date()
    const { control,handleSubmit,formState: { errors },reset } = useForm()
    const [date, setDate] = useState(dateS);
    const [heure, setHeure] = useState(canAdd ? openAdd.obj.time : '');
    // const vHeure = heure.toISOString().slice(11, 16).split(":").map((v, i) => i == 0 ? (parseInt(v) + 3).toString() : v).join(":")

    const handleAdd = data => {
        setLoading(true)
        const datas = openAdd.type ? 
            { 
                id:openAdd.obj.id,
                date:formatDate(date),
                object: data.object,
                place: data.place,
                time: data.time
            }
            :
            {
                "date": formatDate(date),
                "object": data.object,
                "place": data.place,
                "time": data.time,
                'conv': messageState.convId,
                "isVpInformation": isVp
            }

        axiosInstance.post(openAdd && openAdd.type ? "/Meeting/Edit":"/meeting/add", datas, {
            headers: {
                'Authorization': `Bearer ${authState.token}`
            }
        })
        .then((response) => {
            console.log(response.data.message,response.data.data)
            setAlertContenu(text.addd)
            setAlert(true)
            const meet = openAdd.type ? meeting.map(p => p.id === openAdd.obj.id ? {...p,date:format(new Date(date), 'yyyy-MM-dd'),object: data.object,place: data.place,time: data.time}:p):[...meeting,response.data.data]
            setMeeting(meet)
            if(type !== 'fake_profil') setMeet(meet)
            setLoading(false)
            setOpenAdd(null)
            reset()
            setDate(new Date())
            setHeure(new Date())
        })
        .catch((error) => {
            console.log(error)
            setLoading(false)
        })
    }

    return(
        <div style={{ padding: "18px 30px" }}>
                    <DialogTitle id="alert-dialog-title" sx={{ textAlign: "center",minWidth:'25em' }}>
                        <p style={{ fontWeight: "800", fontSize: "22px" }}>
                            {canAdd ? text.editInfo:text.nwrdv} :
                        </p>
                    </DialogTitle>
                    {openAdd &&
                    <DialogContent>
                        <Box flexDirection="row">
                    <div style={{ marginBottom: "12px",width:'100%' }}>
                        <FormRow txtCol="#fff" defaultValue={openAdd.type ? openAdd.obj.object:''} bgCol="#d48b70" label='Object' name='object' comp='Select' options={type === 'fake_profil' ? typeMeet2 : typeMeet} control={control} />
                    </div>
                    <div style={{ marginBottom: "12px" }}>
                        <FormRow
                            defaultValue={openAdd.type ? openAdd.obj.place:''}
                            txtCol="#fff"
                            bgCol="#d48b70"
                            label={text.plc}
                            name='place'
                            control={control}
                            error={errors.name}
                        />
                            </div>
                            <div style={{ marginBottom: "12px" }}>
                                <Grid container gap='15px'>
                                    <Grid item xs={3.5} variant="maroon" style={{ minHeight: '50px' }}>
                                        <Typography variant="white" style={{
                                            height: '100%',
                                            display: 'flex', alignItems: 'center', justifyContent: 'center',fontSize:'1.3em', fontFamily:'Chewy Pro'
                                        }}>
                                            {text.date}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={4.5}>
                                        <DatePicker
                                            showIcon
                                            popperPlacement="top-end"
                                            selected={date}
                                            onChange={(date) => setDate(date)}
                                            dateFormat="dd/MM/yyyy"
                                            className="datepickerW"
                                        />

                                    </Grid>
                                </Grid>
                            </div>
                            <div style={{ marginBottom: "12px" }}>
                                <FormRow
                                    defaultValue={openAdd.type ? openAdd.obj.time : ''}
                                    txtCol="#fff"
                                    bgCol="#d48b70"
                                    label={text.heure}
                                    name='time'
                                    control={control}
                                    error={errors.name}
                                />
                                {/* <Grid container gap='15px'>
                                    <Grid item xs={3.5} variant="maroon" style={{ minHeight: '50px' }}>
                                        <Typography variant="white" style={{
                                            height: '100%',
                                            display: 'flex', alignItems: 'center', justifyContent: 'center',fontSize:'1.3em', fontFamily:'Chewy Pro'
                                        }}>
                                            {text.heure}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={7.5}>
                                        <DatePicker
                                            selected={heure}
                                            onChange={(date) => setHeure(date)}
                                            showTimeSelect
                                            showTimeSelectOnly
                                            timeIntervals={15}
                                            timeCaption="Time"
                                            dateFormat="HH:mm"
                                            className="datepickerW"
                                        />
                                    </Grid>
                                </Grid> */}
                            </div>
                        </Box>
                    </DialogContent>}
                    <DialogActions>
                        <Button
                            variant='contained'
                            sx={{
                                fontWeight: "700",
                                bgcolor: "error.main",
                                color: "white",
                                "&:hover": {
                                    bgcolor: "error.dark",
                                }
                            }}
                            onClick={() => setOpenAdd(null)}
                        >
                            {text.annuler}
                        </Button>
                        <Button
                            variant='contained'
                            sx={{
                                fontWeight: "700",
                                bgcolor: "success.main",
                                color: "white",
                                "&:hover": {
                                    bgcolor: "success.dark",
                                }
                            }}
                            onClick={handleSubmit(handleAdd)}
                        >
                            {loading ? text.phload : text.cnfrm}
                        </Button>
                    </DialogActions>
                </div>
    )
}
    return (
        <Box padding=" 10px 00px">
            <CustomAlert
                open={alert}
                autoHideDuration={1500}
                onClose={() => setAlert(false)}
                severity="success"
                message={alertContenu}
            />

            {/* pop up add */}
            <CustomPopup open={openAdd ? true:false} onClose={() => setOpenAdd(null)}>
                <MeetingC/>
            </CustomPopup>

            {/* pop up delete */}
            <CustomPopup open={openDelete} onClose={() => setOpenDelete(false)}>
                <div style={{ padding: "18px 30px" }}>
                    <DialogTitle id="alert-dialog-title" sx={{ textAlign: "center" }}>
                        <p style={{ fontWeight: "800", fontSize: "22px" }}>
                            {text.dltrdv}
                        </p>
                    </DialogTitle>
                    <DialogContent>
                        <Box>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <Stack sx={{ mb: "8px", fontWeight: "700", fontSize: "20px", mr: "6px" }}>
                                    {text.obj} :
                                </Stack>
                                <Stack sx={{ mb: "8px", fontWeight: "700", fontSize: "20px", color: "#757575" }}>
                                    {meetToDelete?.object}
                                </Stack>
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <Stack sx={{ mb: "8px", fontWeight: "700", fontSize: "20px", mr: "6px" }}>
                                    {text.date}:
                                </Stack>
                                <Stack sx={{ mb: "8px", fontWeight: "700", fontSize: "20px", color: "#757575" }}>
                                    {meetToDelete?.date}
                                </Stack>
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <Stack sx={{ mb: "8px", fontWeight: "700", fontSize: "20px", mr: "6px" }}>
                                    {text.plc} :
                                </Stack>
                                <Stack sx={{ mb: "8px", fontWeight: "700", fontSize: "20px", color: "#757575" }}>
                                    {meetToDelete?.place}
                                </Stack>
                            </div>
                        </Box>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            variant='contained'
                            sx={{
                                fontWeight: "700",
                                bgcolor: "error.main",
                                color: "white",
                                "&:hover": {
                                    bgcolor: "error.dark",
                                }
                            }}
                            onClick={() => setOpenDelete(false)}
                        >
                            {text.annuler}
                        </Button>
                        <Button
                            variant='contained'
                            sx={{
                                fontWeight: "700",
                                bgcolor: "success.main",
                                color: "white",
                                "&:hover": {
                                    bgcolor: "success.dark",
                                }
                            }}
                            onClick={() => handleDelete()}
                        >
                            {loading ? text.phload : text.cnfrm}
                        </Button>
                    </DialogActions>
                </div>
            </CustomPopup>

            <Accordion style={{ backgroundColor: "transparent", boxShadow: 'none' }}>

                <AccordionSummary style={{ minHeight: '20px',backgroundColor: "#057dcd", height: "30px", padding: '0', marginBottom: "4px" }} >
                    <Typography variant="white" style={{ textAlign: 'center', width: "85%" }}>
                        {type === 'fake_profil' ? text.otherimp1.split('/')[0]+'/'+text.otherimp1.split('/')[2]:text.otherimp1}
                    </Typography>
                        <Badge badgeContent={meeting?.length} color="secondary" showZero>
                            <YellowNotificationsIcon  style={{color:'#f9e238'}}/>
                        </Badge>
                </AccordionSummary>
                <AccordionDetails style={{ padding: '0' }}>
                    <Stack spacing={'2px'}>
                        <TabOtherInfo1 header columns={[
                            { xs: 2, text: text.obj, color: '#fff' },
                            { xs: 3, text: text.plc, color: '#fff' },
                            { xs: 2.6, text: text.date, color: '#fff' },
                            { xs: 2, text: text.time, color: '#fff' },
                            { xs: 2, text: text.actions, color: '#fff' },
                        ]} />
                        {
                            meeting?.map((mt, i) =>
                                <TabOtherInfo1 mt={mt} type={type} row={i} key={'mt' + i} columns={[
                                    { xs: 2, text: getTrad(mt.object),color:'#fff' },
                                    { xs: 3, text: mt.place },
                                    { xs: 2.6, text: mt.date },
                                    { xs: 2, text: mt.time },
                                    {
                                        xs: 2,
                                        customContent: true,
                                        content: <Stack style={{flexDirection:'row',gap:2}}>
                                            <Stack onClick={()=>!mt.status && setOpenAdd({type:true,obj:mt})}>
                                                <BorderColorIcon fontSize="small" style={{color:mt.status ? '#999999':'#82c8f9'}} />
                                            </Stack>
                                            <Stack onClick={() => !mt.status && openDel(mt)}>
                                                <HighlightOffIcon fontSize="small" style={{color:mt.status ? '#999999':'#ff7b7b'}}  />
                                            </Stack>
                                        </Stack>
                                    },

                                ]} />
                            )
                        }
                    </Stack>
                    <Stack display='flex' alignItems="flex-end">
                        <IconButton
                            style={{ width: "fit-content" }}
                            // onClick={()=>console.log('date : ',new Date(2024,5,15,12,10))}
                            onClick={() => setOpenAdd({type:false})}
                        >
                            <AddCircleIcon style={{color:'#45d6e5'}}/>
                        </IconButton>
                    </Stack>
                </AccordionDetails>
            </Accordion>

        </Box>
    )
}

export default Meetings