import React, { useState, useRef, useEffect, useContext } from "react";
import useLanguage from "../hooks/useLanguage";
import { Stack, Alert, Snackbar, Typography, Button, Box, Radio, RadioGroup, Accordion, AccordionDetails, AccordionSummary, Dialog, Grid, DialogTitle, DialogContent, DialogActions } from "@mui/material";

import TimeDisplayer from "../tools/timedisplayer";
import { useForm, Controller } from 'react-hook-form'
import { testData } from "../../DataUser";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import WiseGifs from "./Gifs/WiseGifs";
import SexualGifs from "./Gifs/SexualGifs";
import GayGifs from "./Gifs/GayGifs";
import LesbianGifs from "./Gifs/LesbianGifs";
import TransgenderGifs from "./Gifs/TransgenderGifs";
import TravestiteGifs from "./Gifs/TravestiteGifs"
import BodyMessage from "./BodyMessage";
import { GifsContext } from "../state/gifContext";
import { MessageContext } from "../state/MessageContext";
import useMessage from "../hooks/useMessage";
import { AuthStateContext } from "../state/AuthStateContext";
import Popup from "./Popup";
import Pusher from 'pusher-js'
import { apiURL, baseURL } from "../../helper/conf";
import { PUSHER_SECRET, CLUSTER } from "../../helper/conf";
import axiosInstance from "../../axios";
import DeleteIcon from '@mui/icons-material/Delete';
import CustomPopup from "../Admin/CustomPopup";
import CustomAlert from "../Admin/CustomAlert";
import Snackbars, { SendMessage } from "../tools/Snackbars";

const Message = ({ photoSeen }) => {
    const [alerto, setAlerto] = useState(false)
    const [alertContenu, setAlertContenu] = useState("")
    const { authState } = useContext(AuthStateContext)
    const { text } = useLanguage()
    const { control, handleSubmit } = useForm()
    const { messageState,images,setImage,onHoldCount } = useContext(MessageContext)
    const [open,setOpen] = useState(false)
    

    const { gifs } = useContext(GifsContext)
    const [gifL, setGifL] = useState([])
    const [photo, setPhoto] = useState([])
    
    

    
    const updateGifs = (newGifs) => {
        if (!gifL.includes(newGifs)) {
            setOpenAlert(2)
            setGifL([...gifL, newGifs]);
        } else {
            console.log("Le GIF est déjà dans le tableau.");
        }
    };

    const updatePhotos = (newPhoto) => {
        if(newPhoto.type == 'gift'){
            setPhoto([newPhoto]);
        }else{
            if (!photo.includes(newPhoto)) {
                const prev = photo.filter((p)=> p.type == 'photo' )
                setPhoto([...prev, newPhoto]);
            } else {
                console.log("Le Photo est déjà dans le tableau.");
            }
        }
    };
    const deletePhotos = (newPhoto) => {
        if (photo.includes(newPhoto)) {
            setPhoto(photo.filter((p) => p.id !== newPhoto.id));
        } else {
            console.log("Le Photo est déjà supprimer.");
        }
    };

    const gifsInput = useRef(null)
    const imageInput = useRef(null)




    //demande photo
    const [pop, setPop] = useState(false);
    const [openAlert, setOpenAlert] = useState(0)
    const handleClose = () => {
        setOpenAlert(0);
    };
    const handleClickOpen = () => {
        setPop(true);
    };
    const handleCl = () => {
        setPop(false);
    };

  
    //delete on the photo attachement list
    const [popPhoto, setPopPhoto] = useState(false)
    const [photoToDelete, setPhotoToDelete] = useState()
    const [loading, setLoading] = useState(false)
    const handelDelete = () => {
        setLoading(true)
        axiosInstance.delete(`/photo/delete/${photoToDelete.id}`, {
            headers: {
                'Authorization': `Bearer ${authState.token}`,
            },
        })
            .then((response) => {
                console.log(response.data.message)
                setAlertContenu(response.data.message)
                setPhotoToDelete(false)
                setLoading(false)
                setAlerto(true)
                setImage(images.filter((i) => i.id !== photoToDelete.id))
                setPopPhoto(false)
            })
            .catch((error) => {
                console.log(error)
                setLoading(false)
            })
    }

    // GET PHOTO CADEAU LIST
    const [ gifts, setGifts ] = useState()
    useEffect(()=>{
        axiosInstance.get('/photo_gifts', {
            headers: {
                'Authorization': `Bearer ${authState.token}`,
            },
        })
        .then((response) => {
            console.log(response.data.data)
            setGifts(response.data.data)
        })
        .catch((error) => {
            console.log(error)
        })
    },[])

    // PUSHER PHOTO CADEAU LIST
    useEffect(()=>{
        const pusher = new Pusher(PUSHER_SECRET,{cluster:CLUSTER,channelAuthorization:{endpoint:`${apiURL}/pusher/user-auth`,transport:'jsonp'}})
        const channel = pusher.subscribe("private-addGifts")
        channel.bind(`${authState?.userId}`, (data) => {
            setGifts((prev) => [ ...prev, ...data])
        })
        return()=>{
            channel.unbind()
            pusher.unsubscribe("private-addGifts")
        }
    },[])

    // PUSHER PHOTO CADEAU DELETE
    useEffect(()=>{
        const pusher = new Pusher(PUSHER_SECRET,{cluster:CLUSTER,channelAuthorization:{endpoint:`${apiURL}/pusher/user-auth`,transport:'jsonp'}})
        const channel = pusher.subscribe("private-deleteGifts")
        channel.bind(`${authState?.userId}`, (data) => {
            setGifts((prev) => prev.filter((d) => d.id != data.id))
        })
        return()=>{
            channel.unbind()
            pusher.unsubscribe("private-deleteGifts")
        }
    },[])


    // Request gifts
    const [ typeGifts, setTypeGifts ] = useState();
    const [ popGifts, setPopGifts ] = useState(false)
    const handleRequestGifts = () => {
        setLoading(true)
        axiosInstance.post("/giftsRequest/add", {
            "convId": messageState.convId,
            "type": typeGifts,
        }, {
            headers: { 
                'Authorization': `Bearer ${authState.token}`,
            },
        })
        .then((response) => {
            console.log(response.data.message)
            setTypeGifts("")
            setLoading(false)
            setAlertContenu(response.data.message)
            setAlerto(true)
            setPopGifts(false)
        })
        .catch((error) => {
            console.log(error)
        })
    }

    return (
        <Stack spacing={2} style={{ padding: '20px 0' }}>
            <CustomPopup open={popGifts} onClose={() => setPopGifts(false)}>
                <div style={{ padding: "18px 30px"}}>
                    <DialogTitle id="alert-dialog-title" sx={{ fontWeight: "800", fontSize: "32px"}}>
                        {text.reqGift2} :
                    </DialogTitle>
                    <DialogContent>
                        <Box>
                            <Stack sx={{ mb: "8px", fontWeight: "700"}}>
                                {text.reqGift1} :
                            </Stack>
                            <input 
                                value={typeGifts}
                                onChange={(e) => setTypeGifts(e.target.value)}
                                type="text" 
                                style={{ padding: "16px", width: "350px", fontSize: "20px", borderRadius: "10px"}} 
                            />
                        </Box>
                    </DialogContent>
                    <DialogActions>
                        <Button variant='contained' sx={{ fontWeight: "700"}} onClick={handleRequestGifts}>
                            { loading ? text.phload : text.phsend }
                        </Button>
                    </DialogActions>
                </div>
            </CustomPopup>
            <Snackbar
                open={openAlert ? true : false}
                autoHideDuration={1500}
                onClose={handleClose}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
                <Alert onClose={handleClose} severity="success">
                    {openAlert === 1 ? text.phalert : openAlert === 2 ? text.gifajt : ''}
                </Alert>
            </Snackbar>

            {/* custum for delete photo */}
            <CustomAlert
                open={alerto}
                autoHideDuration={1500}
                onClose={() => setAlerto(false)}
                severity="success"
                message={alertContenu}
            />
            <CustomPopup open={popPhoto} onClose={() => setPopPhoto(false)}>
                <div style={{ padding: "18px 30px" }}>
                    <DialogTitle id="alert-dialog-title" sx={{ textAlign: "center" }}>
                        <p style={{ fontWeight: "800", fontSize: "22px" }}>
                            {text.dltimg}
                        </p>
                    </DialogTitle>
                    <DialogContent>
                        <Box>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <Stack sx={{ mb: "8px", fontWeight: "700", fontSize: "20px", mr: "6px" }}>
                                    {text.delauteur}:
                                </Stack>
                                <Stack sx={{ mb: "8px", fontWeight: "700", fontSize: "20px", color: "#757575" }}>
                                    {photoToDelete?.id}
                                </Stack>
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <Stack sx={{ mb: "8px", fontWeight: "700", fontSize: "20px", mr: "6px" }}>
                                    {text.deldesc}:
                                </Stack>
                                <Stack sx={{ mb: "8px", fontWeight: "700", fontSize: "20px", color: "#757575" }}>
                                    {photoToDelete?.photoName}
                                </Stack>
                            </div>
                        </Box>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            variant='contained'
                            sx={{
                                fontWeight: "700",
                                bgcolor: "error.main",
                                color: "white",
                                "&:hover": {
                                    bgcolor: "error.dark",
                                }
                            }}
                            onClick={() => setPopPhoto(false)}
                        >
                            {text.delrefuse}
                        </Button>
                        <Button
                            variant='contained'
                            sx={{
                                fontWeight: "700",
                                bgcolor: "success.main",
                                color: "white",
                                "&:hover": {
                                    bgcolor: "success.dark",
                                }
                            }}
                            onClick={handelDelete}
                        >
                            {loading ? text.phload : text.delaccept}
                        </Button>
                    </DialogActions>
                </div>
            </CustomPopup>
            
            <Stack display='flex' flexDirection='row' justifyContent='space-between' alignItems="center" style={{ height: '50px', backgroundColor: "#64483b", padding: "0 5px" }}> {/* Head */}
            <Snackbars open={open} setOpen={setOpen}/>
                <Typography variant='pfsboldwhite' textAlign='center'>
                    <Typography fontSize={14}>
                        {text.messageto} : {messageState.real_profil.user_name}
                    </Typography>
                    <Typography fontSize={13}>
                        {text.suty} : {messageState?.real_profil.subscription}
                    </Typography>
                </Typography>
                <TimeDisplayer />
                <Stack className="profilcontainer" style={{ backgroundColor: '#ffed00', borderRadius: '7px' }} display="flex" justifyContent="center" alignItems="center">
                    <Typography variant="pfsbold" padding="0 5px" textAlign='center'>{text.convatt}: {onHoldCount < 0 ? 0 : onHoldCount}</Typography>
                </Stack>
            </Stack>
            <SendMessage photo={photo} gifL={gifL}/>
            <Stack spacing={2} padding="0 10px">
                <Accordion style={{ backgroundColor: "transparent", boxShadow: 'none', paddingTop: '1.2em' }}>
                    <AccordionSummary style={{ width: "100%", backgroundColor: "#6cc9ff", cursor: "pointer", height: '2em', minHeight: '2.5em' }} expandIcon={<ExpandMoreIcon style={{ color: '#fff' }} />}>
                        <Typography variant="xswhite" style={{ textAlign: "center", width: '100%', fontWeight: "bold", textTransform: "uppercase" }}>{text.features}</Typography>
                    </AccordionSummary>
                    <Stack display="flex" justifyContent="center" alignItems="center" style={{ width: "40%", backgroundColor: "#76227e", height: '30px', marginTop: "10px" }}>
                        <Typography variant="xswhite" style={{ textAlign: "center", fontWeight: "bold" }}>{text.picalreadysent}</Typography>
                    </Stack>
                    <Grid container spacing={1}>
                        {photoSeen?.map((image, i) => (
                            <Grid item xs={3} key={i} sx={{ position: "relative" }}>
                                <div style={{ padding: '4px', fontWeight: '600' }}>
                                    <img src={`${baseURL}/photorequests/${image.photoName}`} style={{ width: '100px', height: '100px' }} alt="photo" />
                                </div>
                            </Grid>
                        ))}
                    </Grid>

                    <Stack flexDirection="row" justifyContent="space-between" sx={{ marginTop: "30px" }} >
                        <Stack display="flex" justifyContent="center" alignItems="center" style={{ width: "40%", backgroundColor: "#FF5733", height: '30px' }}>
                            <Typography variant="xswhite" style={{ textAlign: "center", fontWeight: "bold" }}>{text.pj}</Typography>
                        </Stack>

                        <Stack flexDirection='row' display='flex' justifyContent="center" alignItems='center' gap='5px' height='30px'>

                            <div style={{ display: "flex", bgcolor: "red", gap: "2px", flexDirection: "column"}}>
                                <Button 
                                    variant="hover" 
                                    style={{ width: 'fit-content', backgroundColor: "#1852bc" }} 
                                    onClick={handleClickOpen}
                                >
                                    <Typography variant="pfsboldwhite" style={{ textTransform: "capitalize", fontSize: "14px" }}>
                                        {text.ptreq}
                                    </Typography>
                                </Button>
                                <Button 
                                    variant="hover" 
                                    style={{ backgroundColor: "#3e2723" }} 
                                    onClick={() => setPopGifts(true) }
                                >
                                    <Typography variant="pfsboldwhite" style={{ textTransform: "capitalize", fontSize: "14px" }}>
                                        {text.reqGift}
                                    </Typography>
                                </Button>
                                <Dialog
                                    open={pop}
                                    onClose={handleCl}
                                    aria-labelledby="alert-dialog-title"
                                    aria-describedby="alert-dialog-description"
                                >
                                    <Popup handleCl={handleCl} setOpenAlert={setOpenAlert} convId={messageState?.convId} />
                                </Dialog>
                            </div>

                        </Stack>

                    </Stack>

                    <Stack flexDirection='row' sx={{ marginTop: "10px" }}>
                        <Controller
                            name="attachment"
                            control={control}
                            defaultValue='none'
                            render={({ field }) =>
                                <RadioGroup {...field} defaultValue="none" row>
                                    <Stack display="flex" flexDirection="row" gap={3} sx={{ width: "100%" }}>
                                        <Grid container spacing={1}>
                                            {images?.map((image) => (
                                                <Grid item xs={3} key={image.id} sx={{ position: "relative" }}>
                                                    <div style={{ padding: '4px', fontWeight: '600' }} onClick={() => updatePhotos(image)}>
                                                        <img src={`${baseURL}/photorequests/${image.photoName}`} style={{ width: '100px', height: '100px' }} alt="photo" />
                                                    </div>
                                                    <p
                                                        style={{ position: 'absolute', top: 1, right: 1, color: "red", cursor: "pointer" }}
                                                        onClick={() => { setPhotoToDelete(image); setPopPhoto(true); }}
                                                    >
                                                        <DeleteIcon />
                                                    </p>
                                                </Grid>
                                            ))}
                                        </Grid>
                                    </Stack>
                                    <Stack display="flex" justifyContent="center" alignItems="center" style={{ backgroundColor: "#76227e", height: '30px', marginTop: "10px" }}>
                                        <Typography variant="xswhite" style={{ textAlign: "center", fontWeight: "bold", padding: "0 20px" }}>
                                            {text.phttsnd}
                                        </Typography>
                                    </Stack>
                                    <Stack display="flex" flexDirection="row" gap={3} sx={{ width: "100%" }}>
                                        {photo?.length === 0 ?
                                            <Typography sx={{ fontWeight: 400, py: "14px", color: "gray" }}>
                                                {text.vd}
                                            </Typography>
                                            :
                                            <Grid container spacing={1}>
                                                {photo?.map((image) => {
                                                    return (
                                                        <Grid item xs={3} key={image.id}>
                                                            <div
                                                                style={{ padding: '4px', fontWeight: '600' }}
                                                                onClick={() => deletePhotos(image)}
                                                            >
                                                                <img src={`${baseURL}/photorequests/${image.photoName}`} style={{ width: '100px', height: '100px' }} alt="photo" />
                                                            </div>
                                                        </Grid>
                                                    )
                                                })}
                                            </Grid>
                                        }
                                    </Stack>
                                </RadioGroup>
                            }
                        />
                    </Stack>

                    <Stack display="flex" justifyContent="center" alignItems="center" style={{ width: "40%", backgroundColor: "#9d2e30", height: '30px' }}>
                        <Typography variant="xswhite" style={{ textAlign: "center", fontWeight: "bold" }}>{text.giftsatt}</Typography>
                    </Stack>

                    <Stack flexDirection='row'>
                        <Controller
                            name="giftsattachment"
                            control={control}
                            defaultValue='none'
                            render={({ field }) =>
                                <RadioGroup {...field} defaultValue="none" row>
                                    <Grid gap={3} container paddingTop='20px' paddingBottom='20px' columns={{xs:6}}>
                                        {gifts?.map((p)=>{
                                            return(
                                                <Grid key={p.id}>
                                                    <Stack>
                                                        <img src={`${baseURL}/photorequests/${p.photoName}`} alt="" style={{ height: "80px" }} />
                                                    </Stack>
                                                    <Stack onClick={() => updatePhotos(p)} >
                                                        <Radio value={p.photoName} />
                                                    </Stack>
                                                </Grid>
                                            )
                                        })}
                                    </Grid>
                                </RadioGroup>
                            }
                        />
                    </Stack>


                    <Stack style={{ width: "40%" }}>
                        <Accordion style={{ backgroundColor: "transparent", boxShadow: 'none' }}>

                            <AccordionSummary style={{ width: "100%", backgroundColor: "#de5b6d", cursor: "pointer", minHeight: '20px', height: "30px" }} expandIcon={<ExpandMoreIcon style={{ color: '#fff' }} />}>
                                <Typography variant="xswhite" style={{ textAlign: "center", width: '100%', fontWeight: "bold", textTransform: "uppercase" }}>{text.gifpj}</Typography>
                            </AccordionSummary>
                            <AccordionDetails style={{ cursor: "pointer", width: '100%' }}>
                                <WiseGifs setGifs={updateGifs} />
                            </AccordionDetails>
                            <AccordionDetails style={{ cursor: "pointer", width: '100%' }}>
                                <SexualGifs setGifs={updateGifs} />
                            </AccordionDetails>
                            <AccordionDetails style={{ cursor: "pointer", width: '100%' }}>
                                <GayGifs setGifs={updateGifs} />
                            </AccordionDetails>
                            <AccordionDetails style={{ cursor: "pointer", width: '100%' }}>
                                <LesbianGifs setGifs={updateGifs} />
                            </AccordionDetails>
                            <AccordionDetails style={{ cursor: "pointer", width: '100%' }}>
                                <TransgenderGifs setGifs={updateGifs} />
                            </AccordionDetails>
                            <AccordionDetails style={{ cursor: "pointer", width: '100%' }}>
                                <TravestiteGifs setGifs={updateGifs} />
                            </AccordionDetails>
                        </Accordion>
                    </Stack>

                    <Stack>
                        <Typography sx={{ fontWeight: 700 }}>
                            {text.gifn} : {gifL?.length}
                        </Typography>
                        <Box>
                            {gifL?.length === 0 ?
                                <Typography sx={{ fontWeight: 400, py: "14px", color: "gray" }}>
                                    {text.gifsel}
                                </Typography>
                                :
                                <Box>
                                    {gifL.map((gif, i) =>
                                        <span
                                            key={i}
                                            onClick={() => setGifL(p => p.filter((j) => j.id !== gif.id))}
                                            style={{ cursor: 'pointer' }}
                                        >
                                            <img src={`${baseURL}/gifs/${gif.name}`} style={{ width: "100px", height: "100px" }} alt="gif" />
                                        </span>
                                    )}
                                </Box>
                            }
                        </Box>
                        <Button sx={{ color: "black", fontWeight: 700 }} onClick={() => setGifL([])}>
                            {text.restfn}
                        </Button>
                    </Stack>
                </Accordion>
                {(messageState.type_mess !== 'message' && messageState.type_mess !== 'stop' && messageState.type_mess !== 'gif' && messageState.type_mess !== 'photo') ? 
                    <Stack style={{width:'100%',border:'2px solid #d48b70',height:'20em'}}>
                        <Stack style={{backgroundColor:'#d48b70',padding:'5px',color:'#fff'}}>{text.conv}: {messageState?.convId}</Stack>
                        <Stack style={{flexDirection:'row',height:'100%'}}>
                                <Stack style={{flex:1,placeItems:'center',justifyContent:'center'}}>
                                    <img 
                                        style={{width:'10em'}} 
                                        src={`/images/sitetest/${messageState.type_mess == 'teaser' ? 'accroche' : (messageState.type_mess === 'stop' ? 'stop' : 'poke')}.png`} 
                                        alt="img"
                                    />
                                </Stack>
                                <Stack style={{flex:2,placeItems:'center',justifyContent:'center'}}>
                                    <p 
                                        style={{fontSize:'2em',fontWeight:800,textAlign:'center'}}
                                    >
                                        {messageState.type_mess === 'teaser' ? text.msgacc : (messageState.type_mess === 'stop' ? text.msgstop : text.msgpoke)}
                                    </p>
                                </Stack>
                        </Stack>
                    </Stack> 
                : 
                    <div>
                        {messageState.type_mess === 'stop' && 
                            <div>
                                <Stack style={{flex:1,placeItems:'center',justifyContent:'center'}}>
                                    <img 
                                        style={{width:'10em'}} 
                                        src={`/images/sitetest/stop.png`}
                                        alt="img"
                                    />
                                </Stack>
                                <Stack style={{flex:2,placeItems:'center',justifyContent:'center'}}>
                                    <p 
                                        style={{fontSize:'2em',fontWeight:800,textAlign:'center'}}
                                    >
                                        {text.msgstop}
                                    </p>
                                </Stack>
                            </div>
                        }
                        <BodyMessage setOpen={setOpen} />
                    </div>
                }
            </Stack>
        </Stack>
    )
}
export default Message