const text = [
    { //fr

        usrsd : "Côté utilisateur",
        tmpp: "Politique de Confidentialité de TextModing®",
        lstmj: "Dernière mise à jour ",
        ppint: "Chez TextModing®, nous attachons une importance particulière à la confidentialité et à la protection des données personnelles de nos visiteurs. Cette politique de confidentialité décrit les pratiques de TextModing® en matière de collecte, d'utilisation, de conservation et de protection des informations recueillies via notre formulaire de contact. En utilisant ce formulaire, vous acceptez les termes décrits dans cette politique.",
        pp1ttl: "Collecte des Données",
        pp2ttl: "Utilisation des Données",
        pp3ttl: "Partage des Données",
        pp4ttl: "Conservation des Données",
        pp5ttl: "Sécurité des Données",
        pp6ttl: "Vos Droits",
        pp7ttl: "Modifications de la Politique de Confidentialité",
        pp8ttl: "Contact",
        pp1int: "Lorsque vous remplissez notre formulaire de contact, nous collectons les informations suivantes :",
        pp2int: "Les informations collectées via notre formulaire de contact sont utilisées uniquement dans les buts suivants :",
        pp3int: "TextModing® ne vend, ne loue et ne divulgue aucune information personnelle recueillie via le formulaire de contact à des tiers, sauf dans les cas suivants :",
        pp4int: "Les informations fournies via le formulaire de contact sont conservées uniquement le temps nécessaire pour répondre à votre demande ou pour toute durée requise par la loi applicable. Une fois la finalité atteinte, les données sont supprimées de manière sécurisée.",
        pp5int: "TextModing® met en œuvre des mesures de sécurité techniques et organisationnelles pour protéger les informations que vous partagez avec nous. Toutefois, bien que nous fassions de notre mieux pour sécuriser vos informations, nous ne pouvons garantir la sécurité absolue des données transmises en ligne.",
        pp6int: "En vertu de la législation sur la protection des données, vous disposez des droits suivants concernant vos informations personnelles :",
        pp7int: "TextModing® se réserve le droit de mettre à jour cette politique de confidentialité pour refléter les changements dans nos pratiques ou pour se conformer aux évolutions légales. Toute modification sera publiée sur cette page avec la date de mise à jour. Nous vous encourageons à consulter régulièrement cette politique.",
        pp8int: "Pour toute question ou demande concernant cette politique de confidentialité, veuillez nous contacter à :",
        pp1out: "Aucune autre information personnelle n'est demandée ni collectée dans le cadre du formulaire de contact.",
        pp2out: "",
        pp3out: "",
        pp4out: "",
        pp5out: "",
        pp6out: "Pour exercer vos droits, vous pouvez nous contacter à l'adresse suivante : ",
        pp7out: "",
        pp8out: "En utilisant notre formulaire de contact, vous acceptez cette politique de confidentialité.",
        pp1cont: "- Nom de votre société, l’adresse de votre site web, l’dresse physique de votre société, votre nom : pour pouvoir vous identifier et personnaliser notre réponse.\n- Adresse e-mail : pour pouvoir vous contacter en réponse à votre demande.\n- Objet de votre Message ainsi que votre message : pour comprendre la nature de votre demande et y répondre de manière appropriée.",
        pp2cont: "- Répondre à votre demande : Nous utilisons votre nom, votre adresse e-mail et votre message pour vous fournir une réponse personnalisée.\n- Amélioration de nos services : Nous pouvons analyser de manière anonyme les questions ou commentaires récurrents afin d'améliorer notre support et nos services.",
        pp3cont: "- Conformité légale : si nous devons partager des informations en réponse à une obligation légale ou à une demande des autorités compétentes.\n- Protection des droits de TextModing® : pour défendre nos droits en cas de litige ou pour prévenir des fraudes.",
        pp4cont: "",
        pp5cont: "",
        pp6cont: "- Droit d'accès : vous pouvez demander à consulter les informations que nous avons recueillies à votre sujet.\n- Droit de rectification : vous pouvez demander la correction de toute information inexacte.\n- Droit de suppression : vous pouvez demander la suppression de vos informations personnelles lorsque leur conservation n’est plus nécessaire.\n- Droit de restriction : vous pouvez limiter l'utilisation de vos informations dans certains cas.",
        pp7cont: "",

        ppaccept1adm: "En vous connectant à notre plateforme, vous confirmez que vous avez lu notre",
        ppadm: "accord de confidentialité pour les administrateurs",
        ppaccept2adm: "de la plateforme de TextModing® et que vous y adhérez librement",
        tmppadm: "Politique de Confidentialité pour les Administrateurs de TextModing®",
        ppintadm: "TextModing® s'engage à protéger la confidentialité et la sécurité des données personnelles de ses partenaires administrateurs et propriétaires de sites de rencontre. Cette politique de confidentialité décrit nos pratiques en matière de collecte, d'utilisation, de conservation et de protection des données des administrateurs qui utilisent notre plateforme d’animation pour sites de rencontre. En accédant aux fonctionnalités de TextModing®, vous acceptez les pratiques décrites dans cette politique.",
        pp1ttladm: "Collecte des Données",
        pp2ttladm: "Utilisation des Données",
        pp3ttladm: "Partage des Données",
        pp4ttladm: "Conservation des Données",
        pp5ttladm: "Sécurité des Données",
        pp6ttladm: "Droits des Administrateurs",
        pp7ttladm: "Cookies et Technologies Similaires",
        pp8ttladm: "Modifications de la Politique de Confidentialité",
        pp9ttladm: "Contact",
        pp1intadm: "Dans le cadre de notre relation de partenariat et pour l'utilisation de notre plateforme, nous pouvons collecter les informations suivantes concernant les administrateurs des sites partenaires :",
        pp2intadm: "Les données collectées sont utilisées pour les finalités suivantes :",
        pp3intadm: "TextModing® ne vend ni ne loue les données personnelles des administrateurs et ne les partage qu'avec des tiers lorsque cela est nécessaire aux finalités suivantes :",
        pp4intadm: "Les données personnelles des administrateurs sont conservées aussi longtemps que nécessaire pour atteindre les objectifs décrits dans cette politique ou pour se conformer aux obligations légales en vigueur. Une fois que les données ne sont plus nécessaires, elles sont supprimées de manière sécurisée.",
        pp5intadm: "TextModing® applique des mesures de sécurité techniques et organisationnelles strictes pour protéger les données personnelles des administrateurs contre toute forme d'accès non autorisé, d'altération, de divulgation ou de destruction. Malgré nos efforts, aucune transmission de données sur Internet n’est complètement sécurisée ; nous mettons donc tout en œuvre pour minimiser les risques.",
        pp6intadm: "Les administrateurs disposent de droits spécifiques concernant leurs informations personnelles, y compris :",
        pp7intadm: "Pour améliorer votre expérience d’utilisation, nous utilisons des cookies et d'autres technologies similaires pour suivre les sessions de connexion et personnaliser l’interface de la plateforme. Vous pouvez configurer vos paramètres de navigateur pour limiter l'utilisation de ces cookies, mais cela pourrait affecter certaines fonctionnalités de la plateforme.",
        pp8intadm: "TextModing® se réserve le droit de modifier cette politique de confidentialité afin de refléter des changements dans nos pratiques ou pour se conformer aux obligations légales. Toute modification sera publiée sur cette page avec une date de mise à jour. Nous vous encourageons à consulter régulièrement cette politique.",
        pp9intadm: "Pour toute question ou demande relative à cette politique de confidentialité, vous pouvez nous contacter à :",
        pp1outadm: "",
        pp2outadm: "",
        pp3outadm: "",
        pp4outadm: "",
        pp5outadm: "",
        pp6outadm: "Pour exercer ces droits, veuillez nous contacter à l’adresse suivante : admin@creativ-network.net",
        pp7outadm: "",
        pp8outadm: "",
        pp9outadm: "En utilisant la plateforme TextModing®, les administrateurs acceptent cette politique de confidentialité et s’engagent à l’appliquer dans le cadre de leur usage de nos services.",
        pp1contadm: "- Informations d’identification : nom, prénom, nom de l’entreprise, fonction, identifiant d'administrateur.\n- Coordonnées : adresse e-mail professionnelle, numéro de téléphone.\n- Informations de connexion : identifiants de compte pour accéder à la plateforme TextModing®.\n- Données d’utilisation : historiques de connexion, préférences d'utilisation, et interactions avec les fonctionnalités de la plateforme.\n- Données financières : détails de facturation et informations de paiement, uniquement si nécessaires dans le cadre des transactions.",
        pp2contadm: "- Accès et gestion des fonctionnalités : permettre aux administrateurs d'accéder aux 15 fonctionnalités principales de TextModing® (gestion des conversations, contrôle qualité, paie des opérateurs, gestion du journal de bord des opérateurs, gestion des demandes de photos, gestion des cadeaux virtuels, gestion des GIFs, gestion du trafic des messages, gestion des notes, etc.).\n- Assistance et support : fournir un support technique et une assistance personnalisée en cas de problème ou de besoin d’informations complémentaires.\n- Amélioration de nos services : analyser les données d'utilisation pour améliorer les fonctionnalités et l'expérience de la plateforme.\n- Facturation et suivi des paiements : traiter les transactions et fournir des justificatifs de facturation.\n- Sécurité : surveiller les connexions et activités suspectes pour garantir la sécurité des comptes administrateurs.",
        pp3contadm: "- Fournisseurs de services : Nous pouvons faire appel à des prestataires de services pour des opérations spécifiques (ex. : hébergement, gestion des paiements). Ceux-ci sont tenus de traiter vos données de manière confidentielle et sécurisée.\n- Obligations légales : Nous pouvons divulguer vos informations si cela est requis par la loi ou pour répondre aux demandes des autorités.\n- Protection des droits : en cas de litige, nous pourrions partager des informations dans le cadre de procédures juridiques pour défendre les droits de TextModing®.",
        pp4contadm: "",
        pp5contadm: "",
        pp6contadm: "- Droit d’accès : demander à consulter les informations personnelles que nous détenons.\n- Droit de rectification : demander la correction des informations inexactes ou obsolètes.\n- Droit de suppression : demander la suppression de vos informations personnelles dans certaines circonstances.\n- Droit à la limitation du traitement : limiter l’utilisation de vos données dans certains cas.",
        pp7contadm: "",
        pp8contadm: "",

        sccss: "Succès",
        smthngwntwrng: "Une erreur s'est produite",
        cntcts: "Contacts",
        frmlrdcntct: "Formulaire de contact",
        ttr: "Titre",
        mr: "M",
        mme: "Mme",
        mlle: "Mlle",
        sct: "Société",
        stwb: "Site web",
        addrss: "Adresse",
        prvncdprtm: "Province / Département",
        cdpstl: "Code postal",
        cntr: "Pays",
        prnmcntct: "Prénom du contact",
        nmcntct: "Nom du contact",
        tlphn: "Téléphone",
        eml: "Email",
        objdvtrcntct: "Objet de votre contact",
        dmdpht: "Demande de démo",
        prtnrt: "Partenariat",
        affltn: "Affiliation",
        prsrdv: "Prise de rendez-vous",
        dmdbrchr: "Demande de brochure",
        othr: "Autres",
        vtrmsg: "Votre message",
        attchmt: "Attachement",
        pltqcnfdtxt: "En nous soumettant ce formulaire de contact, vous déclarez avoir lu et pris connaissance de notre",
        pltqcnfdtxt2: "politique de confidentialité",
        xcldrssttm: "clés de réussite de TextModing®",
        cldrssttl1: "Coûts abordables",
        cldrssttl2: "Technologie avancée",
        cldrssttl3: "Adaptabilité de la plateforme",
        cldrssttl4: "Efficacité opérationnelle",
        cldrssttl5: "Transparence et communication",
        cldrssttl6: "Engagement envers la satisfaction client",
        cldrsstxt1: "Nous proposons des services compétitifs avec un excellent rapport qualité-prix, permettant à nos clients d'optimiser leur budget tout en bénéficiant de solutions de qualité.",
        cldrsstxt2: "Nous exploitons des technologies de pointe pour assurer des performances robustes, une sécurité accrue et une gestion fluide des activités sur la plateforme.",
        cldrsstxt3: "Nous offrons une flexibilité qui permet d'ajuster la plateforme en fonction des besoins spécifiques de chaque client, garantissant ainsi une réponse personnalisée et efficace.",
        cldrsstxt4: "Nous maximisons l'efficacité des processus internes pour assurer des délais de mise en œuvre courts et des opérations fluides, sans compromettre la qualité du service.",
        cldrsstxt5: "Nous maintenons une communication claire et transparente avec les clients, en offrant une visibilité totale sur les performances et les résultats de leurs projets.",
        cldrsstxt6: "Nous plaçons la satisfaction client au cœur de nos priorités en mettant en œuvre des solutions rapides et en étant constamment à l'écoute des besoins et retours de nos partenaires.",
        prdtstm: "Les produits* de TextModing®",
        prdtttl1: "Interface Test",
        prdtttl2: "Tableau de bord",
        prdtttl3: "Plateforme d'animation",
        prdttxt1: "L'interface de test de TextModing® permet aux futurs clients d'explorer et d'expérimenter les fonctionnalités essentielles de la plateforme, y compris l'envoi de messages ordinaires, de messages d'accroche, de pokes, et de relances, assuré par nos opérateurs chat, pour une immersion complète dans notre environnement interactif.",
        prdttxt2: "Le tableau de bord de TextModing® offre aux administrateurs, superviseurs, contrôleurs de qualité et comptables une vue d'ensemble intuitive et centralisée, facilitant la gestion en temps réel des opérations, la supervision des performances, l'assurance qualité, et le suivi financier de la plateforme.",
        prdttxt3: "La plateforme d'animation de sites de rencontres TextModing® offre aux opérateurs chat un environnement interactif et optimisé pour gérer les échanges, créer des connexions engageantes et assurer une animation dynamique et efficace des interactions entre les utilisateurs.",
        prdindpdts: "Les 3 produits sont interdépendants",
        intrcteffcctprfrmnc: "“Interaction. Efficacité. Performance”",
        abtus: "À Propos de nous",
        abtustxt: "TextModing® est une plateforme innovante dédiée à l'animation de sites de rencontres par des opérateurs professionnels. Nous offrons des services personnalisés de modération, gestion de contenu, et animation pour améliorer l'expérience des utilisateurs sur des plateformes de rencontres. Grâce à notre équipe expérimentée, nous garantissons des interactions authentiques et engageantes qui favorisent une expérience utilisateur de qualité.",
        rdswt: "Lire la suite",
        lnsdst: "Liens du site",
        lngdst: "Langue du site",
        mnaddrss: "Adresse principale",
        rprsnttbrblg: "Représentant du bureau belge",
        ttdrtsrsrvs: "Tous droits réservés",
        apr: "À propos",
        ftrs: "Fonctionnalités",
        avntgs: "Avantages",
        cldrsst: "Clés de réussite",
        nsprdts: "Nos produits",
        orpltfrm1: "Notre plateforme",
        orpltfrm2: "d'animation de sites de rencontre",
        orpltfrmtxt: "TextModing® est une plateforme performante conçue pour animer et optimiser les sites de rencontres en ligne. Grâce à ses outils avancés et son interface intuitive, elle permet de gérer efficacement les échanges entre utilisateurs et d'assurer un haut niveau d'interaction.",
        flxqtdns: "Flux quotidiens",
        rqstdmo: "Demander un démo",
        aprtxt1: "TextModing® est une plateforme innovante dédiée à l'animation de sites de rencontres par des opérateurs professionnels. Nous offrons des services personnalisés de modération, gestion de contenu, et animation pour améliorer l'expérience des utilisateurs sur des plateformes de rencontres. Grâce à notre équipe expérimentée, nous garantissons des interactions authentiques et engageantes qui favorisent une expérience utilisateur de qualité.",
        aprtxt2: "Notre plateforme permet aux opérateurs de gérer efficacement les conversations, assurer un contrôle qualité, et superviser le trafic des messages. Avec des fonctionnalités avancées telles que la gestion des demandes de photos, des cadeaux virtuels et Gifs, ainsi que la prise de notes pour un suivi optimal des interactions, TextModing® est un outil complet pour les sites souhaitant offrir une expérience interactive et dynamique.",
        aprtxt3: "Nous nous engageons à fournir des services de haute qualité avec une équipe disponible pour accompagner les besoins spécifiques des sites de rencontres et optimiser l'engagement des membres.",
        xftftm: "Fonctionnalités de TextModing®",
        ft1ttl: "Gestion des conversations",
        ft2ttl: "Gestion des agents",
        ft3ttl: "Salle de panique",
        ft4ttl: "Gestion des notes",
        ft5ttl: "Messages d'accroche",
        ft6ttl: "Messages Poke",
        ft7ttl: "Messages de relance",
        ft8ttl: "Demande de photos ",
        ft9ttl: "Signalement des agents",
        ft10ttl: "Journal de bord",
        ft11ttl: "Messages dupliqués",
        ft12ttl: "Gestion des cadeaux",
        ft13ttl: "Supervision et gestion de trafic",
        ft14ttl: "Contrôle de qualité",
        ft15ttl: "Comptabilité",
        ft1txt: "permet aux administrateurs, team leaders et superviseurs de surveiller, contrôler et analyser en temps réel les échanges, assurant la qualité et la fluidité des interactions sur les sites de rencontres.",
        ft2txt: "permet aux administrateurs, team leaders et superviseurs de suivre, encadrer et évaluer les performances des agents en temps réel, garantissant une animation efficace des sites de rencontres.",
        ft3txt: "offre aux administrateurs, team leaders et superviseurs un espace sécurisé pour gérer les urgences, résoudre rapidement les incidents critiques et assurer la continuité des opérations sur les sites de rencontres.",
        ft4txt: "permet aux administrateurs, team leaders et superviseurs de créer, suivre et partager des notes sur les interactions des agents, optimisant la coordination et le suivi des performances.",
        ft5txt: "permet aux administrateurs, team leaders et superviseurs de créer, personnaliser et déployer des messages d'introduction efficaces pour optimiser l'engagement des utilisateurs sur les sites de rencontres.",
        ft6txt: "permet aux administrateurs, team leaders et superviseurs de configurer, envoyer et analyser des messages poke stratégiques pour relancer l'engagement et stimuler l'interaction des utilisateurs.",
        ft7txt: "permet aux administrateurs, team leaders et superviseurs de programmer, personnaliser et analyser des messages de suivi, visant à réactiver l'engagement des utilisateurs inactifs sur les sites.",
        ft8txt: "permet aux administrateurs, team leaders et superviseurs de superviser, approuver et gérer les demandes de photos des opérateurs, assurant la conformité et la qualité des contenus échangés.",
        ft9txt: "permet aux administrateurs, team leaders et superviseurs de suivre, examiner et traiter les signalements concernant les agents, assurant une modération proactive et un contrôle qualité optimal.",
        ft10txt: "permet aux administrateurs, team leaders et superviseurs de suivre, enregistrer et analyser les prises de notes des opérateurs pour optimiser la performance et la communication.",
        ft11txt: "permet aux administrateurs, team leaders et superviseurs d'identifier et de gérer les messages répétitifs pour améliorer la qualité des interactions avec les utilisateurs.",
        ft12txt: "permet aux administrateurs, team leaders et superviseurs de superviser et d'analyser l'envoi de contenus ludiques pour renforcer l'engagement des utilisateurs.",
        ft13txt: "permet aux administrateurs, team leaders et superviseurs d'analyser les flux de messages pour optimiser les performances et assurer une communication fluide entre les utilisateurs.",
        ft14txt: "permet aux administrateurs, team leaders et superviseurs d'évaluer les interactions des opérateurs pour garantir un service de haute qualité et une satisfaction client optimale.",
        ft15txt: "permet aux administrateurs, comptables de suivre et de gérer les transactions financières, assurant ainsi une transparence et une efficacité dans la gestion des ressources.",
        nsecrr: "Nous écrire",
        cqdfftm: "Ce qui différencie TextModing®",
        cqdffttl: "TextModing® se distingue de ses concurrents par plusieurs avantages clés :",
        cqdffttl1: "Expertise spécialisée",
        cqdffttl2: "Personnalisation et flexibilité",
        cqdffttl3: "Support continu et formation",
        cqdffttl4: "Innovation constante",
        cqdfftxt1: "TextModing® réunit des talents du web hautement qualifiés, tels que les animateurs chat, superviseurs, contrôleurs de qualité, et gestionnaires de trafic, garantissant une animation de site de rencontres optimale et professionnelle.",
        cqdfftxt2: "Grâce à notre plateforme, nous offrons des solutions sur mesure adaptées aux besoins spécifiques de chaque client, permettant une gestion plus ciblée et efficace. ",
        cqdfftxt3: "TextModing® ne se contente pas de fournir des services, nous investissons dans la formation continue de notre personnel pour assurer une adaptation rapide aux nouvelles tendances et technologies, tout en offrant un soutien constant à nos partenaires pour favoriser leur croissance.",
        cqdfftxt4: "En étant à l'avant-garde des dernières technologies et stratégies d'animation, TextModing® assure à ses clients un avantage concurrentiel durable sur le marché en perpétuelle évolution.",
        btsd : "Côté bot",
        nttkn : "Notes prises",
        mssdnt : "Notes manquées",
        gfts : "Cadeaux",
        rqsts : "Demandes",
        messonhldallctd : "Message en attente déjà attribué",
        messonhldntallctd : "Message en attente non attribué",
        totnummess : "Nombre total de messages",
        messpssdqc : "Messages ayant passé le contrôle qualité",
        messntpssqc : "Messages n'ayant pas passé le contrôle qualité",
        paymnts : "Paiements",
        bnk : "Banque",
        ppl : "Paypal",
        ws : "Wise",
        yrsf : "Yoursafe",
        rçes: "Reçues",
        envoyees: "Envoyées",
        dmndees: "Demandées",
        nvaux: "Nouveaux",

        adddest: "Adresse e-mail du destinataire",

        cmpn : " Entreprise ",
        tmfld : " Textmoding folder ",
        accar : " Zone d'accès ",
        assvldt : " Validité d'accès ",

        acc8 : " Configuration des taux et des primes ",

        mtng : "Réunion",
        rp : "Real Profil",
        fp : "Fake Profil",
        infopernoper : "Informations permanentes / non permanentes",
        dltnt : "Supprimer la note",
        dltmesspnc : "Voulez-vous vraiment supprimer ce message de panique ?",
        convbnnd : "Conversation interdite",
        suspconv : "Suspendre la conversation",
        upldd : "Téléchargé",
        addpht : "Ajouter une photo",
        phtsnttofp : "Liste des photos envoyées aux faux profils",
        pkmesscrtd : "Message Poke créé avec succès",
        updt : "Mise à jour",
        dltpkmess : "Voulez-vous vraiment supprimer ce message poke ?",
        plt : "Plateforme",
        dltdmd : "Voulez-vous vraiment supprimer cette demande ? ",
        agntinfrmd : "Agent informed",
        dlttsrmess : "Voulez-vous vraiment supprimer ce message d'accroche ?",
        updttsrmess : "Mise à jour du message d'accroche",

        updtdsucc : "Mise à jour réussie",
        nonotif : "Pas de notification",
        continfo : "Content Information",
        exp : "Expéditeur",
        recep : "Récepteur",
        mdpupdtd : "Password updated successfully",
        opsusp : "Operator suspended successfully",
        opdec : "Operator disconnected successfully",
        decop : "Voulez-vous vraiment déconnecter cet opérateur :  ",
        frop : "Voulez-vous vraiment licencier cet opérateur :  ",
        chngmdp : "Changer le mot de passe",
        suspop : "Voulez-vous vraiment suspendre cet opérateur : ",
        onhld : "En attente",
        supprmess : "Supprimer ce message",
        messinfo : "Informations sur le message",
        vld : "Confirmer",
        ok : "Ok",
        enc : "En cours ...",

        updtmdp : "Voulez-vous changer le mot de passe ?",
        nwmdp : "Entrez le nouveau mot de passe",
        dltsup : "Voulez-vous vraiment supprimer ce superviseur ?",
        ntsup : "Note à un superviseur",
        nthr : "Note ici...",
        dltimg : "Voulez-vous vraiment supprimer cette image ?",
        phttsnd : "Photo à envoyer",
        vd : "Vide (sélectionner une photo)",
        addd : "Ajouté",
        nwrdv : "Ajouter une nouvelle information",
        cnfrm : "Confirmer",
        dltrdv : "Voulez-vous vraiment supprimer ce Rendez-vous/ Réunion/ Vacances?",
        addnonperminfo : "Ajouter des informations non permanentes",
        dltinfo : "Voulez-vous vraiment supprimer cette information ?",
        idd : "ID",
        addperminfo : "Ajouter des informations permanentes",
        messreport : "Message rapporté au superviseur",
        usrnm : "Nom",

        pp11 : "Creativ'Network, une société de mise en relation professionnelle pour des travaux du web, dont le siège social est situé au 71-75 Shelton Street, Covent Garden, London, WC2H 9JQ, et ayant son bureau de représentation au Roodborstjesstraat 4, Wondelgem 9032, ci-après dénommée « la Société »,",
        pp12 : "Et :",
        pp13 : "Travailleur indépendant, qui, en vertu du contrat de Travailleur Indépendant qui le lie avec « La Société » utilise les matériels ainsi que les propriétés intellectuelles de cette dernière, ci-après dénommé « le Travailleur Indépendant »,",
        pp14 : "Ci-après dénommées individuellement « la Partie » et collectivement « les Parties »,",
        pp21 : "La Société exploite une plateforme d'animation de sites de rencontres dont elle est la propriétaire exclusive. La gestion opérationnelle de cette plateforme est confiée à des opérateurs tchat, des superviseurs, des team leaders, des contrôleurs de qualité, employés en tant que travailleurs indépendants par la Société.",
        pp22 : "Dans le cadre de cette relation professionnelle, le Travailleur Indépendant sera amené à avoir accès à des informations confidentielles relatives à la plateforme, aux utilisateurs des sites de rencontres, aux processus opérationnels, ainsi qu'à toute autre information protégée appartenant à la Société.",
        pp23 : "Ceci étant exposé, il a été convenu ce qui suit :",
        pp31 : "Le présent accord de confidentialité a pour objet de protéger les informations confidentielles que la Société pourrait être amenée à communiquer au Travailleur Indépendant dans le cadre de ses fonctions liées à la gestion de la plateforme d'animation de sites de rencontres.",
        pp41 : "Aux fins du présent accord, les « Informations Confidentielles » désignent toutes les informations, sous quelque forme que ce soit (écrite, orale, électronique, etc.), relatives à la plateforme d'animation de sites de rencontres, aux utilisateurs, aux stratégies de développement, aux processus techniques et opérationnels, aux données financières, ainsi que toute autre information désignée comme confidentielle par la Société, que le Travailleur Indépendant pourrait recevoir ou avoir accès dans le cadre de son travail.",
        pp51 : "Le Travailleur Indépendant s'engage à :",
        pp52 : "- Ne pas divulguer, reproduire, transmettre, ou rendre accessible les Informations Confidentielles, en tout ou en partie, à des tiers, sans l'autorisation écrite préalable de la Société.",
        pp53 : "- Utiliser les Informations Confidentielles uniquement dans le cadre des activités liées à la gestion de la plateforme, et uniquement dans l'intérêt de la Société.",
        pp54 : "- Prendre toutes les mesures nécessaires pour protéger la confidentialité des Informations Confidentielles et empêcher toute utilisation non autorisée.",
        pp55 : "- Restituer à la Société, sur demande, tout document ou support contenant des Informations Confidentielles, sans en conserver de copies, sous quelque forme que ce soit.",
        pp61 : "Le présent accord prend effet au moment où le travailleur marquera son accord lors de son identification à l'accès à la plateforme d'animation et restera en vigueur pendant toute la durée de la relation professionnelle entre la Société et le Travailleur Indépendant. Les obligations de confidentialité subsisteront pendant une période de 10 ans après la fin de ladite relation, quelle qu'en soit la cause.",
        pp71 : "Ne sont pas considérées comme Informations Confidentielles :",
        pp72 : "- Les informations qui étaient déjà connues du public au moment de leur communication au Travailleur Indépendant, ou qui deviendraient publiques autrement que par la violation du présent accord.",
        pp73 : "- Les informations que le Travailleur Indépendant peut prouver avoir reçues de manière légitime d'un tiers non lié par une obligation de confidentialité.",
        pp74 : "- Les informations développées de manière indépendante par le Travailleur Indépendant sans l'utilisation des Informations Confidentielles.",
        pp81 : "En cas de manquement aux obligations prévues par le présent accord, le Travailleur Indépendant reconnaît que la Société pourra engager toutes les actions légales nécessaires pour protéger ses droits et obtenir réparation des dommages subis.",
        pp91 : "Le présent accord est régi par le droit belge. En cas de litige relatif à l'interprétation ou à l'exécution du présent accord, les Parties s'engagent à rechercher une solution amiable. À défaut d'accord amiable, les tribunaux compétents de Gand seront seuls compétents.",

        pp1 : "Entre les Parties",
        pp2 : "Préambule",
        pp3 : "Article 1 : Objet",
        pp4 : "Article 2 : Définition des Informations Confidentielles",
        pp5 : "Article 3 : Obligations du Travailleur Indépendant",
        pp6 : "Article 4 : Durée",
        pp7 : "Article 5 : Exclusions",
        pp8 : "Article 6 : Dispositions Générales",
        pp9 : "Article 7 : Loi Applicable et Juridiction Compétente",
        paymail: 'Adresse e-mail de paiement',
        scc : 'Créé',
        login: 'Connexion',
        password: 'Mot de passe',

        mail:'Email',
        qlogout: 'Voulez-vous vous déconnecter?',
        logout: 'Se déconnecter',
        yes: 'Oui',
        annuler:'Annuler',
        no: 'Non',
        editer:'Editer',
        editNote: 'Edition du note',
        notesuppr:'Note supprimée',
        operatorpage: "Page d'opérateur",
        supervisorpage: "Page de superviseur",
        adminpage: "Page d'administrateur",
        addnote:'Note ajoutée',
        required: "Ce champ est obligatoire",
        invalid: "Email invalide",
        registered: "Cette adresse e-mail n'est pas enregistrée",
        incorrectpass: "Mot de passe incorrect",
        atleast: "Le mot de passe doit contenir au moins",
        characters: "caractères",

        convman : "Gestion des conversations",
        agman : "Gestion des agents",
        notesman : "Gestion des notes",
        agrep : "Signalements des agents",
        gftgtfman : "Gestion des cadeaux",
        tmfldaa : "Autorisation d'accès au dossier TextModing",
        hlp : "Aide",
        pltflng : "Langue de la plateforme",
        pr: "Demandes de photos",
        wlcm : "Bienvenue",
        wlcmtxt : "TextModing est une plateforme d'animation conçue pour les sites de rencontres, offrant des fonctionnalités essentielles pour la gestion des conversations et des agents. Elle améliore l'efficacité et la rentabilité des partenaires en fournissant des outils qui rationalisent les interactions et optimisent l'engagement des utilisateurs.",

        messageto: 'Message à',
        convatt: 'Conversation en attente',
        mincharlen: 'Au moins 120 caractères',
        char: 'caractère',
        chars:  'caractères',
        pj: 'Photo jointe',
        writemessage: 'Ecrire un message ...',
        picalreadysent: "Photo vue par l'utilisateur",
        gifpj: 'GIFS',
        opengif: 'Ouvrir GIF',
        sendMessage: 'Envoyer le message',
        reportSup: 'Envoyer une note au superviseur',
        conv: 'Conversation ID',
        say: 'dit',
        timeIntro: 'à',
        elements: 'éléments',
        element: 'élément',
        none: 'Aucun',

        stat: 'Statistiques',
        french: 'Français',
        english: 'Anglais',
        italian: 'Italien',
        dutch: 'Néérlandais',
        spanish: 'Espagnol',
        german: 'Allemand',
        botprofil: 'Profil du Bot',
        userprofil: "Profil de l' utilisateur",
        otherInfo: 'Autres informations importantes',
        codepost: 'Code postal',
        ville: 'Ville',
        region: 'Région',
        pays: 'Pays',
        taille: 'Taille',
        eyes: 'Couleur des yeux',
        hair: 'Couleur des cheveux',
        morph: 'Morphologie',
        situation: 'État civil',
        search: 'Recherche',
        job: 'Profession',
        signe: 'Signes distinctifs',
        descri: 'Description',
        infosupp: 'Infos supplémentaires',

        supervisiontchat: 'Supervision tchat',
        statconvop: 'Stat et conversation opérateur',
        convs: 'Conversations',
        report: 'Report',
        agentInform:'Agent informé',
        events: 'Evènement',
        photosrequests: 'Demande de photo',
        warmup: 'Warmup',
        gifattch: 'Attachement gif',
        fakeprofiles: 'Faux profils',
        pagenotfound: 'Page introuvable',

        suivvraiprof: 'Suivi des vrais profils',
        suiviop: 'Suivi des opérateurs',
        admintion: 'Administration',
        affiliation : 'Affiliation',

        operator: 'Opérateur',
        supervisor: 'Superviseur',
        administrator: 'Administrateur',

        week: 'semaine',
        weeks: 'semaines',
        today: "Aujourd'hui",

        start: 'Début',
        end: 'Fin',
        platform: 'Plateformes',
        searchfor: 'Rechercher',
        go: 'Aller',
        allmasc: 'Tous',
        allfem: 'Toutes',

        id: 'Identifiant',
        opname: 'Nom opérateur',
        sentmess: 'Messages envoyés',
        recmess: 'Messages reçus',
        relmess: 'Messages de relance',

        date: 'Date',
        messid: 'Identifiant message',
        convid: 'Identifiant conversation',
        exp: "Sender",
        recpt: 'Récepteur',
        opsign: 'Opérateur à signaler',
        opexped: 'Opérateur expéditeur',
        action: 'Action',
        messs: 'Messages',
        filterdate: 'Filtrer les recherches entre deux dates',
        filtermess: 'Filtrer conversation par message',
        from: 'De',
        to: 'à',
        eventList: 'Liste des évènements',
        num: 'Numéro',
        eventSearch: 'Recherche un évènement',
        actions: 'Actions',

        eventname: "Nom de l'évènement",
        eventdate: "Date de l'évènement",
        uploadimage: "Télécharger des images",
        role: "Rôle",
        logIn: "Se connecter",
        ppaccept1: "En vous connectant à notre plateforme, vous confirmez que vous avez lu notre",
        ppaccept2: "et que vous avez suivi la formation appropriée pour mener à bien votre mission",
        pp: "accord de confidentialité",
        copyright: "© 2023-2024 Copyright TextModing® - Tous droits réservés",
        accountant: 'Comptable',
        qualitycontroller: 'Contrôleur de qualité',
        adminid: "ID administrateur",
        accountantid: "ID comptable",
        operatorid: "ID opérateur",
        qcid: "ID contrôleur de qualité",
        confirmpass:'Confirmez le mot de passe',
        alreadyreg:'Déjà enregistré ? Se connecter',
        notifs : "Notifications",
        limite:'Limité',
        save:'Sauvegarder',
        archives:'Archives',
        ttlmsg:'Total des messages',
        controlLog:'Login Contrôleur',
        nbrmess:'Nombre de messages',
        appQC:'Appréciations du QC',
        esqmq:'Esquive manquant',
        dbspace:'Double espace',
        notelu:'Note lue',
        actpris:'Action prise',
        ferme:'Fermé',
        losn:'Liste des notes envoyées',
        lorn : 'Liste des notes reçues',
        validity:'Validité',
        editInfo:'Modifier une information',
        controlID:'Contrôleur ID',
        statcontrolct:'Statistiques de contrôle par contrôleur',
        statcontrolag:'Statistiques de contrôle par Agent',
        statcontrol:'Statistiques de contrôle',
        traitement:'Traitement',
        agentid:'Agent ID',
        statconvatt: 'Statistiques des Conversations en Attente',
        propesqu : 'Saisissez une esquive à une proposition de rendez-vous',
        msgacc:"Veuillez rédiger un message d'accroche",
        msgpoke:'Veuillez rédiger un message Poke',
        heure:'Heure',
        esqv:'Esquive',
        esquive:'Esquives rencontres et rendez-vous',
        other:'Autres',
        sexe:'Sexe',
        bannir:'Bannir',
        deconnecter: 'Déconnecter',
        agntLgn:'Agent en ligne',
        features:'Fonctionnalités',
        rdv:'Rendez-vous',
        holiday:'Vacances',
        valider:'Valider',
        panicmsg:'ce message de panique ?',
        jour:'Jour',
        jours:'Jours',
        conversation:'Conversation',
        profil:'Profil',
        suspendre:'Suspendre',
        expulser:'Expulser',
        information:'Information',
        meeting:'Réunion',
        online:'En ligne',
        onHold:'En attente',
        treated:'Traitées',
        listPhotoSend : 'Liste de photos envoyées',
        suspendu:'Suspendu',
        suspend:'Suspendre',
        etat: 'Etat',
        
        actqc4: 'Message validé',
        panicmess:'Message de panique',
        contenu:'Contenu',
        raison:'Raison',
        reportmess:'Signaler un message',
        language1:'Langage',
        reg:"S'inscrire",
        enreg:["Pas encore inscrit ?", "S'enregistrer"],
        passdiff:'Mot de passe différent',
        supervisorid: "ID Superviseur",
        convonhold : "Conversations en attente",
        supdash : "Tableau de bord du superviseur",
        choverv : "Aperçu du chat",
        months: ["Janvier", "Fevrier", "Mars", "Avril", "Mai", "Juin", "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre"],
        year: 'Année',
        month: 'Mois',
        startingdate : "Date de début",
        closingdate : "Date de clôture",
        agentReport: 'Agent signalé',
        agents: "Agents",
        out: "Sortis",
        in: "Entrés",
        details: "Détails",
        panicroom: "Salle de panique",
        total: "Total",
        notes: "Notes",
        teasermessages: "Messages d'accroche",
        pokemessages: "Messages Poke",
        attdisc:'Attente de Discussion',
        gifajt:'Gif Ajouter',
        stopmessages: "Messages d'arrêt",
        sent: "Envoyés",
        asnwered: "Répondus",
        requested: "Demandés",
        logbook: "Journal de bord",
        recorded: "Enregistrés",
        notrecorded: "Non enregistrés",
        duplicatedmessages: "Messages dupliqués",
        back: "Retour",
        suty : "Type d'abonnement",
        ag : "Âge",
        gend : "Sexe",
        sexor : "Orientation sexuelle",
        tow : "Ville",
        
        users: "Utilisateurs",
        sender: "Expéditeur",
        receiver: "Récepteur",
        agent: "Agent",
        status: "Statut",
        read: "Lu",
        active: "Actif",
        edit: "Modifier",
        user: "Utilisateur",
        sendnote : "Envoyer une note",
        oh : "En attente",
        mess : "Message",
        trconv : "Conversations traitées",
        idconv : "ID de la conversation",
        idmess : "ID du message",
        otherimp : "Autres informations importantes",
        otherimp1 : "Réunion/ Rencontres/ Vacances",
        otherimp2 : "Informations permanentes",
        otherimp3 : "Informations utiles, non permanentes",
        
        aglist : "Liste d'agents",
        pfsperf : "Performance des plateformes",
        agperf : "Performance des agents",
        nwag : "Ajouter de nouveaux agents",
        agname : "Nom de l'agent",
        agcountry : "Pays de l'agent",
        aglogin : "Login de l'agent",
        agloginpw : "Mot de passe de l'agent",
        langpf : "Plate-forme linguistique",
        onl : "En ligne",
        offl : "Hors ligne",
        susp : "Suspendre",
        koff : "Renvoyer",
        grotot : "Total brut",
        agnumbewoon : "Nombre de faux profils",
        totsenmess : "Total des messages envoyés",
        totrecmess : "Total des messages reçus",
        totrep : "Total des rapports",
        real : "Vrai nom",
        prov : "Province",
        weight : "Poids",
        child: "Enfants",
        time: "Temps",
        fstnm : "Prénom",
        lstnm : "Nom de famille",
        dtofbir : "Date de naissance",
        skypeid : "ID Skype",
        crnwag : "Créer un nouvel agent",
        usrprfl : "Profil de l'utilisateur",
        sndmess : "Envoyer un message",
        blckusr : "Bloquer l'utilisateur",
        lstnts : "Liste de notes",
        crtsndnts : "Créer / Envoyer une note",
        evnt : "Événement",
        ntid : "ID de la note",
        nt : "Note",
        dlt : "Supprimer",
        cndid: "ID du candidat",
        language: "Langue",
        perc1: "Vous avez composé ",
        perc2: " du message de l'utilisateur",
        giftsatt : "Cadeaux joints",
        sndnt : "Envoyer la note",
        lsttsrmsg : "Liste des messages d'accroche",
        crttsrmsg : "Créer des messages d'accroche",
        idtsrmsg : "ID du message d'accroche",
        tsrmsg : "Message d'accroche",
        dlvrdt : "Livré à",
        wiseg:"Gifs sages",
        sexg:"Gifs sexuels",
        gayg:"Gifs gays",
        Lesbg: "Gifs lesbiens",
         transg:"Gifs transgenres",
        travg:"Gifs travestis",
                
        bdsm:"BDSM",
        dont: "Ne soyez pas long",
        blj:"Fellation",
        org:"Orgasme",
        vagpen:"Pénétration vaginale",
        sod:"Sodomie",
        titss:"Nichons",

        bdsmg: "BDSM Gay",
        wisegg: "Gifs gays sages",
        sexgg:"Gifs gays sur le sexe",

        bdsml: "BDSM Lesbienne",
        wiselg:"Gifs Lesbiennes sages",
        sexlg:"Gifs de sexe lesbien",

        maletrans:"Transgenre masculin",
        femaletrans:"Femme transgenre",
        lstpkmsg : "Liste des messages poke",
        crtpkmsg : "Créer un message poke",
        idpkmsg : "ID du message poke",
        pkmsg : "Message poke",
        lststpmsg : "Liste des messages d'arrêt",
        idstpmsg : "ID du message d'arrêt",
        stpmsg : "Message d'arrêt",
        sntto : "Envoyé à",
        agntwrtngstp : "Agent rédigeant l'arrêt",
        dtofdlvr : "Date de livraison",
        dtofans : "Date de réponse",
        stpperfanal : "Arrêter l'analyse des performances",
        stpgrssttl : "Arrêter le total brut",
        rsptostp : "Réponse à la relance",
        rsptostp2 : "Réponse à l'accroche",
        rsptostp3 : "Réponse au Poke",
        stpprcntresp : "Arrêt % réponse",
        avrgrsptm : "Temps de réponse moyen",

        lstphtrq : "Liste des demandes de photos",
        phttp : "Type de photo",
        idrq : "ID de la demande",
        pht : "Photo",
        upld : "Upload",
        snd : "Envoyer",
        lstrprt : "Liste des rapports",
        rpttp : "Type de rapport",
        idrprt : "ID du rapport",
        msgrprtd : "Message rapporté",
        infrmagnt : "Informer l'agent",
        lstlgbk : "Liste du journal de bord",
        lstmssnglgbk : "Liste des carnets de route manquants",
        lgbktp : "Type de carnet de bord",
        idlgbk : "ID du journal de bord",
        infs : "Informations",
        lvlofimprt : "Niveau d'importance",
        prmnnt : "Permanente",
        ntprmnnt : "Non permanente",
        mssnglgbk : "Carnet de bord manquant",
        mrklgbk : "Marquer le carnet de bord",
        
        dh: "Date et heure",
        mid : "Message ID",
        repmess : "Signaler ce message",
        bts: "Bot dit",
        obj : "Objet",
        plc : "Lieu",

        lstdplmss : "Liste des messages dupliqués",
        iddplmss : "ID des messages dupliqués",
        lstgfs : "Liste des GIFs",
        ddgfs : "Ajouter un nouveau GIF",
        gftp : "Type de GIF",
        gfimg : "Image GIF",

        nat: "Nature",
        tmslt : "Tranche horaire",
        gfsnt : "GIFs envoyés",

        qcdash : "Tableau de bord du contrôleur de la qualité",
        qctrl : "Contrôle de la qualité",
        lstmess : "Liste des messages",
        qcpnl : "Canal de contrôle de la qualité",
        ptreq : "Demande de photo",
        sp : "Envoyer une photo",
        sgft : "Envoyer un cadeau",
        sgif : "Envoyer un Gif",
        qccrit : "Critères de qualité du QC",
        qccrit1 : "Bonne discussion",
        qccrit2 : "Mauvaise discussion",
        qccrit3 : "Profil d'utilisateur considéré",
        qccrit4 : "Profil du bot considéré",
        qccrit5 : "Conversation engagée par le bot",
        qccrit6 : "CTA par l'agent",
        qccrit7 : "Conversation insensée",
        qccrit8 : "Utilisateur mécontent du bot",
        qccrit9 : 'Note bien prise',
        actqc1 : "Informer l'agent",
        actqc2 : "Suspendre l'agent",
        actqc3 : "Renvoyer l'agent",
      
        sndmailtoadm : "Envoyer un courriel à l'administrateur",
        yrnm : "Votre nom",
        yrcmpnm : "Le nom de votre entreprise",
        yrmladdr : "Votre adresse électronique",
        yradmid : "Votre identifiant d'administrateur",
        objctfyrrqst : "Objet de votre demande",
        yrmss : "Votre message",
        yrattchmt : "Pièce jointe",
        sndthml : "Envoyer le courriel",
        ddnwadm : "Ajouter un nouvel administrateur",
        nm : "Nom",
        mladd : "Adresse électronique",
        cmpnnm : "Nom de la société",
        tmfldr : "Dossier TextModing",
        psswrd : "Mot de passe",
        accssar : "Zones d'accès",
        valdt : "Validité",
        rgstr : "Enregistrer",

        accdash : "Tableau de bord du comptable",
        adc1:'Excellent agent',
        adc2:'Agent moyen',
        adc3:'Agent médiocre',
        acc : "Comptes",
        acc1 : "Statistiques de l'agent",
        acc2 : "Paiement par virement",
        acc3 : "Paiement par Paypal",
        acc4 : "Paiement par wise",
        acc5 : "Paiement par Yoursafe",
        acc6 : "Ajouter un comptable",

        admdash : "Tableau de bord de l'administrateur",
        chttlov : "Vue d'ensemble de l'outil de chat",
        suptraffman : "Supervision et gestion du trafic",
        vw : "Voir",
        ratepermess : "Taux par message",
        bonus : "Bonus",
        totpay : "Paiement total",
        paymeth : "Mode de paiement",
        grosstot : "Total brut au mois de ",
        prstat : "Imprimer le relevé",
        agfn : "Prénom de l'agent",
        agln : "Nom de famille de l'agent",
        payadd : "Adresse e-mail Paypal",
        nysent : "Pas encore envoyé",
        wiseadd : "Adresse électronique de Wise",
        yoursafeadd : "Adresse électronique de Yoursafe",
        bankn : "Nom de la banque",
        iban : "IBAN",
        bic : "Code BIC",

        lstsup : "Liste des superviseurs",
        ddnwsup : "Ajouter un nouveau superviseur",
        trffcmng : "Gestion du trafic",
        supnm : "Nom du superviseur",
        suplgn : "Connexion du superviseur",
        suplgnpsswrd : "Mot de passe de connexion du superviseur",
        idcnddt : "ID du candidat",
        cntry : "Pays",
        lgn : "Connexion",
        crtnwsup : "Créer un nouveau superviseur",
        fragntonln : "Agent libre en ligne",
        msgalltag : "Message alloué à l'agent",
        allct : "Attribuer",
        addqc : "Ajouter un contrôleur de qualité",
        crtqc : "Créer un contrôleur de qualité",
        crtnwacc: "Créer un nouveau comptable",

        gifn: "GIFs à envoyer",
        restfn: "Réinitialiser",
        gifsel: "Vide (sélectionner des gifs)",
        alerttxt: "Gifs envoyés avec succès.",
        option: "options",

        phalert: "Demande de photo envoyée.",
        phtitre: "Demande de photo",
        phlabel: "Type de photo",
        phsend: "Envoyer",
        phload: "Chargement...",

        delalert: "Supprimé(e)",
        deltitre1: "Voulez-vous vraiment supprimer ",
        deltitre2: "cette demande ?",
        delauteur: "Auteur",
        deldesc: "Description",
        delaccept: "Oui",
        delrefuse: "Non",
        delload: "Charge..",

        lstqc :  "Liste des contrôleurs de qualité" ,
        qcnm :  "Nom du contrôleur qualité" ,
        qclgn :  "Login du contrôleur qualité" ,
        qclgnpsswrd :  "Mot de passe de connexion du contrôleur qualité" ,
        lstacc :  "Liste des comptables" ,
        accnm :  "Nom du comptable" ,
        acclgn :  "Login du Comptable" ,
        acclgnpsswrd :  "Mot de passe de connexion du comptable" ,

        biev: "Bienvenue",
        selecrt: "Interface d'envoi de message",
        chxha: "Choisissez un vrai et un faux profil",
        chxF: "Liste des vrais profils",
        chxV: "Liste des faux profils",
        nomd: "Nom",
        langs: "Langue",
        errdj: "Sélectionner un vrai et un faux profil",
        plch: "Entrer votre message",
        pokePh: "Poke Messages (pas encore envoyer)",
        teaserPh: "Liste des messages d'accroche (pas encore envoyer)",
        inter: "Interface de test de TextModing",
        interSel: "Veuillez sélectionner les tâches que vous voulez effectuer",
        des1: "Envoi de message personnalisé à un profil.",
        des2: "Envoi de message pour attirer l'attention du profil.",
        des3: "Envoi personnalisé de messages captivants à des nouveaux inscrits pour engager des conversations.",
        des4: "Envoi de message pour relancer la conversation.",
        tit1: "Envoi message",
        tit2: "Message d'accroche",
        tit3: "Message poke",
        tit4: "Message de relance",
        tache: "Tâche",
        bienvTest: "Bienvenue dans l'interface de test de",
        enterEP: "Entrer votre email et mot de passe",
        instTest: "Instructions",
        sendInt: "Interface d'envoi",
        unPlP: "Choisissez un ou plusieurs vrais profils",
        unNouv: "Nouvelle",
        unReç: "Reçus",
        unNouv1: "Nouveau",
        payTot: "Total de paiment",
        allou: "Déja alloués",
        Nallou: "Pas encore alloués",
        totSoum: "Total de message",
        Soum: "Soumis à une QC",
        nonSoum: "Non soumis à une QC",
        boot: "Bot",
        rvr: "Rendez-vous/Réunions/Vacances",
        banit: "Banni",
        suspt: "Suspendu",
        aucunt: "Aucun message",
        msgstop: "Veuillez rédiger un message de relance",
        messrt: "Taux de message",
        pkmessrt: "Taux de poke message",
        tsrmessrt: "Taux de message d'accroche",
        stpmessrt: "Taux de message de relance",
        bnsrt: "Taux de bonus",
        mesdes: "L'envoi de message sur un site de rencontres est crucial pour capter l'attention et susciter l'intérêt de l'autre personne. L'essentiel est de rester authentique, de montrer un intérêt sincère et de poser des questions ouvertes qui encouragent une réponse.",
        mesdes1: "Parmi les profils, choisissez un vrai et un faux pour envoyer un message.",
        mesdes2: "Veillez à bien choisir la langue de correspondance.",
        mesdes3: "Rédigez votre message test.",
        mesdes4: "Vous avez 5 minutes pour répondre au message dans la plateforme d'animation pour les opérateurs.",
        mesdes5: "Vérifiez vos stats dans votre tableau de bord d'administrateur.",
        stopdes: "Les messages de relance sur un site de rencontre sont destinés à raviver l'intérêt et à réengager la conversation après une période de silence.",
        stopdes1: "Choisissez un vrai et un faux profils",
        stopdes2: "Rédiger un message habituel",
        stopdes3: "Attendez 24 heures après que l'opérateur tchat ait répondu au message du vrai profil",
        stopdes4: "Après 24 heures, le message non répondu par le vrai profil se transforme en Message de relance, que l'opérateur se chargera de composer et de le lui envoyer.",
        stopdes5: "Vérifiez vos stats dans votre tableau de bord d'administrateur",
        teaserdes: "Les messages d'accroche sur un site de rencontres sont cruciaux pour capter l'attention et susciter l'intérêt de l'autre personne. L'essentiel est de rester authentique, de montrer un intérêt sincère et de poser des questions ouvertes qui encouragent une réponse",
        teaserdes1: "Parmi les profils, choisissez un vrai et un faux pour envoyer un message d'accroche",
        teaserdes2: "Veillez à bien choisir la langue de correspondance",
        teaserdes3: "Sur la plateforme d'animation pour les opérateurs, rédigez les messages d'accroche",
        teaserdes5: "Vérifiez vos stats dans votre tableau de bord d'administrateur",
        pokedes: "Les messages 'Poke' pour un site de rencontres sont des messages courts et légers, conçus pour attirer l'attention de manière subtile et engageante.",
        pokedes1: "Choisissez le pays et la langue des vrais profils avec lesquels vous voulez envoyer des messages Poke",
        pokedes2: "Choisissez un à plusieurs profils avec lesquels vous voulez envoyer des messages Poke",
        pokedes3: "Vérifiez vos stats dans votre tableau de bord d'administrateur",
        stopbla: "Liste de message de relance déjà envoyé",
        respVp: "Réponse du vrai profil",
        pasEn: "pas encore.",
        notifAccr: "Teste Message d'accroche: Choix Profils fait avec succès",
        notifPoke: "Teste Message Poke: Choix Profil fait avec succès",
        pokeErr: "cliquer un ou plusieurs vrai profil",
        teaserErr: "cliquez sur un faux et un vrai profil",
        reqGift : "Demande de cadeaux",
        reqGift1 : "Type de cadeau",
        reqGift2 : "Demande de cadeau",
    },


    { //en

        usrsd: "User side",
        tmpp: "TextModing® Privacy Policy",
        lstmj: "Last updated ",
        ppint: "At TextModing®, we place a strong emphasis on the privacy and protection of our visitors' personal data. This privacy policy outlines TextModing®'s practices regarding the collection, use, retention, and protection of information collected through our contact form. By using this form, you agree to the terms outlined in this policy.",
        pp1ttl: "Data Collection",
        pp2ttl: "Data Use",
        pp3ttl: "Data Sharing",
        pp4ttl: "Data Retention",
        pp5ttl: "Data Security",
        pp6ttl: "Your Rights",
        pp7ttl: "Changes to the Privacy Policy",
        pp8ttl: "Contact",
        pp1int: "When you fill out our contact form, we collect the following information:",
        pp2int: "The information collected through our contact form is used solely for the following purposes:",
        pp3int: "TextModing® does not sell, rent, or disclose any personal information collected through the contact form to third parties, except in the following cases:",
        pp4int: "The information provided via the contact form is kept only for as long as necessary to respond to your request or for the period required by applicable law. Once the purpose has been fulfilled, the data is securely deleted.",
        pp5int: "TextModing® implements technical and organizational security measures to protect the information you share with us. However, while we do our best to secure your information, we cannot guarantee the absolute security of data transmitted online.",
        pp6int: "Under data protection legislation, you have the following rights regarding your personal information:",
        pp7int: "TextModing® reserves the right to update this privacy policy to reflect changes in our practices or to comply with legal developments. Any changes will be published on this page with the update date. We encourage you to regularly review this policy.",
        pp8int: "For any questions or requests regarding this privacy policy, please contact us at:",
        pp1out: "No other personal information is requested or collected through the contact form.",
        pp2out: "",
        pp3out: "",
        pp4out: "",
        pp5out: "",
        pp6out: "To exercise your rights, you can contact us at the following address:",
        pp7out: "",
        pp8out: "By using our contact form, you agree to this privacy policy.",
        pp1cont: "- Your company name, website address, physical address, and your name: to identify you and personalize our response.\n- Email address: to contact you in response to your request.\n- Subject of your message and your message: to understand the nature of your request and respond appropriately.",
        pp2cont: "- Responding to your request: We use your name, email address, and message to provide a personalized response.\n- Improving our services: We may anonymously analyze recurring questions or comments to improve our support and services.",
        pp3cont: "- Legal compliance: if we need to share information in response to a legal obligation or request from competent authorities.\n- Protection of TextModing®'s rights: to defend our rights in case of a dispute or to prevent fraud.",
        pp4cont: "",
        pp5cont: "",
        pp6cont: "- Right of access: you can request to review the information we have collected about you.\n- Right of rectification: you can request the correction of any inaccurate information.\n- Right to erasure: you can request the deletion of your personal information when it is no longer necessary for retention.\n- Right to restriction: you can limit the use of your information in certain cases.",
        pp7cont: "",
        ppaccept1adm: "By logging into our platform, you confirm that you have read our",
        ppadm: "administrator confidentiality agreement",
        ppaccept2adm: "for the TextModing® platform and that you freely adhere to it.",
        tmppadm: "Privacy Policy for Administrators of TextModing®",
        ppintadm: "TextModing® is committed to protecting the privacy and security of the personal data of its administrator partners and owners of dating sites. This privacy policy describes our practices regarding the collection, use, storage, and protection of data from administrators who use our platform for dating site management. By accessing TextModing®'s features, you agree to the practices outlined in this policy.",
        pp1ttladm: "Data Collection",
        pp2ttladm: "Data Use",
        pp3ttladm: "Data Sharing",
        pp4ttladm: "Data Retention",
        pp5ttladm: "Data Security",
        pp6ttladm: "Administrator Rights",
        pp7ttladm: "Cookies and Similar Technologies",
        pp8ttladm: "Changes to the Privacy Policy",
        pp9ttladm: "Contact",
        pp1intadm: "As part of our partnership relationship and for the use of our platform, we may collect the following information regarding the administrators of partner sites:",
        pp2intadm: "The collected data is used for the following purposes:",
        pp3intadm: "TextModing® does not sell or rent personal data of administrators and only shares them with third parties when necessary for the following purposes:",
        pp4intadm: "Administrator personal data is kept as long as necessary to achieve the purposes outlined in this policy or to comply with applicable legal obligations. Once the data is no longer needed, it is securely deleted.",
        pp5intadm: "TextModing® applies strict technical and organizational security measures to protect administrators' personal data against unauthorized access, alteration, disclosure, or destruction. Despite our efforts, no data transmission over the Internet is fully secure; therefore, we take all necessary precautions to minimize risks.",
        pp6intadm: "Administrators have specific rights regarding their personal information, including:",
        pp7intadm: "To enhance your user experience, we use cookies and other similar technologies to track login sessions and personalize the platform interface. You can configure your browser settings to limit the use of these cookies, but this may affect some platform features.",
        pp8intadm: "TextModing® reserves the right to modify this privacy policy to reflect changes in our practices or to comply with legal requirements. Any modifications will be posted on this page with an update date. We encourage you to review this policy regularly.",
        pp9intadm: "For any questions or inquiries related to this privacy policy, you can contact us at:",
        pp1outadm: "",
        pp2outadm: "",
        pp3outadm: "",
        pp4outadm: "",
        pp5outadm: "",
        pp6outadm: "To exercise these rights, please contact us at the following address: admin@creativ-network.net",
        pp7outadm: "",
        pp8outadm: "",
        pp9outadm: "By using the TextModing® platform, administrators accept this privacy policy and agree to apply it within the scope of their use of our services.",
        pp1contadm: "- Identification Information: name, surname, company name, role, administrator ID.\n- Contact Information: professional email address, phone number.\n- Login Information: account credentials to access the TextModing® platform.\n- Usage Data: login history, usage preferences, and interactions with the platform's features.\n- Financial Data: billing details and payment information, only if necessary for transactions.",
        pp2contadm: "- Access and Feature Management: allow administrators to access the 15 main features of TextModing® (conversation management, quality control, operator payroll, operator log management, photo request management, virtual gift management, GIF management, message traffic management, rating management, etc.).\n- Support and Assistance: provide technical support and personalized assistance in case of issues or additional information needs.\n- Service Improvement: analyze usage data to improve platform features and user experience.\n- Billing and Payment Tracking: process transactions and provide billing documentation.\n- Security: monitor logins and suspicious activities to ensure the security of administrator accounts.",
        pp3contadm: "- Service Providers: We may engage third-party service providers for specific operations (e.g., hosting, payment management). These providers are required to process your data confidentially and securely.\n- Legal Obligations: We may disclose your information if required by law or to respond to requests from authorities.\n- Protection of Rights: in case of a dispute, we may share information as part of legal proceedings to defend TextModing®'s rights.",
        pp4contadm: "",
        pp5contadm: "",
        pp6contadm: "- Right of Access: request to view the personal information we hold.\n- Right to Rectification: request correction of inaccurate or outdated information.\n- Right to Deletion: request deletion of your personal information under certain circumstances.\n- Right to Restriction of Processing: limit the use of your data in specific cases.",
        pp7contadm: "",
        pp8contadm: "",
        sccss: "Success",
        smthngwntwrng: "An error has occurred",
        cntcts: "Contacts",
        frmlrdcntct: "Contact form",
        ttr: "Title",
        mr: "Mr.",
        mme: "Mrs.",
        mlle: "Ms.",
        sct: "Company",
        stwb: "Website",
        addrss: "Address",
        prvncdprtm: "Province / department",
        cdpstl: "Postal code",
        cntr: "Country",
        prnmcntct: "Contact first name",
        nmcntct: "Contact last name",
        tlphn: "Phone",
        eml: "Email",
        objdvtrcntct: "Subject of your contact",
        dmdpht: "Demo request",
        prtnrt: "Partnership",
        affltn: "Affiliation",
        prsrdv: "Appointment booking",
        dmdbrchr: "Brochure request",
        othr: "Others",
        vtrmsg: "Your message",
        attchmt: "Attachment",
        pltqcnfdtxt: "By submitting this contact form, you declare that you have read and understood our",
        pltqcnfdtxt2: "privacy policy",

        xcldrssttm: "keys to success of TextModing®",
        cldrssttl1: "Affordable costs",
        cldrssttl2: "Advanced technology",
        cldrssttl3: "Platform adaptability",
        cldrssttl4: "Operational efficiency",
        cldrssttl5: "Transparency and communication",
        cldrssttl6: "Commitment to customer satisfaction",
        cldrsstxt1: "We offer competitive services with excellent value for money, enabling our clients to optimize their budgets while benefiting from quality solutions.",
        cldrsstxt2: "We leverage cutting-edge technologies to ensure robust performance, enhanced security, and smooth management of operations on the platform.",
        cldrsstxt3: "We provide flexibility that allows the platform to be tailored to the specific needs of each client, ensuring a personalized and effective response.",
        cldrsstxt4: "We maximize the efficiency of internal processes to ensure short implementation times and smooth operations without compromising service quality.",
        cldrsstxt5: "We maintain clear and transparent communication with clients, offering full visibility into the performance and outcomes of their projects.",
        cldrsstxt6: "We place customer satisfaction at the core of our priorities by implementing quick solutions and being constantly attentive to the needs and feedback of our partners.",
        prdtstm: "TextModing® products*",
        prdtttl1: "Test interface",
        prdtttl2: "Dashboard",
        prdtttl3: "Engagement platform",
        prdttxt1: "The TextModing® test interface allows prospective clients to explore and experience the essential features of the platform, including sending ordinary messages, hooks, pokes, and follow-ups, facilitated by our chat operators, for a complete immersion in our interactive environment.",
        prdttxt2: "The TextModing® dashboard provides administrators, supervisors, quality controllers, and accountants with an intuitive and centralized overview, facilitating real-time management of operations, performance monitoring, quality assurance, and financial tracking of the platform.",
        prdttxt3: "The TextModing® engagement platform offers chat operators an optimized interactive environment to manage exchanges, create engaging connections, and ensure dynamic and effective interaction among users.",
        prdindpdts: "The 3 products are interdependent",
        intrcteffcctprfrmnc: "“Interaction. Efficiency. Performance”",
        abtus: "About us",
        abtustxt: "TextModing® is an innovative platform dedicated to engaging dating sites through professional operators. We offer personalized moderation, content management, and engagement services to enhance user experiences on dating platforms. With our experienced team, we guarantee authentic and engaging interactions that promote a quality user experience.",
        rdswt: "Read more",
        lnsdst: "Site links",
        lngdst: "Site language",
        mnaddrss: "Main address",
        rprsnttbrblg: "Belgian office representative",
        ttdrtsrsrvs: "All rights reserved",
        apr: "About",
        ftrs: "Features",
        avntgs: "Advantages",
        cldrsst: "Keys to success",
        nsprdts: "Our products",
        orpltfrm1: "Our platform",
        orpltfrm2: "for running dating sites",
        orpltfrmtxt: "TextModing® is a high-performance platform designed to manage and optimize online dating sites. With its advanced tools and intuitive interface, it enables effective management of user interactions, ensuring a high level of engagement.",
        flxqtdns: "Daily flows",
        rqstdmo: "Request a demo",

        aprtxt1: "TextModing® is an innovative platform dedicated to the management of dating sites by professional operators. We offer personalized services for moderation, content management, and engagement to enhance user experience on dating platforms. With our experienced team, we guarantee authentic and engaging interactions that promote a high-quality user experience.",
        aprtxt2: "Our platform allows operators to effectively manage conversations, ensure quality control, and supervise message traffic. With advanced features such as photo requests, virtual gifts, and GIFs management, as well as note-taking for optimal interaction tracking, TextModing® is a comprehensive tool for sites seeking to offer an interactive and dynamic experience.",
        aprtxt3: "We are committed to providing high-quality services with a team available to support the specific needs of dating sites and optimize member engagement.",

        xftftm: "TextModing® features",

        ft1ttl: "Conversation management",
        ft2ttl: "Agent management",
        ft3ttl: "Panic room",
        ft4ttl: "Notes management",
        ft5ttl: "Opening messages",
        ft6ttl: "Poke messages",
        ft7ttl: "Follow-Up messages",
        ft8ttl: "Photo requests",
        ft9ttl: "Agent reporting",
        ft10ttl: "Logbook",
        ft11ttl: "Duplicate messages",
        ft12ttl: "Gift management",
        ft13ttl: "Traffic supervision and management",
        ft14ttl: "Quality control",
        ft15ttl: "Accounting",

        ft1txt: "Allows administrators, team leaders, and supervisors to monitor, control, and analyze interactions in real-time, ensuring the quality and smoothness of communication on dating sites.",
        ft2txt: "Enables administrators, team leaders, and supervisors to track, guide, and evaluate agent performance in real-time, ensuring effective site management.",
        ft3txt: "Provides administrators, team leaders, and supervisors with a secure space to handle emergencies, quickly resolve critical incidents, and ensure the continuity of operations on dating sites.",
        ft4txt: "Allows administrators, team leaders, and supervisors to create, track, and share notes on agent interactions, optimizing coordination and performance tracking.",
        ft5txt: "Enables administrators, team leaders, and supervisors to create, customize, and deploy effective introduction messages to optimize user engagement on dating sites.",
        ft6txt: "Allows administrators, team leaders, and supervisors to configure, send, and analyze strategic poke messages to rekindle engagement and stimulate user interaction.",
        ft7txt: "Allows administrators, team leaders, and supervisors to schedule, customize, and analyze follow-up messages aimed at reactivating engagement from inactive users on the site.",
        ft8txt: "Allows administrators, team leaders and supervisors to supervise, approve and manage operators' photo requests, ensuring the conformity and quality of the content exchanged.",
        ft9txt: "Allows administrators, team leaders, and supervisors to track, review, and handle reports regarding agents, ensuring proactive moderation and optimal quality control.",
        ft10txt: "Enables administrators, team leaders, and supervisors to track, log, and analyze operator notes to optimize performance and communication.",
        ft11txt: "Allows administrators, team leaders, and supervisors to identify and manage repetitive messages to improve interaction quality with users.",
        ft12txt: "Allows administrators, team leaders, and supervisors to oversee and analyze the sending of engaging content to strengthen user interaction.",
        ft13txt: "Allows administrators, team leaders, and supervisors to analyze message flows to optimize performance and ensure smooth communication between users.",
        ft14txt: "Allows administrators, team leaders, and supervisors to evaluate operator interactions to guarantee high-quality service and optimal customer satisfaction.",
        ft15txt: "Allows administrators and accountants to monitor and manage financial transactions, ensuring transparency and efficiency in resource management.",

        nsecrr: "Contact Us",

        cqdfftm: "What differentiates TextModing®",
        cqdffttl: "TextModing® stands out from its competitors with several key advantages:",
        cqdffttl1: "Specialized expertise",
        cqdffttl2: "Customization and flexibility",
        cqdffttl3: "Ongoing support and training",
        cqdffttl4: "Constant innovation",

        cqdfftxt1: "TextModing® brings together highly skilled web talents, such as chat operators, supervisors, quality controllers, and traffic managers, ensuring optimal and professional management of dating sites.",
        cqdfftxt2: "Through our platform, we offer tailored solutions adapted to each client's specific needs, allowing for more targeted and efficient management.",
        cqdfftxt3: "TextModing® does not just provide services; we invest in the continuous training of our staff to ensure quick adaptation to new trends and technologies, while offering constant support to our partners to foster their growth.",
        cqdfftxt4: "By staying at the forefront of the latest technologies and engagement strategies, TextModing® ensures its clients a sustainable competitive advantage in the ever-evolving market.",
        btsd: "Bot side",
        nttkn: "Notes taken",
        mssdnt: "Missed notes",
        gfts: "Gifts",
        rqsts: "Requests",
        messonhldallctd: "Message on hold already allocated",
        messonhldntallctd: "Message on hold not allocated",
        totnummess: "Total number of messages",
        messpssdqc: "Messages passed QC",
        messntpssqc: "Messages not passing QC",
        paymnts: "Payments",
        bnk: "Bank",
        ppl: "Paypal",
        ws: "Wise",
        yrsf: "Yoursafe",
        rçes: "Received",
        envoyees: "Sent",
        dmndees: "Requested",
        nvaux: "New",

        adddest: "Recipient's e-mail address",

        cmpn: "Company",
        tmfld: "Textmoding folder",
        accar: "Access area",
        assvldt: "Access validity",

        acc8: "Rate and Bonus Set up",

        mtng: "Meeting",
        rp: "Real Profil",
        fp: "Fake Profil",
        infopernoper: "Permanent / non-permanent information",
        dltnt: "Delete note",
        dltmesspnc: "Do you really want to delete this panic message?",
        convbnnd: "Banned conversation",
        suspconv: "Suspend the conversation",
        upldd: "Uploaded",
        addpht: "Add photo",
        phtsnttofp: "List of photos sent to fake profiles",
        pkmesscrtd: "Poke message created successfully",
        updt: "Update",
        dltpkmess: "Do you really want to delete this poke message?",
        plt: "Platform",
        dltdmd: "Do you really want to delete this request? ",
        agntinfrmd: "Agent informed",
        dlttsrmess: "Do you really want to delete this teaser message?",
        updttsrmess: "Update teaser message",

        updtdsucc: "Updated successfully",
        nonotif: "No notification",
        continfo: "Content Information",
        exp: "Sender",
        recep: "Receiver",
        mdpupdtd: "Password updated successfully",
        opsusp: "Operator suspended successfully",
        opdec: "Operator disconnected successfully",
        decop: "Do you really want to disconnect this operator:  ",
        frop: "Do you really want to fire this operator:  ",
        chngmdp: "Change password",
        suspop: "Do you really want to suspend this operator: ",
        onhld: "On hold",
        supprmess: "Delete this message",
        messinfo: "Message information",
        vld: "Confirm",
        ok: "Ok",
        enc: "In progress ...",

        updtmdp: "Do you want to change the password?",
        nwmdp: "Enter new password",
        dltsup: "Do you really want to delete this supervisor?",
        ntsup: "Note to a supervisor",
        nthr: "Note here...",
        dltimg: "Do you really want to delete this picture?",
        phttsnd: "Photo to send",
        vd: "Empty (select a photo)",
        addd: "Added",
        nwrdv: "Add new information",
        cnfrm: "Confirm",
        dltrdv: "Do you really want to delete this Rendez-vous/ Meetings/ Holidays?",
        addnonperminfo: "Add non-permanent information ",
        dltinfo: "Do you really want to delete this information?",
        idd: "ID",
        addperminfo: "Add permanent information ",
        messreport: "Message reported to supervisor",
        usrnm: "Username",

    	paymail: 'Payment e-mail address',
        pp11: "Creativ'Network, a company specializing in professional networking for web-related work, with its registered office located at 71-75 Shelton Street, Covent Garden, London, WC2H 9JQ, and having its representative office at Roodborstjesstraat 4, Wondelgem 9032, hereinafter referred to as 'the Company',",
        pp12: "And:",
        pp13: "An independent contractor who, under the Independent Contractor Agreement with 'the Company,' utilizes the materials and intellectual properties of the latter, hereinafter referred to as 'the Independent Contractor',",
        pp14: "Hereinafter individually referred to as 'the Party' and collectively as 'the Parties',",
        pp21: "The Company operates a dating site management platform of which it is the exclusive owner. The operational management of this platform is entrusted to chat operators, supervisors, team leaders, and quality controllers, employed as independent contractors by the Company.",
        pp22: "As part of this professional relationship, the Independent Contractor will have access to confidential information related to the platform, the users of the dating sites, the operational processes, and any other protected information owned by the Company.",
        pp23: "Having stated the above, it is agreed as follows:",
        pp31: "The purpose of this confidentiality agreement is to protect the confidential information that the Company may disclose to the Independent Contractor in connection with their duties related to the management of the dating site management platform.",
        pp41: "For the purposes of this agreement, 'Confidential Information' refers to all information, in any form (written, oral, electronic, etc.), related to the dating site management platform, the users, development strategies, technical and operational processes, financial data, as well as any other information designated as confidential by the Company that the Independent Contractor may receive or have access to in the course of their work.",
        pp51: "The Independent Contractor agrees to:",
        pp52: "- Not disclose, reproduce, transmit, or make accessible the Confidential Information, in whole or in part, to third parties without the prior written consent of the Company.",
        pp53: "- Use the Confidential Information solely in the context of activities related to the management of the platform and only in the interest of the Company.",
        pp54: "- Take all necessary measures to protect the confidentiality of the Confidential Information and prevent any unauthorized use.",
        pp55: "- Return to the Company, upon request, any document or medium containing Confidential Information, without retaining any copies in any form.",
        pp61: "This agreement becomes effective when the contractor consents during their identification for access to the management platform and will remain in force for the duration of the professional relationship between the Company and the Independent Contractor. The confidentiality obligations will survive for a period of 10 years following the end of this relationship, whatever the cause.",
        pp71: "The following are not considered Confidential Information:",
        pp72: "- Information that was already known to the public at the time it was disclosed to the Independent Contractor, or which becomes public other than through a breach of this agreement.",
        pp73: "- Information that the Independent Contractor can prove was received legitimately from a third party not bound by a confidentiality obligation.",
        pp74: "- Information developed independently by the Independent Contractor without using the Confidential Information.",
        pp81: "In the event of a breach of the obligations set forth in this agreement, the Independent Contractor acknowledges that the Company may take all necessary legal actions to protect its rights and seek compensation for damages suffered.",
        pp91: "This agreement is governed by Belgian law. In the event of a dispute regarding the interpretation or execution of this agreement, the Parties agree to seek an amicable resolution. Failing an amicable agreement, the competent courts of Ghent shall have exclusive jurisdiction.",
        pp1: "Between the Parties",
        pp2: "Preamble",
        pp3: "Article 1: Purpose",
        pp4: "Article 2: Definition of Confidential Information",
        pp5: "Article 3: Obligations of the Independent Contractor",
        pp6: "Article 4: Duration",
        pp7: "Article 5: Exclusions",
        pp8: "Article 6: General Provisions",
        pp9: "Article 7: Governing Law and Jurisdiction",
        scc: 'Created',
        login: 'Login',
        password: 'Password',
        confirmpass:'Confirm password',
        alreadyreg:'Already registered? Login',
        notifs: "Notifications",
        limite:'Limited',
        save:'Save',
        archives:'Archives',
        ttlmsg:'Total Messages',
        controlLog:'Controller Login',
        nbrmess:'Number of messages',
        appQC:'QC Appreciations',
        esqmq:'Missing dodge',
        dbspace:'Double space',
        notelu:'Note Read',
        actpris:'Action Taken',
        ferme:'Closed',
        losn:'List of sent Notes',
        lorn:'List of received Notes',
        validity:'Validity',
        editInfo:'Edit information',
        controlID:'ID Controller',
        statcontrolct:'Control Statistics by Controller',
        statcontrolag:'Control Statistics by Agent',
        statcontrol:'Control Statistics',
        traitement:'Treatment',
        agentid:'Agent ID',
        statconvatt:'Conversations on Hold Statistics',
        propesqu:'Enter an excuse for a date request',
        msgacc:'Please write a teaser message',
        msgpoke:'Please write a Poke message',
        heure:'Hour',
        esqv:'Dodge',
        esquive:'Dodges meetings and appointments',
        other:'Others',
        sexe:'Gender',
        bannir:'Ban',
        agntLgn : 'Online Agent',
        features:'Features',
        rdv:'Appointment',
        holiday:'Holiday',
        valider:'Validate',
        panicmsg:'this panic message?',
        jour:'Day',
        jours:'Days',
        conversation:'Conversation',
        profil:'Profile',
        suspendre:'Suspend',
        expulser:'Expel',
        information:'Information',
        meeting:'Meeting',
        online:'Online',
        total:'Total',
        onHold:'OnHold',
        treated:'Treated',
        listPhotoSend:'List of sent photos',
        agentReport:'Reported agent',
        suspendu:'Suspended',
        suspend:'Suspend',
        etat:'Status',
        deconnecter:'Disconnect',
        actqc4 : 'Message validated',
        panicmess:'Panic message',
        contenu:'Content',
        raison:'Reason',
        language1:'Language',
        reportmess:'Report a message',
        mail:'Email',
        reg:'Register',
        enreg:['Not registered yet?','Register'],
        passdiff:'Different password',
        addnote: 'Note added',
        qlogout: 'Do you want to log out?',
        annuler:'Cancel',
        notesuppr: 'Note deleted',
        editer:'Edit',
        editNote:'Edit Note',
        logout: 'Log Out',
        yes: 'Yes',
        no: 'No',
        operatorpage: "Operators page",
        supervisorpage: "Supervisors page",
        adminpage: "Administrators page",

        required: "This field is required",
        invalid: "Invalid email",
        registered: "This e-mail address is not registered",
        incorrectpass: "Incorrect password",
        atleast: "Password must contain at least",
        characters: "characters",

        convman: "Conversations management",
        agman: "Agents management",
        notesman: "Notes management",
        agrep: "Agents reports",
        gftgtfman: "Gifts and gifs management",
        tmfldaa: "TextModing folder access allowance",
        hlp: "Help",
        pltflng: "Platform language",
        pr: "Photo requests",
        wlcm: "Welcome",
        wlcmtxt: "TextModing is an animation platform designed for dating sites, offering essential features for managing conversations and agents. It enhances efficiency and profitability for partners by providing tools that streamline interactions and optimize user engagement.",

        messageto: 'Message to',
        convatt: 'Conversation on hold',
        mincharlen: 'At least 120 characters',
        char: 'character',
        chars:  'characters',
        pj: 'Photo attachment',
        writemessage: 'Write a message ...',
        picalreadysent: 'Photo seen by the user',
        gifpj: 'GIFS',
        opengif: 'Open GIF',
        sendMessage: 'Send the message',
        reportSup: 'Send a note to Supervisor',
        conv: 'Conversation ID',
        say: 'says',
        timeIntro: 'at',
        elements: 'elements',
        element: 'element',
        none: 'None',

        stat: 'Statistics',
        french: 'French',
        english: 'English',
        italian: 'Italian',
        dutch: 'Dutch',
        spanish: 'Spanish',
        german: 'German',
        botprofil: 'Bot Profile',
        userprofil: "User profile",
        otherInfo: 'Other important informations',
        codepost: 'Postal code',
        ville: 'City',
        region: 'Region',
        pays: 'Country',
        taille: 'Size',
        eyes: 'Eyes color',
        hair: 'Hair color',
        morph: 'Morphology',
        situation: 'Marital status',
        searchfor: 'Search for',
        job: 'Occupation',
        signe: 'Distinctive signs',
        descri: 'Description',
        infosupp: 'Additional information',

        supervisiontchat: 'Chat supervision',
        statconvop: 'Stat and operator conversation',
        convs: 'Conversations',
        report: 'Report',
        agentInform:'Informed agent',
        events: 'Event',
        photosrequests: 'Photo request',
        warmup: 'Warmup',
        gifattch: 'Gif attachment',
        fakeprofiles: 'Fake profiles',
        pagenotfound: 'Page not found',

        suivvraiprof: 'Real profiles monitoring',
        suiviop: 'Operators monitoring',
        admintion: 'Administration',
        affiliation : 'Membership',

        operator: 'Operator',
        supervisor: 'Supervisor',
        administrator: 'Administrator',

        week: 'week',
        weeks: 'weeks',
        today: 'Today',

        start: 'Start',
        end: 'End',
        platform: 'Platforms',
        search: 'Looking for',
        go: 'Go',
        allmasc: 'All',
        allfem: 'All',

        id: 'Identifier',
        opname: 'Operator name',
        sentmess: 'Sent messages',
        recmess: 'Messages received',
        relmess: 'Dunning messages',

        date: 'Date',
        messid: 'Message identifier',
        convid: 'Conversation identifier',
        exped: 'Sender',
        recpt: 'Receiver',
        opsign: 'Operator to report',
        opexped: 'Sending operatorr',
        action: 'Action',
        messs: 'Messages',
        filterdate: 'Filter searches between two dates',
        filtermess: 'Filter conversation by message',
        from: 'From',
        to: 'to',
        eventList: 'Events list',
        num: 'Number',
        eventSearch: 'Search for an event',
        actions: 'Actions',

        eventname: 'Event name',
        eventdate: 'Event date',
        uploadimage: "Upload images",
        role: "Role",
        logIn: "Log in",
        
        ppaccept1: "By logging on to our platform, you confirm that you have read our",
        ppaccept2: "and have taken the appropriate training to carry out your assignment.",
        pp: "confidentiality agreement",
        copyright: "© 2023-2024 Copyright TextModing® - All rights reserved.",
        accountant: 'Accountant',
        qualitycontroller: 'Quality controller',
        adminid: "Admin ID",
        accountantid: "Accountant ID",
        operatorid: "Operator ID",
        qcid: "Quality Controller ID",
        supervisorid: "Supervisor ID",
        convonhold: "Conversations on hold",
        supdash: "Supervisor dashboard",
        choverv: "Chat overview",
        months: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
        year: 'Year',
        month: 'Month',
        startingdate: 'Starting date',
        closingdate: 'Closing date',

        agents: "Agents",
        out: "Out",
        in: "In",
        details: "Details",
        panicroom: "Panic room",
        notes: "Notes",
        teasermessages: "Teaser messages",
        pokemessages: "Poke messages",
        attdisc:'Waiting for Discussion',
        gifajt:'Gif Add',
        stopmessages: "Stop messages",
        sent: "Sent",
        asnwered: "Answered",
        requested: "Requested",
        logbook: "Log book",
        recorded: "Recorded",
        notrecorded: "Not recorded",
        duplicatedmessages: "Duplicated messages",
        back: "Back",


        suty: "Subscription type",
        ag: "Age",
        gend: "Gender",
        sexor: "Sexual orientation",
        tow: "Town",
        
        users: "Users",
        sender: "Sender",
        receiver: "Receiver",
        agent: "Agent",
        status: "Status",
        read: "Read",
        active: "Active",
        edit: "Edit",
        user: "User",
        sendnote: "Send note",
        oh: "On hold",
        mess: "Message",
        trconv: "Treated conversations",
        idconv: "Conversation ID",
        idmess: "Message ID",
        otherimp: "Other important informations",
        otherimp1: "Meeting/ Meetings/ Holidays",
        otherimp2: "Permanent informations",
        otherimp3: "Useful informations, not permanent ",
        
        aglist: "List of agents",
        pfsperf: "Platforms performance",
        agperf: "Agents performance",
        nwag: "Add new agents",
        agname: "Agent name",
        agcountry: "Agent country",
        aglogin: "Agent login",
        agloginpw: "Agent login password",
        langpf: "Language platform",
        onl: "Online",
        offl: "Offline",
        susp: "Suspend",
        koff: "Kick off",
        grotot: "Gross Total",
        agnumbewoon: "Number of fake profiles",
        totsenmess: "Total messages sent",
        totrecmess: "Total messages received",
        totrep: "Total reports",
        real: "Real name",
        prov: "Province",
        weight: "Weight",
        child: "Children",
        time: "Time",
        fstnm: "First name",
        lstnm: "Last name",
        dtofbir: "Date of birth",
        skypeid: "Skype ID",
        crnwag: "Create new agent",
        usrprfl: "User profile",
        sndmess: "Send message",
        blckusr: "Block user",
        lstnts: "List of notes",
        crtsndnts: "Create / Send a note",
        evnt: "Event",
        ntid: "Note ID",
        nt: "Note",
        dlt: "Delete",
        cndid: "Candidate ID",
        language: "Language",
        perc1: "You have composed ",
        perc2: "of the user's message",
        giftsatt: "Gifts attachment",
        sndnt: "Send the note",
        lsttsrmsg: "List of teaser messages",
        crttsrmsg: "Create teaser messages",
        idtsrmsg: "Teaser message ID",
        tsrmsg: "Teaser message",
        dlvrdt: "Delivered to",
        wiseg: "Wise Gifs",
        sexg: "Sexual Gifs",
        gayg: "Gay Gifs",
        Lesbg: "Lesbian Gifs",
        transg: "Transgender Gifs",
        travg: "Travestite Gifs",
        
        bdsm: "BDSM",
        dont: "Don't be long",
        blj: "Blowjob",
        org: "Orgasm",
        vagpen: "Vaginal penetration",
        sod: "Sodomy",
        titss: "Tits",

        bdsmg: "BDSM Gay",
        wisegg: "Wise Gay Gifs",
        sexgg: "Sex Gay Gifs",

        bdsml: "BDSM Lesbian",
        wiselg: "Wise Lesbian Gifs",
        sexlg: "Sex Lesbian Gifs",

        maletrans: "Male transgender",
        femaletrans: "Female transgender",
        lstpkmsg: "List of poke messages",
        crtpkmsg: "Create poke message",
        idpkmsg: "Poke message ID",
        pkmsg: "Poke message",
        lststpmsg: "List of stop messages",
        idstpmsg: "Stop message ID",
        stpmsg: "Stop message",
        sntto: "Sent to",
        agntwrtngstp: "Agent writing the stop",
        dtofdlvr: "Date of delivery",
        dtofans: "Date of answer",
        stpperfanal: "Stop performance analysis",
        stpgrssttl: "Stop gross total",
        rsptostp: "Response to stop",
        rsptostp2: "Response to the Teaser",
        rsptostp3: "Response to the Poke",
        stpprcntresp: "Stop % response",
        avrgrsptm : "Average response time",

        lstphtrq: "List of photos request",
        phttp: "Photo type",
        idrq: "Request ID",
        pht: "Photo",
        upld: "Upload",
        snd: "Send",
        lstrprt: "List of reports",
        rpttp: "Report type",
        idrprt: "Report ID",
        msgrprtd: "Message reported",
        infrmagnt: "Inform agent",
        lstlgbk: "List of log book",
        lstmssnglgbk: "List of missing log book",
        lgbktp: "Log book type",
        idlgbk: "Log book ID",
        infs: "Informations",
        lvlofimprt: "Level of importance",
        prmnnt: "Permanent",
        ntprmnnt: "Not permanent",
        mssnglgbk: "Missing log book",
        mrklgbk: "Mark log book",
        
        dh: "Date and Hour",
        mid: "Message ID",
        repmess: "Report this message",
        bts: "Bot says",
        obj: "Object",
        plc: "Place",

        lstdplmss: "List of duplicated messages",
        iddplmss: "Duplicated messages ID",
        lstgfs: "List of GIFs",
        ddgfs: "Add new GIF",
        gftp: "GIF type",
        gfimg: "GIF image",

        nat: "Nature",
        tmslt: "Time Slot",
        gfsnt: "GIFs sent",

        qcdash: "Quality Controller Dashboard",
        qctrl: 'Quality control',
        lstmess: "List of messages",
        qcpnl: "Quality control pannel",
        ptreq: "Request photo",
        sp: "Send photo",
        sgft: "Send Gift",
        sgif: "Send Gif",
        qccrit: "QC criterias",
        qccrit1: "Good Chat",
        qccrit2: "Bad Chat",
        qccrit3: "User profile regarded",
        qccrit4: "Bot profile regarded",
        qccrit5: "Conversation engaged by the bot",
        qccrit6: "CTA by agent",
        qccrit7: "Conversation non sense",
        qccrit8: "User unhappy about the bot",
        qccrit9:'Note well taken',
        actqc1: "Inform the agent",
        actqc2: "Suspend the agent",
        actqc3: "Fire the agent",
        adc1:'Excellent agent',
        adc2:'Average agent',
        adc3:'Poor agent',
        sndmailtoadm: "Send an email to the admin",
        yrnm: "Your name",
        yrcmpnm: "Your company name",
        yrmladdr: "Your email address",
        yradmid: "Your admin ID",
        objctfyrrqst: "Object of your request",
        yrmss: "Your message",
        yrattchmt: "Attachment",
        sndthml: "Send the email",
        ddnwadm: "Add new admin",
        nm: "Name",
        mladd: "Email address",
        cmpnnm: "Company name",
        tmfldr: "TextModing folder",
        psswrd: "Password",
        accssar: "Access areas",
        valdt: "Validity",
        rgstr: "Register",

        accdash: "Accountant Dashboard",
        acc: "Accounts",
        acc1: "Agent statistics",
        acc2: "Payment by wire transfer",
        acc3: "Payment by Paypal",
        acc4: "Payment by wise",
        acc5: "Payment by Yoursafe",
        acc6: "Add an accountant",

        admdash: "Admin dashboard",
        chttlov: "Chattool overview",
        suptraffman: "Supervising and traffic Management",
        vw: "View",
        
        ratepermess: "Rate per message",
        bonus: "Bonus",
        totpay: "Total payment",
        paymeth: "Payment method",
        grosstot: "Gross Total as at month of ",
        prstat: "Print the statement",
        agfn: "Agent first name",
        agln: "Agent last name",
        payadd: "Paypal e-mail address",
        nysent: "Not yet sent",
        wiseadd: "Wise e-mail address",
        yoursafeadd: "Yoursafe e-mai,l address",
        bankn: "Bank name",
        iban: "IBAN",
        bic: "BIC code",

        lstsup: "List of supervisors",
        ddnwsup: "Add new supervisor",
        trffcmng: "Traffic management",
        supnm: "Supervisor name",
        suplgn: "Supervisor login",
        suplgnpsswrd: "Supervisor login password",
        idcnddt: "Candidate ID",
        cntry: "Country",
        lgn: "Login",
        crtnwsup: "Create new supervisor",
        fragntonln: "Free agent online",
        msgalltag: "Message allocation to agent",
        allct: "Allocate",
        addqc: "Add Quality Controller",
        crtqc: "Create Quality Controller",
        crtnwacc: "Create new accountant",

        gifn: "GIFs to send",
        restfn: "Reset",
        gifsel: "Empty (select gifs)",
        alerttxt: "GIFs sent successfully.",
        option: "options",

        phalert: "Request for sent photo.",
        phtitre: "Photo request",
        phlabel: "Type of photo",
        phsend: "Send",
        phload: "loading...",

        delalert: "Deleted",
        deltitre1: "Do you really want to delete ",
        deltitre2: "this request ?",
        delauteur: "Author",
        deldesc: "Description",
        delaccept: "Yes",
        delrefuse: "No",
        delload: "Load..",

        lstqc: "List of Quality Controller",
        qcnm: "Quality controller name",
        qclgn: "Quality controller login",
        qclgnpsswrd: "Quality controller login password",
        lstacc: "List of Accountant",
        accnm: "Accountant name",
        acclgn: "Accountant login",
        acclgnpsswrd: "Accountant login password",

        biev: "Welcome",
        selecrt: "Message Sending Interface",
        chxha: "Choose a real and a fake profile",
        chxF: "List of real profiles",
        chxV: "List of fake profiles",
        nomd: "Name",
        langs: "Language",
        errdj: " Select a real and a fake profile",
        plch: "Enter your message",
        pokePh: "Poke Messages (not yet sent)",
        teaserPh: "List of hook messages (not yet sent)",
        inter: "TextModing Test Interface",
        interSel: "Please select the tasks you want to perform",
        des1: "Sending a personalized message to a profile.",
        des2: "Sending a message to catch the profile's attention.",
        des3: "Personalized sending of engaging messages to new sign-ups to start conversations.",
        des4: "Sending a message to restart the conversation.",
        tit1: "Send message",
        tit2: "Teaser message",
        tit3: "Poke message",
        tit4: "Stop message",
        tache: "Task",
        bienvTest: "Welcome to the test interface of",
        enterEP: "Enter your email and password",
        instTest: "Instructions",
        sendInt: "Sending Interface",
        unPlP: "Choose one or more real profiles.",
        unNouv: "New",
        unReç: "Received",
        unNouv1: "New",
        payTot: "Total payment",
        allou: "Already allocated",
        Nallou: "Not yet allocated",
        totSoum: "Total message",
        Soum: "Submitted to QC",
        nonSoum: "Not submitted to QC",
        boot: "Boot",
        rvr: "Appointments/Meetings/Vacations",
        banit: "Banned",
        suspt: "Suspended",
        aucunt: "No message",
        msgstop: "Please write a stop message",
        messrt: "Message Rate",
        pkmessrt: "Poke Message Rate",
        tsrmessrt: "Teaser Message Rate",
        stpmessrt: "Stop Message Rate",
        bnsrt: "Bonus Rate",
        mesdes: "Sending messages on a dating site is crucial to capture the other person's attention and spark their interest. The key is to stay authentic, show genuine interest, and ask open-ended questions that encourage a response.",
        mesdes1: "Among the profiles, choose one real and one fake to send a message.",
        mesdes2: "Make sure to choose the appropriate correspondence language.",
        mesdes3: "Write your test message.",
        mesdes4: "You have 5 minutes to reply to the message on the operators' animation platform.",
        mesdes5: "Check your stats on your administrator dashboard.",
        stopdes: "Follow-up messages on a dating site are meant to rekindle interest and re-engage the conversation after a period of silence.",
        stopdes1: "Choose one real and one fake profile",
        stopdes2: "Draft a usual message",
        stopdes3: "Wait 24 hours after the chat operator has replied to the message from the real profile",
        stopdes4: "After 24 hours, the message not replied to by the real profile turns into a Follow-up Message, which the operator will compose and send.",
        stopdes5: "Check your stats on your administrator dashboard",
        teaserdes: "Teaser messages on a dating site are crucial for grabbing attention and sparking the other person's interest. The key is to stay authentic, show genuine interest, and ask open-ended questions that encourage a response",
        teaserdes1: "Among the profiles, choose one real and one fake to send a teaser message",
        teaserdes2: "Make sure to choose the appropriate correspondence language",
        teaserdes3: "On the animation platform for operators, draft the teaser messages",
        teaserdes5: "Check your stats on your administrator dashboard",
        pokedes: "Poke messages for a dating site are short and light messages designed to subtly and engagingly grab attention.",
        pokedes1: "Choose the country and language of the real profiles with which you want to send Poke messages",
        pokedes2: "Select one or more profiles with which you want to send Poke messages",
        pokedes3: "Check your stats on your administrator dashboard",
        stopbla: "List of follow-up messages already sent",
        respVp: "Response from the real profile",
        pasEn: "not yet.",
        notifAccr: "Test Hook Message: Profile selection made successfully",
        notifPoke: "Test Poke Message: Profile selection made successfully",
        pokeErr: "Click one or more real profiles",
        teaserErr: "click a fake and a real profile",
        reqGift : "Request gifts",
        reqGift1 : "Type of gift",
        reqGift2 : "Gift request",
    },


    { // it

        usrsd : "Lato utente",
        tmpp: "Politica sulla Privacy di TextModing®",
        lstmj: "Ultimo aggiornamento ",
        ppint: "In TextModing® attribuiamo una particolare importanza alla privacy e alla protezione dei dati personali dei nostri visitatori. Questa politica sulla privacy descrive le pratiche di TextModing® relative alla raccolta, all'uso, alla conservazione e alla protezione delle informazioni raccolte tramite il nostro modulo di contatto. Utilizzando questo modulo, accetti i termini descritti in questa politica.",
        pp1ttl: "Raccolta dei Dati",
        pp2ttl: "Utilizzo dei Dati",
        pp3ttl: "Condivisione dei Dati",
        pp4ttl: "Conservazione dei Dati",
        pp5ttl: "Sicurezza dei Dati",
        pp6ttl: "I Tuoi Diritti",
        pp7ttl: "Modifiche alla Politica sulla Privacy",
        pp8ttl: "Contatti",
        pp1int: "Quando compili il nostro modulo di contatto, raccogliamo le seguenti informazioni:",
        pp2int: "Le informazioni raccolte tramite il nostro modulo di contatto vengono utilizzate esclusivamente per i seguenti scopi:",
        pp3int: "TextModing® non vende, affitta né divulga alcuna informazione personale raccolta tramite il modulo di contatto a terzi, tranne nei seguenti casi:",
        pp4int: "Le informazioni fornite tramite il modulo di contatto vengono conservate solo per il tempo necessario a rispondere alla tua richiesta o per il periodo previsto dalla legge applicabile. Una volta raggiunto lo scopo, i dati vengono eliminati in modo sicuro.",
        pp5int: "TextModing® adotta misure di sicurezza tecniche e organizzative per proteggere le informazioni che condividi con noi. Tuttavia, pur facendo del nostro meglio per proteggere le tue informazioni, non possiamo garantire la sicurezza assoluta dei dati trasmessi online.",
        pp6int: "In base alla legislazione sulla protezione dei dati, hai i seguenti diritti riguardo alle tue informazioni personali:",
        pp7int: "TextModing® si riserva il diritto di aggiornare questa politica sulla privacy per riflettere eventuali modifiche nelle nostre pratiche o per conformarsi a modifiche legali. Qualsiasi modifica sarà pubblicata su questa pagina con la data di aggiornamento. Ti incoraggiamo a consultare regolarmente questa politica.",
        pp8int: "Per qualsiasi domanda o richiesta riguardante questa politica sulla privacy, contattaci a:",
        pp1out: "Non vengono richieste né raccolte altre informazioni personali attraverso il modulo di contatto.",
        pp2out: "",
        pp3out: "",
        pp4out: "",
        pp5out: "",
        pp6out: "Per esercitare i tuoi diritti, puoi contattarci al seguente indirizzo:",
        pp7out: "",
        pp8out: "Utilizzando il nostro modulo di contatto, accetti questa politica sulla privacy.",
        pp1cont: "- Nome della tua azienda, indirizzo del sito web, indirizzo fisico della tua azienda, e il tuo nome: per identificarti e personalizzare la nostra risposta.\n- Indirizzo email: per poterti contattare in risposta alla tua richiesta.\n- Oggetto del messaggio e il tuo messaggio: per comprendere la natura della tua richiesta e rispondere in modo appropriato.",
        pp2cont: "- Rispondere alla tua richiesta: Utilizziamo il tuo nome, il tuo indirizzo email e il tuo messaggio per fornirti una risposta personalizzata.\n- Miglioramento dei nostri servizi: Potremmo analizzare in modo anonimo le domande o i commenti ricorrenti per migliorare il nostro supporto e i nostri servizi.",
        pp3cont: "- Conformità legale: se dobbiamo condividere informazioni in risposta a un obbligo legale o a una richiesta delle autorità competenti.\n- Protezione dei diritti di TextModing®: per difendere i nostri diritti in caso di controversia o per prevenire frodi.",
        pp4cont: "",
        pp5cont: "",
        pp6cont: "- Diritto di accesso: puoi richiedere di consultare le informazioni che abbiamo raccolto su di te.\n- Diritto di rettifica: puoi richiedere la correzione di eventuali informazioni imprecise.\n- Diritto di cancellazione: puoi richiedere la cancellazione delle tue informazioni personali quando la loro conservazione non è più necessaria.\n- Diritto di limitazione: puoi limitare l'uso delle tue informazioni in alcuni casi.",
        pp7cont: "",
        ppaccept1adm: "Accedendo alla nostra piattaforma, confermi di aver letto il nostro",
        ppadm: "accordo di riservatezza per gli amministratori",
        ppaccept2adm: "della piattaforma TextModing® e di aderire liberamente ad esso.",
        tmppadm: "Politica sulla Privacy per gli Amministratori di TextModing®",
        ppintadm: "TextModing® si impegna a proteggere la privacy e la sicurezza dei dati personali dei suoi partner amministratori e dei proprietari di siti di incontri. Questa politica sulla privacy descrive le nostre pratiche relative alla raccolta, utilizzo, conservazione e protezione dei dati degli amministratori che utilizzano la nostra piattaforma per la gestione di siti di incontri. Accedendo alle funzionalità di TextModing®, accetti le pratiche descritte in questa politica.",
        pp1ttladm: "Raccolta dei Dati",
        pp2ttladm: "Uso dei Dati",
        pp3ttladm: "Condivisione dei Dati",
        pp4ttladm: "Conservazione dei Dati",
        pp5ttladm: "Sicurezza dei Dati",
        pp6ttladm: "Diritti degli Amministratori",
        pp7ttladm: "Cookie e Tecnologie Simili",
        pp8ttladm: "Modifiche alla Politica sulla Privacy",
        pp9ttladm: "Contatto",
        pp1intadm: "Nell'ambito della nostra relazione di partenariato e per l'uso della nostra piattaforma, possiamo raccogliere le seguenti informazioni riguardanti gli amministratori dei siti partner:",
        pp2intadm: "I dati raccolti vengono utilizzati per i seguenti scopi:",
        pp3intadm: "TextModing® non vende né affitta i dati personali degli amministratori e li condivide solo con terzi quando necessario per i seguenti scopi:",
        pp4intadm: "I dati personali degli amministratori sono conservati per il tempo necessario a raggiungere gli scopi descritti in questa politica o per ottemperare agli obblighi legali applicabili. Una volta che i dati non sono più necessari, vengono cancellati in modo sicuro.",
        pp5intadm: "TextModing® applica misure di sicurezza tecniche e organizzative rigorose per proteggere i dati personali degli amministratori da accessi non autorizzati, alterazioni, divulgazioni o distruzioni. Nonostante i nostri sforzi, nessuna trasmissione di dati su Internet è completamente sicura; pertanto, facciamo del nostro meglio per ridurre al minimo i rischi.",
        pp6intadm: "Gli amministratori hanno diritti specifici riguardo alle loro informazioni personali, tra cui:",
        pp7intadm: "Per migliorare la tua esperienza di utilizzo, utilizziamo cookie e altre tecnologie simili per tracciare le sessioni di accesso e personalizzare l'interfaccia della piattaforma. Puoi configurare le impostazioni del tuo browser per limitare l'uso di questi cookie, ma ciò potrebbe influire su alcune funzionalità della piattaforma.",
        pp8intadm: "TextModing® si riserva il diritto di modificare questa politica sulla privacy per riflettere cambiamenti nelle nostre pratiche o per ottemperare agli obblighi legali. Ogni modifica sarà pubblicata su questa pagina con la data di aggiornamento. Ti invitiamo a consultare regolarmente questa politica.",
        pp9intadm: "Per qualsiasi domanda o richiesta relativa a questa politica sulla privacy, puoi contattarci a:",
        pp1outadm: "",
        pp2outadm: "",
        pp3outadm: "",
        pp4outadm: "",
        pp5outadm: "",
        pp6outadm: "Per esercitare questi diritti, ti preghiamo di contattarci al seguente indirizzo: admin@creativ-network.net",
        pp7outadm: "",
        pp8outadm: "",
        pp9outadm: "Utilizzando la piattaforma TextModing®, gli amministratori accettano questa politica sulla privacy e si impegnano a rispettarla nell'ambito dell'uso dei nostri servizi.",
        pp1contadm: "- Dati identificativi: nome, cognome, nome dell'azienda, ruolo, ID amministratore.\n- Dati di contatto: indirizzo e-mail professionale, numero di telefono.\n- Dati di accesso: credenziali di account per accedere alla piattaforma TextModing®.\n- Dati di utilizzo: storico degli accessi, preferenze d'uso e interazioni con le funzionalità della piattaforma.\n- Dati finanziari: dettagli di fatturazione e informazioni di pagamento, solo se necessari per le transazioni.",
        pp2contadm: "- Accesso e gestione delle funzionalità: consentire agli amministratori di accedere alle 15 principali funzionalità di TextModing® (gestione conversazioni, controllo qualità, gestione stipendio degli operatori, gestione del registro operatori, gestione richieste foto, gestione regali virtuali, gestione GIF, gestione traffico messaggi, gestione valutazioni, ecc.).\n- Assistenza e supporto: fornire supporto tecnico e assistenza personalizzata in caso di problemi o necessità di ulteriori informazioni.\n- Miglioramento dei nostri servizi: analizzare i dati di utilizzo per migliorare le funzionalità e l'esperienza della piattaforma.\n- Fatturazione e monitoraggio dei pagamenti: elaborare le transazioni e fornire documentazione fiscale.\n- Sicurezza: monitorare gli accessi e le attività sospette per garantire la sicurezza degli account amministratori.",
        pp3contadm: "- Fornitori di servizi: Possiamo avvalerci di terze parti per operazioni specifiche (ad esempio, hosting, gestione pagamenti). Questi fornitori sono tenuti a trattare i tuoi dati in modo confidenziale e sicuro.\n- Obblighi legali: Possiamo divulgare le tue informazioni se richiesto dalla legge o per rispondere a richieste delle autorità.\n- Protezione dei diritti: in caso di controversie, potremmo condividere le informazioni nell'ambito di procedimenti legali per difendere i diritti di TextModing®.",
        pp4contadm: "",
        pp5contadm: "",
        pp6contadm: "- Diritto di accesso: richiedere di consultare le informazioni personali che deteniamo.\n- Diritto di rettifica: richiedere la correzione di informazioni imprecise o obsolete.\n- Diritto di cancellazione: richiedere la cancellazione delle tue informazioni personali in determinate circostanze.\n- Diritto di limitazione del trattamento: limitare l'uso dei tuoi dati in determinate situazioni.",
        pp7contadm: "",
        pp8contadm: "",
        sccss: "Successo",
        smthngwntwrng: "Si è verificato un errore",
        cntcts: "Contatti",
        frmlrdcntct: "Modulo di contatto",
        ttr: "Titolo",
        mr: "Sig.",
        mme: "Sig.ra",
        mlle: "Sig.na",
        sct: "Azienda",
        stwb: "Sito web",
        addrss: "Indirizzo",
        prvncdprtm: "Provincia / Dipartimento",
        cdpstl: "Codice postale",
        cntr: "Paese",
        prnmcntct: "Nome del contatto",
        nmcntct: "Cognome del contatto",
        tlphn: "Telefono",
        eml: "Email",
        objdvtrcntct: "Oggetto del tuo contatto",
        dmdpht: "Richiesta di demo",
        prtnrt: "Partnership",
        affltn: "Affiliazione",
        prsrdv: "Prenotazione appuntamento",
        dmdbrchr: "Richiesta di brochure",
        othr: "Altro",
        vtrmsg: "Il tuo messaggio",
        attchmt: "Allegato",
        pltqcnfdtxt: "Inviando questo modulo di contatto, dichiari di aver letto e compreso la nostra",
        pltqcnfdtxt2: "informativa sulla privacy",
        xcldrssttm: "chiavi per il successo di TextModing®",
        cldrssttl1: "Costi accessibili",
        cldrssttl2: "Tecnologia avanzata",
        cldrssttl3: "Adattabilità della piattaforma",
        cldrssttl4: "Efficienza operativa",
        cldrssttl5: "Trasparenza e comunicazione",
        cldrssttl6: "Impegno per la soddisfazione del cliente",
        cldrsstxt1: "Offriamo servizi competitivi con un eccellente rapporto qualità-prezzo, consentendo ai nostri clienti di ottimizzare i loro budget beneficiando di soluzioni di qualità.",
        cldrsstxt2: "Sfruttiamo tecnologie all'avanguardia per garantire prestazioni robuste, maggiore sicurezza e una gestione fluida delle operazioni sulla piattaforma.",
        cldrsstxt3: "Offriamo flessibilità che consente di adattare la piattaforma alle esigenze specifiche di ogni cliente, garantendo così una risposta personalizzata ed efficace.",
        cldrsstxt4: "Massimizziamo l'efficienza dei processi interni per garantire tempi di attuazione brevi e operazioni fluide senza compromettere la qualità del servizio.",
        cldrsstxt5: "Manteniamo una comunicazione chiara e trasparente con i clienti, offrendo una visibilità totale sulle prestazioni e i risultati dei loro progetti.",
        cldrsstxt6: "Poniamo la soddisfazione del cliente al centro delle nostre priorità implementando soluzioni rapide e ascoltando costantemente le esigenze e i feedback dei nostri partner.",
        prdtstm: "Prodotti di TextModing®*",
        prdtttl1: "Interfaccia di prova",
        prdtttl2: "Cruscotto",
        prdtttl3: "Piattaforma di coinvolgimento",
        prdttxt1: "L'interfaccia di prova di TextModing® consente ai potenziali clienti di esplorare e sperimentare le funzionalità essenziali della piattaforma, inclusa l'invio di messaggi ordinari, di aggancio, poke e follow-up, facilitato dai nostri operatori chat, per un'immersione completa nel nostro ambiente interattivo.",
        prdttxt2: "Il cruscotto di TextModing® offre ad amministratori, supervisori, controllori di qualità e contabili una panoramica centrale e intuitiva, facilitando la gestione in tempo reale delle operazioni, il monitoraggio delle prestazioni, l'assicurazione della qualità e il tracciamento finanziario della piattaforma.",
        prdttxt3: "La piattaforma di coinvolgimento di TextModing® offre agli operatori chat un ambiente interattivo ottimizzato per gestire gli scambi, creare connessioni coinvolgenti e garantire un'interazione dinamica ed efficace tra gli utenti.",
        prdindpdts: "I 3 prodotti sono interdipendenti",
        intrcteffcctprfrmnc: "“Interazione. Efficienza. Prestazione”",
        abtus: "Chi siamo",
        abtustxt: "TextModing® è una piattaforma innovativa dedicata all'animazione di siti di incontri attraverso operatori professionisti. Offriamo servizi personalizzati di moderazione, gestione dei contenuti e animazione per migliorare l'esperienza degli utenti sulle piattaforme di incontri. Grazie al nostro team esperto, garantiamo interazioni autentiche e coinvolgenti che favoriscono un'esperienza utente di qualità.",
        rdswt: "Leggi di più",
        lnsdst: "Link del sito",
        lngdst: "Lingua del sito",
        mnaddrss: "Indirizzo principale",
        rprsnttbrblg: "Rappresentante dell'ufficio belga",
        ttdrtsrsrvs: "Tutti i diritti riservati",
        apr: "Chi siamo",
        ftrs: "Funzionalità",
        avntgs: "Vantaggi",
        cldrsst: "Chiavi del successo",
        nsprdts: "I nostri prodotti",
        orpltfrm1: "La nostra piattaforma",
        orpltfrm2: "per la gestione di siti di incontri",
        orpltfrmtxt: "TextModing® è una piattaforma performante progettata per gestire e ottimizzare i siti di incontri online. Grazie ai suoi strumenti avanzati e all'interfaccia intuitiva, consente una gestione efficace delle interazioni tra utenti, garantendo un alto livello di coinvolgimento.",
        flxqtdns: "Flussi quotidiani",
        rqstdmo: "Richiedi una demo",

        aprtxt1: "TextModing® è una piattaforma innovativa dedicata alla gestione dei siti di incontri da parte di operatori professionisti. Offriamo servizi personalizzati di moderazione, gestione dei contenuti e animazione per migliorare l'esperienza degli utenti sulle piattaforme di incontri. Grazie al nostro team esperto, garantiamo interazioni autentiche e coinvolgenti che promuovono un'esperienza utente di alta qualità.",
        aprtxt2: "La nostra piattaforma consente agli operatori di gestire efficacemente le conversazioni, garantire il controllo qualità e supervisionare il traffico dei messaggi. Con funzionalità avanzate come la gestione delle richieste di foto, dei regali virtuali e dei GIF, nonché la registrazione delle note per un monitoraggio ottimale delle interazioni, TextModing® è uno strumento completo per i siti che desiderano offrire un'esperienza interattiva e dinamica.",
        aprtxt3: "Ci impegniamo a fornire servizi di alta qualità con un team sempre disponibile a supportare le esigenze specifiche dei siti di incontri e ottimizzare il coinvolgimento degli utenti.",

        xftftm: "Funzionalità di TextModing®",

        ft1ttl: "Gestione delle conversazioni",
        ft2ttl: "Gestione degli agenti",
        ft3ttl: "Sala di emergenza",
        ft4ttl: "Gestione delle note",
        ft5ttl: "Messaggi di apertura",
        ft6ttl: "Messaggi Poke",
        ft7ttl: "Messaggi di follow-up",
        ft8ttl: "Richieste di foto",
        ft9ttl: "Segnalazione degli agenti",
        ft10ttl: "Registro delle attività",
        ft11ttl: "Messaggi duplicati",
        ft12ttl: "Gestione dei regali",
        ft13ttl: "Supervisione e gestione del traffico",
        ft14ttl: "Controllo qualità",
        ft15ttl: "Contabilità",

        ft1txt: "Permette agli amministratori, team leader e supervisori di monitorare, controllare e analizzare le interazioni in tempo reale, garantendo la qualità e la fluidità delle comunicazioni sui siti di incontri.",
        ft2txt: "Permette agli amministratori, team leader e supervisori di monitorare, guidare e valutare le prestazioni degli agenti in tempo reale, garantendo una gestione efficace dei siti di incontri.",
        ft3txt: "Offre agli amministratori, team leader e supervisori uno spazio sicuro per gestire le emergenze, risolvere rapidamente gli incidenti critici e garantire la continuità operativa sui siti di incontri.",
        ft4txt: "Permette agli amministratori, team leader e supervisori di creare, monitorare e condividere note sulle interazioni degli agenti, ottimizzando la coordinazione e il monitoraggio delle prestazioni.",
        ft5txt: "Permette agli amministratori, team leader e supervisori di creare, personalizzare e distribuire messaggi di apertura efficaci per ottimizzare l'interazione degli utenti sui siti di incontri.",
        ft6txt: "Permette agli amministratori, team leader e supervisori di configurare, inviare e analizzare messaggi poke strategici per rilanciare l'interazione e stimolare l'impegno degli utenti.",
        ft7txt: "Permette agli amministratori, team leader e supervisori di pianificare, personalizzare e analizzare messaggi di follow-up per riattivare l'interesse degli utenti inattivi sul sito.",
        ft8txt: "Permette agli amministratori, team leader e supervisori di supervisionare, approvare e gestire le richieste di foto degli operatori, garantendo la conformità e la qualità dei contenuti scambiati.",
        ft9txt: "Permette agli amministratori, team leader e supervisori di monitorare, esaminare e gestire le segnalazioni relative agli agenti, garantendo una moderazione proattiva e un controllo qualità ottimale.",
        ft10txt: "Permette agli amministratori, team leader e supervisori di monitorare, registrare e analizzare le note degli operatori per ottimizzare le prestazioni e la comunicazione.",
        ft11txt: "Permette agli amministratori, team leader e supervisori di identificare e gestire i messaggi ripetitivi per migliorare la qualità delle interazioni con gli utenti.",
        ft12txt: "Permette agli amministratori, team leader e supervisori di monitorare e analizzare l'invio di contenuti coinvolgenti per rafforzare l'interazione degli utenti.",
        ft13txt: "Permette agli amministratori, team leader e supervisori di analizzare i flussi di messaggi per ottimizzare le prestazioni e garantire una comunicazione fluida tra gli utenti.",
        ft14txt: "Permette agli amministratori, team leader e supervisori di valutare le interazioni degli operatori per garantire un servizio di alta qualità e la massima soddisfazione del cliente.",
        ft15txt: "Permette agli amministratori e ai contabili di monitorare e gestire le transazioni finanziarie, garantendo trasparenza ed efficienza nella gestione delle risorse.",

        nsecrr: "Contattaci",

        cqdfftm: "Cosa differenzia TextModing®",
        cqdffttl: "TextModing® si distingue dai concorrenti per diversi vantaggi chiave:",
        cqdffttl1: "Esperienza specializzata",
        cqdffttl2: "Personalizzazione e flessibilità",
        cqdffttl3: "Supporto continuo e formazione",
        cqdffttl4: "Innovazione costante",

        cqdfftxt1: "TextModing® riunisce talenti altamente qualificati del web, come operatori chat, supervisori, controllori di qualità e gestori del traffico, garantendo una gestione ottimale e professionale dei siti di incontri.",
        cqdfftxt2: "Grazie alla nostra piattaforma, offriamo soluzioni personalizzate adattate alle esigenze specifiche di ogni cliente, consentendo una gestione più mirata ed efficace.",
        cqdfftxt3: "TextModing® non si limita a fornire servizi, ma investe nella formazione continua del proprio personale per garantire un adattamento rapido alle nuove tendenze e tecnologie, offrendo supporto costante ai partner per favorirne la crescita.",
        cqdfftxt4: "Essendo all'avanguardia delle ultime tecnologie e strategie di animazione, TextModing® assicura ai suoi clienti un vantaggio competitivo duraturo in un mercato in continua evoluzione.",
        btsd : "Lato bot",
        nttkn : "Note prese",
        mssdnt : "Note mancate",
        gfts : "Regali",
        rqsts : "Richieste",
        messonhldallctd : "Messaggio in sospeso già assegnato",
        messonhldntallctd : "Messaggio in sospeso non assegnato",
        totnummess : "Numero totale di messaggi",
        messpssdqc : "Messaggi passati il controllo qualità",
        messntpssqc : "Messaggi non passati il controllo qualità",
        paymnts : "Pagamenti",
        bnk : "Banca",
        ppl : "Paypal",
        ws : "Wise",
        yrsf : "Yoursafe",
        rçes: "Ricevuto",
        envoyees: "Inviato",
        dmndees: "Richiesto",
        nvaux: "Nuovo",

        adddest: "Indirizzo e-mail del destinatario",

        cmpn: "Azienda",
        tmfld: "Cartella di modifica del testo",
        accar: "Area di accesso",
        assvldt: "Validità di accesso",

        acc8: "Impostazione tariffa e bonus",

        mtng: "Riunione",
        rp: "Real Profil",
        fp: "Profilo falso",
        infopernoper: "Informazioni permanenti/non permanenti",
        dltnt: "Cancella nota",
        dltmesspnc: "Vuoi davvero cancellare questo messaggio di panico?",
        convbnnd: "Conversazione vietata",
        suspconv: "Sospendere la conversazione",
        upldd: "Caricato",
        addpht: "Aggiungi foto",
        phtsnttofp: "Elenco delle foto inviate ai profili falsi",
        pkmesscrtd: "Messaggio Poke creato con successo",
        updt: "Aggiornamento",
        dltpkmess: "Vuoi davvero cancellare questo messaggio poke?",
        plt: "Piattaforma",
        dltdmd: "Vuoi davvero cancellare questa richiesta? ",
        agntinfrmd: "Agente informato",
        dlttsrmess: "Vuoi davvero cancellare questo messaggio teaser?",
        updttsrmess: "Aggiorna il messaggio teaser",

        updtdsucc: "Aggiornato con successo",
        nonotif: "Nessuna notifica",
        continfo: "Informazioni sul contenuto",
        exp: "Mittente",
        recep: "Ricevitore",
        mdpupdtd: "Password aggiornata con successo",
        opsusp: "Operatore sospeso con successo",
        opdec: "Operatore disconnesso con successo",
        decop: "Si vuole veramente disconnettere questo operatore:  ",
        frop: "Si vuole veramente licenziare questo operatore:  ",
        chngmdp: "Cambia password",
        suspop: "Vuoi veramente sospendere questo operatore: ",
        onhld: "In attesa",
        supprmess: "Cancella questo messaggio",
        messinfo: "Informazioni sul messaggio",
        vld: "Conferma",
        ok: "Ok",
        enc: "In corso...",

        updtmdp: "Vuoi cambiare la password?",
        nwmdp: "Inserire la nuova password",
        dltsup: "Vuoi davvero cancellare questo supervisore?",
        ntsup: "Nota per un supervisore",
        nthr: "Nota qui...",
        dltimg: "Vuoi davvero cancellare questa immagine?",
        phttsnd: "Foto da inviare",
        vd: "Vuoto (selezionare una foto)",
        addd: "Aggiunta",
        nwrdv: "Aggiungere una nuova informazione",
        cnfrm: "Conferma",
        dltrdv: "Volete davvero cancellare questo Rendez-vous/ Riunioni/ Vacanze?",
        addnonperminfo: "Aggiungi informazioni non permanenti",
        dltinfo: "Vuoi davvero cancellare questa informazione?",
        idd: "ID",
        addperminfo: "Aggiungi informazioni permanenti",
        messreport: "Messaggio segnalato al supervisore",
        usrnm: "Nome utente",

        pp11 : "Creativ'Network, una società di intermediazione professionale per lavori web, con sede legale in 71-75 Shelton Street, Covent Garden, Londra, WC2H 9JQ, e con ufficio di rappresentanza in Roodborstjesstraat 4, Wondelgem 9032, di seguito denominata « la Società »,",
        pp12 : "E:",
        pp13 : "Lavoratore autonomo, che, in virtù del contratto di Lavoratore Autonomo che lo lega a « La Società » utilizza i materiali e le proprietà intellettuali di quest'ultima, di seguito denominato « il Lavoratore Autonomo »,",
        pp14 : "Di seguito denominati singolarmente « la Parte » e collettivamente « le Parti »,",
        pp21 : "La Società gestisce una piattaforma di animazione di siti di incontri di cui è l'unica proprietaria. La gestione operativa di tale piattaforma è affidata a operatori di chat, supervisori, team leader, controllori della qualità, impiegati come lavoratori autonomi dalla Società.",
        pp22 : "Nel contesto di questa relazione professionale, il Lavoratore Autonomo avrà accesso a informazioni riservate relative alla piattaforma, agli utenti dei siti di incontri, ai processi operativi, nonché a qualsiasi altra informazione protetta appartenente alla Società.",
        pp23 : "Detto ciò, si conviene quanto segue:",
        pp31 : "Il presente accordo di riservatezza ha lo scopo di proteggere le informazioni riservate che la Società potrebbe comunicare al Lavoratore Autonomo nell'ambito delle sue funzioni relative alla gestione della piattaforma di animazione di siti di incontri.",
        pp41 : "Ai fini del presente accordo, le « Informazioni Riservate » si riferiscono a tutte le informazioni, in qualsiasi forma (scritta, orale, elettronica, ecc.), relative alla piattaforma di animazione di siti di incontri, agli utenti, alle strategie di sviluppo, ai processi tecnici e operativi, ai dati finanziari, nonché a qualsiasi altra informazione designata come riservata dalla Società, che il Lavoratore Autonomo potrebbe ricevere o avere accesso nell'ambito del suo lavoro.",
        pp51 : "Il Lavoratore Autonomo si impegna a:",
        pp52 : "- Non divulgare, riprodurre, trasmettere o rendere accessibili le Informazioni Riservate, in tutto o in parte, a terzi, senza l'autorizzazione scritta preventiva della Società.",
        pp53 : "- Utilizzare le Informazioni Riservate solo nel contesto delle attività relative alla gestione della piattaforma e solo nell'interesse della Società.",
        pp54 : "- Adottare tutte le misure necessarie per proteggere la riservatezza delle Informazioni Riservate e prevenire qualsiasi uso non autorizzato.",
        pp55 : "- Restituire alla Società, su richiesta, qualsiasi documento o supporto contenente Informazioni Riservate, senza conservarne copie, in qualsiasi forma.",
        pp61 : "Il presente accordo entra in vigore al momento in cui il lavoratore accetta durante l'identificazione per l'accesso alla piattaforma di animazione e rimarrà in vigore per tutta la durata della relazione professionale tra la Società e il Lavoratore Autonomo. Gli obblighi di riservatezza continueranno per un periodo di 10 anni dopo la conclusione della suddetta relazione, indipendentemente dalla causa.",
        pp71 : "Non sono considerate Informazioni Riservate:",
        pp72 : "- Le informazioni che erano già conosciute dal pubblico al momento della loro comunicazione al Lavoratore Autonomo, o che diventeranno pubbliche in altro modo rispetto alla violazione del presente accordo.",
        pp73 : "- Le informazioni che il Lavoratore Autonomo può dimostrare di aver ricevuto legittimamente da un terzo non vincolato da un obbligo di riservatezza.",
        pp74 : "- Le informazioni sviluppate in modo indipendente dal Lavoratore Autonomo senza l'uso delle Informazioni Riservate.",
        pp81 : "In caso di violazione degli obblighi previsti dal presente accordo, il Lavoratore Autonomo riconosce che la Società potrà intraprendere tutte le azioni legali necessarie per proteggere i suoi diritti e ottenere risarcimento per i danni subiti.",
        pp91 : "Il presente accordo è regolato dalla legge belga. In caso di controversia relativa all'interpretazione o all'esecuzione del presente accordo, le Parti si impegnano a cercare una soluzione amichevole. In assenza di accordo amichevole, i tribunali competenti di Gand saranno gli unici competenti.",
        pp1 : "Tra le Parti",
        pp2 : "Premessa",
        pp3 : "Articolo 1: Oggetto",
        pp4 : "Articolo 2: Definizione delle Informazioni Riservate",
        pp5 : "Articolo 3: Obblighi del Lavoratore Autonomo",
        pp6 : "Articolo 4: Durata",
        pp7 : "Articolo 5: Esclusioni",
        pp8 : "Articolo 6: Disposizioni Generali",
        pp9 : "Articolo 7: Legge Applicabile e Giurisdizione Competente",
        paymail: 'Indirizzo e-mail di pagamento',
        scc: 'Creato',
        login: 'Login',
        mail:'Email',
        password: 'Password',
        confirmpass:'Conferma la password',
        alreadyreg:'Già registrato? Login',
        notifs: "Notifiche",
        limite:'Limitato',
        save:'Salvare',
        archives:'Archivi',
        ttlmsg:'Totale Messaggi',
        controlLog:'Login del Controllore',
        nbrmess:'Numero di messaggi',
        appQC:'Apprezzamenti del QC',
        esqmq:'Schivata mancante',
        dbspace:'Doppio spazio',
        notelu:'Nota letta',
        actpris:'Azione intrapresa',
        ferme:'Chiuso',
        losn:'Elenco delle note inviate',
        lorn:'Elenco delle note ricevute',
        validity:'Validità',
        editInfo:"Modificare un'informazione",
        controlID:'Controllore ID',
        statcontrolct:'Statistiche di controllo per controllore',
        statcontrolag:'Statistiche di controllo per Agente',
        statcontrol:'Statistiche di controllo',
        traitement:'Trattamento',
        agentid:'ID agente',
        statconvatt:'Statistiche delle Conversazioni in Attesa',

        propesqu:'Inserisci una scusa per una proposta di appuntamento',
        msgacc:'Si prega di scrivere un messaggio accattivante',
        msgpoke:'Si prega di scrivere un messaggio Poke',
        heure:'Ora',
        esqv:'Schivata',
        esquive:'Schivate incontri e appuntamenti',
        other:'Altri',
        sexe:'Sesso',
        bannir:'Bandire',
        agntLgn:'Agente Online',
        features:'Funzionalità',
        rdv:'Appuntamento',
        holiday:'Vacanza',
        valider:'Convalidare',
        panicmsg:'questo messaggio di panico?',
        jour:'Giorno',
        jours:'Giorni',
        conversation:'Conversazione',
        profil:'Profilo',
        suspendre:'Sospendere',
        expulser:'Espellere',
        information:'Informazione',
        meeting:'Riunione',
        online:'Online',
        onHold:'Behandelt',
        treated:'Trattato',
        listPhotoSend:'Elenco di foto inviate',
        agentReport:'Agente segnalato',
        suspendu:'Sospeso',
        
        etat:'Stato',
        deconnecter:'Disconnettere',
        actqc4: 'Messaggio convalidato',
        panicmess:'Messaggio di panico',
        contenu:'Contenuto',
        raison:'Ragione',
        reportmess:'Segnala un messaggio',
        language1:'Lingua',
        reg:'Registrati',
        enreg:['Non sei ancora registrato?','Registrati'],
        passdiff:'Password diversa',
        qlogout: 'Vuoi uscire?',
        annuler:'Annulla',
        addnote: "Nota aggiunta",
        notesuppr: 'Nota eliminata',
        editer:'Modificare',
        editNote:'Modifica Nota',
        logout: 'Disconnettersi',
        yes: 'Sì',
        no: 'No',

        operatorpage: "Pagina operatore",
        supervisorpage: "Pagina dei supervisori",
        adminpage: "Pagina degli amministratori",

        required: "Questo campo è obbligatorio",
        invalid: "Email non valida",
        registered: "Questo indirizzo e-mail non è registrato",
        incorrectpass: "Password non corretta",
        atleast: "La password deve contenere almeno",
        characters: "caratteri",

        convman: "Gestione delle conversazioni",
        agman: "Gestione degli agenti",
        notesman: "Gestione delle note",
        agrep: "Rapporti sugli agenti",
        gftgtfman: "Gestione dei regali e degli omaggi",
        tmfldaa: "Permesso di accesso alla cartella TextModing",
        hlp: "Aiuto",
        pltflng: "Lingua della piattaforma",
        pr: "Richieste di foto",
        wlcm: "Benvenuti",
        wlcmtxt: "TextModing è una piattaforma di animazione progettata per i siti di incontri, che offre funzionalità essenziali per la gestione delle conversazioni e degli agenti. Migliora l'efficienza e la redditività dei partner fornendo strumenti che semplificano le interazioni e ottimizzano il coinvolgimento degli utenti.",

        messageto: "Messaggio a",
        convatt: "Conversazione in attesa",
        mincharlen: "Almeno 120 caratteri",
        char: "carattere",
        chars: "caratteri",
        pj: "Foto allegate",
        writemessage: 'Scrivi un messaggio...',
        picalreadysent: "Foto vista dall'utente",
        gifpj: 'GIFS',
        opengif: 'Apri GIF',
        sendMessage: 'Invia il messaggio',
        reportSup: 'Inviare una nota al supervisore',
        conv: 'ID conversazione',
        say: 'dice',
        timeIntro: 'at',
        elements: 'elementi',
        element: 'elemento',
        none: 'Nessuno',

        stat: 'Statistics',
        french: 'Francese',
        english: 'Inglese',
        italian: 'Italiano',
        dutch: 'Olandese',
        spanish: 'Spagnolo',
        german: 'Tedesco',
        botprofil: 'Profilo Bot',
        userprofil: "Profilo utente",
        otherInfo: "Altre informazioni importanti",
        codepost: 'Codice postale',
        ville: "Città",
        region: "Regione",
        pays: 'Paese',
        taille: 'Taglia',
        eyes: 'Colore degli occhi',
        hair: 'Colore dei capelli',
        morph: 'Morfologia',
        situation: "Stato civile",
        searchfor: 'Cerca per',
        job: "Occupazione",
        signe: "Segni distintivi",
        descri: "Descrizione",
        infosupp: 'Informazioni aggiuntive',
        supervisiontchat: 'Supervisione della chat',
        statconvop: 'Conversazione tra statisti e operatori',
        convs: 'Conversazioni',
        report: 'Rapporto',
        agentInform:'Agente informato',
        events: 'Evento',
        photosrequests: 'Richiesta di foto',
        warmup: 'Warmup',
        gifattch: 'Allegato Gif',
        fakeprofiles: 'Profili falsi',
        pagenotfound: 'Pagina non trovata',
        suivvraiprof: 'Monitoraggio dei profili reali',
        suiviop: "Monitoraggio degli operatori",
        admintion: 'Amministrazione',
        affiliation: 'Affiliazione',

        operator: 'Operatore',
        supervisor: 'Supervisore',
        administrator: 'Amministratore',
        week: 'settimana',
        weeks: 'settimane',
        today: 'Oggi',

        start: 'Inizio',
        end: 'Fine',
        platform: 'Piattaforme',
        search: 'Ricerca',
        go: 'Vai',
        allmasc: 'Tutti',
        allfem: 'Tutti',

        id: 'Identificatore',
        opname: "Nome dell'operatore",
        sentmess: "Messaggi inviati",
        recmess: "Messaggi ricevuti",
        relmess: "Messaggi in sospeso",

        date: "Data",
        messid: "Identificatore del messaggio",
        convid: "Identificatore della conversazione",
        exped: "Mittente",
        recpt: 'Ricevitore',
        opsign: "Operatore da segnalare",
        opexped: 'Operatore da inviare',
        action: 'Azione',
        messs: 'Messaggi',
        filterdate: 'Filtra le ricerche tra due date',
        filtermess: 'Filtra le conversazioni per messaggio',
        from: 'Da',
        to: 'a',
        eventList: 'Elenco eventi',
        num: 'Numero',
        eventSearch: 'Cerca un evento',
        actions: 'Azioni',
        eventname: "Nome dell'evento",
        eventdate: "Data dell'evento",
        uploadimage: "Carica immagini",
        role: "Ruolo",
        logIn: "Log in",
        ppaccept1: "Accedendo alla nostra piattaforma, confermate di aver letto le nostre",
        ppaccept2: "e di aver seguito la formazione adeguata per svolgere il suo incarico",
        pp: "accordo di riservatezza",
        copyright: "© 2023-2024 Copyright TextModing® - Tutti i diritti riservati",
        accountant: "contabile",
        qualitycontroller: "controllore della qualità",
        adminid: "ID amministratore",
        accountantid: "ID contabile",
        operatorid: "ID operatore",
        qcid: "ID controllore qualità",
        supervisorid: "ID supervisore",
        convonhold: "Conversazioni in attesa",
        supdash: "Cruscotto del supervisore",
        choverv: "Panoramica della chat",
        months: ["gennaio", "febbraio", "marzo", "aprile", "maggio", "giugno", "luglio", "agosto", "settembre", "ottobre", "novembre", "dicembre"],
        year: 'Anno',
        month: 'Mese',
        startingdate: 'Data di inizio',
        closingdate: 'Data di chiusura',

        agents: "Agenti",
        out: "Uscite",
        in: "Ingressi",
        details: "Dettagli",
        panicroom: "Stanza del panico",
        notes: "Note",
        teasermessages: "Messaggi teaser",
        pokemessages: "Messaggi Poke",
        attdisc:'Attesa di Discussione',
        gifajt:'Gif Aggiungi',
        stopmessages: "Messaggi di stop",
        sent: "Inviato",
        asnwered: "Risposto",
        requested: "Richiesto",
        logbook: "Diario",
        recorded: "Registrato",
        notrecorded: "Non registrato",
        duplicatedmessages: "Messaggi duplicati",
        back: "Indietro",
        suty: "Tipo di abbonamento",
        ag: "Età",
        gend: "Sesso",
        sexor: "Orientamento sessuale",
        tow: "Città",
        
        users: "Utenti",
        sender: "Mittente",
        receiver: "Ricevitore",
        agent: "Agente",
        status: "Stato",
        read: "Lettura",
        active: "Attivo",
        edit: "Modifica",
        user: "Utente",
        sendnote: "Invia una nota",
        oh: "In attesa",
        mess: "Messaggio",
        trconv: "Conversazioni trattate",
        idconv: "ID conversazione",
        idmess: "ID messaggio",
        otherimp: "Altre informazioni importanti",
        otherimp1: "Riunione/ Incontri/ Vacanze",
        otherimp2: "Informazioni permanenti",
        otherimp3: "Informazioni utili, non permanenti",
        
        aglist: "Elenco agenti",
        pfsperf: "Prestazioni della piattaforma",
        agperf: "Prestazioni degli agenti",
        nwag: "Aggiungi nuovi agenti",
        agname: "Nome agente",
        agcountry: "Paese dell'agente",
        aglogin: "Accesso agente",
        agloginpw: "Password agente",
        langpf: "Piattaforma linguistica",
        onl: "Online",
        offl: "Offline",
        suspend: "Sospensione",
        koff: "Reinvio",
        grotot: "Totale lordo",
        agnumbewoon: "Numero di profili falsi",
        totsenmess: "Messaggi totali inviati",
        totrecmess: "Totale messaggi ricevuti",
        totrep: "Totale rapporti",
        real: "Nome reale",
        prov: "Provincia",
        weight: "Peso",
        child: "Bambini",
        time: "Tempo",
        fstnm: "Nome",
        lstnm: "Cognome",
        dtofbir: "Data di nascita",
        skypeid: "ID Skype",
        crnwag: "Crea nuovo agente",
        usrprfl: "Profilo utente",
        sndmess: "Invia messaggio",
        blckusr: "Blocca utente",
        lstnts: "Elenco di note",
        crtsndnts: "Crea/Invia una nota",
        evnt: "Evento",
        ntid: "ID nota",
        nt: "Nota",
        dlt: "Elimina",
        cndid: "ID candidato",
        language: "Lingua",
        perc1: "Avete composto il ",
        perc2: " del messaggio dell'utente",
        giftsatt: "Regali allegati",
        sndnt: "Invia la nota",
        lsttsrmsg: "Elenco dei messaggi teaser",
        crttsrmsg: "Crea messaggi teaser",
        idtsrmsg: "ID del messaggio teaser",
        tsrmsg: "Messaggio teaser",
        dlvrdt: "Consegnato a",
        wiseg:"Gif sagge",
        sexg:"Gif sessuali",
        gayg:"Gif gay",
        Lesbg: "Gif lesbiche",
         transg:"Gif transgender",
        travg:"Gif travestite",
                
        bdsm:"BDSM",
        dont: "Non essere lungo",
        blj:"Pompino",
        org:"Orgasmo",
        vagpen:"Penetrazione vaginale",
        sod:"Sodomia",
        titss:"Tette",

        bdsmg: "BDSM Gay",
        wisegg: "Gif Gay Saggi",
        sexgg:"Gif di sesso gay",

        bdsml: "BDSM Lesbica",
        wiselg:"Gif lesbiche sagge",
        sexlg:"Gif di sesso lesbico",

        maletrans:"Maschio transgender",
        femaletrans:"Transgender femmina",
        lstpkmsg: "Elenco dei messaggi poke",
        crtpkmsg: "Crea messaggio poke",
        idpkmsg: "ID del messaggio poke",
        pkmsg: "Messaggio poke",
        lststpmsg: "Elenco dei messaggi di arresto",
        idstpmsg: "ID messaggio di stop",
        stpmsg: "Messaggio di arresto",
        sntto: "Inviato a",
        agntwrtngstp: "Agente che scrive l'arresto",
        dtofdlvr: "Data di consegna",
        dtofans: "Data della risposta",
        stpperfanal: "Interrompere l'analisi delle prestazioni",
        stpgrssttl: "Arresto del totale lordo",
        rsptostp: "Risposta all'arresto",
        rsptostp2: "Risposta all'Aggancio",
        rsptostp3: "Risposta al Poke",
        stpprcntresp: "Risposta in % all'arresto",
        avrgrsptm: "Tempo medio di risposta",

        lstphtrq: "Richiesta elenco foto",
        phttp: "Tipo di foto",
        idrq: "ID richiesta",
        pht: "Foto",
        upld: "Upload",
        snd: "Invia",
        lstrprt: "Elenco dei rapporti",
        rpttp: "Tipo di rapporto",
        idrprt: "ID rapporto",
        msgrprtd: "Messaggio riportato",
        infrmagnt: "Agente informativo",
        lstlgbk: "Elenco del registro",
        lstmssnglgbk: "Elenco dei registri mancanti",
        lgbktp: "Tipo di registro",
        idlgbk: "ID del giornale di bordo",
        infs: "Informazioni",
        lvlofimprt: "Livello di importanza",
        prmnnt: "Permanente",
        ntprmnnt: "Non permanente",
        mssnglgbk: "Registro mancante",
        mrklgbk: "Contrassegnare il registro",
        
        dh: "Data e ora",
        mid: "ID messaggio",
        repmess: "Segnala questo messaggio",
        bts: "Bot dice",
        obj: "Oggetto",
        plc: "Luogo",

        lstdplmss: "Elenco dei messaggi duplicati",
        iddplmss: "ID dei messaggi duplicati",
        lstgfs: "Elenco di GIFs",
        ddgfs: "Aggiungi nuova GIF",
        gftp: "Tipo di GIF",
        gfimg: "Immagine GIF",

        nat: "Natura",
        tmslt: "Slot temporale",
        gfsnt: "GIF inviate",

        qcdash: "Cruscotto del controllore di qualità",
        qctrl: "Controllo qualità",
        lstmess: "Elenco dei messaggi",
        qcpnl: "Canale controllo qualità",
        ptreq: "Richiesta foto",
        sp: "Invia foto",
        sgft: "Invia regalo",
        sgif: "Invia Gif",
        qccrit: "Criteri QC",
        qccrit1: "Chat buona",
        qccrit2: "Chat scadente",
        qccrit3: "Profilo utente considerato",
        qccrit4: "Profilo bot considerato",
        qccrit5: "Conversazione affrontata dal bot",
        qccrit6: "CTA da parte dell'agente",
        qccrit7: "Conversazione senza senso",
        qccrit8: "Utente insoddisfatto del bot",
        qccrit9:'Nota ben presa',
        actqc1: "Informare l'agente",
        actqc2: "Sospendere l'agente",
        actqc3: "Licenziare l'agente",
        adc1:'Agente eccellente',
        adc2:'Agente medio',
        adc3:'Agente mediocre',
        sndmailtoadm: "Invia un'e-mail all'amministratore",
        yrnm: "Il vostro nome",
        yrcmpnm: "Il nome della vostra azienda",
        yrmladdr: "Il vostro indirizzo e-mail",
        yradmid: "ID amministratore",
        objctfyrrqst: "Oggetto della richiesta",
        yrmss: "Il vostro messaggio",
        yrattchmt: "Allegato",
        sndthml: "Invia l'e-mail",
        ddnwadm: "Aggiungi un nuovo amministratore",
        nm: "Nome",
        mladd: "Indirizzo e-mail",
        cmpnnm: "Nome della società",
        tmfldr: "Cartella TextModing",
        psswrd: "Password",
        accssar: "Aree di accesso",
        valdt: "Validità",
        rgstr: "Registro",

        accdash: "Cruscotto contabile",
        acc: "Conti",
        acc1: "Statistiche agenti",
        acc2: "Pagamento con bonifico",
        acc3: "Pagamento con Paypal",
        acc4: "Pagamento tramite saggio",
        acc5: "Pagamento con Yoursafe",
        acc6: "Aggiungi un contabile",

        admdash: "Cruscotto amministratore",
        chttlov: "Panoramica Chattool",
        suptraffman: "Supervisione e gestione del traffico",
        vw: "Vedere",
        
        ratepermess: "Tariffa per messaggio",
        bonus: "Bonus",
        totpay: "Pagamento totale",
        paymeth: "Metodo di pagamento",
        grosstot: "Totale lordo al mese di ",
        prstat: "Stampa dell'estratto conto",
        agfn: "Nome agente",
        agln: "Cognome dell'agente",
        payadd: "Indirizzo e-mail Paypal",
        nysent: "Non ancora inviato",
        wiseadd: "Indirizzo e-mail di Wise",
        yoursafeadd: "Indirizzo e-mail di Yoursafe",
        bankn: "Nome della banca",
        iban: "IBAN",
        bic: "Codice BIC",

        lstsup: "Elenco dei supervisori",
        ddnwsup: "Aggiungi un nuovo supervisore",
        trffcmng: "Gestione del traffico",
        supnm: "Nome del supervisore",
        suplgn: "Connessione supervisore",
        suplgnpsswrd: "Password di accesso al supervisore",
        idcnddt: "ID candidato",
        cntry: "Paese",
        lgn: "Accesso",
        crtnwsup: "Creazione di un nuovo supervisore",
        fragntonln: "Agente libero online",
        msgalltag: "Messaggio assegnato all'agente",
        allct: "Assegnazione",
        addqc: "Aggiungi controllore qualità",
        crtqc: "Crea controllore qualità",
        crtnwacc: "Creare un nuovo contabile",

        gifn: "GIF da inviare",
        restfn: "Reset",
        gifsel: "Vuoto (selezionare GIF)",
        alerttxt: "GIF inviati con successo.",
        option: "opzioni",

        phalert: "Richiesta di foto inviata.",
        phtitre: "Richiesta di foto",
        phlabel: "Tipo di foto",
        phsend: "Invia",
        phload: "Caricamento...",

        delalert: "Soppresso",
        deltitre1: "Vuoi davvero eliminare questa ",
        deltitre2: "richiesta ?",
        delauteur: "Autore",
        deldesc: "Descrizione",
        delaccept: "Sì",
        delrefuse: "No",
        delload: "Caricare..",

        lstqc: "Elenco dei controllori della qualità",
        qcnm: "Nome del controllore della qualità",
        qclgn: "Login controllore qualità",
        qclgnpsswrd: "Password di accesso del controllore della qualità",
        lstacc: "Elenco dei contabili",
        accnm: "Nome del contabile",
        acclgn: "Login del contabile",
        acclgnpsswrd: "Password di accesso del contabile",

        biev: "Benvenuto / Benvenuta",
        selecrt: "Interfaccia di invio messaggi",
        chxha: "Scegli un profilo vero e uno falso",
        chxF: "Elenco dei profili veri",
        chxV: "Elenco dei profili falsi",
        nomd: "Nome",
        langs: "Lingua",
        errdj: "Seleziona un profilo vero e uno falso",
        plch: "Inserisci il tuo messaggio",
        pokePh: "Messaggi Poke (non ancora inviati)",
        teaserPh: "Elenco dei messaggi di aggancio (non ancora inviati)",
        inter: "Interfaccia di test di TextModing",
        interSel: "Si prega di selezionare le attività che si desidera eseguire",
        des1: "Invio di un messaggio personalizzato a un profilo.",
        des2: "Invio di un messaggio per attirare l'attenzione del profilo.",
        des3: "Invio personalizzato di messaggi accattivanti ai nuovi iscritti per avviare conversazioni.",
        des4: "Invio di un messaggio per riavviare la conversazione.",
        tit1: "Invio messaggio",
        tit2: "Messaggio teaser",
        tit3: "Messaggio poke",
        tit4: "Messaggio di arresto",
        tache: "Compito",
        bienvTest: "Benvenuto nell'interfaccia di test di",
        enterEP: "Inserisci la tua email e password",
        instTest: "Istruzioni",
        sendInt: "Interfaccia di invio",
        unPlP: "Scegli uno o più profili reali.",
        unNouv: "Nuova",
        unReç: "Ricevute",
        unNouv1: "Nuovo",
        payTot: "Totale pagamento",
        allou: "Già allocati",
        Nallou: "Non ancora assegnati",
        totSoum: "Messaggio totale",
        Soum: "Inviato a QC",
        nonSoum: "Non inviato a QC",
        boot: "Boot",
        rvr: "Appuntamenti/Riunioni/Vacanze",
        banit: "Bandito",
        suspt: "Sospeso",
        aucunt: "Nessun messaggio",
        msgstop: "Per favore scrivi un messaggio di follow-up",
        messrt: "Tasso di messaggio",
        pkmessrt: "Tasso di poke message",
        tsrmessrt: "Tasso di messaggio teaser",
        stpmessrt: "Tasso di messaggio di stop",
        bnsrt: "Tasso di bonus",
        mesdes: "Inviare messaggi su un sito di incontri è fondamentale per catturare l'attenzione e suscitare l'interesse dell'altra persona. L'importante è rimanere autentici, mostrare un interesse sincero e porre domande aperte che incoraggino una risposta.",
        mesdes1: "Tra i profili, scegli uno vero e uno falso per inviare un messaggio.",
        mesdes2: "Assicurati di scegliere la lingua di corrispondenza corretta.",
        mesdes3: "Scrivi il tuo messaggio di prova.",
        mesdes4: "Hai 5 minuti per rispondere al messaggio sulla piattaforma di animazione per gli operatori.",
        mesdes5: "Verifica le tue statistiche nel tuo pannello di amministrazione.",
        stopdes: "I messaggi di sollecito su un sito di incontri servono a ravvivare l'interesse e a rilanciare la conversazione dopo un periodo di silenzio.",
        stopdes1: "Scegli un profilo reale e uno falso",
        stopdes2: "Redigi un messaggio abituale",
        stopdes3: "Aspetta 24 ore dopo che l'operatore della chat ha risposto al messaggio del profilo reale",
        stopdes4: "Dopo 24 ore, il messaggio non risposto dal profilo reale si trasforma in un Messaggio di sollecito, che l'operatore si occuperà di redigere e inviare.",
        stopdes5: "Controlla le tue statistiche nel tuo pannello di amministrazione",
        teaserdes: "I messaggi di accattivamento su un sito di incontri sono cruciali per attirare l'attenzione e suscitare l'interesse dell'altra persona. È fondamentale rimanere autentici, mostrare un interesse sincero e fare domande aperte che incoraggiano una risposta",
        teaserdes1: "Tra i profili, scegli uno reale e uno falso per inviare un messaggio di accattivamento",
        teaserdes2: "Assicurati di scegliere la lingua di corrispondenza corretta",
        teaserdes3: "Sulla piattaforma di animazione per gli operatori, redigi i messaggi di accattivamento",
        teaserdes5: "Controlla le tue statistiche nel tuo pannello di amministrazione",
        pokedes: "I messaggi 'Poke' su un sito di incontri sono messaggi brevi e leggeri, progettati per attirare l'attenzione in modo sottile e coinvolgente.",
        pokedes1: "Scegli il paese e la lingua dei profili reali a cui vuoi inviare messaggi Poke",
        pokedes2: "Seleziona uno o più profili a cui vuoi inviare messaggi Poke",
        pokedes3: "Controlla le tue statistiche nel tuo pannello di amministrazione",
        stopbla: "Elenco dei messaggi di follow-up già inviati",
        respVp: "Risposta dal profilo reale",
        pasEn: "non ancora.",
        notifAccr: "Messaggio di aggancio di prova: Selezione profilo effettuata con successo",
        notifPoke: "Messaggio Poke di prova: Selezione profilo effettuata con successo",
        pokeErr: "Clicca su uno o più profili reali",
        teaserErr: "clicca su un profilo falso e uno vero",
        reqGift : "Richiedi regali",
        reqGift1 : "Tipo di regalo",
        reqGift2 : "Richiesta di regalo",
    },


    { // nl

        usrsd : "Gebruikerszijde",
        tmpp: "TextModing® Privacybeleid",
        lstmj: "Laatst bijgewerkt ",
        ppint: "Bij TextModing® hechten we veel waarde aan de privacy en bescherming van de persoonlijke gegevens van onze bezoekers. Dit privacybeleid beschrijft de praktijken van TextModing® met betrekking tot het verzamelen, gebruiken, bewaren en beschermen van informatie die via ons contactformulier wordt verzameld. Door dit formulier te gebruiken, gaat u akkoord met de voorwaarden die in dit beleid worden beschreven.",
        pp1ttl: "Gegevensverzameling",
        pp2ttl: "Gebruik van Gegevens",
        pp3ttl: "Delen van Gegevens",
        pp4ttl: "Bewaring van Gegevens",
        pp5ttl: "Beveiliging van Gegevens",
        pp6ttl: "Uw Rechten",
        pp7ttl: "Wijzigingen in het Privacybeleid",
        pp8ttl: "Contact",
        pp1int: "Wanneer u ons contactformulier invult, verzamelen we de volgende informatie:",
        pp2int: "De via ons contactformulier verzamelde informatie wordt alleen gebruikt voor de volgende doeleinden:",
        pp3int: "TextModing® verkoopt, verhuurt of verstrekt geen persoonlijke informatie die via het contactformulier wordt verzameld aan derden, behalve in de volgende gevallen:",
        pp4int: "De via het contactformulier verstrekte informatie wordt alleen bewaard zolang als nodig is om uw verzoek te beantwoorden of zolang de toepasselijke wetgeving dat vereist. Zodra het doel is bereikt, worden de gegevens op een veilige manier verwijderd.",
        pp5int: "TextModing® implementeert technische en organisatorische beveiligingsmaatregelen om de informatie die u met ons deelt te beschermen. Hoewel we ons best doen om uw informatie te beveiligen, kunnen we de absolute veiligheid van online verzonden gegevens niet garanderen.",
        pp6int: "Op basis van de wetgeving inzake gegevensbescherming heeft u de volgende rechten met betrekking tot uw persoonlijke gegevens:",
        pp7int: "TextModing® behoudt zich het recht voor dit privacybeleid bij te werken om veranderingen in onze praktijken weer te geven of om te voldoen aan wettelijke vereisten. Wijzigingen zullen op deze pagina worden gepubliceerd met de update-datum. We raden u aan om dit beleid regelmatig te raadplegen.",
        pp8int: "Voor vragen of verzoeken over dit privacybeleid kunt u contact met ons opnemen via:",
        pp1out: "Er worden geen andere persoonlijke gegevens opgevraagd of verzameld via het contactformulier.",
        pp2out: "",
        pp3out: "",
        pp4out: "",
        pp5out: "",
        pp6out: "Om uw rechten uit te oefenen, kunt u contact met ons opnemen via het volgende adres:",
        pp7out: "",
        pp8out: "Door ons contactformulier te gebruiken, gaat u akkoord met dit privacybeleid.",
        pp1cont: "- Naam van uw bedrijf, websiteadres, fysiek adres van uw bedrijf en uw naam: om u te identificeren en ons antwoord te personaliseren.\n- E-mailadres: om contact met u op te nemen als reactie op uw verzoek.\n- Onderwerp van uw bericht en uw bericht: om de aard van uw verzoek te begrijpen en op een passende manier te reageren.",
        pp2cont: "- Beantwoorden van uw verzoek: We gebruiken uw naam, e-mailadres en bericht om een gepersonaliseerd antwoord te geven.\n- Verbeteren van onze diensten: We kunnen anoniem veelgestelde vragen of opmerkingen analyseren om onze ondersteuning en diensten te verbeteren.",
        pp3cont: "- Wettelijke naleving: als we informatie moeten delen in reactie op een wettelijke verplichting of een verzoek van bevoegde autoriteiten.\n- Bescherming van de rechten van TextModing®: om onze rechten te verdedigen in geval van een geschil of om fraude te voorkomen.",
        pp4cont: "",
        pp5cont: "",
        pp6cont: "- Recht op toegang: u kunt verzoeken om de informatie in te zien die we over u hebben verzameld.\n- Recht op rectificatie: u kunt verzoeken om onjuiste informatie te corrigeren.\n- Recht op wissen: u kunt verzoeken om uw persoonlijke gegevens te laten verwijderen wanneer het bewaren daarvan niet langer nodig is.\n- Recht op beperking: u kunt het gebruik van uw gegevens in bepaalde gevallen beperken.",
        pp7cont: "",
        ppaccept1adm: "Door in te loggen op ons platform, bevestigt u dat u onze",
        ppadm: "confidentialiteitsverklaring voor beheerders",
        ppaccept2adm: "van het TextModing®-platform hebt gelezen en dat u zich hier vrijwillig aan houdt.",
        tmppadm: "Privacybeleid voor beheerders van TextModing®",
        ppintadm: "TextModing® zet zich in voor de bescherming van de privacy en veiligheid van de persoonlijke gegevens van haar partnerbeheerders en eigenaren van datingsites. Dit privacybeleid beschrijft onze praktijken met betrekking tot de verzameling, het gebruik, de opslag en de bescherming van gegevens van beheerders die ons platform voor datingsites gebruiken. Door toegang te krijgen tot de functies van TextModing®, gaat u akkoord met de praktijken die in dit beleid worden beschreven.",
        pp1ttladm: "Verzameling van Gegevens",
        pp2ttladm: "Gebruik van Gegevens",
        pp3ttladm: "Delen van Gegevens",
        pp4ttladm: "Bewaring van Gegevens",
        pp5ttladm: "Beveiliging van Gegevens",
        pp6ttladm: "Rechten van Beheerders",
        pp7ttladm: "Cookies en Vergelijkbare Technologieën",
        pp8ttladm: "Wijzigingen in het Privacybeleid",
        pp9ttladm: "Contact",
        pp1intadm: "In het kader van onze samenwerkingsrelatie en voor het gebruik van ons platform, kunnen we de volgende gegevens verzamelen van de beheerders van partnerwebsites:",
        pp2intadm: "De verzamelde gegevens worden voor de volgende doeleinden gebruikt:",
        pp3intadm: "TextModing® verkoopt of verhuurt de persoonlijke gegevens van beheerders niet en deelt deze alleen met derden wanneer dit nodig is voor de volgende doeleinden:",
        pp4intadm: "De persoonlijke gegevens van beheerders worden bewaard zolang dit nodig is om de in dit beleid beschreven doelen te bereiken of om te voldoen aan de geldende wettelijke verplichtingen. Zodra de gegevens niet meer nodig zijn, worden ze veilig verwijderd.",
        pp5intadm: "TextModing® past strikte technische en organisatorische beveiligingsmaatregelen toe om de persoonlijke gegevens van beheerders te beschermen tegen ongeautoriseerde toegang, wijziging, openbaarmaking of vernietiging. Ondanks onze inspanningen is geen enkele gegevensoverdracht via internet volledig veilig; we doen er echter alles aan om de risico's te minimaliseren.",
        pp6intadm: "Beheerders hebben specifieke rechten met betrekking tot hun persoonlijke informatie, waaronder:",
        pp7intadm: "Om uw gebruikerservaring te verbeteren, maken we gebruik van cookies en andere vergelijkbare technologieën om inlogsessies te volgen en de interface van het platform te personaliseren. U kunt de instellingen van uw browser configureren om het gebruik van deze cookies te beperken, maar dit kan sommige platformfuncties beïnvloeden.",
        pp8intadm: "TextModing® behoudt zich het recht voor om dit privacybeleid te wijzigen om wijzigingen in onze praktijken weer te geven of om te voldoen aan wettelijke verplichtingen. Wijzigingen worden op deze pagina gepubliceerd met een bijgewerkte datum. We raden u aan dit beleid regelmatig te raadplegen.",
        pp9intadm: "Voor vragen of verzoeken met betrekking tot dit privacybeleid kunt u contact met ons opnemen via:",
        pp1outadm: "",
        pp2outadm: "",
        pp3outadm: "",
        pp4outadm: "",
        pp5outadm: "",
        pp6outadm: "Om deze rechten uit te oefenen, kunt u contact met ons opnemen via het volgende adres: admin@creativ-network.net",
        pp7outadm: "",
        pp8outadm: "",
        pp9outadm: "Door gebruik te maken van het TextModing®-platform, accepteren beheerders dit privacybeleid en stemmen ze ermee in dit toe te passen in het kader van hun gebruik van onze diensten.",
        pp1contadm: "- Identificerende gegevens: naam, achternaam, bedrijfsnaam, functie, beheerders-ID.\n- Contactgegevens: professioneel e-mailadres, telefoonnummer.\n- Inloggegevens: accountgegevens voor toegang tot het TextModing®-platform.\n- Gebruiksgegevens: inloggeschiedenis, gebruiksvoorkeuren en interacties met de functies van het platform.\n- Financiële gegevens: facturatiegegevens en betaalinformatie, alleen indien nodig voor transacties.",
        pp2contadm: "- Toegang en beheer van functies: beheerders toegang geven tot de 15 belangrijkste functies van TextModing® (gespreksbeheer, kwaliteitscontrole, betaling van operators, beheer van operatorlogboek, fotovraagbeheer, virtuele geschenkenbeheer, GIF-beheer, berichtenverkeerbeheer, beoordelingsbeheer, enz.).\n- Ondersteuning en hulp: technische ondersteuning en gepersonaliseerde hulp bieden bij problemen of aanvullende informatiebehoeften.\n- Verbetering van onze diensten: gebruiksgegevens analyseren om de functies en gebruikerservaring van het platform te verbeteren.\n- Facturering en betalingscontrole: verwerkingen van betalingen en het verstrekken van factuurdocumentatie.\n- Beveiliging: inlog- en verdachte activiteiten controleren om de beveiliging van beheerdersaccounts te waarborgen.",
        pp3contadm: "- Dienstverleners: We kunnen derde partijen inschakelen voor specifieke operaties (bijv. hosting, betalingsbeheer). Deze dienstverleners moeten uw gegevens vertrouwelijk en veilig verwerken.\n- Wettelijke verplichtingen: We kunnen uw gegevens openbaar maken indien wettelijk vereist of om te voldoen aan verzoeken van autoriteiten.\n- Bescherming van rechten: in geval van een geschil kunnen we informatie delen in juridische procedures ter verdediging van de rechten van TextModing®.",
        pp4contadm: "",
        pp5contadm: "",
        pp6contadm: "- Recht van toegang: verzoeken om de persoonlijke gegevens die we bezitten in te zien.\n- Recht op rectificatie: verzoeken om onjuiste of verouderde informatie te corrigeren.\n- Recht op verwijdering: verzoeken om persoonlijke gegevens in bepaalde omstandigheden te verwijderen.\n- Recht op beperking van verwerking: het gebruik van uw gegevens in bepaalde gevallen beperken.",
        pp7contadm: "",
        pp8contadm: "",
        sccss: "Succes",
        smthngwntwrng: "Er is een fout opgetreden",
        cntcts: "Contacten",
        frmlrdcntct: "Contactformulier",
        ttr: "Titel",
        mr: "De heer",
        mme: "Mevrouw",
        mlle: "",
        sct: "Bedrijf",
        stwb: "Website",
        addrss: "Adres",
        prvncdprtm: "Provincie / Departement",
        cdpstl: "Postcode",
        cntr: "Land",
        prnmcntct: "Voornaam van de contactpersoon",
        nmcntct: "Achternaam van de contactpersoon",
        tlphn: "Telefoon",
        eml: "E-mail",
        objdvtrcntct: "Onderwerp van uw contact",
        dmdpht: "Demoverzoek",
        prtnrt: "Partnerschap",
        affltn: "Affiliatie",
        prsrdv: "Afspraak maken",
        dmdbrchr: "Brochure aanvragen",
        othr: "Anders",
        vtrmsg: "Uw bericht",
        attchmt: "Bijlage",
        pltqcnfdtxt: "Door dit contactformulier in te dienen, verklaart u dat u onze",
        pltqcnfdtxt2: "privacyverklaring heeft gelezen en begrepen",
        xcldrssttm: "sleutels tot succes van TextModing®",
        cldrssttl1: "Betaalbare kosten",
        cldrssttl2: "Geavanceerde technologie",
        cldrssttl3: "Aanpassingsvermogen van het platform",
        cldrssttl4: "Operationele efficiëntie",
        cldrssttl5: "Transparantie en communicatie",
        cldrssttl6: "Toewijding aan klanttevredenheid",
        cldrsstxt1: "We bieden concurrerende diensten met een uitstekende prijs-kwaliteitverhouding, waardoor onze klanten hun budgetten kunnen optimaliseren terwijl ze profiteren van kwaliteitsoplossingen.",
        cldrsstxt2: "We maken gebruik van geavanceerde technologieën om robuuste prestaties, verbeterde beveiliging en een soepele werking van de platformactiviteiten te waarborgen.",
        cldrsstxt3: "We bieden flexibiliteit die het mogelijk maakt om het platform aan te passen aan de specifieke behoeften van elke klant, wat zorgt voor een persoonlijke en effectieve reactie.",
        cldrsstxt4: "We maximaliseren de efficiëntie van interne processen om korte implementatietijden en soepele operaties te waarborgen zonder in te boeten op de kwaliteit van de service.",
        cldrsstxt5: "We handhaven duidelijke en transparante communicatie met klanten, en bieden volledige zichtbaarheid van de prestaties en resultaten van hun projecten.",
        cldrsstxt6: "We stellen klanttevredenheid centraal in onze prioriteiten door snelle oplossingen te implementeren en voortdurend aandacht te hebben voor de behoeften en feedback van onze partners.",
        prdtstm: "TextModing® producten*",
        prdtttl1: "Testinterface",
        prdtttl2: "Dashboard",
        prdtttl3: "Betrokkenheidsplatform",
        prdttxt1: "De testinterface van TextModing® stelt potentiële klanten in staat om de essentiële functies van het platform te verkennen en te ervaren, inclusief het versturen van gewone berichten, hooks, pokes en follow-ups, gefaciliteerd door onze chatoperators, voor een volledige onderdompeling in onze interactieve omgeving.",
        prdttxt2: "Het dashboard van TextModing® biedt administrators, supervisors, kwaliteitscontroleurs en accountants een intuïtief en gecentraliseerd overzicht, waardoor het beheer van operaties in realtime, prestatiemonitoring, kwaliteitsborging en financiële tracking van het platform wordt vergemakkelijkt.",
        prdttxt3: "Het betrokkenheidsplatform van TextModing® biedt chatoperators een geoptimaliseerde interactieve omgeving om uitwisselingen te beheren, boeiende verbindingen te creëren en een dynamische en effectieve interactie tussen gebruikers te waarborgen.",
        prdindpdts: "De 3 producten zijn onderling afhankelijk",
        intrcteffcctprfrmnc: "“Interacties. Efficiëntie. Prestatie”",
        abtus: "Over ons",
        abtustxt: "TextModing® is een innovatieve platform dat zich richt op het betrekken van datingsites door professionele operators. We bieden gepersonaliseerde moderatie-, contentbeheer- en betrokkenheidsdiensten om de gebruikerservaring op datingsites te verbeteren. Met ons ervaren team garanderen we authentieke en boeiende interacties die een kwaliteitsvolle gebruikerservaring bevorderen.",
        rdswt: "Lees meer",
        lnsdst: "Site-links",
        lngdst: "Site-taal",
        mnaddrss: "Hoofdadres",
        rprsnttbrblg: "Belgische kantoormedewerker",
        ttdrtsrsrvs: "Alle rechten voorbehouden",
        apr: "Over",
        ftrs: "Functies",
        avntgs: "Voordelen",
        cldrsst: "Sleutels tot succes",
        nsprdts: "Onze producten",
        orpltfrm1: "Ons platform",
        orpltfrm2: "voor het beheren van datingsites",
        orpltfrmtxt: "TextModing® is een krachtig platform ontworpen om datingsites te beheren en te optimaliseren. Dankzij de geavanceerde tools en de intuïtieve interface kunnen gebruikersinteracties effectief worden beheerd, wat zorgt voor een hoog niveau van betrokkenheid.",
        flxqtdns: "Dagelijkse stromen",
        rqstdmo: "Vraag een demo aan",

        aprtxt1: "TextModing® is een innovatief platform dat is gewijd aan het beheer van datingsites door professionele operators. We bieden op maat gemaakte diensten voor moderatie, contentbeheer en engagement om de gebruikerservaring op datingsplatformen te verbeteren. Met ons ervaren team garanderen we authentieke en boeiende interacties die bijdragen aan een hoogwaardige gebruikerservaring.",
        aprtxt2: "Ons platform stelt operators in staat om gesprekken effectief te beheren, kwaliteitscontrole uit te voeren en berichtverkeer te superviseren. Met geavanceerde functies zoals het beheren van foto-aanvragen, virtuele geschenken en GIF's, evenals notities voor optimale interactietracking, is TextModing® een compleet hulpmiddel voor sites die een interactieve en dynamische ervaring willen bieden.",
        aprtxt3: "We zetten ons in voor het leveren van hoogwaardige diensten met een team dat altijd beschikbaar is om de specifieke behoeften van datingsites te ondersteunen en de betrokkenheid van leden te optimaliseren.",

        xftftm: "Functies van TextModing®",

        ft1ttl: "Gespreksbeheer",
        ft2ttl: "Agentbeheer",
        ft3ttl: "Paniekruimte",
        ft4ttl: "Notitiebeheer",
        ft5ttl: "Introductieberichten",
        ft6ttl: "Poke-berichten",
        ft7ttl: "Follow-up berichten",
        ft8ttl: "Foto-aanvragen",
        ft9ttl: "Agentmeldingen",
        ft10ttl: "Logboek",
        ft11ttl: "Dubbele berichten",
        ft12ttl: "Geschenkbeheer",
        ft13ttl: "Verkeersbeheer en -toezicht",
        ft14ttl: "Kwaliteitscontrole",
        ft15ttl: "Boekhouding",

        ft1txt: "Stelt beheerders, teamleiders en supervisors in staat om interacties in realtime te monitoren, te controleren en te analyseren, wat zorgt voor de kwaliteit en de soepelheid van de communicatie op datingsites.",
        ft2txt: "Stelt beheerders, teamleiders en supervisors in staat om de prestaties van agents in realtime te volgen, te begeleiden en te evalueren, wat zorgt voor effectief sitebeheer.",
        ft3txt: "Biedt beheerders, teamleiders en supervisors een veilige ruimte om noodgevallen te beheren, kritieke incidenten snel op te lossen en de operationele continuïteit op datingsites te waarborgen.",
        ft4txt: "Stelt beheerders, teamleiders en supervisors in staat om notities over agentinteracties te maken, te volgen en te delen, wat de coördinatie en prestatiemonitoring optimaliseert.",
        ft5txt: "Stelt beheerders, teamleiders en supervisors in staat om effectieve introductieberichten te maken, aan te passen en in te zetten om de betrokkenheid van gebruikers op datingsites te optimaliseren.",
        ft6txt: "Stelt beheerders, teamleiders en supervisors in staat om strategische poke-berichten te configureren, te versturen en te analyseren om de betrokkenheid te hervatten en interactie te stimuleren.",
        ft7txt: "Stelt beheerders, teamleiders en supervisors in staat om follow-up berichten in te plannen, aan te passen en te analyseren om inactieve gebruikers op de site opnieuw te activeren.",
        ft8txt: "Stelt beheerders, teamleiders en supervisors in staat om de foto-aanvragen van operators te controleren, goed te keuren en te beheren, waarbij de conformiteit en kwaliteit van de uitgewisselde content wordt gewaarborgd.",
        ft9txt: "Stelt beheerders, teamleiders en supervisors in staat om meldingen over agents te volgen, te beoordelen en te verwerken, wat zorgt voor proactieve moderatie en optimale kwaliteitscontrole.",
        ft10txt: "Stelt beheerders, teamleiders en supervisors in staat om de notities van operators te volgen, te registreren en te analyseren om de prestaties en communicatie te optimaliseren.",
        ft11txt: "Stelt beheerders, teamleiders en supervisors in staat om herhalende berichten te identificeren en te beheren om de kwaliteit van interacties met gebruikers te verbeteren.",
        ft12txt: "Stelt beheerders, teamleiders en supervisors in staat om het verzenden van boeiende inhoud te beheren en te analyseren om de betrokkenheid van gebruikers te versterken.",
        ft13txt: "Stelt beheerders, teamleiders en supervisors in staat om berichtstromen te analyseren om de prestaties te optimaliseren en een soepele communicatie tussen gebruikers te garanderen.",
        ft14txt: "Stelt beheerders, teamleiders en supervisors in staat om agentinteracties te evalueren om een hoogwaardige service en klanttevredenheid te waarborgen.",
        ft15txt: "Stelt beheerders en accountants in staat om financiële transacties te volgen en te beheren, wat zorgt voor transparantie en efficiëntie in het resourcebeheer.",

        nsecrr: "Neem contact met ons op",

        cqdfftm: "Wat maakt TextModing® anders?",
        cqdffttl: "TextModing® onderscheidt zich van de concurrentie door verschillende belangrijke voordelen:",
        cqdffttl1: "Specialistische expertise",
        cqdffttl2: "Aanpassing en flexibiliteit",
        cqdffttl3: "Continue ondersteuning en training",
        cqdffttl4: "Continue innovatie",

        cqdfftxt1: "TextModing® brengt hooggekwalificeerde webtalenten samen, zoals chat operators, supervisors, kwaliteitscontroleurs en verkeersmanagers, wat zorgt voor optimaal en professioneel beheer van datingsites.",
        cqdfftxt2: "Dankzij ons platform bieden we op maat gemaakte oplossingen die zijn afgestemd op de specifieke behoeften van elke klant, wat zorgt voor gerichter en efficiënter beheer.",
        cqdfftxt3: "TextModing® biedt niet alleen diensten aan, maar investeert ook in de voortdurende training van ons personeel om een snelle aanpassing aan nieuwe trends en technologieën te waarborgen, terwijl we onze partners constant ondersteunen om hun groei te bevorderen.",
        cqdfftxt4: "Door voorop te blijven lopen in de nieuwste technologieën en strategieën voor animatie, zorgt TextModing® ervoor dat haar klanten een duurzame concurrentievoorsprong hebben op de voortdurend veranderende markt.",
        btsd : "Botzijde",
        nttkn : "Genomen notities",
        mssdnt : "Gemiste notities",
        gfts : "Cadeaus",
        rqsts : "Verzoeken",
        messonhldallctd : "Bericht in afwachting al toegewezen",
        messonhldntallctd : "Bericht in afwachting niet toegewezen",
        totnummess : "Totaal aantal berichten",
        messpssdqc : "Berichten geslaagd voor QC",
        messntpssqc : "Berichten niet geslaagd voor QC",
        paymnts : "Betalingen",
        bnk : "Bank",
        ppl : "Paypal",
        ws : "Wise",
        yrsf : "Yoursafe",
        rçes: "Ontvangen",
        envoyees: "Verzonden",
        dmndees: "Aangevraagd",
        nvaux: "Nieuw",

        adddest: "E-mailadres ontvanger",

        cmpn: "Bedrijf",
        tmfld: "Tekstmodemap",
        accar: "Toegangsgebied", 
        assvldt: "Toegangsgeldigheid",

        acc8: "Tarief en Bonus instellen",

        mtng: "Meeting",
        rp: "Echt profiel",
        fp: "Nepprofiel",
        infopernoper: "Permanente / niet-permanente informatie",
        dltnt: "Notitie verwijderen",
        dltmesspnc: "Wilt u dit paniekbericht echt verwijderen?",
        convbnnd: "Verbannen gesprek",
        suspconv: "Hang het gesprek op",
        upldd: "Geüpload",
        addpht: "Foto toevoegen",
        phtsnttofp: "Lijst met foto's verzonden naar nepprofielen",
        pkmesscrtd: "Poke-bericht succesvol aangemaakt",
        updt: "Bijwerken",
        dltpkmess: "Wil je dit poke bericht echt verwijderen?",
        plt: "Platform",
        dltdmd: "Wil je dit verzoek echt verwijderen? ",
        agntinfrmd: "Agent op de hoogte",
        dlttsrmess: "Wil je dit teaser bericht echt verwijderen?",
        updttsrmess: "Update teaser bericht",

        updtdsucc: "Met succes bijgewerkt",
        nonotif: "Geen melding",
        continfo: "Inhoudelijke informatie",
        exp: "Afzender",
        recep: "Ontvanger",
        mdpupdtd: "Wachtwoord succesvol bijgewerkt",
        opsusp: "Operator met succes opgeschort",
        opdec: "Operator met succes losgekoppeld",
        decop: "Wilt u deze operator echt loskoppelen:  ",
        frop: "Wil je deze operator echt ontslaan:  ",
        chngmdp: "Wijzig wachtwoord",
        suspop: "Wil je deze operator echt schorsen: ",
        onhld: "In de wacht",
        supprmess: "Verwijder dit bericht",
        messinfo: "Berichtinformatie",
        vld: "Bevestigen",
        ok: "Ok",
        enc: "Wordt aan gewerkt ...",

        updtmdp: "Wilt u het wachtwoord wijzigen?",
        nwmdp: "Voer nieuw wachtwoord in",
        dltsup: "Wil je deze supervisor echt verwijderen?",
        ntsup: "Notitie voor een supervisor",
        nthr: "Notitie hier...",
        dltimg: "Wil je deze foto echt verwijderen?",
        phttsnd: "Te versturen foto",
        vd: "Leeg (selecteer een foto)",
        addd: "Toegevoegd",
        nwrdv: "Nieuwe informatie toevoegen",
        cnfrm: "Bevestigen",
        dltrdv: "Wilt u deze Rendez-vous/ Vergaderingen/ Vakanties echt verwijderen?",
        addnonperminfo: "Voeg niet-permanente informatie toe",
        dltinfo: "Wilt u deze informatie echt verwijderen?",
        idd: "ID",
        addperminfo: "Permanente informatie toevoegen ",
        messreport: "Bericht gemeld aan supervisor",
        usrnm: "Gebruikersnaam",

        pp11 : "Creativ'Network, een bedrijf voor professionele bemiddeling voor webwerkzaamheden, gevestigd te 71-75 Shelton Street, Covent Garden, Londen, WC2H 9JQ, en met een vertegenwoordigingskantoor te Roodborstjesstraat 4, Wondelgem 9032, hierna aangeduid als « de Vennootschap »,",
        pp12 : "En:",
        pp13 : "Zelfstandige, die op grond van de Zelfstandige Overeenkomst die hem verbindt met « De Vennootschap » gebruik maakt van de materialen en intellectuele eigendommen van laatstgenoemde, hierna aangeduid als « de Zelfstandige »,",
        pp14 : "Hierna afzonderlijk aangeduid als « de Partij » en gezamenlijk als « de Partijen »,",
        pp21 : "De Vennootschap exploiteert een platform voor het beheer van datingsites waarvan zij de exclusieve eigenaar is. Het operationele beheer van dit platform is toevertrouwd aan chatoperators, supervisors, teamleiders, kwaliteitscontroleurs, die door de Vennootschap als zelfstandigen worden ingehuurd.",
        pp22 : "In het kader van deze professionele relatie zal de Zelfstandige toegang hebben tot vertrouwelijke informatie met betrekking tot het platform, de gebruikers van de datingsites, de operationele processen, evenals tot andere beschermde informatie die eigendom is van de Vennootschap.",
        pp23 : "Gezien het bovenstaande, wordt als volgt overeengekomen:",
        pp31 : "Deze geheimhoudingsovereenkomst heeft tot doel de vertrouwelijke informatie te beschermen die de Vennootschap mogelijk aan de Zelfstandige verstrekt in het kader van zijn taken met betrekking tot het beheer van het platform voor datingsites.",
        pp41 : "Voor de doeleinden van deze overeenkomst verwijzen de « Vertrouwelijke Informatie » naar alle informatie, in welke vorm dan ook (schriftelijk, mondeling, elektronisch, etc.), met betrekking tot het platform voor datingsites, de gebruikers, de ontwikkelingsstrategieën, de technische en operationele processen, de financiële gegevens, evenals alle andere informatie die door de Vennootschap als vertrouwelijk wordt aangeduid en die de Zelfstandige kan ontvangen of waartoe hij toegang heeft in het kader van zijn werk.",
        pp51 : "De Zelfstandige verbindt zich ertoe om:",
        pp52 : "- Geen Vertrouwelijke Informatie, geheel of gedeeltelijk, aan derden te onthullen, reproduceren, door te geven of toegankelijk te maken zonder voorafgaande schriftelijke toestemming van de Vennootschap.",
        pp53 : "- Vertrouwelijke Informatie alleen te gebruiken in het kader van de activiteiten die verband houden met het beheer van het platform en uitsluitend ten behoeve van de Vennootschap.",
        pp54 : "- Alle noodzakelijke maatregelen te nemen om de vertrouwelijkheid van de Vertrouwelijke Informatie te beschermen en ongeautoriseerd gebruik te voorkomen.",
        pp55 : "- Op verzoek van de Vennootschap alle documenten of materialen die Vertrouwelijke Informatie bevatten terug te geven, zonder daarvan kopieën te bewaren in welke vorm dan ook.",
        pp61 : "Deze overeenkomst treedt in werking op het moment dat de werknemer zijn akkoord geeft bij zijn identificatie voor toegang tot het platform voor animatie en blijft van kracht gedurende de gehele duur van de professionele relatie tussen de Vennootschap en de Zelfstandige. De geheimhoudingsverplichtingen blijven bestaan gedurende een periode van 10 jaar na beëindiging van de relatie, ongeacht de oorzaak.",
        pp71 : "De volgende worden niet beschouwd als Vertrouwelijke Informatie:",
        pp72 : "- Informatie die al publiekelijk bekend was op het moment van de communicatie aan de Zelfstandige, of die op andere wijze publiekelijk wordt, behalve door een schending van deze overeenkomst.",
        pp73 : "- Informatie die de Zelfstandige kan aantonen legaal te hebben ontvangen van een derde die niet gebonden is aan een geheimhoudingsverplichting.",
        pp74 : "- Informatie die onafhankelijk door de Zelfstandige is ontwikkeld zonder gebruik te maken van Vertrouwelijke Informatie.",
        pp81 : "In geval van een schending van de verplichtingen die in deze overeenkomst zijn vastgelegd, erkent de Zelfstandige dat de Vennootschap alle juridische stappen kan ondernemen die nodig zijn om haar rechten te beschermen en schadevergoeding te verkrijgen voor geleden schade.",
        pp91 : "Deze overeenkomst wordt beheerst door het Belgische recht. In geval van geschillen over de interpretatie of uitvoering van deze overeenkomst, verbinden de Partijen zich ertoe een minnelijke oplossing te zoeken. Bij gebrek aan een minnelijke oplossing zijn uitsluitend de bevoegde rechtbanken van Gent bevoegd.",
        pp1 : "Tussen de Partijen",
        pp2 : "Inleiding",
        pp3 : "Artikel 1: Doel",
        pp4 : "Artikel 2: Definitie van Vertrouwelijke Informatie",
        pp5 : "Artikel 3: Verplichtingen van de Zelfstandige",
        pp6 : "Artikel 4: Duur",
        pp7 : "Artikel 5: Uitzonderingen",
        pp8 : "Artikel 6: Algemene Bepalingen",
        pp9 : "Artikel 7: Toepasselijk Recht en Bevoegde Rechtbank",
        paymail: "E-mailadres voor betaling",
        scc: "Aangemaakt",
        login: 'Login',
        mail:'Email',
        reg:'Register',
        password: 'Wachtwoord',
        confirmpass:'Bekreft mot de pass',
        alreadyreg:'Allerede registrert? å logge inn',
        notifs: "Meldingen",
        limite:'Beperkt',
        save:'Opslaan',
        archives:'Archieven',
        ttlmsg:'Totaal Berichten',
        controlLog:'Controller-inlog',
        nbrmess:'Aantal berichten',
        appQC:'QC Waarderingen',
        esqmq:'Ontbrekende ontwijking',
        dbspace:'Dubbele spatie',
        notelu:'Notitie gelezen',
        actpris:'Actie ondernomen',
        ferme:'Gesloten',
        losn:'Lijst van verzonden notities',
        lorn:'Lijst van ontvangen notities',
        validity:'Geldigheid',
        editInfo:'Informatie wijzigen',
        controlID:'ID-controller',
        statcontrolct:'Controle Statistieken per Controller',
        statcontrolag:'Controle Statistieken per Agent',
        statcontrol:'Controle Statistieken',
        traitement:'Behandeling',
        agentid:'Agent-ID',
        statconvatt:'Statistieken van Gesprekken in de Wacht',

        propesqu:'Voer een smoesje in voor een afspraakverzoek',
        msgacc:'Schrijf alstublieft een pakkende boodschap',
        msgpoke:'Gelieve een Poke-bericht te schrijven',
        heure:'Uur',
        esqv:'Ontwijken',
        esquive:'Ontwijken ontmoetingen en afspraken',
        other:'Anderen',
        sexe:'Geslacht',
        bannir:'Verbannen',
        deconnecter: 'Loskoppelen',
        agntLgn:'Online Agent',
        features:'Kenmerken',
        rdv:'Afspraak',
        holiday:'Vakantie',
        valider:'Valideren',
        panicmsg:'dit paniekbericht?',
        jour:'Dag',
        jours:'Dagen',
        conversation:'Gesprek',
        profil:'Profiel',
        suspendre:'Opschorten',
        expulser:'Verwijderen',
        information:'Informatie',
        meeting:'Vergadering',
        online:'Online',
        total:'Totaal',
        treated:'Behandeld',
        onHold:'In de wacht',
        listPhotoSend:"Lijst van verzonden foto's",
        agentReport:'Gemelde agent',
        suspendu:'Geschorst',
        raison:'Rede',
        etat:'Status',
        actqc4: 'Bericht gevalideerd',
        panicmess:'Paniekbericht',
        contenu:'Inhoud',
        language1:'Taal',
        reportmess:'Meld een bericht',
        enreg:['Ikke registrert ennå?','Registrere'],
        passdiff:'Et annet passord',
        qlogout: 'Wilt u uitloggen?',
        editNote:'Notitie Bewerken',
        annuler:'Annuleren',
        addnote: "Opmerking toegevoegd",
        logout: 'Uitloggen',
        notesuppr: "Notitie verwijderd",
        editer:'Bewerken',
        yes: 'Ja',
        no: 'Nee',
        operatorpage: "Operators-pagina",
        supervisorpage: "Supervisors-pagina",
        adminpage: "Beheerderspagina",

        required: "Dit veld is verplicht",
        invalid: "Ongeldige e-mail",
        registered: "Dit e-mailadres is niet geregistreerd",
        incorrectpass: "Onjuist wachtwoord",
        atleast: "Wachtwoord moet ten minste",
        characters: "karakters",

        convman: "Beheer van gesprekken",
        agman: "Agentenbeheer",
        notesman: "Beheer notities",
        agrep: "Agenten rapporten",
        gftgtfman: "Beheer van geschenken en gifs",
        tmfldaa: "TextModing map toegangsrechten",
        hlp: "Help",
        pltflng: "Platformtaal",
        pr: "Foto verzoeken",
        wlcm: "Welkom",
        wlcmtxt: "TextModing is een animatieplatform dat is ontworpen voor datingsites en dat essentiële functies biedt voor het beheren van conversaties en agenten. Het verbetert de efficiëntie en winstgevendheid voor partners door tools te bieden die interacties stroomlijnen en de betrokkenheid van gebruikers optimaliseren.",

        messageto: "Bericht aan",
        convatt: "Gesprek in de wacht",
        mincharlen: "Ten minste 120 tekens",
        char: "karakter",
        chars: "tekens",
        pj: "Foto bijlage",
        writemessage: "Schrijf een bericht ...",
        picalreadysent: "Foto gezien door de gebruiker",
        gifpj: "GIFS",
        opengif: "GIF openen",
        sendMessage: "Stuur het bericht",
        reportSup: "Stuur een bericht naar de supervisor",
        conv: "Gesprek ID",
        say: "zegt",
        timeIntro: "bij",
        elements: 'elementen',
        element: "element",
        none: "Geen",

        stat: "Statistieken",
        french: 'Frans',
        english: 'Engels',
        italian: 'Italiaans',
        dutch: 'Nederlands',
        spanish: 'Spaans',
        german: 'Duitse',
        botprofil: "Botprofiel",
        userprofil: "gebruikersprofiel",
        otherInfo: "Andere belangrijke informatie",
        codepost: "Postcode",
        ville: "Stad",
        region: "Regio",
        pays: "Land",
        taille: "Grootte",
        eyes: 'Kleur ogen',
        hair: 'Haarkleur',
        morph: 'Morfologie',
        situation: 'Burgerlijke staat',
        searchfor: 'Zoeken naar',
        job: 'Beroep',
        signe: 'Onderscheidende tekens',
        descri: 'Beschrijving',
        infosupp: 'Aanvullende informatie',
        supervischat: 'Toezicht chat',
        statconvop: "Stat en operator gesprek",
        convs: "Gesprekken",
        report: 'Rapport',
        agentInform:'Geïnformeerde agent',
        events: 'Gebeurtenis',
        photosrequests: "Foto's aanvragen",
        warmup: 'Opwarming',
        gifattch: 'Gif-bijlage',
        fakeprofiles: 'Nepprofielen',
        pagenotfound: 'Pagina niet gevonden',

        suivvraiprof: 'Echte profielen bewaking',
        suiviop: "Operators monitoring",
        admintion: "Administratie",
        affiliation: "Lidmaatschap",

        operator: "Operator",
        supervisor: "Supervisor",
        administrator: "Beheerder",
        week: "week",
        weeks: "weken",
        today: 'Vandaag',

        start: 'Start',
        end: 'Einde',
        platform: 'Platformen',
        search: 'Zoeken',
        go: "Go",
        allmasc: "Alle",
        allfem: "Alle",

        id: "Identifier",
        opname: "Operator naam",
        sentmess: "Verzonden berichten",
        recmess: "Ontvangen berichten",
        relmess: "Gestuurde berichten",
        date: "Datum",
        messid: "identificatiecode bericht",
        convid: "Identificatiecode gesprek",
        exped: "Afzender",
        recpt: "Ontvanger",
        opsign: "Operator te melden",
        opexped: "Operatorr verzenden",
        action: "Actie",
        messs: "Berichten",

        filterdate: 'Filter zoekopdrachten tussen twee datums',
        filtermess: 'Filter conversatie op bericht',
        from: 'Van',
        to: 'naar',
        eventList: 'Gebeurtenissenlijst',
        num: 'Aantal',
        eventSearch: 'Zoeken naar een gebeurtenis',
        actions: 'Acties',

        eventname: 'Naam gebeurtenis',
        eventdate: 'Gebeurtenisdatum',
        uploadimage: "Upload afbeeldingen",
        role: "Rol",
        logIn: "Aanmelden",
        ppaccept1: "Door in te loggen op ons platform bevestig je dat je onze",
        ppaccept2: "en de juiste training hebt gevolgd om uw opdracht uit te voeren.",
        pp: "vertrouwelijkheidsovereenkomst",
        copyright: "© 2023-2024 Copyright TextModing® - Alle rechten voorbehouden.",
        accountant: "Accountant",
        qualitycontroller: "Kwaliteitscontroleur",
        adminid: "Admin ID",
        accountantid: "Accountant ID",
        operatorid: "Operator ID",
        qcid: "Quality Controller ID",
        supervisorid: "Supervisor ID",
        convonhold: "Gesprekken in de wacht",
        supdash: "Supervisor dashboard",
        choverv: "Chatoverzicht",
        months: ["Januari", "Februari", "Maart", "April", "Mei", "Juni", "Juli", "Augustus", "September", "Oktober", "November", "December"],
        year: 'Jaar',
        month: 'Maand',
        startingdate: 'Begindatum',
        closingdate: 'Einddatum',

        agents: "Agenten",
        out: "Uitgangen",
        in: "Inputs",
        details: "Details",
        panicroom: "Paniekkamer",
        notes: "Notities",
        teasermessages: "Teaser messages",
        pokemessages: "Poke-berichten",
        attdisc:'Wachten op Discussie',
        gifajt:'Gif Toevoegen',
        stopmessages: "Stopberichten",
        sent: "Verzonden",
        asnwered: "Beantwoord",
        requested: "Aangevraagd",
        logbook: "Logboek",
        recorded: "Opgenomen",
        notrecorded: "Niet opgenomen",
        duplicatedmessages: "Dubbele berichten",
        back: "Terug",
        suty: "Abonnementstype",
        ag: "Leeftijd",
        gend: "Geslacht",
        sexor: "Seksuele geaardheid",
        tow: "Stad",
        
        users: "Gebruikers",
        sender: "Afzender",
        receiver: "Ontvanger",
        agent: "Agent",
        status: "Status",
        read: "Gelezen",
        active: "Actief",
        edit: "Wijzigen",
        user: "Gebruiker",
        sendnote: "Verstuur een notitie",
        oh: "Wachten",
        mess: "Bericht",
        trconv: "Behandelde gesprekken",
        idconv: "Gesprek-ID",
        idmess: "Bericht-ID",
        otherimp: "Andere belangrijke informatie",
        otherimp1: "Vergadering/ Ontmoetingen/ Vakanties",
        otherimp2: "Permanente informatie",
        otherimp3: "Nuttige informatie, niet permanent",
        
        aglist: "Agent lijst",
        pfsperf: "Platformprestaties",
        agperf: "Agent prestaties",
        nwag: "Nieuwe agenten toevoegen",
        agname: "Agent naam",
        agcountry: "Land agent",
        aglogin: "Agent login",
        agloginpw: "Agent wachtwoord",
        langpf: "Taalplatform",
        onl: "Online",
        offl: "Offline",
        suspend: "Opschorten",
        koff: "Opnieuw verzenden",
        grotot: "Totaal bruto",
        agnumbewoon: "Aantal nepprofielen",
        totsenmess: "Totaal verzonden berichten",
        totrecmess: "Totaal ontvangen berichten",
        totrep: "Totaal rapporten",
        real: "Echte naam",
        prov: "Provincie",
        weight: "Weight",
        child: "Kinderen",
        time: "Tijd",
        fstnm: "Voornaam",
        lstnm: "Achternaam",
        dtofbir: "Geboortedatum",
        skypeid: "Skype-ID",
        crnwag: "Nieuwe agent aanmaken",
        usrprfl: "Gebruikersprofiel",
        sndmess: "Bericht verzenden",
        blckusr: "Gebruiker blokkeren",
        lstnts: "Lijst met notities",
        crtsndnts: "Maak / Verzend een notitie",
        evnt: "Gebeurtenis",
        ntid: "Notitie-ID",
        nt: "Opmerking",
        dlt: "Verwijderen",
        cndid: "Kandidaat-ID",
        language: "Taal",
        perc1: 'Je hebt ',
        perc2: " van het bericht van de gebruiker samengesteld",
        giftsatt: "Bijgevoegde geschenken",
        sndnt: "Verzend de notitie",
        lsttsrmsg: "Lijst met teaser-berichten",
        crttsrmsg: "Teaser-berichten maken",
        idtsrmsg: "Teaser bericht ID",
        tsrmsg: "Teaser bericht",
        dlvrdt: "Geleverd aan",
        wiseg:"Wijze Gifs",
        sexg:"Seksuele Gifs",
        gayg:"Gay Gifs",
        Lesbg: "Lesbische Gifs",
         transg:"Transgender Gifs",
        travg:"Travestiet Gifs",
                
        bdsm:"BDSM",
        dont: "Blijf niet te lang",
        blj:"Blowjob",
        org:"Orgasme",
        vagpen:"Vaginale penetratie",
        sod:"Sodomie",
        titss:"Tieten",
        
        bdsmg: "BDSM Gay",
        wisegg: "Wijze homo gifs",
        sexgg:"Sex Gay Gifs",
        
        bdsml: "Lesbische BDSM",
        wiselg:"Wijze Lesbische Gifs",
        sexlg:"Sex Lesbian Gifs",
        
        maletrans:"Mannelijke transgender",
        femaletrans:"Vrouwelijke transgender",
        lstpkmsg: "Lijst met gepokte berichten",
        crtpkmsg: "Maak een poke bericht",
        idpkmsg: "Poke bericht ID",
        pkmsg: "Poke bericht",
        lststpmsg: "Lijst met stopberichten",
        idstpmsg: "Stop bericht ID",
        stpmsg: "Stopbericht",
        sntto: "Verzonden naar",
        agntwrtngstp: "Agent die de stop schrijft",
        dtofdlvr: "Datum van levering",
        dtofans: "Datum van antwoord",
        stpperfanal: "Stop prestatieanalyse",
        stpgrssttl: "Stop brutototaal",
        rsptostp: "Reactie op stopzetting",
        rsptostp2: "Reactie op de Hook",
        rsptostp3: "Reactie op de Poke",
        stpprcntresp: "Stop % respons",
        avrgrsptm: "Gemiddelde responstijd",

        lstphtrq: "Lijst met opgevraagde foto's",
        phttp: "Soort foto",
        idrq: "Verzoek ID",
        pht: "Foto",
        upld: "Uploaden",
        snd: "Verzenden",
        lstrprt: "Lijst met rapporten",
        rpttp: "Rapporttype",
        idrprt: "Rapport-ID",
        msgrprtd: "Gerapporteerd bericht",
        infrmagnt: "Inform agent",
        lstlgbk: "Lijst van logboek",
        lstmssnglgbk: "Lijst van ontbrekende logboeken",
        lgbktp: "Type logboek",
        idlgbk: "Logboek ID",
        infs: "Informatie",
        lvlofimprt: "Niveau van belangrijkheid",
        prmnnt: "Permanent",
        ntprmnnt: "Niet permanent",
        mssnglgbk: "Ontbrekend logboek",
        mrklgbk: "Markeer logboek",

        dh: "Datum en uur",
        mid: "Bericht-ID",
        repmess: "Dit bericht melden",
        bts: "Bot zegt",
        obj: "Object",
        plc: "Plaats",

        lstdplmss: "Lijst met dubbele berichten",
        iddplmss: "ID gedupliceerde berichten",
        lstgfs: "Lijst met GIF's",
        ddgfs: "Nieuwe GIF toevoegen",
        gftp: "GIF type",
        gfimg: "GIF-afbeelding",

        nat: "Aard",
        tmslt: "Tijdslot",
        gfsnt: "Verzonden GIF's",

        qcdash: "Dashboard voor kwaliteitscontrole",
        qctrl: "Kwaliteitscontrole",
        lstmess: "Lijst met berichten",
        qcpnl: "Kwaliteitscontrole pannel",
        ptreq: "Foto aanvragen",
        sp: "Foto verzenden",
        sgft: "Cadeau verzenden",
        sgif: "Gif verzenden",
        qccrit: "QC-criteria",
        qccrit1: "Goede chat",
        qccrit2: "Slechte chat",
        qccrit3: "Gebruikersprofiel bekeken",
        qccrit4: "Botprofiel bekeken",
        qccrit5: "Conversatie aangegaan door de bot",
        qccrit6: "CTA door agent",
        qccrit7: "Gesprek niet zinvol",
        qccrit8: "Gebruiker ontevreden over de bot",
        qccrit9:'Goed genoteerd',
        actqc1: "Informeer de agent",
        actqc2: "De agent opschorten",
        actqc3: "Ontsla de agent",
        adc1:'Uitstekende agent',
        adc2:'Gemiddelde agent',
        adc3:'Slechte agent',
        sndmailnaaradm: "Stuur een e-mail naar de beheerder",
        yrnm: "Uw naam",
        yrcmpnm: "Uw bedrijfsnaam",
        yrmladdr: "Uw e-mailadres",
        yradmid: "Uw admin-ID",
        objctfyrrqst: "Object van uw verzoek",
        yrmss: "Uw bericht",
        yrattchmt: "Bijlage",
        sndthml: "Verzend de e-mail",
        ddnwadm: "Nieuwe admin toevoegen",
        nm: "Naam",
        mladd: "E-mailadres",
        cmpnnm: "Bedrijfsnaam",
        tmfldr: "TekstModing map",
        psswrd: "Wachtwoord",
        accssar: "Toegangsgebieden",
        valdt: "Geldigheid",
        rgstr: "Registreren",

        accdash: "Accountant Dashboard",
        acc: "Rekeningen",
        acc1: "Agent statistieken",
        acc2: "Betaling per overschrijving",
        acc3: "Betaling via Paypal",
        acc4: "Betaling per wijze",
        acc5: "Betaling via Yoursafe",
        acc6: "Boekhouder toevoegen",

        admdash: "Admin dashboard",
        chttlov: "Chattool overzicht",
        suptraffman: "Toezicht op en beheer van het verkeer",
        vw: "Zien",
        
        ratepermess: "Tarief per bericht",
        bonus: "Bonus",
        totpay: "Totale betaling",
        paymeth: "Betalingsmethode",
        grosstot: "Bruto totaal per maand van ",
        prstat: "Afschrift afdrukken",
        agfn: "Voornaam agent",
        agln: "Achternaam agent",
        payadd: "Paypal e-mailadres",
        nysent: "Nog niet verzonden",
        wiseadd: "E-mailadres van Wise",
        yoursafeadd: "E-mailadres van Yoursafe",
        bankn: "Banknaam",
        iban: "IBAN",
        bic: "BIC code",

        lstsup: "Lijst van toezichthouders",
        ddnwsup: "Nieuwe supervisor toevoegen",
        trffcmng: "Verkeersbeheer",
        supnm: "Naam supervisor",
        suplgn: "Supervisor login",
        suplgnpsswrd: "Wachtwoord voor aanmelding supervisor",
        idcnddt: "Kandidaat ID",
        cntry: "Land",
        lgn: "Login",
        crtnwsup: "Nieuwe supervisor maken",
        fragntonln: "Free agent online",
        msgalltag: "Bericht toewijzing aan agent",
        allct: "Toewijzen",
        addqc: "Kwaliteitscontroleur toevoegen",
        crtqc: "Creëer kwaliteitscontroleur",
        crtnwacc: "Nieuwe accountant aanmaken",

        gifn: "Te verzenden GIFs",
        restfn: "Reset",
        gifsel: "Leeg (selecteer gifs)",
        alerttxt: "GIFs succesvol verzonden.",
        option: "opties",

        phalert: "Verzoek om foto verzonden.",
        phtitre: "Verzoek om foto",
        phlabel: "Soort foto",
        phsend: "Verzenden",
        phload: "Laden...",

        delalert: "Verwijderd",
        deltitre1: "Wil je deze aanvraag echt ",
        deltitre2: "verwijderen ?",
        delauteur: "Auteur",
        deldesc: "Beschrijving",
        delaccept: "Ja",
        delrefuse: "Nee",
        delload: "Laden..",

        lstqc: "Lijst van kwaliteitscontroleurs",
        qcnm: "Naam kwaliteitscontroleur",
        qclgn: "Login kwaliteitscontroleur",
        qclgnpsswrd: "Inlogwachtwoord kwaliteitscontroleur",
        lstacc: "Lijst van boekhouders",
        accnm: "Naam boekhouder",
        acclgn: "Login boekhouder ",
        acclgnpsswrd: "Inlogwachtwoord boekhouder" ,

        biev: "Welkom",
        selecrt: "Berichtverzendingsinterface",
        chxha: "Kies een echt en een vals profiel",
        chxF: "Lijst van echte profielen",
        chxV: "Lijst van valse profielen",
        nomd: "Naam",
        langs: "Taal",
        errdj: "Selecteer een echt en een vals profiel",
        plch: "Voer je bericht in",
        pokePh: "Poke-berichten (nog niet verzonden)",
        teaserPh: "Lijst van aanhaakberichten (nog niet verzonden)",
        inter: "Testinterface van TextModing",
        interSel: "Selecteer alstublieft de taken die u wilt uitvoeren",
        des1: "Het verzenden van een gepersonaliseerd bericht naar een profiel.",
        des2: "Het verzenden van een bericht om de aandacht van het profiel te trekken.",
        des3: "Gepersonaliseerde verzending van boeiende berichten naar nieuwe inschrijvingen om gesprekken te starten.",
        des4: "Het verzenden van een bericht om het gesprek te herstarten.",
        tit1: "Bericht verzenden",
        tit2: "Teaser bericht",
        tit3: "Poke bericht",
        tit4: "Stop bericht",
        tache: "Taak",
        bienvTest: "Welkom bij de testinterface van",
        enterEP: "Voer uw e-mail en wachtwoord in",
        instTest: "Instructies",
        sendInt: "Verzenden Interface",
        unPlP: "Kies één of meer echte profielen.",
        unNouv: "Nieuwe",
        unReç: "Ontvangen",
        unNouv1: "Nieuw",
        payTot: "Totale betaling",
        allou: "Al toegewezen",
        Nallou: "Nog niet toegewezen",
        totSoum: "Totaal bericht",
        Soum: "Ingediend voor QC",
        nonSoum: "Niet ingediend voor QC",
        boot: "Bot",
        rvr: "Afspraken/Bijeenkomsten/Vakanties",
        banit: "Verbannen",
        suspt: "Geschorst",
        aucunt: "Geen bericht",
        msgstop: "Schrijf een vervolgbericht",
        messrt: "Berichttarief",
        pkmessrt: "Poke berichttarief",
        tsrmessrt: "Teaser berichttarief",
        stpmessrt: "Stop berichttarief",
        bnsrt: "Bonustarief",
        mesdes: "Berichten sturen op een datingsite is cruciaal om de aandacht van de ander te trekken en hun interesse te wekken. Het is belangrijk om authentiek te blijven, oprechte interesse te tonen en open vragen te stellen die een reactie aanmoedigen.",
        mesdes1: "Kies uit de profielen een echt en een nep profiel om een bericht te sturen.",
        mesdes2: "Zorg ervoor dat u de juiste correspondentie taal kiest.",
        mesdes3: "Schrijf uw testbericht.",
        mesdes4: "U heeft 5 minuten om te reageren op het bericht op het animatieplatform voor operators.",
        mesdes5: "Controleer uw statistieken in uw beheerdersdashboard.",
        stopdes: "Follow-up berichten op een datingsite zijn bedoeld om de interesse weer op te wekken en het gesprek opnieuw te starten na een periode van stilte.",
        stopdes1: "Kies een echt en een nep profiel",
        stopdes2: "Stel een gebruikelijk bericht op",
        stopdes3: "Wacht 24 uur nadat de chatoperator op het bericht van het echte profiel heeft gereageerd",
        stopdes4: "Na 24 uur verandert het niet beantwoorde bericht van het echte profiel in een Follow-up Bericht, dat de operator zal opstellen en verzenden.",
        stopdes5: "Controleer uw statistieken in uw beheerdersdashboard",
        teaserdes: "Teaserberichten op een datingsite zijn cruciaal om de aandacht te trekken en de interesse van de ander te wekken. Het is belangrijk om authentiek te blijven, oprechte interesse te tonen en open vragen te stellen die een reactie aanmoedigen",
        teaserdes1: "Kies uit de profielen een echt en een nep profiel om een teaserbericht te sturen",
        teaserdes2: "Zorg ervoor dat u de juiste correspondentie taal kiest",
        teaserdes3: "Stel op het animatieplatform voor operators de teaserberichten op",
        teaserdes5: "Controleer uw statistieken in uw beheerdersdashboard",
        pokedes: "Poke-berichten voor een datingsite zijn korte en lichte berichten die zijn ontworpen om subtiel en boeiend de aandacht te trekken.",
        pokedes1: "Kies het land en de taal van de echte profielen waarmee je Poke-berichten wilt versturen",
        pokedes2: "Selecteer een of meerdere profielen waarmee je Poke-berichten wilt versturen",
        pokedes3: "Controleer je statistieken in je beheerdersdashboard",
        stopbla: "Lijst van al verzonden opvolgingsberichten",
        respVp: "Antwoord van het echte profiel",
        pasEn: "nog niet.",
        notifAccr: "Test Aanknopingsbericht: Profielkeuze succesvol gemaakt",
        notifPoke: "Test Poke Bericht: Profielkeuze succesvol gemaakt",
        pokeErr: "Klik op een of meer echte profielen",
        teaserErr: "klik op een nep en een echt profiel",
        reqGift : "Cadeaus aanvragen",
        reqGift1 : "Soort cadeau",
        reqGift2 : "Cadeau aanvraag",
    },

    { // es

        usrsd : "Lado del usuario",
        tmpp: "Política de Privacidad de TextModing®",
        lstmj: "Última actualización ",
        ppint: "En TextModing® damos una gran importancia a la privacidad y protección de los datos personales de nuestros visitantes. Esta política de privacidad describe las prácticas de TextModing® con respecto a la recopilación, uso, retención y protección de la información recopilada a través de nuestro formulario de contacto. Al utilizar este formulario, acepta los términos descritos en esta política.",
        pp1ttl: "Recopilación de Datos",
        pp2ttl: "Uso de los Datos",
        pp3ttl: "Compartición de los Datos",
        pp4ttl: "Retención de los Datos",
        pp5ttl: "Seguridad de los Datos",
        pp6ttl: "Sus Derechos",
        pp7ttl: "Modificaciones de la Política de Privacidad",
        pp8ttl: "Contacto",
        pp1int: "Cuando rellena nuestro formulario de contacto, recopilamos la siguiente información:",
        pp2int: "La información recopilada a través de nuestro formulario de contacto se utiliza exclusivamente para los siguientes fines:",
        pp3int: "TextModing® no vende, alquila ni divulga ninguna información personal recopilada a través del formulario de contacto a terceros, salvo en los siguientes casos:",
        pp4int: "La información proporcionada a través del formulario de contacto se conserva solo el tiempo necesario para responder a su solicitud o por el período que exija la legislación aplicable. Una vez cumplido el propósito, los datos se eliminan de forma segura.",
        pp5int: "TextModing® implementa medidas de seguridad técnicas y organizativas para proteger la información que comparte con nosotros. Sin embargo, aunque hacemos todo lo posible por asegurar su información, no podemos garantizar la seguridad absoluta de los datos transmitidos en línea.",
        pp6int: "De acuerdo con la legislación de protección de datos, usted tiene los siguientes derechos sobre sus datos personales:",
        pp7int: "TextModing® se reserva el derecho de actualizar esta política de privacidad para reflejar cambios en nuestras prácticas o para cumplir con los cambios legislativos. Cualquier modificación será publicada en esta página con la fecha de actualización. Le recomendamos que consulte esta política regularmente.",
        pp8int: "Para cualquier pregunta o solicitud relacionada con esta política de privacidad, póngase en contacto con nosotros en:",
        pp1out: "No se solicita ni recopila ninguna otra información personal a través del formulario de contacto.",
        pp2out: "",
        pp3out: "",
        pp4out: "",
        pp5out: "",
        pp6out: "Para ejercer sus derechos, puede ponerse en contacto con nosotros en la siguiente dirección:",
        pp7out: "",
        pp8out: "Al utilizar nuestro formulario de contacto, acepta esta política de privacidad.",
        pp1cont: "- Nombre de su empresa, dirección de su sitio web, dirección física de su empresa y su nombre: para poder identificarlo y personalizar nuestra respuesta.\n- Dirección de correo electrónico: para poder ponernos en contacto con usted en respuesta a su solicitud.\n- Asunto de su mensaje y su mensaje: para entender la naturaleza de su solicitud y responder adecuadamente.",
        pp2cont: "- Responder a su solicitud: Utilizamos su nombre, su dirección de correo electrónico y su mensaje para proporcionarle una respuesta personalizada.\n- Mejora de nuestros servicios: Podemos analizar de forma anónima preguntas o comentarios recurrentes para mejorar nuestro soporte y servicios.",
        pp3cont: "- Cumplimiento legal: si debemos compartir información en respuesta a una obligación legal o una solicitud de las autoridades competentes.\n- Protección de los derechos de TextModing®: para defender nuestros derechos en caso de disputa o para prevenir fraudes.",
        pp4cont: "",
        pp5cont: "",
        pp6cont: "- Derecho de acceso: puede solicitar consultar la información que hemos recopilado sobre usted.\n- Derecho de rectificación: puede solicitar la corrección de cualquier información incorrecta.\n- Derecho de supresión: puede solicitar la eliminación de sus datos personales cuando su conservación ya no sea necesaria.\n- Derecho de restricción: puede limitar el uso de sus datos en ciertos casos.",
        pp7cont: "",
        ppaccept1adm: "Al iniciar sesión en nuestra plataforma, confirma que ha leído nuestro",
        ppadm: "acuerdo de confidencialidad para administradores",
        ppaccept2adm: "de la plataforma TextModing® y que acepta libremente cumplir con él.",
        tmppadm: "Política de Privacidad para Administradores de TextModing®",
        ppintadm: "TextModing® se compromete a proteger la privacidad y seguridad de los datos personales de sus socios administradores y propietarios de sitios de citas. Esta política de privacidad describe nuestras prácticas sobre la recopilación, uso, almacenamiento y protección de los datos de los administradores que utilizan nuestra plataforma para gestionar sitios de citas. Al acceder a las funciones de TextModing®, acepta las prácticas descritas en esta política.",
        pp1ttladm: "Recopilación de Datos",
        pp2ttladm: "Uso de los Datos",
        pp3ttladm: "Compartición de los Datos",
        pp4ttladm: "Conservación de los Datos",
        pp5ttladm: "Seguridad de los Datos",
        pp6ttladm: "Derechos de los Administradores",
        pp7ttladm: "Cookies y Tecnologías Similares",
        pp8ttladm: "Modificaciones de la Política de Privacidad",
        pp9ttladm: "Contacto",
        pp1intadm: "Como parte de nuestra relación de asociación y para el uso de nuestra plataforma, podemos recopilar la siguiente información sobre los administradores de los sitios asociados:",
        pp2intadm: "Los datos recopilados se utilizan para los siguientes fines:",
        pp3intadm: "TextModing® no vende ni alquila los datos personales de los administradores y solo los comparte con terceros cuando es necesario para los siguientes fines:",
        pp4intadm: "Los datos personales de los administradores se conservarán durante el tiempo necesario para cumplir con los objetivos descritos en esta política o para cumplir con las obligaciones legales aplicables. Una vez que los datos ya no sean necesarios, se eliminarán de forma segura.",
        pp5intadm: "TextModing® aplica medidas de seguridad técnicas y organizativas estrictas para proteger los datos personales de los administradores contra accesos no autorizados, alteraciones, divulgación o destrucción. A pesar de nuestros esfuerzos, ninguna transmisión de datos a través de Internet es completamente segura; por lo tanto, tomamos todas las precauciones necesarias para minimizar los riesgos.",
        pp6intadm: "Los administradores tienen derechos específicos sobre su información personal, incluidos:",
        pp7intadm: "Para mejorar su experiencia de uso, utilizamos cookies y otras tecnologías similares para seguir las sesiones de inicio de sesión y personalizar la interfaz de la plataforma. Puede configurar los ajustes de su navegador para limitar el uso de estas cookies, pero esto podría afectar algunas funciones de la plataforma.",
        pp8intadm: "TextModing® se reserva el derecho de modificar esta política de privacidad para reflejar cambios en nuestras prácticas o para cumplir con las obligaciones legales. Cualquier modificación se publicará en esta página con una fecha de actualización. Le recomendamos que consulte esta política regularmente.",
        pp9intadm: "Para cualquier pregunta o consulta relacionada con esta política de privacidad, puede contactarnos a:",
        pp1outadm: "",
        pp2outadm: "",
        pp3outadm: "",
        pp4outadm: "",
        pp5outadm: "",
        pp6outadm: "Para ejercer estos derechos, póngase en contacto con nosotros en la siguiente dirección: admin@creativ-network.net",
        pp7outadm: "",
        pp8outadm: "",
        pp9outadm: "Al utilizar la plataforma TextModing®, los administradores aceptan esta política de privacidad y se comprometen a aplicarla dentro del marco de su uso de nuestros servicios.",
        pp1contadm: "- Información de identificación: nombre, apellido, nombre de la empresa, cargo, ID de administrador.\n- Información de contacto: dirección de correo electrónico profesional, número de teléfono.\n- Información de inicio de sesión: credenciales de cuenta para acceder a la plataforma TextModing®.\n- Datos de uso: historial de inicios de sesión, preferencias de uso e interacciones con las funcionalidades de la plataforma.\n- Datos financieros: detalles de facturación e información de pago, solo si son necesarios para las transacciones.",
        pp2contadm: "- Acceso y gestión de funcionalidades: permitir a los administradores acceder a las 15 principales funcionalidades de TextModing® (gestión de conversaciones, control de calidad, pago de operadores, gestión del registro de operadores, gestión de solicitudes de fotos, gestión de regalos virtuales, gestión de GIFs, gestión de tráfico de mensajes, gestión de notas, etc.).\n- Soporte y asistencia: proporcionar soporte técnico y asistencia personalizada en caso de problemas o necesidades de información adicional.\n- Mejora de nuestros servicios: analizar los datos de uso para mejorar las funcionalidades y la experiencia de la plataforma.\n- Facturación y seguimiento de pagos: procesar las transacciones y proporcionar documentación de facturación.\n- Seguridad: supervisar los inicios de sesión y actividades sospechosas para garantizar la seguridad de las cuentas de los administradores.",
        pp3contadm: "- Proveedores de servicios: Podemos contratar a proveedores de servicios para operaciones específicas (por ejemplo, alojamiento, gestión de pagos). Estos proveedores deben procesar sus datos de manera confidencial y segura.\n- Obligaciones legales: Podemos divulgar su información si es requerido por la ley o para cumplir con las solicitudes de las autoridades.\n- Protección de derechos: en caso de disputa, podemos compartir información en procedimientos legales para defender los derechos de TextModing®.",
        pp4contadm: "",
        pp5contadm: "",
        pp6contadm: "- Derecho de acceso: solicitar ver la información personal que poseemos.\n- Derecho de rectificación: solicitar la corrección de información incorrecta o desactualizada.\n- Derecho de supresión: solicitar la eliminación de su información personal en determinadas circunstancias.\n- Derecho de limitación del tratamiento: limitar el uso de sus datos en ciertos casos.",
        pp7contadm: "",
        pp8contadm: "",
        sccss: "Éxito",
        smthngwntwrng: "Se ha producido un error",
        cntcts: "Contactos",
        frmlrdcntct: "Formulario de contacto",
        ttr: "Título",
        mr: "Sr.",
        mme: "Sra.",
        mlle: "Srta.",
        sct: "Empresa",
        stwb: "Sitio web",
        addrss: "Dirección",
        prvncdprtm: "Provincia / Departamento",
        cdpstl: "Código postal",
        cntr: "País",
        prnmcntct: "Nombre del contacto",
        nmcntct: "Apellido del contacto",
        tlphn: "Teléfono",
        eml: "Correo electrónico",
        objdvtrcntct: "Asunto de su contacto",
        dmdpht: "Solicitud de demostración",
        prtnrt: "Asociación",
        affltn: "Afiliación",
        prsrdv: "Reserva de cita",
        dmdbrchr: "Solicitud de folleto",
        othr: "Otros",
        vtrmsg: "Su mensaje",
        attchmt: "Adjunto",
        pltqcnfdtxt: "Al enviar este formulario de contacto, declara que ha leído y comprendido nuestra",
        pltqcnfdtxt2: "política de privacidad",
        xcldrssttm: "claves para el éxito de TextModing®",
        cldrssttl1: "Costos asequibles",
        cldrssttl2: "Tecnología avanzada",
        cldrssttl3: "Adaptabilidad de la plataforma",
        cldrssttl4: "Eficiencia operativa",
        cldrssttl5: "Transparencia y comunicación",
        cldrssttl6: "Compromiso con la satisfacción del cliente",
        cldrsstxt1: "Ofrecemos servicios competitivos con una excelente relación calidad-precio, permitiendo a nuestros clientes optimizar sus presupuestos mientras se benefician de soluciones de calidad.",
        cldrsstxt2: "Aprovechamos tecnologías de vanguardia para asegurar un rendimiento robusto, mayor seguridad y una gestión fluida de las operaciones en la plataforma.",
        cldrsstxt3: "Brindamos flexibilidad que permite adaptar la plataforma a las necesidades específicas de cada cliente, garantizando así una respuesta personalizada y efectiva.",
        cldrsstxt4: "Maximizamos la eficiencia de los procesos internos para asegurar tiempos de implementación cortos y operaciones fluidas sin comprometer la calidad del servicio.",
        cldrsstxt5: "Mantenemos una comunicación clara y transparente con los clientes, ofreciendo visibilidad total sobre el rendimiento y los resultados de sus proyectos.",
        cldrsstxt6: "Colocamos la satisfacción del cliente en el centro de nuestras prioridades, implementando soluciones rápidas y estando constantemente atentos a las necesidades y comentarios de nuestros socios.",
        prdtstm: "Productos de TextModing®*",
        prdtttl1: "Interfaz de prueba",
        prdtttl2: "Tablero",
        prdtttl3: "Plataforma de participación",
        prdttxt1: "La interfaz de prueba de TextModing® permite a los clientes potenciales explorar y experimentar las funciones esenciales de la plataforma, incluyendo el envío de mensajes ordinarios, ganchos, pokes y seguimientos, facilitado por nuestros operadores de chat, para una inmersión completa en nuestro entorno interactivo.",
        prdttxt2: "El tablero de TextModing® ofrece a administradores, supervisores, controladores de calidad y contables una visión centralizada e intuitiva, facilitando la gestión en tiempo real de las operaciones, el monitoreo del rendimiento, la garantía de calidad y el seguimiento financiero de la plataforma.",
        prdttxt3: "La plataforma de participación de TextModing® ofrece a los operadores de chat un entorno interactivo optimizado para gestionar intercambios, crear conexiones atractivas y asegurar una interacción dinámica y efectiva entre los usuarios.",
        prdindpdts: "Los 3 productos son interdependientes",
        intrcteffcctprfrmnc: "“Interacción. Eficiencia. Rendimiento”",
        abtus: "Sobre nosotros",
        abtustxt: "TextModing® es una plataforma innovadora dedicada a la animación de sitios de citas a través de operadores profesionales. Ofrecemos servicios personalizados de moderación, gestión de contenido y participación para mejorar la experiencia de los usuarios en plataformas de citas. Gracias a nuestro equipo experimentado, garantizamos interacciones auténticas y atractivas que fomentan una experiencia de usuario de calidad.",
        rdswt: "Leer más",
        lnsdst: "Enlaces del sitio",
        lngdst: "Idioma del sitio",
        mnaddrss: "Dirección principal",
        rprsnttbrblg: "Representante de la oficina belga",
        ttdrtsrsrvs: "Todos los derechos reservados",
        apr: "Acerca de",
        ftrs: "Funciones",
        avntgs: "Ventajas",
        cldrsst: "Claves del éxito",
        nsprdts: "Nuestros productos",
        orpltfrm1: "Nuestra plataforma",
        orpltfrm2: "para la gestión de sitios de citas",
        orpltfrmtxt: "TextModing® es una plataforma potente diseñada para gestionar y optimizar sitios de citas online. Gracias a sus herramientas avanzadas y su interfaz intuitiva, permite gestionar eficazmente las interacciones entre usuarios, garantizando un alto nivel de compromiso.",
        flxqtdns: "Flujos diarios",
        rqstdmo: "Solicitar una demo",

        aprtxt1: "TextModing® es una plataforma innovadora dedicada a la gestión de sitios de citas por operadores profesionales. Ofrecemos servicios personalizados de moderación, gestión de contenidos y animación para mejorar la experiencia de los usuarios en las plataformas de citas. Con nuestro equipo experimentado, garantizamos interacciones auténticas y atractivas que favorecen una experiencia de usuario de alta calidad.",
        aprtxt2: "Nuestra plataforma permite a los operadores gestionar eficazmente las conversaciones, asegurar el control de calidad y supervisar el tráfico de mensajes. Con funciones avanzadas como la gestión de solicitudes de fotos, regalos virtuales y GIFs, así como la toma de notas para un seguimiento óptimo de las interacciones, TextModing® es una herramienta completa para sitios que desean ofrecer una experiencia interactiva y dinámica.",
        aprtxt3: "Nos comprometemos a ofrecer servicios de alta calidad con un equipo siempre disponible para apoyar las necesidades específicas de los sitios de citas y optimizar la participación de los miembros.",

        xftftm: "Funciones de TextModing®",

        ft1ttl: "Gestión de conversaciones",
        ft2ttl: "Gestión de agentes",
        ft3ttl: "Sala de emergencia",
        ft4ttl: "Gestión de notas",
        ft5ttl: "Mensajes de introducción",
        ft6ttl: "Mensajes Poke",
        ft7ttl: "Mensajes de seguimiento",
        ft8ttl: "Solicitudes de fotos",
        ft9ttl: "Reportes de agentes",
        ft10ttl: "Registro de actividades",
        ft11ttl: "Mensajes duplicados",
        ft12ttl: "Gestión de regalos",
        ft13ttl: "Supervisión y gestión del tráfico",
        ft14ttl: "Control de calidad",
        ft15ttl: "Contabilidad",

        ft1txt: "Permite a administradores, líderes de equipo y supervisores monitorear, controlar y analizar las interacciones en tiempo real, garantizando la calidad y fluidez de la comunicación en los sitios de citas.",
        ft2txt: "Permite a administradores, líderes de equipo y supervisores monitorear, guiar y evaluar el desempeño de los agentes en tiempo real, garantizando una gestión efectiva de los sitios de citas.",
        ft3txt: "Ofrece a administradores, líderes de equipo y supervisores un espacio seguro para gestionar emergencias, resolver incidentes críticos rápidamente y garantizar la continuidad operativa en los sitios de citas.",
        ft4txt: "Permite a administradores, líderes de equipo y supervisores crear, monitorear y compartir notas sobre las interacciones de los agentes, optimizando la coordinación y el seguimiento del desempeño.",
        ft5txt: "Permite a administradores, líderes de equipo y supervisores crear, personalizar y desplegar mensajes de introducción efectivos para optimizar el compromiso de los usuarios en los sitios de citas.",
        ft6txt: "Permite a administradores, líderes de equipo y supervisores configurar, enviar y analizar mensajes Poke estratégicos para reactivar el compromiso y fomentar la interacción de los usuarios.",
        ft7txt: "Permite a administradores, líderes de equipo y supervisores programar, personalizar y analizar mensajes de seguimiento para reactivar a los usuarios inactivos en el sitio.",
        ft8txt: "Permite a los administradores, jefes de equipo y supervisores supervisar, aprobar y gestionar las solicitudes de fotos de los operadores, garantizando la conformidad y calidad de los contenidos intercambiados.",
        ft9txt: "Permite a administradores, líderes de equipo y supervisores monitorear, revisar y manejar los reportes sobre los agentes, asegurando una moderación proactiva y un control de calidad óptimo.",
        ft10txt: "Permite a administradores, líderes de equipo y supervisores hacer seguimiento, registrar y analizar las notas de los operadores para optimizar el desempeño y la comunicación.",
        ft11txt: "Permite a administradores, líderes de equipo y supervisores identificar y gestionar los mensajes repetitivos para mejorar la calidad de las interacciones con los usuarios.",
        ft12txt: "Permite a administradores, líderes de equipo y supervisores supervisar y analizar el envío de contenido interactivo para reforzar el compromiso de los usuarios.",
        ft13txt: "Permite a administradores, líderes de equipo y supervisores analizar los flujos de mensajes para optimizar el desempeño y garantizar una comunicación fluida entre usuarios.",
        ft14txt: "Permite a administradores, líderes de equipo y supervisores evaluar las interacciones de los operadores para garantizar un servicio de alta calidad y la máxima satisfacción del cliente.",
        ft15txt: "Permite a administradores y contadores hacer seguimiento y gestionar las transacciones financieras, asegurando así transparencia y eficiencia en la gestión de los recursos.",

        nsecrr: "Contáctanos",

        cqdfftm: "Lo que diferencia a TextModing®",
        cqdffttl: "TextModing® se distingue de sus competidores por varias ventajas clave:",
        cqdffttl1: "Experiencia especializada",
        cqdffttl2: "Personalización y flexibilidad",
        cqdffttl3: "Soporte continuo y capacitación",
        cqdffttl4: "Innovación constante",

        cqdfftxt1: "TextModing® reúne a talentos altamente cualificados en la web, como operadores de chat, supervisores, controladores de calidad y gestores de tráfico, lo que garantiza una gestión óptima y profesional de los sitios de citas.",
        cqdfftxt2: "Gracias a nuestra plataforma, ofrecemos soluciones personalizadas adaptadas a las necesidades específicas de cada cliente, permitiendo una gestión más enfocada y eficaz.",
        cqdfftxt3: "TextModing® no solo ofrece servicios, sino que también invierte en la capacitación continua de su personal para garantizar una rápida adaptación a las nuevas tendencias y tecnologías, mientras brinda apoyo constante a nuestros socios para impulsar su crecimiento.",
        cqdfftxt4: "Al estar a la vanguardia de las últimas tecnologías y estrategias de animación, TextModing® asegura a sus clientes una ventaja competitiva duradera en el mercado en constante evolución.",
        btsd : "Lado del bot",
        nttkn : "Notas tomadas",
        mssdnt : "Notas perdidas",
        gfts : "Regalos",
        rqsts : "Solicitudes",
        messonhldallctd : "Mensaje en espera ya asignado",
        messonhldntallctd : "Mensaje en espera no asignado",
        totnummess : "Número total de mensajes",
        messpssdqc : "Mensajes aprobados en control de calidad",
        messntpssqc : "Mensajes no aprobados en control de calidad",
        paymnts : "Pagos",
        bnk : "Banco",
        ppl : "Paypal",
        ws : "Wise",
        yrsf : "Yoursafe",
        rçes: "Recibido",
        envoyees: "Enviado",
        dmndees: "Solicitado",
        nvaux: "Nuevo",

        adddest: "Dirección de correo electrónico del destinatario",

        cmpn: "Empresa",
        tmfld: "Carpeta Textmoding",
        accar: "Área de acceso",
        assvldt: "Validez de acceso",

        acc8: "Configuración de tarifas y bonos",

        mtng: "Reunión",
        rp: "Perfil real",
        fp: "Perfil falso",
        infopernoper: "Información permanente / no permanente",
        dltnt: "Borrar nota",
        dltmesspnc: "¿Realmente quieres borrar este mensaje de pánico?",
        convbnnd: "Conversación prohibida",
        suspconv: "Suspender la conversación",
        upldd: "Subido",
        addpht: "Añadir foto",
        phtsnttofp: "Lista de fotos enviadas a perfiles falsos",
        pkmesscrtd: "Mensaje Poke creado con éxito",
        updt: "Actualización",
        dltpkmess: "¿Realmente quieres borrar este mensaje poke?",
        plt: "Plataforma",
        dltdmd: "¿Realmente quieres borrar esta petición? ",
        agntinfrmd: "Agente informado",
        dlttsrmess: "¿Realmente desea borrar este mensaje de provocación?",
        updttsrmess: "Actualizar mensaje teaser",

        updtdsucc: "Actualizado con éxito",
        nonotif: "Sin notificación",
        continfo: "Información sobre el contenido",
        exp: "Remitente",
        recep: "Receptor",
        mdpupdtd: "Contraseña actualizada con éxito",
        opsusp: "Operador suspendido con éxito",
        opdec: "Operador desconectado con éxito",
        decop: "¿Realmente desea desconectar a este operador:  ",
        frop: "¿Realmente desea despedir a este operador:  ",
        chngmdp: "Cambiar contraseña",
        suspop: "¿Realmente quiere suspender a este operador: ",
        onhld: "En espera",
        supprmess: "Borrar este mensaje",
        messinfo: "Información del mensaje",
        vld: "Confirmar",
        ok: "Ok",
        enc: "En curso ...",

        updtmdp: "¿Desea cambiar la contraseña?",
        nwmdp: "Introduzca una nueva contraseña",
        dltsup: "¿Realmente desea borrar este supervisor?",
        ntsup: "Nota a un supervisor",
        nthr: "Nota aquí...",
        dltimg: "¿De verdad quieres borrar esta foto?",
        phttsnd: "Foto para enviar",
        vd: "Vacío (seleccione una foto)",
        addd: "Añadida",
        nwrdv: "Añadir nueva información",
        cnfrm: "Confirmar",
        dltrdv: "¿Realmente desea suprimir este Rendez-vous/ Reuniones/ Vacaciones?",
        addnonperminfo: "Añadir información no permanente",
        dltinfo: "¿Realmente desea eliminar esta información?",
        idd: "ID",
        addperminfo: "Añadir información permanente ",
        messreport: "Mensaje informado al supervisor",
        usrnm: "Nombre de usuario",

        pp11 : "Creativ'Network, una empresa de intermediación profesional para trabajos web, con sede en 71-75 Shelton Street, Covent Garden, Londres, WC2H 9JQ, y con oficina de representación en Roodborstjesstraat 4, Wondelgem 9032, en adelante denominada « la Sociedad »,",
        pp12 : "Y:",
        pp13 : "Trabajador autónomo, que, en virtud del contrato de Trabajador Autónomo que lo vincula con « La Sociedad » utiliza los materiales así como las propiedades intelectuales de esta última, en adelante denominado « el Trabajador Autónomo »,",
        pp14 : "En adelante denominados individualmente « la Parte » y colectivamente « las Partes »,",
        pp21 : "La Sociedad opera una plataforma de animación de sitios de citas de la que es la propietaria exclusiva. La gestión operativa de dicha plataforma está a cargo de operadores de chat, supervisores, líderes de equipo, controladores de calidad, empleados como trabajadores autónomos por la Sociedad.",
        pp22 : "En el marco de esta relación profesional, el Trabajador Autónomo tendrá acceso a información confidencial relacionada con la plataforma, los usuarios de los sitios de citas, los procesos operativos, así como cualquier otra información protegida que pertenezca a la Sociedad.",
        pp23 : "Dicho esto, se acuerda lo siguiente:",
        pp31 : "El presente acuerdo de confidencialidad tiene como objetivo proteger la información confidencial que la Sociedad pueda comunicar al Trabajador Autónomo en el marco de sus funciones relacionadas con la gestión de la plataforma de animación de sitios de citas.",
        pp41 : "A efectos del presente acuerdo, las « Información Confidencial » se refiere a toda la información, en cualquier forma (escrita, oral, electrónica, etc.), relacionada con la plataforma de animación de sitios de citas, los usuarios, las estrategias de desarrollo, los procesos técnicos y operativos, los datos financieros, así como cualquier otra información designada como confidencial por la Sociedad, que el Trabajador Autónomo pueda recibir o tener acceso en el marco de su trabajo.",
        pp51 : "El Trabajador Autónomo se compromete a:",
        pp52 : "- No divulgar, reproducir, transmitir o hacer accesible la Información Confidencial, en su totalidad o en parte, a terceros, sin la autorización escrita previa de la Sociedad.",
        pp53 : "- Utilizar la Información Confidencial únicamente en el contexto de las actividades relacionadas con la gestión de la plataforma y exclusivamente en beneficio de la Sociedad.",
        pp54 : "- Tomar todas las medidas necesarias para proteger la confidencialidad de la Información Confidencial y evitar cualquier uso no autorizado.",
        pp55 : "- Devolver a la Sociedad, a solicitud, cualquier documento o material que contenga Información Confidencial, sin conservar copias de ningún tipo.",
        pp61 : "El presente acuerdo entra en vigor en el momento en que el trabajador acepte durante su identificación para acceder a la plataforma de animación y permanecerá en vigor durante toda la duración de la relación profesional entre la Sociedad y el Trabajador Autónomo. Las obligaciones de confidencialidad continuarán durante un período de 10 años después de la finalización de dicha relación, cualquiera que sea la causa.",
        pp71 : "No se consideran Información Confidencial:",
        pp72 : "- La información que ya era conocida por el público en el momento de su comunicación al Trabajador Autónomo, o que se haga pública de otra manera que no sea por violación de este acuerdo.",
        pp73 : "- La información que el Trabajador Autónomo pueda demostrar que ha recibido legítimamente de un tercero no vinculado por una obligación de confidencialidad.",
        pp74 : "- La información desarrollada de manera independiente por el Trabajador Autónomo sin utilizar la Información Confidencial.",
        pp81 : "En caso de incumplimiento de las obligaciones previstas en el presente acuerdo, el Trabajador Autónomo reconoce que la Sociedad podrá emprender todas las acciones legales necesarias para proteger sus derechos y obtener compensación por los daños sufridos.",
        pp91 : "El presente acuerdo se rige por la legislación belga. En caso de disputa relativa a la interpretación o ejecución del presente acuerdo, las Partes se comprometen a buscar una solución amistosa. En caso de falta de acuerdo amistoso, los tribunales competentes de Gante serán los únicos competentes.",
        pp1 : "Entre las Partes",
        pp2 : "Preámbulo",
        pp3 : "Artículo 1: Objeto",
        pp4 : "Artículo 2: Definición de Información Confidencial",
        pp5 : "Artículo 3: Obligaciones del Trabajador Autónomo",
        pp6 : "Artículo 4: Duración",
        pp7 : "Artículo 5: Exclusiones",
        pp8 : "Artículo 6: Disposiciones Generales",
        pp9 : "Artículo 7: Ley Aplicable y Jurisdicción Competente",

        paymail: 'Dirección de correo electrónico de pago',
        scc: 'Creado',
        italian: 'Italiano',
        mail:'Correo electrónico',
        dutch: 'Holandés',
        spanish: 'Español',
        german: 'Alemán',
        convonhold: "Conversaciones en espera",
        supdash: "Cuadro de mando del supervisor",
        choverv: "Visión general del chat",
        login: 'Inicio de sesión',
        password: 'Contraseña',

        qlogout: '¿Quieres cerrar sesión?',
        editNote:'Editar Nota',
        annuler:'Cancelar',
        addnote: 'Nota añadida',
        logout: 'Cerrar sesión',
        editer:'Editar',
        yes: 'Sí',
        notesuppr:'Nota borrada',
        no: 'No',
        operatorpage: "Página de operadores",
        supervisorpage: "Página de los supervisores",
        adminpage: "Página de administradores",

        required: "Este campo es obligatorio",
        invalid: "Correo electrónico no válido",
        registered: "Esta dirección de correo electrónico no está registrada",
        incorrectpass: "Contraseña incorrecta",
        atleast: "La contraseña debe contener al menos",
        characters: "caracteres",

        convman: "Gestión de conversaciones",
        agman: "Gestión de agentes",
        notesman: "Gestión de notas",
        agrep: "Informes de agentes",
        gftgtfman: "Gestión de regalos y gifs",
        tmfldaa: "Permiso de acceso a la carpeta TextModing",
        hlp: "Ayuda",
        pltflng: "Lenguaje de plataforma",
        pr: "Solicitudes de fotos",
        wlcm: "Bienvenido",
        wlcmtxt: "TextModing es una plataforma de animación diseñada para sitios de citas, que ofrece funciones esenciales para la gestión de conversaciones y agentes. Mejora la eficiencia y la rentabilidad de los socios proporcionando herramientas que agilizan las interacciones y optimizan el compromiso de los usuarios.",

        messageto: 'Mensaje para',
        convatt: 'Conversación en espera',
        mincharlen: 'Al menos 120 caracteres',
        char: 'carácter',
        chars: 'caracteres',
        pj: 'Foto adjunta',
        writemessage: 'Escribe un mensaje ...',
        picalreadysent: 'Foto vista por el usuario',
        gifpj: 'GIFS',
        opengif: 'Abrir GIF',
        sendMessage: 'Enviar el mensaje',
        reportSup: 'Enviar una nota al Supervisor',
        conv: 'ID de conversación',
        say: 'Dice',
        timeIntro: 'en',
        elements: 'elementos',
        element: 'elemento',
        none: 'Ninguno',

        stat: 'Estadísticas',
        french: 'Francés',
        english: 'Inglés',
        botprofil: 'Perfil del Bot',
        userprofil: "Perfil de usuario",
        otherInfo: 'Otras informaciones importantes',
        codepost: 'Código postal',
        ville: 'Ciudad',
        region: 'Región',
        pays: 'País',
        taille: 'Talla',
        eyes: 'Color de ojos',
        hair: 'Color de pelo',
        morph: 'Morfología',
        situation: 'Estado civil',
        searchfor: 'Buscar',
        job: 'Ocupación',
        signe: 'Signos distintivos',
        descri: 'Descripción',
        infosupp: 'Información adicional',

        supervisiontchat: 'Supervisión del chat',
        statconvop: 'Conversación de estadísticas y operadores',
        convs: 'Conversaciones',
        report: 'Informe',
        agentInform:'Agente informado',
        events: 'Evento',
        photosrequests: 'Solicitud de fotos',
        warmup: 'Calentamiento',
        gifattch: 'Gif adjunto',
        fakeprofiles: 'Perfiles falsos',
        pagenotfound: 'Página no encontrada',

        suivvraiprof: 'Supervisión de perfiles reales',
        suiviop: 'Monitorización de operadores',
        admintion: 'Administración',
        affiliation : 'Afiliación',

        operator: 'Operador',
        supervisor: 'Supervisor',
        administrator: "Administrador",

        week: 'semana',
        weeks: 'semanas',
        today: 'Hoy',

        start: 'Inicio',
        end: 'Fin',
        platform: 'Plataformas',
        search: 'Búsqueda',
        go: 'Ir',
        allmasc: 'Todos',
        allfem: 'Todos',

        id: 'Identificador',
        opname: 'Nombre del operador',
        sentmess: 'Mensajes enviados',
        recmess: 'Mensajes recibidos',
        relmess: 'Mensajes reclamados',

        date: 'Fecha',
        messid: 'Identificador del mensaje',
        convid: 'Identificador de conversación',
        exped: 'Remitente',
        recpt: 'Receptor',
        opsign: 'Operador a informar',
        opexped: "Operador que envía",
        action: 'Acción',
        messs: 'Mensajes',
        filterdate: 'Filtrar búsquedas entre dos fechas',
        filtermess: 'Filtrar conversación por mensaje',
        from: 'De',
        to: 'to',
        eventList: 'Lista de eventos',
        num: 'Número',
        eventSearch: 'Buscar un evento',
        actions: 'Acciones',

        eventname: 'Nombre del evento',
        eventdate: 'Fecha del evento',
        uploadimage: "Subir imágenes",
        role: "Rol",
        logIn: "Iniciar sesión",
        ppaccept1: "Al iniciar sesión en nuestra plataforma, confirmas que has leído nuestra",
        ppaccept2: "y ha recibido la formación adecuada para llevar a cabo su tarea",
        pp: "acuerdo de confidencialidad",
        copyright: "© 2023-2024 Copyright TextModing® - Todos los derechos reservados",
        accountant: "Contable",
        qualitycontroller: "Controlador de calidad",

        adminid: "ID de administrador",
        accountantid: "ID del contable",
        operatorid: "ID de operador",
        qcid: "ID de controlador de calidad",
        supervisorid: "ID de supervisor", 
        confirmpass:'Confirma la contraseña',
        alreadyreg:'¿Ya está registrado? conectarse',
        notifs: "Notificaciones",
        limite:'Limitado',
        save:'Guardar',
        archives:'Archivos',
        ttlmsg:'Total de Mensajes',
        contorlLog:'Login del Controlador',
        nbrmess:'Número de mensajes',
        appQC:'Apreciaciones del QC',
        esqmq:'Esquiva faltante',
        dbspace:'Doble espacio',
        notelu:'Nota leída',
        actpris:'Acción tomada',
        ferme:'Cerrado',
        losn:'Lista de notas enviadas',
        lorn:'Lista de notas recibidas',
        validity:'Validez',
        editInfo:'Modificar una información',
        controlID:'Controlador de ID',
        statcontrolct:'Estadísticas de control por controlador',
        statcontrolag:'Estadísticas de control por Agente',
        statcontrol:'Estadísticas de control',
        traitement:'Tratamiento',
        agentid:'ID de agente',
        statconvatt:'Estadísticas de Conversaciones en Espera',
        propesqu:'Ingrese una excusa para una solicitud de cita',
        msgacc:'Por favor, redacte un mensaje atractivo',
        msgpoke:'Por favor, escribe un mensaje Poke',
        heure:'Hora',
        esqv:'Esquivar',
        esquive:'Evasiones encuentros y citas',
        other:'Otros',
        sexe:'Sexo',
        bannir:'Desterrar',
        deconnecter:'Desconectar',
        agntLgn:'Agente en línea',
        features:'Características',
        rdv:'Cita',
        holiday:'Vacaciones',
        valider:'Validar',
        panicmsg:'¿este mensaje de pánico?',
        jour:'Día',
        jours:'Días',
        conversastion:'Conversación',
        profil:'Perfil',
        suspendre:'Suspender',
        expulser:'Expulsar',
        information:'Información',
        meeting:'Reunión',
        online:'En línea',
        treated:'Tratado',
        onHold:'En espera',
        listPhotoSend:'Lista de fotos enviadas',
        agentReport:'Agente reportado',
        suspendu:'Suspendido',
        raison:'Razón',
        etat:'Estado',
        actqc4:'Mensaje validado',
        panicmess:'Mensaje de pánico',
        contenu:'Contenido',
        reportmess:'Reportar un mensaje',
        language1:'Lengua',
        reg:'Inscribirse',
        enreg:['Todavía no estas registrado?','Registro'],
        passdiff:'Contraseña diferente',
        months: ["enero", "febrero", "marzo", "abril", "mayo", "junio", "julio", "agosto", "septiembre", "octubre", "noviembre", "diciembre"],
        year: 'Año',
        month: 'Mes',
        startingdate: 'Fecha de inicio',
        closingdate: 'Fecha de cierre',

        agents: "Agentes",
        out: "Salidas",
        in: "Entradas",
        details: "Detalles",
        panicroom: "Sala de pánico",
        total: "Total",
        notes: "Notas",
        teasermessages: "Mensajes teaser",
        pokemessages: "Mensajes Poke",
        attdisc:'Esperando Discusión',
        gifajt:'Gif Añadir',
        stopmessages: "Mensajes de parada",
        sent: "Enviado",
        asnwered: "Contestado",
        requested: "Solicitado",
        logbook: "Bitácora",
        recorded: "Grabado",
        notrecorded: "No registrado",
        duplicatedmessages: "Mensajes duplicados",
        back: "Volver",
        suty: "Tipo de suscripción",
        ag: "Edad",
        gend: "Sexo",
        sexor: "Orientación sexual",
        tow: "Ciudad",
        
        users: "Usuarios",
        sender: "Remitente",
        receiver: "Receptor",
        agent: "Agente",
        status: "Estado",
        read: "Lectura",
        active: "Activo",
        edit: "Modificar",
        user: "Usuario",
        sendnote: "Enviar una nota",
        oh: "Esperando",
        mess: "Mensaje",
        trconv: "Conversaciones tratadas",
        idconv: "ID de conversación",
        idmess: "ID de mensaje",
        otherimp: "Otras informaciones importantes",
        otherimp1: "Reunión/ Encuentros/ Vacaciones",
        otherimp2: "Informaciones permanentes",
        otherimp3: "Informaciones útiles, no permanentes",
        
        aglist: "Lista de agentes",
        pfsperf: "Rendimiento de la plataforma",
        agperf: "Rendimiento de los agentes",
        nwag: "Añadir nuevos agentes",
        agname: "Nombre del agente",
        agcountry: "País del agente",
        aglogin: "Inicio de sesión del agente",
        agloginpw: "Contraseña del agente",
        langpf: "Plataforma lingüística",
        onl: "En línea",
        offl: "Fuera de línea",
        suspend: "Suspender",
        koff: "Reenviar",
        grotot: "Total bruto",
        agnumbewoon: "Número de perfiles falsos",
        totsenmess: "Total de mensajes enviados",
        totrecmess: "Total de mensajes recibidos",
        totrep: "Total de informes",
        real: "Nombre real",
        prov: "Provincia",
        weight: "Peso",
        child: "Niños",
        time: "Hora",
        fstnm: "Nombre",
        lstnm: "Apellido",
        dtofbir: "Fecha de nacimiento",
        skypeid: "ID de Skype",
        crnwag: "Crear nuevo agente",
        usrprfl: "Perfil de usuario",
        sndmess: "Enviar mensaje",
        blckusr: "Bloquear usuario",
        lstnts: "Lista de notas",
        crtsndnts: "Crear / Enviar una nota",
        evnt: "Evento",
        ntid: "ID de nota",
        nt: "Nota",
        dlt: "Borrar",
        cndid: "ID del candidato",
        language: "Idioma",
        perc1: "Ha compuesto el ",
        perc2: " del mensaje del usuario",
        giftsatt: "Regalos adjuntos",
        sndnt: "Enviar la nota",
        lsttsrmsg: "Lista de mensajes teaser",
        crttsrmsg: "Crear mensajes teaser",
        idtsrmsg: "ID del mensaje teaser",
        tsrmsg: "Teaser message",
        dlvrdt: "Entregado a",
        wiseg:"Gifs sabios",
        sexg:"Gifs Sexuales",
        gayg:"Gifs Gays",
        Lesbg: "Lesbian Gifs",
         transg:"Transgender Gifs",
        travg:"Travesti Gifs",
                
        bdsm:"BDSM",
        dont: "No tardes",
        blj:"Mamada",
        org:"Orgasmo",
        vagpen:"Penetración vaginal",
        sod:"Sodomía",
        titss:"Tetas",
        
        bdsmg: "BDSM Gay",
        wisegg: "Wise Gay Gifs",
        sexgg:"Sexo Gay Gifs",
        
        bdsml: "BDSM Lesbiana",
        wiselg:"Wise Lesbian Gifs",
        sexlg:"Sex Lesbian Gifs",
        
        maletrans:"Male transgender",
        femaletrans:"Female transgender",
        lstpkmsg: "Lista de mensajes poke",
        crtpkmsg: "Crear mensaje poke",
        idpkmsg: "ID mensaje poke",
        pkmsg: "Mensaje poke",
        lststpmsg: "Lista de mensajes de parada",
        idstpmsg: "ID de mensaje de parada",
        stpmsg: "Mensaje de parada",
        sntto: "Enviado a",
        agntwrtngstp: "Agente que escribe la parada",
        dtofdlvr: "Fecha de entrega",
        dtofans: "Fecha de respuesta",
        stpperfanal: "Detener análisis de rendimiento",
        stpgrssttl: "Parar total bruto",
        rsptostp: "Respuesta a la parada",
        rsptostp2: "Respuesta al Gancho",
        rsptostp3: "Respuesta al Poke",
        stpprcntresp: "% de respuesta a la parada",
        avrgrsptm : "Tiempo medio de respuesta",

        lstphtrq: "Solicitud de lista de fotos",
        phttp: "Tipo de foto",
        idrq: "ID de la solicitud",
        pht: "Foto",
        upld: "Subir",
        snd: "Enviar",
        lstrprt: "Lista de informes",
        rpttp: "Tipo de informe",
        idrprt: "ID del informe",
        msgrprtd: "Mensaje informado",
        infrmagnt: "Informar al agente",
        lstlgbk: "Lista del libro de registro",
        lstmssnglgbk: "Lista del libro de registro que falta",
        lgbktp: "Tipo de libro de registro",
        idlgbk: "ID del libro de registro",
        infs: "Información",
        lvlofimprt: "Nivel de importancia",
        prmnnt: "Permanente",
        ntprmnnt: "No permanente",
        mssnglgbk: "Falta el libro de registro",
        mrklgbk: "Marcar libro de registro",

        dh: "Fecha y hora",
        mid: "ID del mensaje",
        repmess: "Informar de este mensaje",
        bts: "Bot dice",
        obj: "Objeto",
        plc: "Lugar",

        lstdplmss: "Lista de mensajes duplicados",
        iddplmss: "ID de mensajes duplicados",
        lstgfs: "Lista de GIFs",
        ddgfs: "Añadir nuevo GIF",
        gftp: "Tipo de GIF",
        gfimg: "Imagen GIF",

        nat: "Naturaleza",
        tmslt: "Franja horaria",
        gfsnt: "GIFs enviados",

        qcdash: "Cuadro de mando del controlador de calidad",
        qctrl: "Control de calidad",
        lstmess: "Lista de mensajes",
        qcpnl: "Panel de control de calidad",
        ptreq: "Solicitar foto",
        sp: "Enviar foto",
        sgft: "Enviar regalo",
        sgif: "Enviar Gif",
        qccrit: "Criterios de control de calidad",
        qccrit1: "Buen Chat",
        qccrit2: "Chat malo",
        qccrit3: "Perfil de usuario considerado",
        qccrit4: "Perfil de bot considerado",
        qccrit5: "Conversación iniciada por el bot",
        qccrit6: "CTA por el agente",
        qccrit7: "Conversación sin sentido",
        qccrit8: "Usuario descontento con el bot",
        qccrit9:'Nota bien tomada',
        actqc1: "Informar al agente",
        actqc2: "Suspender al agente",
        actqc3: "Despedir al agente",
        adc1:'Agente excelente',
        adc2:'Agente medio',
        adc3:'Agente mediocre',
        sndmailtoadm: "Enviar un correo electrónico al administrador",
        yrnm: "Tu nombre",
        yrcmpnm: "Tu nombre de empresa",
        yrmladdr: "Su dirección de correo electrónico",
        yradmid: "Su ID de administrador",
        objctfyrrqst: "Objeto de su solicitud",
        yrmss: "Su mensaje",
        yrattchmt: "Archivo adjunto",
        sndthml: "Enviar el correo electrónico",
        ddnwadm: "Añadir nuevo admin",
        nm: "Nombre",
        mladd: "Dirección de correo electrónico",
        cmpnnm: "Nombre de la empresa",
        tmfldr: "Carpeta TextModing",
        psswrd: "Contraseña",
        accssar: "Áreas de acceso",
        valdt: "Validez",
        rgstr: "Registro",

        accdash: "Cuadro de mando contable",
        acc: "Cuentas",
        acc1: "Estadísticas del agente",
        acc2: "Pago por transferencia bancaria",
        acc3: "Pago por Paypal",
        acc4: "Pago por wise",
        acc5: "Pago por Yoursafe",
        acc6: "Añadir un contable",

        admdash: "Admin dashboard",
        chttlov: "Visión general de Chattool",
        suptraffman: "Supervisión y gestión del tráfico",
        vw: "Ver",
        
        ratepermess: "Tarifa por mensaje",
        bonus: "Bonificación",
        totpay: "Pago total",
        paymeth: "Forma de pago",
        grosstot: "Total bruto a mes de ",
        prstat: "Imprimir el extracto",
        agfn: "Nombre del agente",
        agln: "Apellido del agente",
        payadd: "Dirección de correo electrónico de Paypal",
        nysent: "Aún no enviado",
        wiseadd: "Dirección de correo electrónico de Wise",
        yoursafeadd: "Dirección de correo electrónico de Yoursafe",
        bankn: "Nombre del banco",
        iban: "IBAN",
        bic: "Código BIC",

        lstsup: "Lista de supervisores",
        ddnwsup: "Añadir un nuevo supervisor",
        trffcmng: "Gestión de tráfico",
        supnm: "Nombre del supervisor",
        suplgn: "Conexión del supervisor",
        suplgnpsswrd: "Contraseña de conexión del supervisor",
        idcnddt: "ID del candidato",
        cntry: "País",
        lgn: "Conexión",
        crtnwsup: "Crear un nuevo supervisor",
        fragntonln: "Agente libre en línea",
        msgalltag: "Mensaje asignado al agente",
        allct: "Asignar",
        addqc: "Añadir controlador de calidad",
        crtqc: "Crear controlador de calidad",
        crtnwacc: "Crear un nuevo contable",

        gifn: "GIFs para enviar",
        restfn: "Restablecer",
        gifsel: "Vacío (seleccionar GIFs)",
        alerttxt: "GIFs enviados con éxito.",
        option: "opciones",

        phalert: "Solicitud de foto enviada.",
        phtitre: "Solicitud de foto",
        phlabel: "Tipo de foto",
        phsend: "Enviar",
        phload: "Cargando...",

        delalert: "Suprimido",
        deltitre1: "¿Realmente quieres eliminar ",
        deltitre2: "esta solicitud ?",
        delauteur: "Autor",
        deldesc: "Descripción",
        delaccept: "Sí",
        delrefuse: "No",
        delload: "Cargar..",

        lstqc: "Lista de controladores de calidad",
        qcnm: "Nombre del controlador de calidad",
        qclgn: "Inicio de sesión del controlador de calidad",
        qclgnpsswrd: "Contraseña del controlador de calidad",
        lstacc: "Lista de contables",
        accnm: "Nombre del contable",
        acclgn: "Nombre de usuario del contable",
        acclgnpsswrd: "Contraseña del contable",

        biev: "Bienvenido / Bienvenida",
        selecrt: "Interfaz de envío de mensajes",
        chxha: "Elige un perfil verdadero y uno falso",
        chxF: "Lista de perfiles reales",
        chxV: "Lista de perfiles falsos",
        nomd: "Nombre",
        langs: "Lengua",
        errdj: "Selecciona un perfil verdadero y uno falso",
        plch: "Introduce tu mensaje",
        pokePh: "Mensajes Poke (aún no enviados)",
        teaserPh: "Lista de mensajes de enganche (aún no enviados)",
        inter: "Interfaz de prueba de TextModing",
        interSel: "Por favor, seleccione las tareas que desea realizar",
        des1: "Envío de un mensaje personalizado a un perfil.",
        des2: "Envío de un mensaje para captar la atención del perfil.",
        des3: "Envío personalizado de mensajes atractivos a nuevos inscritos para iniciar conversaciones.",
        des4: "Envío de un mensaje para reiniciar la conversación.",
        tit1: "Enviar mensaje",
        tit2: "Mensaje de gancho",
        tit3: "Mensaje poke",
        tit4: "Mensaje de parada",
        tache: "Tarea",
        bienvTest: "Bienvenido a la interfaz de prueba de",
        enterEP: "Introduce tu correo electrónico y contraseña",
        instTest: "Instrucciones",
        sendInt: "Interfaz de envío",
        unPlP: "Elija uno o más perfiles reales.",
        unNouv: "Nueva",
        unReç: "Recibidas",
        unNouv1: "Nuevo",
        payTot: "Pago total",
        allou: "Ya asignados",
        Nallou: "Aún no asignados",
        totSoum: "Mensaje total",
        Soum: "Enviado a QC",
        nonSoum: "No enviado a QC",
        boot: "Boot",
        rvr: "Citas/Reuniones/Vacaciones",
        banit: "Prohibido",
        suspt: "Suspendido",
        aucunt: "Ningún mensaje",
        msgstop: "Por favor redacte un mensaje de seguimiento",
        messrt: "Tasa de mensajes",
        pkmessrt: "Tasa de mensajes poke",
        tsrmessrt: "Tasa de mensajes teaser",
        stpmessrt: "Tasa de mensajes de stop",
        bnsrt: "Tasa de bonificación",
        mesdes: "Enviar mensajes en un sitio de citas es crucial para captar la atención y despertar el interés de la otra persona. Lo esencial es ser auténtico, mostrar un interés genuino y hacer preguntas abiertas que fomenten una respuesta.",
        mesdes1: "Entre los perfiles, elige uno real y uno falso para enviar un mensaje.",
        mesdes2: "Asegúrese de elegir el idioma de correspondencia adecuado.",
        mesdes3: "Redacta tu mensaje de prueba.",
        mesdes4: "Tienes 5 minutos para responder al mensaje en la plataforma de animación para operadores.",
        mesdes5: "Verifica tus estadísticas en tu panel de administración.",
        stopdes: "Los mensajes de seguimiento en un sitio de citas están destinados a reavivar el interés y reactivar la conversación después de un período de silencio.",
        stopdes1: "Elige un perfil real y uno falso",
        stopdes2: "Redacta un mensaje habitual",
        stopdes3: "Espera 24 horas después de que el operador de chat haya respondido al mensaje del perfil real",
        stopdes4: "Después de 24 horas, el mensaje no respondido por el perfil real se convierte en un Mensaje de seguimiento, que el operador se encargará de redactar y enviar.",
        stopdes5: "Verifica tus estadísticas en tu panel de administración",
        teaserdes: "Los mensajes de atracción en un sitio de citas son cruciales para captar la atención y despertar el interés de la otra persona. Lo esencial es ser auténtico, mostrar un interés genuino y hacer preguntas abiertas que fomenten una respuesta",
        teaserdes1: "Entre los perfiles, elige uno real y uno falso para enviar un mensaje de atracción",
        teaserdes2: "Asegúrate de elegir el idioma de correspondencia adecuado",
        teaserdes3: "En la plataforma de animación para operadores, redacta los mensajes de atracción",
        teaserdes5: "Verifica tus estadísticas en tu panel de administración",
        pokedes: "Los mensajes 'Poke' en un sitio de citas son mensajes cortos y ligeros, diseñados para captar la atención de manera sutil y atractiva.",
        pokedes1: "Elige el país y el idioma de los perfiles reales con los que deseas enviar mensajes Poke",
        pokedes2: "Selecciona uno o varios perfiles con los que deseas enviar mensajes Poke",
        pokedes3: "Verifica tus estadísticas en tu panel de administración",
        stopbla: "Lista de mensajes de seguimiento ya enviados",
        respVp: "Respuesta del perfil real",
        pasEn: "aún no.",
        notifAccr: "Mensaje de enganche de prueba: Selección de perfil realizada con éxito",
        notifPoke: "Mensaje Poke de prueba: Selección de perfil realizada con éxito",
        pokeErr: "Haz clic en uno o más perfiles reales",
        teaserErr: "haz clic en un perfil falso y uno real",
        reqGift : "Solicitar regalos",
        reqGift1 : "Tipo de regalo",
        reqGift2 : "Solicitud de regalo",
    },

    { // de

        usrsd : "Benutzerseite",
        tmpp: "TextModing® Datenschutzrichtlinie",
        lstmj: "Letzte Aktualisierung ",
        ppint: "Bei TextModing® legen wir großen Wert auf die Privatsphäre und den Schutz der persönlichen Daten unserer Besucher. Diese Datenschutzrichtlinie beschreibt die Praktiken von TextModing® hinsichtlich der Erhebung, Nutzung, Aufbewahrung und des Schutzes von Informationen, die über unser Kontaktformular gesammelt werden. Durch die Nutzung dieses Formulars stimmen Sie den in dieser Richtlinie beschriebenen Bedingungen zu.",
        pp1ttl: "Datenerhebung",
        pp2ttl: "Datenverwendung",
        pp3ttl: "Datenweitergabe",
        pp4ttl: "Datenaufbewahrung",
        pp5ttl: "Datensicherheit",
        pp6ttl: "Ihre Rechte",
        pp7ttl: "Änderungen der Datenschutzrichtlinie",
        pp8ttl: "Kontakt",
        pp1int: "Wenn Sie unser Kontaktformular ausfüllen, erheben wir folgende Informationen:",
        pp2int: "Die über unser Kontaktformular gesammelten Informationen werden ausschließlich für die folgenden Zwecke verwendet:",
        pp3int: "TextModing® verkauft, vermietet oder gibt keine über das Kontaktformular gesammelten persönlichen Informationen an Dritte weiter, außer in den folgenden Fällen:",
        pp4int: "Die über das Kontaktformular bereitgestellten Informationen werden nur so lange gespeichert, wie es für die Beantwortung Ihrer Anfrage erforderlich ist oder wie es die geltende Gesetzgebung vorschreibt. Sobald der Zweck erfüllt ist, werden die Daten sicher gelöscht.",
        pp5int: "TextModing® setzt technische und organisatorische Sicherheitsmaßnahmen um, um die Informationen, die Sie mit uns teilen, zu schützen. Obwohl wir unser Bestes tun, um Ihre Informationen zu sichern, können wir die absolute Sicherheit der online übertragenen Daten nicht garantieren.",
        pp6int: "Nach den Datenschutzgesetzen haben Sie folgende Rechte in Bezug auf Ihre persönlichen Daten:",
        pp7int: "TextModing® behält sich das Recht vor, diese Datenschutzrichtlinie zu aktualisieren, um Änderungen in unseren Praktiken widerzuspiegeln oder um gesetzlichen Anforderungen zu entsprechen. Alle Änderungen werden auf dieser Seite mit dem Aktualisierungsdatum veröffentlicht. Wir empfehlen Ihnen, diese Richtlinie regelmäßig zu überprüfen.",
        pp8int: "Für Fragen oder Anfragen zu dieser Datenschutzrichtlinie können Sie uns unter folgender Adresse kontaktieren:",
        pp1out: "Es werden keine weiteren persönlichen Daten über das Kontaktformular angefordert oder gesammelt.",
        pp2out: "",
        pp3out: "",
        pp4out: "",
        pp5out: "",
        pp6out: "Um Ihre Rechte auszuüben, können Sie uns unter folgender Adresse kontaktieren:",
        pp7out: "",
        pp8out: "Durch die Nutzung unseres Kontaktformulars stimmen Sie dieser Datenschutzrichtlinie zu.",
        pp1cont: "- Name Ihres Unternehmens, Adresse Ihrer Website, physische Adresse Ihres Unternehmens und Ihr Name: um Sie zu identifizieren und unsere Antwort zu personalisieren.\n- E-Mail-Adresse: um Sie als Antwort auf Ihre Anfrage zu kontaktieren.\n- Betreff Ihrer Nachricht und Ihre Nachricht: um die Natur Ihrer Anfrage zu verstehen und entsprechend zu antworten.",
        pp2cont: "- Beantwortung Ihrer Anfrage: Wir verwenden Ihren Namen, Ihre E-Mail-Adresse und Ihre Nachricht, um Ihnen eine personalisierte Antwort zu geben.\n- Verbesserung unserer Dienste: Wir können wiederkehrende Fragen oder Kommentare anonym analysieren, um unseren Support und unsere Dienste zu verbessern.",
        pp3cont: "- Rechtliche Verpflichtung: Wenn wir Informationen aufgrund einer gesetzlichen Verpflichtung oder einer Anfrage von zuständigen Behörden weitergeben müssen.\n- Schutz der Rechte von TextModing®: Zur Verteidigung unserer Rechte im Falle eines Rechtsstreits oder zur Verhinderung von Betrug.",
        pp4cont: "",
        pp5cont: "",
        pp6cont: "- Auskunftsrecht: Sie können anfordern, die Informationen einzusehen, die wir über Sie gesammelt haben.\n- Berichtigungsrecht: Sie können die Korrektur von ungenauen Informationen verlangen.\n- Recht auf Löschung: Sie können die Löschung Ihrer persönlichen Daten verlangen, wenn deren Aufbewahrung nicht mehr erforderlich ist.\n- Recht auf Einschränkung: Sie können die Nutzung Ihrer Daten in bestimmten Fällen einschränken.",
        pp7cont: "",
        ppaccept1adm: "Indem Sie sich in unsere Plattform einloggen, bestätigen Sie, dass Sie unsere",
        ppadm: "Vertraulichkeitserklärung für Administratoren",
        ppaccept2adm: "der TextModing®-Plattform gelesen haben und ihr freiwillig zustimmen.",
        tmppadm: "Datenschutzrichtlinie für Administratoren von TextModing®",
        ppintadm: "TextModing® verpflichtet sich, die Privatsphäre und Sicherheit der persönlichen Daten seiner Partner-Administratoren und Betreiber von Dating-Websites zu schützen. Diese Datenschutzrichtlinie beschreibt unsere Praktiken in Bezug auf das Sammeln, Verwenden, Speichern und Schützen der Daten von Administratoren, die unsere Plattform für Dating-Websites nutzen. Durch den Zugriff auf die Funktionen von TextModing® stimmen Sie den in dieser Richtlinie beschriebenen Praktiken zu.",
        pp1ttladm: "Datensammlung",
        pp2ttladm: "Nutzung der Daten",
        pp3ttladm: "Weitergabe der Daten",
        pp4ttladm: "Speicherung der Daten",
        pp5ttladm: "Datensicherheit",
        pp6ttladm: "Rechte der Administratoren",
        pp7ttladm: "Cookies und ähnliche Technologien",
        pp8ttladm: "Änderungen der Datenschutzrichtlinie",
        pp9ttladm: "Kontakt",
        pp1intadm: "Im Rahmen unserer Partnerschaftsbeziehung und zur Nutzung unserer Plattform können wir folgende Informationen über die Administratoren von Partner-Websites sammeln:",
        pp2intadm: "Die gesammelten Daten werden für die folgenden Zwecke verwendet:",
        pp3intadm: "TextModing® verkauft oder vermietet keine persönlichen Daten von Administratoren und teilt diese nur mit Dritten, wenn dies für die folgenden Zwecke erforderlich ist:",
        pp4intadm: "Die persönlichen Daten von Administratoren werden so lange gespeichert, wie es für die Erreichung der in dieser Richtlinie beschriebenen Ziele oder zur Erfüllung der geltenden gesetzlichen Verpflichtungen erforderlich ist. Sobald die Daten nicht mehr benötigt werden, werden sie sicher gelöscht.",
        pp5intadm: "TextModing® ergreift strenge technische und organisatorische Sicherheitsmaßnahmen, um die persönlichen Daten von Administratoren vor unbefugtem Zugriff, Veränderung, Offenlegung oder Zerstörung zu schützen. Trotz unserer Bemühungen ist keine Datenübertragung über das Internet vollständig sicher; wir tun jedoch unser Bestes, um die Risiken zu minimieren.",
        pp6intadm: "Administrator*innen haben bestimmte Rechte bezüglich ihrer persönlichen Daten, einschließlich:",
        pp7intadm: "Um Ihre Nutzungserfahrung zu verbessern, verwenden wir Cookies und ähnliche Technologien, um Login-Sitzungen zu verfolgen und die Benutzeroberfläche der Plattform zu personalisieren. Sie können Ihre Browsereinstellungen so konfigurieren, dass die Verwendung dieser Cookies eingeschränkt wird, aber dies könnte einige Funktionen der Plattform beeinträchtigen.",
        pp8intadm: "TextModing® behält sich das Recht vor, diese Datenschutzrichtlinie zu ändern, um Änderungen in unseren Praktiken widerzuspiegeln oder um gesetzlichen Verpflichtungen nachzukommen. Jede Änderung wird auf dieser Seite mit einem aktualisierten Datum veröffentlicht. Wir empfehlen Ihnen, diese Richtlinie regelmäßig zu überprüfen.",
        pp9intadm: "Für Fragen oder Anfragen zu dieser Datenschutzrichtlinie können Sie uns unter folgender Adresse kontaktieren:",
        pp1outadm: "",
        pp2outadm: "",
        pp3outadm: "",
        pp4outadm: "",
        pp5outadm: "",
        pp6outadm: "Um diese Rechte auszuüben, können Sie uns unter der folgenden Adresse kontaktieren: admin@creativ-network.net",
        pp7outadm: "",
        pp8outadm: "",
        pp9outadm: "Durch die Nutzung der TextModing®-Plattform stimmen Administratoren dieser Datenschutzrichtlinie zu und verpflichten sich, sie im Rahmen der Nutzung unserer Dienste anzuwenden.",
        pp1contadm: "- Identifikationsdaten: Name, Vorname, Firmenname, Funktion, Administrator-ID.\n- Kontaktdaten: berufliche E-Mail-Adresse, Telefonnummer.\n- Login-Daten: Anmeldeinformationen für den Zugriff auf die TextModing®-Plattform.\n- Nutzungsdaten: Login-Historie, Nutzungseinstellungen und Interaktionen mit den Funktionen der Plattform.\n- Finanzdaten: Abrechnungsdetails und Zahlungsinformationen, nur wenn erforderlich für Transaktionen.",
        pp2contadm: "- Zugriff und Verwaltung von Funktionen: Administratoren den Zugriff auf die 15 wichtigsten Funktionen von TextModing® (Gesprächsmanagement, Qualitätskontrolle, Bezahlung von Operatoren, Verwaltung des Operatoren-Logbuchs, Verwaltung von Fotowünschen, Verwaltung virtueller Geschenke, Verwaltung von GIFs, Verwaltung von Nachrichtenverkehr, Bewertungsmanagement usw.) ermöglichen.\n- Unterstützung und Support: Technischen Support und persönliche Hilfe bei Problemen oder Anfragen nach weiteren Informationen bereitstellen.\n- Verbesserung unserer Dienste: Nutzung von Nutzungsdaten zur Verbesserung der Funktionen und der Benutzererfahrung der Plattform.\n- Abrechnung und Zahlungsüberwachung: Bearbeitung von Zahlungen und Bereitstellung von Rechnungsdokumenten.\n- Sicherheit: Überwachung von Login-Versuchen und verdächtigen Aktivitäten, um die Sicherheit von Administratoren-Konten zu gewährleisten.",
        pp3contadm: "- Dienstleister: Wir können Drittanbieter für bestimmte Operationen hinzuziehen (z. B. Hosting, Zahlungsabwicklung). Diese Anbieter sind verpflichtet, Ihre Daten vertraulich und sicher zu behandeln.\n- Gesetzliche Verpflichtungen: Wir können Ihre Informationen offenlegen, wenn dies gesetzlich erforderlich ist oder um behördlichen Anfragen nachzukommen.\n- Schutz von Rechten: Im Falle von Streitigkeiten könnten wir Informationen im Rahmen von rechtlichen Verfahren teilen, um die Rechte von TextModing® zu verteidigen.",
        pp4contadm: "",
        pp5contadm: "",
        pp6contadm: "- Recht auf Zugang: Anfordern einer Einsicht in die bei uns gespeicherten persönlichen Daten.\n- Recht auf Berichtigung: Anfordern der Korrektur von unrichtigen oder veralteten Informationen.\n- Recht auf Löschung: Anfordern der Löschung Ihrer persönlichen Daten unter bestimmten Umständen.\n- Recht auf Einschränkung der Verarbeitung: In bestimmten Fällen die Nutzung Ihrer Daten einschränken.",
        pp7contadm: "",
        pp8contadm: "",
        sccss: "Erfolgreich",
        smthngwntwrng: "Es ist ein Fehler aufgetreten",
        cntcts: "Kontakte",
        frmlrdcntct: "Kontaktformular",
        ttr: "Titel",
        mr: "Herr",
        mme: "Frau",
        mlle: "",
        sct: "Unternehmen",
        stwb: "Webseite",
        addrss: "Adresse",
        prvncdprtm: "Provinz / Abteilung",
        cdpstl: "Postleitzahl",
        cntr: "Land",
        prnmcntct: "Vorname des Kontakts",
        nmcntct: "Nachname des Kontakts",
        tlphn: "Telefon",
        eml: "E-Mail",
        objdvtrcntct: "Betreff Ihres Kontakts",
        dmdpht: "Demowunsch",
        prtnrt: "Partnerschaft",
        affltn: "Zugehörigkeit",
        prsrdv: "Terminvereinbarung",
        dmdbrchr: "Broschürenanfrage",
        othr: "Sonstiges",
        vtrmsg: "Ihre Nachricht",
        attchmt: "Anhang",
        pltqcnfdtxt: "Mit dem Absenden dieses Kontaktformulars erklären Sie, dass Sie unsere",
        pltqcnfdtxt2: "Datenschutzrichtlinie gelesen und verstanden haben",
        xcldrssttm: "Schlüssel zum Erfolg von TextModing®",
        cldrssttl1: "Erschwingliche Kosten",
        cldrssttl2: "Fortschrittliche Technologie",
        cldrssttl3: "Anpassungsfähigkeit der Plattform",
        cldrssttl4: "Betriebliche Effizienz",
        cldrssttl5: "Transparenz und Kommunikation",
        cldrssttl6: "Engagement für Kundenzufriedenheit",
        cldrsstxt1: "Wir bieten wettbewerbsfähige Dienstleistungen mit einem hervorragenden Preis-Leistungs-Verhältnis, das es unseren Kunden ermöglicht, ihre Budgets zu optimieren und gleichzeitig von hochwertigen Lösungen zu profitieren.",
        cldrsstxt2: "Wir nutzen modernste Technologien, um robuste Leistungen, erhöhte Sicherheit und ein reibungsloses Management der Plattformaktivitäten zu gewährleisten.",
        cldrsstxt3: "Wir bieten Flexibilität, die es ermöglicht, die Plattform an die spezifischen Bedürfnisse jedes Kunden anzupassen, wodurch eine personalisierte und effektive Reaktion sichergestellt wird.",
        cldrsstxt4: "Wir maximieren die Effizienz interner Prozesse, um kurze Implementierungszeiten und reibungslose Abläufe ohne Kompromisse bei der Servicequalität zu gewährleisten.",
        cldrsstxt5: "Wir halten eine klare und transparente Kommunikation mit den Kunden aufrecht und bieten vollständige Transparenz über die Leistungen und Ergebnisse ihrer Projekte.",
        cldrsstxt6: "Wir stellen die Kundenzufriedenheit in den Mittelpunkt unserer Prioritäten, indem wir schnelle Lösungen umsetzen und ständig auf die Bedürfnisse und Rückmeldungen unserer Partner eingehen.",
        prdtstm: "Produkte von TextModing®*",
        prdtttl1: "Testoberfläche",
        prdtttl2: "Dashboard",
        prdtttl3: "Engagement-Plattform",
        prdttxt1: "Die Testoberfläche von TextModing® ermöglicht potenziellen Kunden, die wesentlichen Funktionen der Plattform zu erkunden und zu erleben, einschließlich des Sendens von normalen Nachrichten, Hooks, Pokes und Follow-ups, unterstützt von unseren Chat-Operatoren, für ein vollständiges Eintauchen in unsere interaktive Umgebung.",
        prdttxt2: "Das Dashboard von TextModing® bietet Administratoren, Aufsichtspersonen, Qualitätskontrolleuren und Buchhaltern eine zentrale und intuitive Übersicht, die die Echtzeitverwaltung von Operationen, die Überwachung der Leistungen, die Qualitätssicherung und die finanzielle Nachverfolgung der Plattform erleichtert.",
        prdttxt3: "Die Engagement-Plattform von TextModing® bietet Chat-Operatoren eine optimierte interaktive Umgebung zur Verwaltung von Austausch, zur Schaffung ansprechender Verbindungen und zur Gewährleistung einer dynamischen und effektiven Interaktion zwischen den Nutzern.",
        prdindpdts: "Die 3 Produkte sind voneinander abhängig",
        intrcteffcctprfrmnc: "“Interaktion. Effizienz. Leistung”",
        abtus: "Über uns",
        abtustxt: "TextModing® ist eine innovative Plattform, die sich der Animation von Dating-Seiten durch professionelle Operatoren widmet. Wir bieten maßgeschneiderte Dienstleistungen in den Bereichen Moderation, Inhaltsverwaltung und Engagement an, um das Benutzererlebnis auf Dating-Plattformen zu verbessern. Dank unseres erfahrenen Teams garantieren wir authentische und ansprechende Interaktionen, die eine qualitativ hochwertige Benutzererfahrung fördern.",
        rdswt: "Weiterlesen",
        lnsdst: "Webseitenlinks",
        lngdst: "Sprache der Webseite",
        mnaddrss: "Hauptadresse",
        rprsnttbrblg: "Vertreter des belgischen Büros",
        ttdrtsrsrvs: "Alle Rechte vorbehalten",
        apr: "Über",
        ftrs: "Funktionen",
        avntgs: "Vorteile",
        cldrsst: "Schlüssel zum Erfolg",
        nsprdts: "Unsere Produkte",
        orpltfrm1: "Unsere Plattform",
        orpltfrm2: "für die Verwaltung von Dating-Websites",
        orpltfrmtxt: "TextModing® ist eine leistungsstarke Plattform, die entwickelt wurde, um Dating-Websites zu verwalten und zu optimieren. Mit fortschrittlichen Tools und einer intuitiven Benutzeroberfläche ermöglicht sie eine effektive Verwaltung der Interaktionen zwischen Nutzern und gewährleistet ein hohes Maß an Engagement.",
        flxqtdns: "Tägliche Flüsse",
        rqstdmo: "Demo anfordern",

        aprtxt1: "TextModing® ist eine innovative Plattform, die sich der Verwaltung von Dating-Websites durch professionelle Betreiber widmet. Wir bieten maßgeschneiderte Moderations-, Inhaltsverwaltungs- und Animationsdienste, um die Nutzererfahrung auf Dating-Plattformen zu verbessern. Mit unserem erfahrenen Team garantieren wir authentische und ansprechende Interaktionen, die eine qualitativ hochwertige Nutzererfahrung fördern.",
        aprtxt2: "Unsere Plattform ermöglicht es den Betreibern, Gespräche effektiv zu verwalten, die Qualitätskontrolle durchzuführen und den Nachrichtenverkehr zu überwachen. Mit fortschrittlichen Funktionen wie der Verwaltung von Fotoanfragen, virtuellen Geschenken und GIFs sowie der Notizenverwaltung für eine optimale Interaktionsverfolgung ist TextModing® ein vollständiges Tool für Websites, die ein interaktives und dynamisches Erlebnis bieten möchten.",
        aprtxt3: "Wir verpflichten uns, hochwertige Dienstleistungen anzubieten, mit einem Team, das immer bereit ist, die spezifischen Bedürfnisse von Dating-Websites zu unterstützen und die Mitgliedsbeteiligung zu optimieren.",

        xftftm: "Funktionen von TextModing®",

        ft1ttl: "Gesprächsmanagement",
        ft2ttl: "Agentenverwaltung",
        ft3ttl: "Notfallraum",
        ft4ttl: "Notizenverwaltung",
        ft5ttl: "Einleitungsnachrichten",
        ft6ttl: "Poke-Nachrichten",
        ft7ttl: "Folgenachrichten",
        ft8ttl: "Fotoanfragen",
        ft9ttl: "Agentenmeldungen",
        ft10ttl: "Protokoll",
        ft11ttl: "Doppelte Nachrichten",
        ft12ttl: "Geschenkverwaltung",
        ft13ttl: "Überwachung und Verkehrsmanagement",
        ft14ttl: "Qualitätskontrolle",
        ft15ttl: "Buchhaltung",

        ft1txt: "Ermöglicht Administratoren, Teamleitern und Supervisors, Interaktionen in Echtzeit zu überwachen, zu kontrollieren und zu analysieren, um die Qualität und den reibungslosen Ablauf der Kommunikation auf Dating-Websites zu gewährleisten.",
        ft2txt: "Ermöglicht Administratoren, Teamleitern und Supervisors, die Leistung der Agenten in Echtzeit zu überwachen, zu führen und zu bewerten, um eine effektive Verwaltung der Dating-Websites zu gewährleisten.",
        ft3txt: "Bietet Administratoren, Teamleitern und Supervisors einen sicheren Raum, um Notfälle zu verwalten, kritische Vorfälle schnell zu lösen und die betriebliche Kontinuität auf Dating-Websites sicherzustellen.",
        ft4txt: "Ermöglicht Administratoren, Teamleitern und Supervisors, Notizen über Agenteninteraktionen zu erstellen, zu verfolgen und zu teilen, um die Koordination und Leistungsüberwachung zu optimieren.",
        ft5txt: "Ermöglicht Administratoren, Teamleitern und Supervisors, effektive Einleitungsnachrichten zu erstellen, anzupassen und zu versenden, um die Nutzerbindung auf Dating-Websites zu optimieren.",
        ft6txt: "Ermöglicht Administratoren, Teamleitern und Supervisors, strategische Poke-Nachrichten zu konfigurieren, zu versenden und zu analysieren, um das Engagement wiederzubeleben und die Interaktion der Nutzer zu fördern.",
        ft7txt: "Ermöglicht Administratoren, Teamleitern und Supervisors, Follow-up-Nachrichten zu planen, anzupassen und zu analysieren, um inaktive Nutzer auf der Website wieder zu aktivieren.",
        ft8txt: "Ermöglicht Administratoren, Teamleitern und Supervisors, Fotoanfragen von der Operatoren zu überwachen, zu genehmigen und zu verwalten und so die Konformität und Qualität der ausgetauschten Inhalte zu gewährleisten.",
        ft9txt: "Ermöglicht Administratoren, Teamleitern und Supervisors, Meldungen über Agenten zu überwachen, zu überprüfen und zu bearbeiten, um proaktive Moderation und optimale Qualitätskontrolle zu gewährleisten.",
        ft10txt: "Ermöglicht Administratoren, Teamleitern und Supervisors, die Notizen der Operatoren zu verfolgen, zu protokollieren und zu analysieren, um die Leistung und Kommunikation zu optimieren.",
        ft11txt: "Ermöglicht Administratoren, Teamleitern und Supervisors, wiederholte Nachrichten zu identifizieren und zu verwalten, um die Qualität der Interaktionen mit den Nutzern zu verbessern.",
        ft12txt: "Ermöglicht Administratoren, Teamleitern und Supervisors, die Versendung von interaktiven Inhalten zu überwachen und zu analysieren, um das Nutzerengagement zu stärken.",
        ft13txt: "Ermöglicht Administratoren, Teamleitern und Supervisors, Nachrichtenflüsse zu analysieren, um die Leistung zu optimieren und eine reibungslose Kommunikation zwischen den Nutzern zu gewährleisten.",
        ft14txt: "Ermöglicht Administratoren, Teamleitern und Supervisors, die Interaktionen der Operatoren zu bewerten, um einen hochwertigen Service und optimale Kundenzufriedenheit zu gewährleisten.",
        ft15txt: "Ermöglicht Administratoren und Buchhaltern, finanzielle Transaktionen zu verfolgen und zu verwalten, um Transparenz und Effizienz im Ressourcenmanagement zu gewährleisten.",

        nsecrr: "Kontaktieren Sie uns",

        cqdfftm: "Was unterscheidet TextModing®?",
        cqdffttl: "TextModing® unterscheidet sich von seinen Mitbewerbern durch mehrere wesentliche Vorteile:",
        cqdffttl1: "Spezialisierte Expertise",
        cqdffttl2: "Personalisierung und Flexibilität",
        cqdffttl3: "Kontinuierliche Unterstützung und Schulung",
        cqdffttl4: "Ständige Innovation",

        cqdfftxt1: "TextModing® vereint hochqualifizierte Web-Talente wie Chat-Operatoren, Supervisoren, Qualitätskontrolleure und Verkehrsmanager, um eine optimale und professionelle Verwaltung von Dating-Websites zu gewährleisten.",
        cqdfftxt2: "Dank unserer Plattform bieten wir maßgeschneiderte Lösungen, die auf die spezifischen Bedürfnisse jedes Kunden zugeschnitten sind, um eine gezielte und effektive Verwaltung zu ermöglichen.",
        cqdfftxt3: "TextModing® bietet nicht nur Dienstleistungen an, sondern investiert auch in die kontinuierliche Schulung unseres Teams, um eine schnelle Anpassung an neue Trends und Technologien zu gewährleisten und gleichzeitig eine konstante Unterstützung für unsere Partner zu bieten, um deren Wachstum zu fördern.",
        cqdfftxt4: "Durch die Führungsrolle bei den neuesten Technologien und Animationsstrategien sichert sich TextModing® einen nachhaltigen Wettbewerbsvorteil auf dem sich ständig verändernden Markt für seine Kunden.",
        btsd : "Botseite",
        nttkn : "Notizen gemacht",
        mssdnt : "Verpasste Notizen",
        gfts : "Geschenke",
        rqsts : "Anfragen",
        messonhldallctd : "Nachricht in Warteschleife bereits zugewiesen",
        messonhldntallctd : "Nachricht in Warteschleife nicht zugewiesen",
        totnummess : "Gesamtanzahl der Nachrichten",
        messpssdqc : "Nachrichten haben die Qualitätskontrolle bestanden",
        messntpssqc : "Nachrichten haben die Qualitätskontrolle nicht bestanden",
        paymnts : "Zahlungen",
        bnk : "Bank",
        ppl : "Paypal",
        ws : "Wise",
        yrsf : "Yoursafe",
        rçes: "Empfangen",
        envoyees: "Gesendet",
        dmndees: "Angefordert",
        nvaux: "Neu",

        adddest: "E-Mail Adresse des Empfängers",

        cmpn: "Unternehmen",
        tmfld: "Textverarbeitungsordner",
        accar: "Zugangsbereich",
        assvldt: "Zugangsberechtigung",

        acc8: "Tarif und Bonus einrichten",

        mtng: "Sitzung",
        rp: "Echtes Profil",
        fp: "Gefälschtes Profil",
        infopernoper: "Permanente / nicht-permanente Information",
        dltnt: "Notiz löschen",
        dltmesspnc: "Wollen Sie diese Panikmeldung wirklich löschen?",
        convbnnd: "Konversation verboten",
        suspconv: "Unterbrechung der Konversation",
        upldd: "Hochgeladen",
        addpht: "Foto hinzufügen",
        phtsnttofp: "Liste der an gefälschte Profile gesendeten Fotos",
        pkmesscrtd: "Poke-Nachricht erfolgreich erstellt",
        updt: "Aktualisierung",
        dltpkmess: "Wollen Sie diese Poke-Nachricht wirklich löschen?",
        plt: "Plattform",
        dltdmd: "Möchten Sie diese Anfrage wirklich löschen? ",
        agntinfrmd: "Agent informiert",
        dlttsrmess: "Möchten Sie diese Teaser-Nachricht wirklich löschen?",
        updttsrmess: "Teaser-Nachricht aktualisieren",

        updtdsucc: "Erfolgreich aktualisiert",
        nonotif: "Keine Benachrichtigung",
        continfo: "Inhaltliche Informationen",
        exp: "Absender",
        recep: "Empfänger",
        mdpupdtd: "Passwort erfolgreich aktualisiert",
        opsusp: "Operator erfolgreich suspendiert",
        opdec: "Bediener erfolgreich abgemeldet",
        decop: "Möchten Sie die Verbindung zu diesem Betreiber wirklich trennen:  ",
        frop: "Möchten Sie diesen Operator wirklich feuern:  ",
        chngmdp: "Passwort ändern",
        suspop: "Möchten Sie diesen Operator wirklich suspendieren: ",
        onhld: "In der Warteschleife",
        supprmess: "Diese Nachricht löschen",
        messinfo: "Nachrichteninformationen",
        vld: "Bestätigen",
        ok: "Ok",
        enc: "In Arbeit ...",
        
        updtmdp: "Möchten Sie das Passwort ändern?",
        nwmdp: "Geben Sie ein neues Kennwort ein",
        dltsup: "Möchten Sie diesen Betreuer wirklich löschen?",
        ntsup: "Notiz an eine Aufsichtsperson",
        nthr: "Notiz hier...",
        dltimg: "Wollen Sie dieses Bild wirklich löschen?",
        phttsnd: "Foto zum Versenden",
        vd: "Leer (wählen Sie ein Foto aus)",
        addd: "Hinzugefügt",
        nwrdv: "Neue Informationen hinzufügen",
        cnfrm: "Bestätigen",
        dltrdv: "Möchten Sie dieses Rendez-vous/ Meetings/ Holidays wirklich löschen?",
        addnonperminfo: "Nicht dauerhafte Informationen hinzufügen",
        dltinfo: "Möchten Sie diese Information wirklich löschen?",
        idd: "ID",
        addperminfo: "Permanente Informationen hinzufügen",
        messreport: "Meldung an den Vorgesetzten",
        usrnm: "Benutzername",

        pp11 : "Creativ'Network, ein Unternehmen zur beruflichen Vermittlung für Webarbeiten, mit Sitz in 71-75 Shelton Street, Covent Garden, London, WC2H 9JQ, und mit einer Niederlassung in der Roodborstjesstraat 4, Wondelgem 9032, im Folgenden „die Gesellschaft“ genannt,",
        pp12 : "Und:",
        pp13 : "Selbständiger, der aufgrund des Selbständigenvertrags, der ihn mit „der Gesellschaft“ verbindet, die Materialien sowie die geistigen Eigentumsrechte dieser letzten nutzt, im Folgenden „der Selbständige“ genannt,",
        pp14 : "Im Folgenden einzeln als „die Partei“ und gemeinsam als „die Parteien“ bezeichnet,",
        pp21 : "Die Gesellschaft betreibt eine Plattform zur Verwaltung von Dating-Websites, deren ausschließliche Eigentümerin sie ist. Das operative Management dieser Plattform wird von Chat-Operatoren, Aufsichtspersonen, Teamleitern und Qualitätsprüfern übernommen, die von der Gesellschaft als Selbständige beschäftigt werden.",
        pp22 : "Im Rahmen dieser beruflichen Beziehung wird der Selbständige Zugang zu vertraulichen Informationen im Zusammenhang mit der Plattform, den Nutzern der Dating-Websites, den Betriebsprozessen sowie zu anderen geschützten Informationen der Gesellschaft haben.",
        pp23 : "Vorausgesetzt wird Folgendes vereinbart:",
        pp31 : "Diese Vertraulichkeitsvereinbarung dient dem Schutz der vertraulichen Informationen, die die Gesellschaft dem Selbständigen im Rahmen seiner Aufgaben im Zusammenhang mit der Verwaltung der Plattform für Dating-Websites mitteilen könnte.",
        pp41 : "Für die Zwecke dieser Vereinbarung bezeichnen die „Vertraulichen Informationen“ alle Informationen, in welcher Form auch immer (schriftlich, mündlich, elektronisch usw.), die die Plattform für Dating-Websites, die Nutzer, die Entwicklungsstrategien, die technischen und operativen Prozesse, die Finanzdaten sowie andere von der Gesellschaft als vertraulich bezeichnete Informationen betreffen, die der Selbständige im Rahmen seiner Arbeit erhalten oder auf die er zugreifen kann.",
        pp51 : "Der Selbständige verpflichtet sich:",
        pp52 : "- Vertrauliche Informationen, ganz oder teilweise, nicht ohne vorherige schriftliche Genehmigung der Gesellschaft an Dritte weiterzugeben, zu reproduzieren, zu übertragen oder zugänglich zu machen.",
        pp53 : "- Vertrauliche Informationen nur im Rahmen der Aktivitäten im Zusammenhang mit der Verwaltung der Plattform und ausschließlich im Interesse der Gesellschaft zu verwenden.",
        pp54 : "- Alle notwendigen Maßnahmen zu ergreifen, um die Vertraulichkeit der Vertraulichen Informationen zu schützen und eine unbefugte Nutzung zu verhindern.",
        pp55 : "- Auf Aufforderung der Gesellschaft alle Dokumente oder Materialien, die Vertrauliche Informationen enthalten, zurückzugeben, ohne Kopien in irgendeiner Form zu behalten.",
        pp61 : "Diese Vereinbarung tritt in Kraft, sobald der Mitarbeiter bei seiner Identifizierung für den Zugang zur Animationsplattform sein Einverständnis erklärt, und bleibt während der gesamten Dauer der beruflichen Beziehung zwischen der Gesellschaft und dem Selbständigen in Kraft. Die Vertraulichkeitsverpflichtungen bleiben für einen Zeitraum von 10 Jahren nach Beendigung dieser Beziehung bestehen, unabhängig vom Grund.",
        pp71 : "Nicht als Vertrauliche Informationen gelten:",
        pp72 : "- Informationen, die zum Zeitpunkt ihrer Mitteilung an den Selbständigen bereits öffentlich bekannt waren oder auf andere Weise öffentlich werden, es sei denn durch Verletzung dieser Vereinbarung.",
        pp73 : "- Informationen, die der Selbständige nachweisen kann, rechtmäßig von einem Dritten erhalten zu haben, der nicht durch eine Geheimhaltungspflicht gebunden ist.",
        pp74 : "- Informationen, die der Selbständige unabhängig entwickelt hat, ohne auf Vertrauliche Informationen zurückzugreifen.",
        pp81 : "Im Falle eines Verstoßes gegen die in dieser Vereinbarung festgelegten Verpflichtungen erkennt der Selbständige an, dass die Gesellschaft alle erforderlichen rechtlichen Schritte einleiten kann, um ihre Rechte zu schützen und Schadensersatz für erlittene Schäden zu erhalten.",
        pp91 : "Diese Vereinbarung unterliegt dem belgischen Recht. Im Falle eines Streits über die Auslegung oder Durchführung dieser Vereinbarung verpflichten sich die Parteien, eine einvernehmliche Lösung zu suchen. Bei fehlender einvernehmlicher Lösung sind ausschließlich die zuständigen Gerichte in Gent zuständig.",
        pp1 : "Zwischen den Parteien",
        pp2 : "Präambel",
        pp3 : "Artikel 1: Gegenstand",
        pp4 : "Artikel 2: Definition der Vertraulichen Informationen",
        pp5 : "Artikel 3: Verpflichtungen des Selbständigen",
        pp6 : "Artikel 4: Dauer",
        pp7 : "Artikel 5: Ausschlüsse",
        pp8 : "Artikel 6: Allgemeine Bestimmungen",
        pp9 : "Artikel 7: Anwendbares Recht und Zuständige Gerichtsbarkeit",

        paymail: 'Zahlungs-E-Mail-Adresse',
        scc: 'Erstellt',
        italian: 'Italienisch',
        dutch: 'Niederländisch',
        spanish: 'Spanisch',
        german: 'Deutsch',
        convonhold: "Gespräche in der Warteschleife",
        supdash: "Dashboard für Vorgesetzte",
        choverv: "Chat-Übersicht",
        login: 'Anmeldung',
        password: 'Passwort',

        qlogout: 'Möchten Sie sich abmelden?',
        logout: 'Abmelden',
        editNote:'Notiz Bearbeiten',
        yes: 'Ja',
        notesuppr:'Note gelöscht',
        addnote:'Anmerkung hinzugefügt',
        editer:'Bearbeiten',
        annuler:'Abbrechen',
        no: 'Nein',
        operatorpage: "Bedienerseite",
        supervisorpage: "Seite für Vorgesetzte",
        adminpage: "Administratoren-Seite",

        required: "Dieses Feld ist erforderlich",
        invalid: "Ungültige E-Mail",
        registered: "Diese E-Mail-Adresse st nicht registriert",
        incorrectpass: "Falsches Passwort",
        atleast: "Das Passwort muss mindestens enthalten",
        characters: "Zeichen",

        convman: "Gesprächsführung",
        agman: "Agenten-Verwaltung",
        notesman: "Verwaltung von Notizen",
        agrep: "Agentenberichte",
        gftgtfman: "Verwaltung von Geschenken und gifs",
        tmfldaa: "Zugriffserlaubnis für TextModing-Ordner",
        hlp: "Hilfe",
        pltflng: "Plattformsprache",
        pr: "Fotoanfragen",
        wlcm: "Willkommen",
        wlcmtxt: "TextModing ist eine Animationsplattform, die für Dating-Seiten entwickelt wurde und wesentliche Funktionen für die Verwaltung von Konversationen und Agenten bietet. Sie steigert die Effizienz und Rentabilität für Partner, indem sie Tools bereitstellt, die Interaktionen rationalisieren und das Engagement der Nutzer optimieren.",

        messageto: "Nachricht an",
        convatt: 'Gespräch in der Warteschleife',
        mincharlen: 'Mindestens 120 Zeichen',
        char: 'Zeichen',
        chars: 'Zeichen',
        pj: 'Fotoanhang',
        writemessage: 'Schreibe eine Nachricht ...',
        picalreadysent: 'Foto vom Benutzer gesehen',
        gifpj: 'GIFS',
        opengif: 'GIF öffnen',
        sendMessage: 'Senden Sie die Nachricht',
        reportSup: 'Notiz an den Supervisor senden',
        conv: 'Konversations-ID',
        say: 'sagt',
        timeIntro: 'bei',
        elements: 'elements',
        element: 'element',
        none: 'Keine',

        stat: 'Statistik',
        french: 'Französisch',
        english: 'Englisch',
        botprofil: 'Bot-Profil',
        userprofil: 'Benutzerprofil',
        otherInfo: 'Andere wichtige Informationen',
        codepost: 'Postleitzahl',
        ville: 'Stadt',
        region: 'Region',
        pays: 'Land',
        taille: 'Größe',
        Augen: 'Augenfarbe',
        hair: 'Haarfarbe',
        morph: 'Morphologie',
        situation: 'Familienstand',
        searchfor: 'Suche nach',
        job: 'Beruf',
        signe: 'Unterscheidungsmerkmale',
        descri: 'Beschreibung',
        infosupp: 'Zusätzliche Informationen',

        supervisiontchat: 'Chat-Überwachung',
        statconvop: 'Konversation zwischen Stat und Operator',
        convs: 'Unterhaltungen',
        report: 'Bericht',
        agentInform:'Informierter Agent',
        events: 'Ereignis',
        photosrequests: 'Fotos anfordern',
        warmup: 'Aufwärmen',
        gifattch: 'Gif-Anhang',
        fakeprofiles: 'Gefälschte Profile',
        pagenotfound: 'Seite nicht gefunden',

        suivvraiprof: 'Überwachung echter Profile',
        suiviop: 'Operatoren-Überwachung',
        admintion: 'Verwaltung',
        affiliation: 'Zugehörigkeit',

        operator: 'Operator',
        supervisor: 'Vorgesetzter',
        administrator: 'Administrator',

        week: 'Woche',
        weeks: 'Wochen',
        today: 'Heute',

        start: 'Start',
        end: 'Ende',
        platform: 'Plattformen',
        search: 'Suche',
        go: 'Los',
        allmasc: 'Alle',
        allfem: 'Alle',

        id: 'Bezeichner',
        opname: 'Name des Betreibers',
        sentmess: 'Gesendete Nachrichten',
        recmess: 'Empfangene Nachrichten',
        relmess: 'gemahnte Nachrichten',

        date: 'Datum',
        messid: 'Kennung der Nachricht',
        convid: 'Kennung des Gesprächs',
        exped: 'Absender',
        recpt: 'Empfänger',
        opsign: 'Zu meldender Bediener',
        opexped: 'Sendender Operatorr',
        action: 'Aktion',
        messs: 'Meldungen',
        filterdate: 'Suche zwischen zwei Daten filtern',
        filtermess: 'Konversation nach Nachricht filtern',
        from: 'Von',
        to: 'bis',
        eventList: 'Ereignisliste',
        num: 'Nummer',
        eventSearch: 'Suche nach einem Ereignis',
        actions: 'Aktionen',

        eventname: 'Name des Ereignisses',
        eventdate: 'Datum der Veranstaltung',
        uploadimage: "Bilder hochladen",
        role: "Rolle",
        logIn: "Anmelden",
        ppaccept1: "Mit der Anmeldung auf unserer Plattform bestätigen Sie, dass Sie unsere",
        ppaccept2: "gelesen haben und dass Sie die entsprechenden Schulungen absolviert haben, um Ihren Auftrag auszuführen",
        pp: "Vertraulichkeitsvereinbarung",
        copyright: "© 2023-2024 Copyright TextModing® - Alle Rechte vorbehalten.",
        accountant: "Buchhalter",
        qualitycontroller: "Qualitätskontrolleur",

        adminid: "Verwaltungs-ID",
        accountantid: "Buchhalter-ID",
        operatorid: "Bediener-ID",
        qcid: "Qualitätskontrolleur-ID",
        mail:'Email',
        supervisorid: "Vorgesetzten-ID",
        confirmpass:'Bestätigen Sie das Passwort',
        alreadyreg:'Bereits registriert? Einloggen',
        notifs: "Benachrichtigungen",
        limite:'Begrenzt',
        save:'Speichern',
        archives:'Archive',
        ttlmsg:'Gesamte Nachrichten',
        controlLog:'Controller-Login',
        nbrmess:'Anzahl der Nachrichten',
        appQC:'QC-Bewertungen',
        esqmq:'Fehlendes Ausweichen',
        dbspace:'Doppelter Abstand',
        notelu:'Notiz gelesen',
        actpris:'Maßnahme ergriffen',
        ferme:'Geschlossen',
        lorn:'Liste der erhaltenen Notizen',
        losn:'Liste der gesendeten Notizen',
        validity:'Geldigheid',
        editInfo:'Informationen bearbeiten',
        controlID:'ID-Controller',
        statcontrolct:'Kontrollstatistiken nach Controller',
        statcontrolag:'Kontrollstatistiken nach Agent',
        statcontrol:'Kontrollstatistiken',
        traitement:'Behandlung',
        agentid:'Agenten-ID',
        statconvatt:'Statistiken der Anrufe in der Warteschleife',
        propesqu:'Geben Sie eine Ausrede für eine Verabredungsanfrage ein',
        msgacc:'Bitte verfassen Sie eine ansprechende Nachricht',
        msgpoke:'Bitte schreiben Sie eine Poke-Nachricht',
        heure:'Stunde',
        esqv:'Ausweichen',
        esquive:'Ausweichmanöver Treffen und Termine',
        other:'Andere',
        sexe:'Geschlecht',
        bannir:'Verbannen',
        deconnecter:'Trennen',
        agntLgn:'Online-Agent',
        features:'Funktionen',
        valider:'Bestätigen',
        rdv:'Termin',
        holiday:'Urlaub',
        panicmsg:'diese Paniknachricht?',
        jour:'Tag',
        jours:'Tage',
        conversation:'Gespräch',
        profil:'Profil',
        suspendre:'Aussetzen',
        expulser:'Vertreiben',
        information:'Information',
        meeting:'Besprechung',
        online:'Online',
        onHold:'In Warteschleife',
        treated:'Behandelt',
        listPhotoSend:'Liste gesendeter Fotos',
        agentReport:'Gemeldeter Agent',
        suspendu:'Suspendiert',
        raison:'Grund',
        suspend:'Suspendieren',
        etat:'Status',
        actqc4:'Nachricht validiert',
        panicmess:'Paniknachricht',
        contenu:'Inhalt',
        language1:'Sprache',
        reportmess:'Nachricht melden',
        enreg:['Noch nicht registriert?','Registrieren'],
        passdiff:'Anderes Passwort',
        months: ["Januar", "Februar", "März", "April", "Mai", "Juni", "Juli", "August", "September", "Oktober", "November", "Dezember"],
        year: 'Jahr',
        month: 'Monat',
        reg:'Registrieren',
        startingdate: 'Anfangsdatum',
        closingdate: 'Abschlussdatum',

        agents: "Agenten",
        out: "Ausgänge",
        in: "Eingänge",
        details: "Details",
        panicroom: "Panikraum",
        total: "Gesamt",
        notes: "Notizen",
        teasermessages: "Teaser-Nachrichten",
        pokemessages: "Poke-Nachrichten",
        attdisc:'Warten auf Diskussion',
        stopmessages: "Stopp-Nachrichten",
        gifajt:'Gif Hinzufügen',
        sent: "Gesendet",
        asnwered: "Beantwortet",
        requested: "Angefordert",
        logbook: "Logbuch",
        recorded: "Aufgezeichnet",
        notrecorded: "Nicht registriert",
        duplicatedmessages: "Doppelte Nachrichten",
        back: "Zurück",
        suty: "Abonnementtyp",
        ag: "Alter",
        gend: "Geschlecht",
        sexor: "Sexuelle Orientierung",
        tow: "Stadt",
        
        users: "Benutzer",
        sender: "Absender",
        receiver: "Empfänger",
        agent: "Agent",
        status: "Status",
        read: "Gelesen",
        active: "Aktiv",
        edit: "Bearbeiten",
        user: "Benutzer",
        sendnote: "Eine Notiz senden",
        oh: "In der Warteschleife",
        mess: "Nachricht",
        trconv: "Bearbeitete Konversationen",
        idconv: "ID der Konversation",
        idmess: "ID der Nachricht",
        otherimp: "Andere wichtige Informationen",
        otherimp1: "Treffen/ Begegnungen/ Ferien",
        otherimp2: "Ständige Informationen",
        otherimp3: "Nützliche Informationen, nicht dauerhaft",
        
        aglist: "Liste der Agenten",
        pfsperf: "Leistung von Plattformen",
        agperf: "Leistung der Agenten",
        nwag: "Neue Agenten hinzufügen",
        agname: "Name des Agenten",
        agcountry: "Land des Agenten",
        aglogin: "Login des Agenten",
        agloginpw: "Kennwort des Agenten",
        langpf: "Sprachplattform",
        onl: "Online",
        offl: "Offline",
        susp: "Suspendieren",
        koff: "Zurückschicken",
        grotot: "Bruttogesamt",
        agnumbewoon: "Anzahl der gefälschten Profile",
        totsenmess: "Summe der gesendeten Nachrichten",
        totrecmess: "Summe der empfangenen Nachrichten",
        totrep: "Gesamtanzahl der Berichte",
        real: "Echter Name",
        prov: "Bundesland",
        weight: "Gewicht",
        child: "Kinder",
        time: "Zeit",
        fstnm: "Vorname",
        lstnm: "Nachname",
        dtofbir: "Geburtsdatum",
        skypeid: "Skype-ID",
        crnwag: "Neuen Agenten erstellen",
        usrprfl: "Benutzerprofil",
        sndmess: "Nachricht senden",
        blckusr: "Benutzer sperren",
        lstnts: "Liste der Notizen",
        crtsndnts: "Notiz erstellen / senden",
        evnt: "Ereignis",
        ntid: "Notiz-ID",
        nt: "Notiz",
        dlt: "Löschen",
        cndid: "Kandidat ID",
        language: "Sprache",
        perc1: "Sie haben ",
        perc2: " der Nachricht des Benutzers verfasst",
        giftsatt: "Geschenke beigefügt",
        sndnt: "Senden Sie die Notiz",
        lsttsrmsg: "Liste der Teaser-Nachrichten",
        crttsrmsg: "Teaser-Nachrichten erstellen",
        idtsrmsg: "ID der Teaser-Nachricht",
        tsrmsg: "Teaser-Nachricht",
        dlvrdt: "Zustellung an",
        wiseg:"Kluge Gifs",         
        sexg:"Sexuelle Gifs",                        
        gayg:"Schwule Gifs",
        Lesbg: "Lesbische Gifs",
         transg:"Transgender Gifs",
        travg:"Travestie-Gifs",
                
        bdsm:"BDSM",
        dont: "Nicht lange fackeln",
        blj:"Blowjob",
        org:"Orgasmus",
        vagpen:"Vaginale Penetration",
        sod:"Sodomie",
        titss:"Titten",

        bdsmg: "BDSM schwul",
        wisegg: "Kluge Gay Gifs",
        sexgg:"Sex-Gay-Gifs",

        bdsml: "BDSM Lesbisch",
        wiselg:"Kluge Lesben Gifs",
        sexlg:"Sex Lesbian Gifs",

        maletrans:"Männlicher Transgender",
        femaletrans:"Weibliche Transgender",
        lstpkmsg: "Liste der Stopfmeldungen",
        crtpkmsg: "Erzeugen einer Poke-Nachricht",
        idpkmsg: "ID der Poke-Nachricht",
        pkmsg: "Poke-Nachricht",
        lststpmsg: "Liste der Stoppmeldungen",
        idstpmsg: "Stoppmeldungs-ID",
        stpmsg: "Stopp-Nachricht",
        sntto: "Gesendet an",
        agntwrtngstp: "Agent, der den Stopp schreibt",
        dtofdlvr: "Datum der Zustellung",
        dtofans: "Datum der Antwort",
        stpperfanal: "Leistungsanalyse stoppen",
        stpgrssttl: "Bruttosumme anhalten",
        rsptostp: "Antwort auf Stopp",
        rsptostp2: "Antwort auf den Hook",
        rsptostp3: "Antwort auf den Poke",
        stpprcntresp: "Stoppen Sie % Antwort",
        avrgrsptm : "Durchschnittliche Antwortzeit",

        lstphtrq: "Liste der angeforderten Fotos",
        phttp: "Foto-Typ",
        idrq: "Anfrage-ID",
        pht: "Foto",
        upld: "Hochladen",
        snd: "Senden",
        lstrprt: "Liste der Berichte",
        rpttp: "Berichtstyp",
        idrprt: "Berichts-ID",
        msgrprtd: "Gemeldete Nachricht",
        infrmagnt: "Bearbeiter informieren",
        lstlgbk: "Liste des Logbuchs",
        lstmssnglgbk: "Liste des fehlenden Logbuchs",
        lgbktp: "Logbuch-Typ",
        idlgbk: "Logbuch-ID",
        infs: "Informationen",
        lvlofimprt: "Grad der Wichtigkeit",
        prmnnt: "Dauerhaft",
        ntprmnnt: "Nicht dauerhaft",
        mssnglgbk: "Fehlendes Logbuch",
        mrklgbk: "Logbuch markieren",
     
        dh: "Datum und Uhrzeit",
        mid: "Nachrichten-ID",
        repmess: "Diese Nachricht melden",
        bts: "Bot sagt",
        obj: "Objekt",
        plc: "Ort",

        lstdplmss: "Liste der duplizierten Nachrichten",
        iddplmss: "ID der doppelten Nachrichten",
        lstgfs: "Liste der GIFs",
        ddgfs: "Neues GIF hinzufügen",
        gftp: "GIF-Typ",
        gfimg: "GIF-Bild",

        nat: "Natur",
        tmslt: "Zeitschlitz",
        gfsnt: "Gesendete GIFs",

        qcdash: "Dashboard für Qualitätskontrolle",
        qctrl: "Qualitätskontrolle",
        lstmess: "Liste der Meldungen",
        qcpnl: "Qualitätskontroll-Panel",
        ptreq: "Foto anfordern",
        sp: "Foto senden",
        sgft: "Geschenk senden",
        sgif: "Gif senden",
        eyes: "Farbe Augen",
        qccrit: "QC-Kriterien",
        qccrit1: "Gutes Gespräch",
        qccrit2: "Schlechter Chat",
        qccrit3: "Benutzerprofil betrachtet",
        qccrit4: "Bot-Profil betrachtet",
        qccrit5: "Vom Bot geführte Konversation",
        qccrit6: "CTA durch Agent",
        qccrit7: "Konversation sinnlos",
        qccrit8: "Benutzer unzufrieden mit dem Bot",
        qccrit9:'Gut zur Kenntnis genommen',
        actqc1: "Informiere den Agenten",
        actqc2: "Den Agenten aussetzen",
        actqc3: "Feuere den Agenten",
        adc1:'Ausgezeichneter Agent',
        adc2:'Durchschnittlicher Agent',
        adc3:'Schlechter Agent',
        sndmailtoadm: "Senden Sie eine E-Mail an den Administrator",
        yrnm: "Ihr Name",
        yrcmpnm: "Ihr Firmenname",
        yrmladdr: "Ihre E-Mail Adresse",
        yradmid: "Ihre Admin-ID",
        objctfyrrqst: "Objekt Ihrer Anfrage",
        yrmss: "Ihre Nachricht",
        yrattchmt: "Anlage",
        sndthml: "Senden Sie die E-Mail",
        ddnwadm: "Neuen Administrator hinzufügen",
        nm: "Name",
        mladd: "E-Mail Adresse",
        cmpnnm: "Firmenname",
        tmfldr: "TextModing-Ordner",
        psswrd: "Kennwort",
        accssar: "Zugangsbereiche",
        valdt: "Gültigkeitsdauer",
        rgstr: "Registrieren",

        accdash: "Dashboard für Buchhalter",
        acc: "Konten",
        acc1: "Bearbeiter-Statistik",
        acc2: "Zahlung per Banküberweisung",
        acc3: "Zahlung per Paypal",
        acc4: "Zahlung per Wise",
        acc5: "Zahlung per Yoursafe",
        acc6: "Einen Buchhalter hinzufügen",

        admdash: "Admin-Dashboard",
        chttlov: "Chattool-Übersicht",
        suptraffman: "Verkehrsüberwachung und -steuerung",
        vw: "Sehen",
        
        ratepermess: "Tarif pro Nachricht",
        bonus: "Bonus",
        totpay: "Gesamtzahlung",
        paymeth: "Zahlungsmethode",
        grosstot: "Bruttosumme zum Monat von",
        prstat: "Ausdruck des Kontoauszugs",
        agfn: "Vorname des Bearbeiters",
        agln: "Nachname des Bearbeiters",
        payadd: "Paypal-E-Mail-Adresse",
        nysent: "Noch nicht gesendet",
        wiseadd: "Wise-E-Mail-Adresse",
        yoursafeadd: "Yoursafe-E-Mail-Adresse",
        bankn: "Name der Bank",
        iban: "IBAN",
        bic: "BIC-Code",

        lstsup: "Liste der Supervisoren",
        ddnwsup: "Einen neuen Supervisor hinzufügen",
        trffcmng: "Verkehrsmanagement",
        supnm: "Name des Supervisors",
        suplgn: "Supervisor-Login",
        suplgnpsswrd: "Supervisor-Login-Passwort",
        idcnddt: "ID des Bewerbers",
        cntry: "Land",
        lgn: "Login",
        crtnwsup: "Einen neuen Supervisor erstellen",
        fragntonln: "Freier Agent online",
        msgalltag: "Dem Agenten zugewiesene Nachricht",
        allct: "Zuweisen",
        addqc: "Qualitätskontrolleur hinzufügen",
        crtqc: "Qualitätskontrolleur erstellen",
        crtnwacc: "Neuen Buchhalter anlegen",

        gifn: "GIFs zu senden",
        restfn: "Zurücksetzen",
        gifsel: "Leer (GIFs auswählen)",
        alerttxt: "GIFs erfolgreich gesendet.",
        option: "Optionen",

        phalert: "Anfrage für gesendetes Foto.",
        phtitre: "Fotoanfrage",
        phlabel: "Fototyp",
        phsend: "Senden",
        phload: "Laden...",

        delalert: "Gelöscht",
        deltitre1: "Möchten Sie diese Anfrage wirklich ",
        deltitre2: "löschen ?",
        delauteur: "Autor",
        deldesc: "Beschreibung",
        delaccept: "Ja",
        delrefuse: "Nein",
        delload: "Laden..",

        lstqc: "Liste der Qualitätskontrolleure",
        qcnm: "Name des Qualitätskontrolleurs",
        qclgn: "Anmeldung Qualitätslotsen",
        qclgnpsswrd: "Kennwort für den Qualitätskontrolleur",
        lstacc: "Liste der Buchhalter",
        accnm: "Name des Buchhalters",
        acclgn: "Buchhalter-Login",
        acclgnpsswrd: "Buchhalter-Login-Passwort",

        biev: "Willkommen",
        selecrt: "Nachrichtensendungsinterface",
        chxha: "Wählen Sie ein echtes und ein falsches Profil",
        chxF: "Liste der echten Profile",
        chxV: "Liste der falschen Profile",
        nomd: "Name",
        langs: "Sprache",
        errdj: "Wählen Sie ein echtes und ein falsches Profil aus",
        plch: "Geben Sie Ihre Nachricht ein",
        pokePh: "Poke-Nachrichten (noch nicht gesendet)",
        teaserPh: "Liste der Ankernachrichten (noch nicht gesendet)",
        inter: "Testoberfläche für TextModing",
        interSel: "Bitte wählen Sie die Aufgaben aus, die Sie ausführen möchten",
        des1: "Versand einer personalisierten Nachricht an ein Profil.",
        des2: "Versand einer Nachricht, um die Aufmerksamkeit des Profils zu erregen.",
        des3: "Personalisierter Versand von fesselnden Nachrichten an Neuanmeldungen, um Gespräche zu beginnen.",
        des4: "Versand einer Nachricht, um das Gespräch neu zu starten.",
        tit1: "Nachricht senden",
        tit2: "Teaser-Nachricht",
        tit3: "Poke-Nachricht",
        tit4: "Stopp-Nachricht",
        tache: "Aufgabe",
        bienvTest: "Willkommen in der Testoberfläche von",
        enterEP: "Geben Sie Ihre E-Mail und Ihr Passwort ein",
        instTest: "Anweisungen",
        sendInt: "Sendeinterface",
        unPlP: "Wählen Sie ein oder mehrere echte Profile aus.",
        unNouv: "Neue",
        unReç: "Erhaltene",
        unNouv1: "Neu",
        payTot: "Gesamtzahlung",
        allou: "Bereits zugewiesen",
        Nallou: "Noch nicht zugewiesen",
        totSoum: "Gesamtnachricht",
        Soum: "Eingereicht bei QC",
        nonSoum: "Nicht eingereicht bei QC",
        boot: "Bot",
        rvr: "Termine/Meetings/Urlaub",
        banit: "Gesperrt",
        suspt: "Suspendiert",
        aucunt: "Keine Nachricht",
        msgstop: "Bitte verfassen Sie eine Nachfassnachricht",
        messrt: "Nachrichtenrate",
        pkmessrt: "Poke-Nachrichtenrate",
        tsrmessrt: "Teaser-Nachrichtenrate",
        stpmessrt: "Stopp-Nachrichtenrate",
        bnsrt: "Bonusrate",
        mesdes: "Das Versenden von Nachrichten auf einer Dating-Website ist entscheidend, um die Aufmerksamkeit der anderen Person zu erregen und ihr Interesse zu wecken. Das Wichtigste ist, authentisch zu bleiben, echtes Interesse zu zeigen und offene Fragen zu stellen, die eine Antwort fördern.",
        mesdes1: "Wählen Sie unter den Profilen ein echtes und ein gefälschtes aus, um eine Nachricht zu senden.",
        mesdes2: "Achten Sie darauf, die richtige Korrespondenzsprache zu wählen.",
        mesdes3: "Verfassen Sie Ihre Testnachricht.",
        mesdes4: "Sie haben 5 Minuten Zeit, um auf die Nachricht auf der Animationsplattform für Operatoren zu antworten.",
        mesdes5: "Überprüfen Sie Ihre Statistiken in Ihrem Administrator-Dashboard.",
        stopdes: "Follow-up-Nachrichten auf einer Dating-Website sollen das Interesse neu wecken und das Gespräch nach einer Phase der Stille wiederbeleben.",
        stopdes1: "Wählen Sie ein echtes und ein falsches Profil",
        stopdes2: "Verfassen Sie eine übliche Nachricht",
        stopdes3: "Warten Sie 24 Stunden, nachdem der Chat-Operator auf die Nachricht des echten Profils geantwortet hat",
        stopdes4: "Nach 24 Stunden wird die vom echten Profil nicht beantwortete Nachricht zu einer Follow-up-Nachricht, die der Operator verfassen und senden wird.",
        stopdes5: "Überprüfen Sie Ihre Statistiken in Ihrem Administrator-Dashboard",
        teaserdes: "Teaser-Nachrichten auf einer Dating-Website sind entscheidend, um die Aufmerksamkeit zu erregen und das Interesse der anderen Person zu wecken. Es ist wichtig, authentisch zu bleiben, echtes Interesse zu zeigen und offene Fragen zu stellen, die eine Antwort fördern",
        teaserdes1: "Wählen Sie aus den Profilen ein echtes und ein gefälschtes aus, um eine Teaser-Nachricht zu senden",
        teaserdes2: "Achten Sie darauf, die richtige Korrespondenzsprache zu wählen",
        teaserdes3: "Verfassen Sie die Teaser-Nachrichten auf der Animationsplattform für Operatoren",
        teaserdes5: "Überprüfen Sie Ihre Statistiken in Ihrem Administrator-Dashboard",
        pokedes: "Poke-Nachrichten für eine Dating-Website sind kurze und leichte Nachrichten, die dazu gedacht sind, subtil und ansprechend Aufmerksamkeit zu erregen.",
        pokedes1: "Wählen Sie das Land und die Sprache der echten Profile, an die Sie Poke-Nachrichten senden möchten",
        pokedes2: "Wählen Sie ein oder mehrere Profile aus, an die Sie Poke-Nachrichten senden möchten",
        pokedes3: "Überprüfen Sie Ihre Statistiken in Ihrem Administrator-Dashboard",
        stopbla: "Liste der bereits gesendeten Folge-Nachrichten",
        respVp: "Antwort vom echten Profil",
        pasEn: "noch nicht.",
        notifAccr: "Testansprache Nachricht: Profilauswahl erfolgreich getroffen",
        notifPoke: "Test Poke Nachricht: Profilauswahl erfolgreich getroffen",
        pokeErr: "Klicken Sie auf ein oder mehrere echte Profile",
        teaserErr: "klicken Sie auf ein falsches und ein echtes Profil",
        reqGift : "Geschenke anfordern",
        reqGift1 : "Art des Geschenks",
        reqGift2 : "Geschenkanfrage",
    },
]
const getKey = lang =>{
    switch (lang){
        case 'fr': return 0
        case 'it': return 2
        case 'nl': return 3
        case 'es': return 4
        case 'de': return 5
        default: return 1 
    }
}
const getText = lang => { return text[getKey(lang)] }

export default getText