import React from "react";
import { Stack, Typography, Button, Box, Grid, Icon, Menu, MenuItem } from "@mui/material";
import { ChevronRight } from "@mui/icons-material"
import useData from "../hooks/useData";
import { Link } from "react-scroll";
import useLanguage from "../hooks/useLanguage";
import RoutesList from "../../helper/routes";
import { address, contactMail } from "../../helper/data";

const sx = { display: "flex", flexDirection: "column", alignItems: "center", padding: "0", "& .MuiTouchRipple-root": { display: "none" }, "&:hover": { backgroundColor: "transparent" } }

const NavItemItem = ({label, imageName}) => {
    return <Stack flexDirection="row" gap="5px" alignItems="center">
        <Box component="img" sx={{ width: "30px", visibility: imageName ? "visible" : "hidden" }} src={`/images/landing/${imageName}`}/>
        <Typography color="#000" textTransform="none" fontWeight="bold">{label}</Typography>
    </Stack>
}

const NavItem = ({label, to, customContent, imageName=""}) => {
    return <Button sx={sx}>
        <Link to={to} spy={true} smooth={true} offset={-85} duration={500}>
            {
                customContent ?? <NavItemItem label={label} imageName={imageName}/>
            }
        </Link>
    </Button>
}

const Title = ({ children }) => {
    return <Stack alignItems="center" margin="10px 0">
        <Stack sx={{ border: "1px solid #d6801c", padding: "3px", width: "fit-content", borderRadius: "23px" }}>
            <Typography sx={{ borderRadius: "20px", backgroundColor: "#d6801c", width: "fit-content", padding: "5px" }} color="#fff" fontWeight="bold">{children}</Typography>
        </Stack>
    </Stack>
}

const Footer = () => {
    const { text } = useLanguage()

    const { setLanguage } = useLanguage()
    const { languagesChangeList: languagesList } = useData()
    const [anchorEl, setAnchorEl] = React.useState(null)
    const open = Boolean(anchorEl)
    const handleClick = event => languagesList.length ? setAnchorEl(event.currentTarget) : {}
    const handleClose = () => setAnchorEl(null)

    return <Stack spacing={5} padding="0 15px">
        <Stack flexDirection="row" alignItems="center" gap="20px">
            <NavItem to="home" customContent={<>
                <Box component="img" src="/images/landing/logo.png" sx={{ height: "50px", width: "50px" }}/>
                <Typography color="#d58f78" textTransform="none" fontWeight="bold">TextModing</Typography>
            </>}/>
            <Typography color="#fff" variant="h3" sx={{ textShadow: "0px 0px 4px #ff4400", fontFamily: "Gladiola", fontStyle: "italic" }}>{text.intrcteffcctprfrmnc}</Typography>
        </Stack>
        
        <Stack spacing={2}>
            <Grid container sx={{ border: "2px solid #fff", width: "100%" }}>
                <Grid item xs={12} md={4} sx={{ borderRight: "2px solid #fff" }}>
                    <Stack spacing={2} alignItems="center" margin="10px">
                        <Title>{text.abtus}</Title>
                        <Typography fontWeight="bold">{text.abtustxt}</Typography>
                        <Link to="apr2" spy={true} smooth={true} offset={-110} duration={500}>
                            <Button sx={{ background: "#ff2525", color: "#fff", textTransform: "none", borderRadius: "20px", fontWeight: "bold", "&:hover": { background: "#ff2525" } }} endIcon={<Icon sx={{ background: "#fff", color: "#ff2525", borderRadius: "50%", display: "flex", alignItems: "center", justifyContent: "center" }}><ChevronRight/></Icon>}>             {text.rdswt}</Button>
                        </Link>
                    </Stack>
                </Grid>
                <Grid item xs={12} md={4} sx={{ borderRight: "2px solid #fff" }}>
                    <Stack spacing={2} alignItems="center" margin="10px">
                        <Title>{text.lnsdst}</Title>
                        <Stack width="100%" alignItems="flex-start" spacing={2}>
                            <NavItem to="apr" imageName="f1.png" label={text.apr}/>
                            <NavItem to="ftrs" imageName="f2.png" label={text.ftrs}/>
                            <NavItem to="avntgs" imageName="f3.png" label={text.avntgs}/>
                            <NavItem to="cldrsst" imageName="f4.png" label={text.cldrsst}/>
                            <NavItem to="nsprdts" imageName="f5.png" label={text.nsprdts}/>
                            <Button onClick={handleClick} sx={sx}>
                                <NavItemItem imageName="f6.png" label={text.lngdst}/>
                            </Button>
                            <Menu
                                anchorEl={anchorEl}
                                open={open}
                                onClose={handleClose}
                                anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                                }}
                                transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                                }}>
                                {
                                    languagesList.map( language =>
                                        <MenuItem key={language.value} onClick={() => {setLanguage(language.value, RoutesList.landing); handleClose()}}><Typography><Icon>{language.icon}</Icon> {language.innerHTML}</Typography></MenuItem>
                                    )
                                }
                            </Menu>
                        </Stack>
                    </Stack>
                </Grid>
                <Grid item xs={12} md={4}>
                    <Stack spacing={2} alignItems="center" margin="10px">
                        <Title>{text.cntcts}</Title>
                        <Stack width="100%" alignItems="flex-start" spacing={2}>
                            <NavItemItem imageName="f7.png" label={`${text.mnaddrss} : ${address}`}/>
                            <NavItemItem label={`${text.rprsnttbrblg} : Roodborstjesstraat 4, Wondelgem 9032`}/>
                            <NavItemItem imageName="f8.png" label={contactMail}/>
                        </Stack>
                    </Stack>
                </Grid>
            </Grid>

            <Typography textAlign="center" fontWeight="bold">©Copyright 2023-2024 -  TextModing® -  {text.ttdrtsrsrvs}</Typography>
        </Stack>
    </Stack>
}

export default Footer