import React, {useState, useContext} from "react";
import { Button, Stack, Icon, Typography, Grid } from "@mui/material";
import useLanguage from "../../hooks/useLanguage";
import Title from "./Title";
import Subtitle from "./Subtitle";
import FormRow from "../../tools/FormRow";
import { useForm } from "react-hook-form";
import { Send } from "@mui/icons-material";
import axiosInstance from "../../../axios";
import { AuthStateContext } from "../../state/AuthStateContext";

export default () => {
    const { text } = useLanguage()
    const { control, reset, handleSubmit, formState: { errors }, setValue } = useForm()
    const [error,setError] = useState('')
    const { authState } = useContext(AuthStateContext)
    const [ file, setFile ] = useState(null)

    //const sendToAdmin = data => {console.log(data)}

    const sendToAdmin = async({name, companyname, mail, receptmail, adminId, object, message, attachment}) => {
        const formData = new FormData();
        formData.append("name", name);
        formData.append("companyname", companyname);
        formData.append("mail", mail);
        formData.append("adminId", adminId);
        formData.append("object", object);
        formData.append("message", message);
        formData.append("attachment", file);
            
        try {
            await axiosInstance.post('/SendMailToAdmin', formData, {
                headers: { 
                    'Authorization': `Bearer ${authState.token}`,
                    'Content-Type': 'multipart/form-data'
                },
                transformRequest: null
            })
            reset()
        } catch(e){
            console.log(e)
            setError(e.response)
        }
    }

    return <Stack spacing={3} height="100%" style={{ overflowY: "scroll", padding: "2%" }}>
        <Title>{text.hlp}</Title>
        <Subtitle>{text.sndmailtoadm}</Subtitle>
        <Stack spacing={2}>
            <FormRow control={control} label={text.yrnm} name="name" error={errors.name} bgCol="#bc99ff" variant="formrow2"/>
            <FormRow control={control} label={text.yrcmpnm} name="companyname" error={errors.companyname} bgCol="#bc99ff" variant="formrow2"/>
            <FormRow control={control} label={text.yrmladdr} name="mail" error={errors.mail} bgCol="#bc99ff" variant="formrow2"/>
            <FormRow control={control} label={text.yradmid} name="adminId" error={errors.adminId} bgCol="#bc99ff" variant="formrow2"/>
            <FormRow control={control} label={text.objctfyrrqst} name="object" error={errors.object} bgCol="#bc99ff" variant="formrow2"/>
            <FormRow control={control} multiline label={text.yrmss} name="message" error={errors.message} bgCol="#bc99ff" variant="formrow2"/>
            <FormRow comp="FileName" onExportFile={setFile} control={control} label={text.yrattchmt} name="attachment" error={errors.attachment} bgCol="#bc99ff" variant="formrow2"/>
        </Stack>
        <Grid container>
            <Grid item xs={10.5} style={{ display: "flex", justifyContent: "flex-end" }}>
                <Button onClick={handleSubmit(sendToAdmin)} style={{ display: "flex", width: "fit-content", flexDirection: "row", borderRadius: "27px", gap: "10px", padding: "7px", backgroundColor: "#f2a490", alignItems: "center", justifyContent: "space-between" }}>
                    <Stack style={{ background: "#fff", borderRadius: "50%", display: "flex", alignItems: "center", justifyContent: "center", height: "40px", width: "40px" }}>
                        <Icon style={{ display: "flex", alignItems: "center", justifyContent: "center", transform: "rotate(-45deg) translateX(3px)", color:"#f2a490"}}><Send/></Icon>
                    </Stack>
                    <Typography color="#fff" textTransform="none" fontWeight="bold">{text.sndthml}</Typography>
                </Button>
            </Grid>
        </Grid>
    </Stack>
}