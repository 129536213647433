import { cData } from "../tools/Snackbars";
import useLanguage from "./useLanguage";
import { FR, GB, IT, NL, ES, DE } from 'country-flag-icons/react/3x2'

const useData = () => {
    const { text } = useLanguage()
    
    const typeMeet = [
        {value:text.meeting,innerHTML:text.meeting},
        {value:text.holiday,innerHTML:text.holiday},
        {value:text.rdv,innerHTML:text.rdv},
        {value:text.other,innerHTML:text.other}
    ]
    const typeMeet2 = [
        {value:text.meeting,innerHTML:text.meeting},
        {value:text.holiday,innerHTML:text.holiday},
    ]
    const getV = (n) => {
        switch(n){
            case 1: return ["Agent Full Name","Agent Full Address","Agent Country of residency",text.language,text.login,"Total payment",text.paymeth,"Email address of payment / IBAN-BIC"]
            case 2 : return [text.agentid,text.login,text.language,"Total payment","Agent Full Name","Agent Full Address","Agent Country of residency","Bank Details"]
            case 3 : return [text.agentid,text.login,text.language,text.platform,'Sent',"Poke","Teaser","Stop","Paid",text.bonus,"Payment",'Paid Method']
            case 4 : return [text.language,text.platform,text.messrt,text.pkmessrt,text.tsrmessrt,text.stpmessrt,text.bnsrt]
            default : return ["Date of Invoice","Invoicing Period","Invoice Number","Agent Full Name","Agent Full Address","Agent Country of residency",text.language,text.login,text.paymeth,"Total payment"]
        }
    }
    const typeStatus = [
        {value: text.nysent, innerHTML: text.nysent},
        {value: text.sent, innerHTML: text.sent},
    ]
    const roleList = [
        { value: 'operator', innerHTML: text.operator },
        { value: 'supervisor', innerHTML: text.supervisor },
        { value: 'administrator', innerHTML: text.administrator },
        { value: 'accountant', innerHTML: text.accountant },
        { value: 'qualitycontroller', innerHTML: text.qualitycontroller },
    ]
    const qualityControllerOpt = [
        {name:text.qccrit1,color:'#00bb00'},
        {name:text.qccrit2,color:'#ff2b36'},
        {name:text.qccrit3,color:'#0045ab'},
        {name:text.qccrit4,color:'#5f4336'},
        {name:text.qccrit5,color:'#29455e'},
        {name:text.qccrit6,color:'#854aff'},
        {name:text.qccrit7,color:'#ff1622'},
        {name:text.qccrit8,color:'#ff1c28'}
    ]
    const qualityControllerOpt1 = [
        {innerHTML:text.convman,value:0},
        {innerHTML:text.agman,value:1},
        {innerHTML:text.panicroom,value:2},
        {innerHTML:text.notesman,value:3},
        {innerHTML:text.teasermessages,value:4},
        {innerHTML:text.pokemessages,value:5},
        {innerHTML:text.stopmessages,value:6},
        {innerHTML:text.pr,value:7},
        {innerHTML:text.agrep,value:8},
        {innerHTML:text.logbook,value:9},
        {innerHTML:text.duplicatedmessages,value:10},
        {innerHTML:text.gftgtfman,value:11},
        {innerHTML:text.suptraffman,value:12},
        {innerHTML:text.qualitycontroller,value:13},
        {innerHTML:text.acc,value:14},
    ]
    const critereQCOpt = [
        {name:text.qccrit1,point:100},
        {name:text.qccrit2,point:4},
        {name:text.qccrit3,point:100},
        {name:text.qccrit4,point:100},
        {name:text.qccrit5,point:50},
        {name:text.qccrit6,point:78},
        {name:text.qccrit7,point:3},
        {name:text.qccrit8,point:1},
        {name:text.qccrit9,point:35}
    ]
    const appCntrlQC = [
        {name:text.adc1,color:'#00bb00'},
        {name:text.adc2,color:'#1483ce'},
        {name:text.adc3,color:'#ff2632'}
    ]
    const qualityResultOpt2 = [
        {name:text.actqc1,color:'#1483ce'},
        {name:text.actqc2,color:'#e16777'},
        {name:text.actqc3,color:'#ff2632'}
    ]
    const noteActions = [
        {name:text.notelu,color:'#5d8cc5'},
        {name:text.actpris,color:'#e94848'},
        {name:text.ferme,color:'#07ad32'},
    ]
    const qualityResultOpt = [
        {name:text.actqc4,color:'#1483ce'},
        {name:text.actqc1,color:'#1483ce'},
        {name:text.actqc2,color:'#e16777'},
        {name:text.actqc3,color:'#ff2632'}
    ]
    const platformsList = [
        { value: 'all', innerHTML: text.allfem }
    ]

    const languagesList = [
        { value: '', innerHTML: 'All', icon: <></> },
        { value: 'fr', innerHTML: text.french, icon: <FR /> },
        { value: 'en', innerHTML: text.english, icon: <GB /> },
        { value: 'it', innerHTML: text.italian, icon: <IT /> },
        { value: 'nl', innerHTML: text.dutch, icon: <NL /> },
        { value: 'es', innerHTML: text.spanish, icon: <ES /> },
        { value: 'de', innerHTML: text.german, icon: <DE /> }
    ]
    const compT = [text.convman,text.agman,text.panicroom,text.notesman,text.teasermessages,text.pokemessages,text.stopmessages,text.pr,text.agrep,text.logbook,text.duplicatedmessages,text.gftgtfman,text.suptraffman,text.qctrl,text.acc,text.tmfldaa]
    const listCAdmin = [...Array(qualityControllerOpt1.length)].map((_,i)=>i)
    const languagesChangeList = [
        { value: 'fr', innerHTML: text.french, icon: <FR /> },
        { value: 'en', innerHTML: text.english, icon: <GB /> },
        { value: 'it', innerHTML: text.italian, icon: <IT /> },
        { value: 'nl', innerHTML: text.dutch, icon: <NL /> },
        { value: 'es', innerHTML: text.spanish, icon: <ES /> },
        { value: 'de', innerHTML: text.german, icon: <DE /> }
    ]
    const languagesgifList = [
        { value: 'fr', innerHTML: text.french },
        { value: 'en', innerHTML: text.english },
        { value: 'it', innerHTML: text.italian },
        { value: 'nl', innerHTML: text.dutch },
        { value: 'es', innerHTML: text.spanish },
        { value: 'de', innerHTML: text.german }
    ]
    const sexualgifList = [
        { value: 'bdsm', innerHTML: text.bdsm },
        { value: 'dont', innerHTML: text.dont },
        { value: 'blj', innerHTML: text.blj },
        { value: 'org', innerHTML: text.org },
        { value: 'vagpen', innerHTML: text.vagpen },
        { value: 'sod', innerHTML: text.sod },
        { value: 'titss', innerHTML: text.titss }
    ]
    const gaygifList = [
        { value: 'bdsmg', innerHTML: text.bdsmg },
        { value: 'wisegg', innerHTML: text.wisegg },
        { value: 'sexgg', innerHTML: text.sexgg },
    ]
    const lesbiangifList = [
        { value: 'bdsml', innerHTML: text.bdsml },
        { value: 'wiselg', innerHTML: text.wiselg },
        { value: 'sexlg', innerHTML: text.sexlg },
    ]
    const transgendergifList = [
        { value: 'maletrans', innerHTML: text.maletrans },
        { value: 'femaletrans', innerHTML: text.femaletrans },
    ]
    const travestitegifList = [
        { value: 'maletranst', innerHTML: text.maletrans },
    ]

    const roles = {
        admin: 'admin',
        accountant: 'accountant',
        operator: 'operator',
        qc: 'quality controller',
        supervisor: 'supervisor',
    }

    const treatedConvs = [
        { date: '10/02/2022', idconv: '005', idmess: '018', sender: 'Sebastian', receiver: 'Chloé', message: 'Hello!', agent: "Tom", platform: '2', active: true },
        { date: '23/07/2029', idconv: '004', idmess: '120', sender: 'Ambre', receiver: 'Mark', message: 'How are you?', agent: "Jean", platform: '8', active: false },
    ]
    const convOnHold = [
        { date: '10/02/2022', idconv: '005', idmess: '018', message: 'Hello!', user: 'Luke', agent: "Tom", platform: '2', onHold: true },
        { date: '23/07/2029', idconv: '004', idmess: '120', message: 'How are you?', user: 'Steven', agent: "Jean", platform: '8', onHold: false },
    ]

    const agentsList = [
        { date: "20/01/2020", name: "Yoan", country: "New Zealand", login: "mailto:yoan030@gmail.com", language: text.english, platform: 8, online: true },
        { date: "17/04/2023", name: "Audrey", country: "Canada", login: "mailto:audrey1@gmail.com", language: text.french, platform: 3, online: false },
    ]

    const platformPerfs = [
        { date: "29/09/2022", platform: 5, agentscount: 30, sentmess: 601, receivedmess: 533, reports: 1 },
        { date: "30/09/2022", platform: 4, agentscount: 23, sentmess: 495, receivedmess: 411, reports: 3 },
    ]

    const agentsPerf = [
        { date: "20/01/2020", name: "Yoan", login: "mailto:yoan030@gmail.com", language: text.english, platform: 5, sentmess: 51, recmess: 46 },
        { date: "17/04/2023", name: "Audrey", login: "mailto:audrey1@gmail.com", language: text.french, platform: 3, sentmess: 30, recmess: 59 },
    ]

    const panicRoom = [
        { date: '22/06/2023', userprofile: 'Zoe', message: 'Panic room message 1', idmess: 133, idconv: 12, platform: 2 },
        { date: '19/01/2022', userprofile: 'Franck', message: 'Panic room message 2', idmess: 64, idconv: 23, platform: 4 },
    ]

    const notesList = [
        { date: '31/12/2022', event: 'Event 1', platform: 1, idnote: 26, note: 'Note 1' },
        { date: '12/05/2023', event: 'Event 2', platform: 1, idnote: 52, note: 'Note 2' },
    ]

    const teaserMessages = [
        { date: '30/11/2023', platform: 1, teaserMessageId: 2, teaserMessage: 'Teaser message content', deliveredTo: 'Mike' }
    ]

    const pokeMessages = [
        { date: '11/11/2022', platform: 2, pokeMessageId: 21, pokeMessage: 'Poke message content', deliveredTo: 'Nicolo' }
    ]

    const stopMessages = [
        { date: '30/01/2022', platform: 4, stopMessageId: 1, stopMessage: 'Stop message content', sentTo: "Joshua", agentWritingTheStop: "Juan", dateOfDelivery: "01/01/2022", dateOfAnswer: "06/01/2022" }
    ]

    const meetings = [
        { obj: "Rendez-vous", place: '', date: '22/06/2023', time: '' },
        { obj: "Holidays", place: '', date: '22/06/2023', time: '' },
        { obj: "Meeting1", place: '', date: '22/06/2023', time: '' },
        { obj: "Meeting2", place: '', date: '22/06/2023', time: '' },
        { obj: "Other", place: '', date: '22/06/2023', time: '' },
    ]

    const permanent = [
        { nature: "", info: '' },
        { nature: "", info: "" },
    ]

    const notpermanent = [
        { nature: "", info: '' },
        { nature: "", info: "" },
    ]

    const photoRequests = [
        { date: '22/02/2023', platform: 2, idrequest: 103, phototype: "Photo type", user: "Johann", agent: "Paul" },
    ]

    const reports = [
        { date: '01/12/2022', platform: 4, idreport: 23, reporttype: "Type of report", agent: "Luis", idmessage: 54, messageReported: 2 },
    ]

    const logBooks = [
        { date: '05/04/2023', platform: 1, idlogbook: 8, logbooktype: "Type of log book", infos: "None", agent: "Enzo", permanent: false },
        { date: '28/06/2023', platform: 4, idlogbook: 12, logbooktype: "Type of log book 2", infos: "None", agent: "Eric", permanent: true },
    ]

    const missingLogBooks = [
        { date: '29/02/2022', platform: 3, idconv: 18, idmess: 36, missingLogBook: "", agent: "Austin", permanent: true },
        { date: '30/12/2022', platform: 1, idconv: 19, idmess: 91, missingLogBook: "M", agent: "Roberto", permanent: false },
    ]

    const statoperator = [
        { time: "00:00 - 00:59", platform: "", language: '', sentmess: '', gifsent: '', receivedmess: '' },
        { time: "01:00 - 01:59", platform: "", language: '', sentmess: '', gifsent: '', receivedmess: '' },
        { time: "02:00 - 02:59", platform: "", language: '', sentmess: '', gifsent: '', receivedmess: '' },
        { time: "03:00 - 03:59", platform: "", language: '', sentmess: '', gifsent: '', receivedmess: '' },
        { time: "04:00 - 04:59", platform: "", language: '', sentmess: '', gifsent: '', receivedmess: '' },
        { time: "05:00 - 05:59", platform: "", language: '', sentmess: '', gifsent: '', receivedmess: '' },
        { time: "06:00 - 06:59", platform: "", language: '', sentmess: '', gifsent: '', receivedmess: '' },
        { time: "07:00 - 07:59", platform: "", language: '', sentmess: '', gifsent: '', receivedmess: '' },
        { time: "08:00 - 08:59", platform: "", language: '', sentmess: '', gifsent: '', receivedmess: '' },
        { time: "09:00 - 09:59", platform: "", language: '', sentmess: '', gifsent: '', receivedmess: '' },
        { time: "10:00 - 10:59", platform: "", language: '', sentmess: '', gifsent: '', receivedmess: '' },
        { time: "11:00 - 11:59", platform: "", language: '', sentmess: '', gifsent: '', receivedmess: '' },
        { time: "12:00 - 12:59", platform: "", language: '', sentmess: '', gifsent: '', receivedmess: '' },
        { time: "13:00 - 13:59", platform: "", language: '', sentmess: '', gifsent: '', receivedmess: '' },
        { time: "14:00 - 14:59", platform: "", language: '', sentmess: '', gifsent: '', receivedmess: '' },
        { time: "15:00 - 15:59", platform: "", language: '', sentmess: '', gifsent: '', receivedmess: '' },
        { time: "16:00 - 16:59", platform: "", language: '', sentmess: '', gifsent: '', receivedmess: '' },
        { time: "17:00 - 17:59", platform: "", language: '', sentmess: '', gifsent: '', receivedmess: '' },
        { time: "18:00 - 18:59", platform: "", language: '', sentmess: '', gifsent: '', receivedmess: '' },
        { time: "19:00 - 19:59", platform: "", language: '', sentmess: '', gifsent: '', receivedmess: '' },
        { time: "20:00 - 20:59", platform: "", language: '', sentmess: '', gifsent: '', receivedmess: '' },
        { time: "21:00 - 21:59", platform: "", language: '', sentmess: '', gifsent: '', receivedmess: '' },
        { time: "22:00 - 22:59", platform: "", language: '', sentmess: '', gifsent: '', receivedmess: '' },
        { time: "23:00 - 23:59", platform: "", language: '', sentmess: '', gifsent: '', receivedmess: '' },
        { time: "TOTAL DATE", platform: "", language: '', sentmess: '', gifsent: '', receivedmess: '' },

    ]

    const qclistofmess = [
        { date: "01/01/24", platform: "", language: "", idconv: "1", idmess: "1", message: "", agent: "", qualitycontrol: "Yes" },
        { date: "01/01/24", platform: "", language: "", idconv: "2", idmess: "2", message: "", agent: "", qualitycontrol: "No" },
    ]

    const duplicatedMessages = [
        { date: '05/01/2023', platform: 2, idconv: 12, idmess: 60, agent: "Diego" },
    ]
    const gifTypes = [
        { value: 'wig', innerHTML: text.wiseg },
        { value: 'seg', innerHTML: text.sexg },
        { value: 'gag', innerHTML: text.gayg },
        { value: 'leg', innerHTML: text.Lesbg },
        { value: 'tsg', innerHTML: text.transg },
        { value: 'tvg', innerHTML: text.travg },
    ]

    const qcpannel = [
        { idconv: "1", idmess: "1", agent: "", message: "", userprofile: "", botprofile: "" },
        { idconv: "2", idmess: "2", agent: "", message: "", userprofile: "", botprofile: "" },
    ]

    const agentstat = [
        { date: "", language: "", platform: "", agent: "", totalsentmess: "12", ratepermess: "1", bonus: "2", totalpayment: "3", paymentmethod: "" },
        { date: "", language: "", platform: "", agent: "", totalsentmess: "23", ratepermess: "2", bonus: "3", totalpayment: "4", paymentmethod: "" }
    ]

    const wiretransfer = [
        { agentlogin: "", agentfirstname: "", agentlastname: "", country: "", totalpayment: "30", bankname: "", iban: "", bic: "" },
        { agentlogin: "", agentfirstname: "", agentlastname: "", country: "", totalpayment: "3", bankname: "", iban: "", bic: "" },
    ]
   
    const paypal = [
        { agentlogin: "", agentfirstname: "", agentlastname: "", country: "", totalpayment: "4", paypaladdress: "" },
        { agentlogin: "", agentfirstname: "", agentlastname: "", country: "", totalpayment: "4", paypaladdress: "" }
    ]

    const wise = [
        { agentlogin: "", agentfirstname: "", agentlastname: "", country: "", totalpayment: "98", wiseaddress: "" },
        { agentlogin: "", agentfirstname: "", agentlastname: "", country: "", totalpayment: "4", wiseaddress: "" }
    ]

    const yoursafe = [
        { agentlogin: "", agentfirstname: "", agentlastname: "", country: "", totalpayment: "4", yoursafeaddress: "" },
        { agentlogin: "", agentfirstname: "", agentlastname: "", country: "", totalpayment: "20", yoursafeaddress: "" }
    ]

    const supervisors = [
        { id: 5, date: "06/06/2022", platform: 1, language: text.french, name: "Allister", login: "allister111@gmail.com" }
    ]

    const trafficManagement = [
        { date: "23/09/2023", platform: 2, idconv: 9, idmess: 401, user: "Jeff", freeagentonline: true },
        { date: "30/09/2023", platform: 1, idconv: 13, idmess: 552, user: "Charles", freeagentonline: false },
    ]
    
    const paymentList = [
        { value: 'wireTransfer', innerHTML: text.acc2 },
        { value: 'paypal', innerHTML: text.acc3 },
        { value: 'wise', innerHTML: text.acc4 },
        { value: 'yoursafe', innerHTML: text.acc5 },
    ]

    return { qualityResultOpt2,getV,qualityControllerOpt1,compT,appCntrlQC,critereQCOpt, listCAdmin, typeStatus,supervisors,noteActions,typeMeet,typeMeet2,qualityControllerOpt,qualityResultOpt, languagesChangeList, trafficManagement, paypal, wise, yoursafe, wiretransfer, agentstat, qcpannel, duplicatedMessages, gifTypes, qclistofmess, statoperator, photoRequests, reports, logBooks, missingLogBooks, notpermanent, permanent, meetings, pokeMessages, stopMessages, teaserMessages, panicRoom, notesList, agentsList, platformPerfs, agentsPerf, roleList, languagesList, platformsList, roles, treatedConvs, convOnHold, sexualgifList, gaygifList, lesbiangifList, transgendergifList, travestitegifList, languagesgifList, paymentList }


}

export default useData
