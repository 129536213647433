import React, {useContext} from "react";
import { Stack, Button, Typography } from "@mui/material";
import useLanguage from "../../hooks/useLanguage";
import Title from "./Title";
import TableRow from "../../tools/TableRow";
import { StatContext } from "../../state/StatContext";

export default ({onDetails}) => {
    const { text } = useLanguage()
    const { stat } = useContext(StatContext);
    const languages = Object.keys(stat?.logbook ?? {});

    const StatLogbookUserSide = (language) => {
        const platforms = Object.keys(stat?.logbook[language] ?? {});
        return (
            <TableRow end columns={[
                { xs: 1,
                    customContent: true,
                    content: <Stack>{language.toUpperCase()}</Stack>
                },
                { xs: 1,
                    customContent: true,
                    content: <Stack>
                        {platforms.map(platform => (
                            <Typography key={platform}>
                                {platform.charAt(0).toUpperCase() + platform.slice(1)}
                            </Typography>
                        ))}

                    </Stack>
                },
                { xs: 1.12,
                    customContent: true,
                    content: <Stack>
                        {platforms.map(platform => (
                            <Typography  key={platform}>
                                {stat.logbook[language][platform]?.userSideTakenRendezVous}
                            </Typography>
                        ))}
                    </Stack>
                },
                { xs: 1.145,
                    customContent: true,
                    content: <Stack>
                        {platforms.map(platform => (
                            <Typography key={platform}>
                                {stat.logbook[language][platform]?.userSideTakenPermanent}
                            </Typography>
                        ))}
                    </Stack>
                },
                { xs: 1.12,
                    customContent: true,
                    content: <Stack>
                        {platforms.map(platform => (
                            <Typography key={platform}>
                                {stat.logbook[language][platform]?.userSideTakenNonPermanent}
                            </Typography>
                        ))}
                    </Stack>
                },
                { xs: 1.145,
                    customContent: true,
                    content: <Stack>
                        {platforms.map(platform => (
                            <Typography key={platform}>
                                {stat.logbook[language][platform]?.userSideTakenEsquive}
                            </Typography>
                        ))}
                    </Stack>
                },
                { xs: 1.12,
                    customContent: true,
                    content: <Stack>
                        {platforms.map(platform => (
                            <Typography key={platform}>
                                {stat.logbook[language][platform]?.userSideMissedRendezVous}
                            </Typography>
                        ))}
                    </Stack>
                },
                { xs: 1.145,
                    customContent: true,
                    content: <Stack>
                        {platforms.map(platform => (
                            <Typography key={platform}>
                                {stat.logbook[language][platform]?.userSideMissedPermanent}
                            </Typography>
                        ))}
                    </Stack>
                },
                { xs: 1.12,
                    customContent: true,
                    content: <Stack>
                        {platforms.map(platform => (
                            <Typography key={platform}>
                                {stat.logbook[language][platform]?.userSideMissedNonPermanent}
                            </Typography>
                        ))}
                    </Stack>
                },
                { xs: 1.145,
                    customContent: true,
                    content: <Stack>
                        {platforms.map(platform => (
                            <Typography key={platform}>
                                {stat.logbook[language][platform]?.userSideMissedEsquive}
                            </Typography>
                        ))}
                    </Stack>
                },
            ]}/>
        );
    };
    const StatLogbookBotSide = (language) => {
        const platforms = Object.keys(stat?.logbook[language] ?? {});
        return (
            <TableRow end columns={[
                { xs: 1,
                    customContent: true,
                    content: <Stack>{language.toUpperCase()}</Stack>
                },
                { xs: 1,
                    customContent: true,
                    content: <Stack>
                        {platforms.map(platform => (
                            <Typography key={platform}>
                                {platform.charAt(0).toUpperCase() + platform.slice(1)}
                            </Typography>
                        ))}

                    </Stack>
                },
                { xs: 1.12,
                    customContent: true,
                    content: <Stack>
                        {platforms.map(platform => (
                            <Typography  key={platform}>
                                {stat.logbook[language][platform]?.botSideTakenRendezVous}
                            </Typography>
                        ))}
                    </Stack>
                },
                { xs: 1.145,
                    customContent: true,
                    content: <Stack>
                        {platforms.map(platform => (
                            <Typography key={platform}>
                                {stat.logbook[language][platform]?.botSideTakenPermanent}
                            </Typography>
                        ))}
                    </Stack>
                },
                { xs: 1.12,
                    customContent: true,
                    content: <Stack>
                        {platforms.map(platform => (
                            <Typography key={platform}>
                                {stat.logbook[language][platform]?.botSideTakenNonPermanent}
                            </Typography>
                        ))}
                    </Stack>
                },
                { xs: 1.145,
                    customContent: true,
                    content: <Stack>
                        {platforms.map(platform => (
                            <Typography key={platform}>
                                {stat.logbook[language][platform]?.botSideTakenEsquive}
                            </Typography>
                        ))}
                    </Stack>
                },
                { xs: 1.12,
                    customContent: true,
                    content: <Stack>
                        {platforms.map(platform => (
                            <Typography key={platform}>
                                {stat.logbook[language][platform]?.botSideMissedRendezVous}
                            </Typography>
                        ))}
                    </Stack>
                },
                { xs: 1.145,
                    customContent: true,
                    content: <Stack>
                        {platforms.map(platform => (
                            <Typography key={platform}>
                                {stat.logbook[language][platform]?.botSideMissedPermanent}
                            </Typography>
                        ))}
                    </Stack>
                },
                { xs: 1.12,
                    customContent: true,
                    content: <Stack>
                        {platforms.map(platform => (
                            <Typography key={platform}>
                                {stat.logbook[language][platform]?.botSideMissedNonPermanent}
                            </Typography>
                        ))}
                    </Stack>
                },
                { xs: 1.145,
                    customContent: true,
                    content: <Stack>
                        {platforms.map(platform => (
                            <Typography key={platform}>
                                {stat.logbook[language][platform]?.botSideMissedEsquive}
                            </Typography>
                        ))}
                    </Stack>
                },
            ]}/>
        );
    };
    
    return <Stack padding="2%" spacing={3} height="100%" style={{ overflowY: "scroll" }}>
        <Title>{text.logbook}</Title>
        <Stack spacing={1}>
            <TableRow header end columns={[
                { xs: 9.6,  text: text.usrsd},
            ]}/>
            <TableRow header end columns={[
                { xs: 1,  text: text.language},
                { xs: 1,  text: text.platform},
                { xs: 4.76,  text: text.nttkn},
                { xs: 4.76,  text: text.mssdnt},
            ]}/>
            <TableRow header end columns={[
                { xs: 1.12,  text: text.rdv},
                { xs: 1.145,  text: text.otherimp2},
                { xs: 1.12,  text: text.otherimp3},
                { xs: 1.145,  text: text.esqv},
                { xs: 1.12,  text: text.rdv},
                { xs: 1.145,  text: text.otherimp2},
                { xs: 1.12,  text: text.otherimp3},
                { xs: 1.145,  text: text.esqv},
            ]}/>
            {languages.map((language) => (<Stack key={language}>{StatLogbookUserSide(language)}</Stack>))}

        </Stack>
        <Stack spacing={1}>
            <TableRow header end columns={[
                { xs: 9.6,  text: text.btsd},
            ]}/>
            <TableRow header end columns={[
                { xs: 1,  text: text.language},
                { xs: 1,  text: text.platform},
                { xs: 4.76,  text: text.nttkn},
                { xs: 4.76,  text: text.mssdnt},
            ]}/>
            <TableRow header end columns={[
                { xs: 1.12,  text: text.rdv},
                { xs: 1.145,  text: text.otherimp2},
                { xs: 1.12,  text: text.otherimp3},
                { xs: 1.145,  text: text.esqv},
                { xs: 1.12,  text: text.rdv},
                { xs: 1.145,  text: text.otherimp2},
                { xs: 1.12,  text: text.otherimp3},
                { xs: 1.145,  text: text.esqv},
            ]}/>
            {languages.map((language) => (<Stack key={language}>{StatLogbookBotSide(language)}</Stack>))}

        </Stack>
        <Button onClick={onDetails} style={{ backgroundColor: '#feba3b', textTransform: 'capitalize', color: '#000', borderRadius: '15px', border: '2px solid #fff', width: 'fit-content', alignSelf: 'center'}}><Typography style={{fontFamily: 'Chewy Pro'}}>{ text.details}</Typography></Button>
    </Stack>
}

