import React,{useContext, useEffect} from 'react'
import { useState } from "react"
import { Alert, Snackbar, Button, Box, Typography, Grid, Stack, CircularProgress } from "@mui/material";
import axiosInstance from '../../axios';
import Pusher from 'pusher-js'
import { PUSHER_SECRET,CLUSTER,apiURL } from "../../helper/conf";
import axios from 'axios';
import { AuthStateContext } from '../state/AuthStateContext';
import Language from '../Language/Language';
import RoutesList from '../../helper/routes';
import useLanguage from '../hooks/useLanguage';
// import { useParams } from 'react-router-dom';
import DisplayMessage from './DisplayMessage';
import BallotIcon from '@mui/icons-material/Ballot';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import { Link, useNavigate } from 'react-router-dom';
import SwitchFeature from './SwitchFeature';
import Logout from './Logout';

const SendMessage = () => {
  const { authState } = useContext(AuthStateContext)
  const { text } = useLanguage()
  const [ openAlert, setOpenAlert ] = useState(false)
  const [ alert, setAlert] = useState()
  const [channel,setChannel] = useState()
  const [ newMessage, setNewMessage] = useState()
  const [lang,setLang] = useState('Fr')
  const [ border, setBorder ] = useState()
  const [ vraiId, setVraiId ] = useState()
  const [ fauxId, setFauxId ] = useState()
  const [ profils, setProfils ] = useState()
  const navigate = useNavigate();
  const token = localStorage.getItem('token');
  const endDate = localStorage.getItem('endDate');
  const [ok, setOk] = useState()
  const [ displayError, setDisplayError ] = useState()

  useEffect(()=>{
      if(!token){
          navigate('/login');
      }else{
          axiosInstance.post('/interface/token',{
              "token" : token,
          }, {
              headers: {
                  'Authorization': `Bearer ${token}`,
              },
          })
          .then((response) => {
              console.log(response)
              if(response.data.success != true){
                  navigate('/login');
              }
              setOk(response.data.success)
          })
          .catch((error) => {
              console.log(error)
              navigate('/login');
          })
      }
  }, [])

  // GET PROFILS
  useEffect(() => {
    axiosInstance.get('/profil', {
      headers: {
          'Authorization': `Bearer ${token}`,
      },
    })
    .then((response) => {
      console.log(response.data)
      setProfils(response.data)
    })
    .catch((error) => {
        console.log(error)
    })
  }, [])

  useEffect(()=>{
    const pusher = new Pusher(PUSHER_SECRET,{cluster:CLUSTER,channelAuthorization:{endpoint:`${apiURL}/pusher/user-auth`,transport:'jsonp'}})
    const chan = pusher.subscribe('private-sendMessage')
    console.log(pusher,chan)
    setChannel(chan)
    
    return ()=> {
      chan.unbind()
      pusher.unsubscribe('private-sendMessage')
    }
  },[])
  
  const handleClose = () => {
    setOpenAlert(false);
  };

  const sendReceiveMessage = async(data) => {
    setLoading(true)
    await axios.post('http://localhost:8000/api/receive_message',JSON.stringify(data))
    .then((res)=>{
      console.log(res)
      setLoading(false)
    })
    .catch((e)=>{
      console.log(e)
      setLoading(false)
    })
  }

  const [ Error, setError ] = useState(false)
  const [ loading, setLoading ] = useState(false)
  const [ lastMess, setLastMess ] = useState()
  const SendMessage = async (e) => {
    const userId = localStorage.getItem('userId');
    e.preventDefault()
    if(!newMessage.length) return;
    setError(false)
    if(fauxId === undefined || vraiId === undefined){
      return setError(true)
    }
    const data = {
      time_ms: 1715941260439,
      events: [
        {
          channel: "private-sendMessage",
          data: JSON.stringify({
            fake_profil: fauxId,
            real_profil: vraiId,
            content: newMessage,
            language: lang.toLocaleLowerCase(),
            type_mess: lastMess ? lastMess.type_mess : 'message',
            last_id: lastMess ? lastMess.id : null,
            userId: userId,
          }),
          event: "client-test",
          name: "client_event",
          socket_id: 40517.255148
        }
      ]
    }
    const dataO = {
      fake_profil: fauxId,
      real_profil: vraiId,
      content: newMessage,
      language: lang.toLocaleLowerCase(),
      type_mess: lastMess ? lastMess.type_mess : 'message',
      last_id: lastMess ? lastMess.id : null,
      userId: userId,
    }
    // sendReceiveMessage(data)              //Offline
    channel.trigger('client-test', dataO) //Online
    setNewMessage('')
    setAlert('Message envoyé avec succés')
    setOpenAlert(true)
    setLoading(false)
  }


  if(ok){
    return (
      <div style={{ maxWidth: "100%", minHeight: "100vh", background: "black", color: "white", paddingBottom: "60px"}}>
          <Snackbar
              open={openAlert}
              autoHideDuration={3000}
              onClose={handleClose}
              anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          >
              <Alert onClose={handleClose} severity="success">
                {alert}
              </Alert>
          </Snackbar>
          <div style={{ display: "flex", width: "100%", justifyContent: "space-between", alignItems: "center", borderBottom: "1px solid gray"}}>
            <Stack maxWidth={50} sx={{ padding: "20px"}}>
                <Link to="/home" >
                  <img src="/images/HeaderImage/logo.png" alt="" height='50px'/>
                </Link>
            </Stack>
            <Typography variant='h4' style={{fontWeight: 'bold', color: "white"}}>
                {text.inter}
            </Typography>
            <Stack maxWidth={50} sx={{ padding: "20px", flexDirection: "row", alignItems: "center", gap: "10px"}}>
                <SwitchFeature />
                <Logout />
            </Stack>
            <Stack maxWidth={50} sx={{ padding: "20px"}}>
                <Language redirectRoute={'/sendmessage'}/>
            </Stack>
          </div>
          
          <div style={{ width: "100%", minHeight: "89vh", display: "flex", justifyContent: "center", alignItems: "center", paddingTop: "40px"}}>
            <div style={{width: "85%"}}>
              <div style={{ marginBottom: "20px", color: "white"}}>

                <div style={{ marginBottom: "40px"}}>
                    <Typography variant='h2' style={{fontWeight: 'bold', marginBottom: '2px', color: 'orange'}}>
                        {text.biev},
                    </Typography>
                    <Typography variant='h3' style={{ marginBottom: '2px', color: '#dddddd' }}>
                      {text.sendInt} {text.messs}
                    </Typography>
                </div>

                <div style={{ marginBottom: "30px"}}>
                    <Stack sx={{marginBottom: '2px', flexDirection: "row", alignItems: "center", gap: "4px"}}>
                        <BallotIcon sx={{ color: 'orange', fontSize: '32px' }} />
                        <Typography variant='h4' style={{fontWeight: 'bold'}}>
                            {text.descri} :
                        </Typography>
                    </Stack>
                    <Typography variant='p' style={{marginBottom: '2px', color: '#dddddd', textAlign: 'justify'}}>
                      {text.mesdes}
                    </Typography>
                </div>

                <div style={{ marginBottom: "30px"}}>
                    <Stack sx={{flexDirection: "row", alignItems: "center", gap: "4px"}}>
                        <ManageAccountsIcon sx={{ color: 'orange', fontSize: '32px' }} />
                        <Typography variant='h4' style={{fontWeight: 'bold'}}>
                            {text.instTest} :
                        </Typography>
                    </Stack>
                    <Stack sx={{ color: '#dddddd' }}>
                        <ul style={{ fontSize: "18px"}}>
                            <li>
                              {text.mesdes1}
                            </li>
                            <li>
                              {text.mesdes2}
                            </li>
                            <li>
                              {text.mesdes3}
                            </li>
                            <li>
                              {text.mesdes4}
                            </li>
                            <li>
                              {text.mesdes5}
                            </li>
                        </ul>
                    </Stack>
                </div>

                <div style={{ marginBottom: '20px', width:'100%'}}>
                  <Typography variant='h4' marginBottom={1}>
                    {text.chxha} :
                  </Typography>
                  <div style={{display: "flex", justifyContent: "space-between", gap: '10px', marginBottom: '4px', width: "100%"}}>
                      {/* liste de realProfil */}
                      <div style={{width: "100%"}}>
                          <Stack sx={{flexDirection: "row", alignItems: "center", gap: "4px", marginBottom: "8px"}}>
                                <SupervisedUserCircleIcon sx={{ color: 'orange', fontSize: '27px' }} />
                                <Typography variant='h5' sx={{color: "orange"}}>
                                    {text.chxF}:
                                </Typography>
                          </Stack>
                          <div className='scroll' style={{ height: '210px', border: '2px solid #ccc', borderRadius: '8px', padding: '10px' }}>
                              <Grid container spacing={2}>
                              {profils?.realProfil.map((p)=>{
                                  const active = p.id == vraiId
                                  return(
                                  <Grid key={p.id} item sm={6} lg={3} sx={{ cursor: 'pointer' }} onClick={ p.bani ? ()=>{} : ( p.suspendu ? ()=>{} : () => { setBorder(); setVraiId(p.id); } )}>
                                      <Box sx={{ border: active ? '2px solid red' : '1px solid white', p: '10px', position: 'relative', opacity: p.bani ? '30%' : (p.suspendu ? '30%' : '100%') }}>
                                          { p.bani && 
                                            <div style={{ position: 'absolute', top: 4, right: 4, background: '#d50000', padding: '0px 6px', borderRadius: '5px', color: 'black', fontSize: '14px', fontWeight: '600' }}>
                                                {text.banit}
                                            </div>
                                          }
                                          { p.suspendu && 
                                            <div style={{ position: 'absolute', top: 4, right: 4, background: '#8e24aa', padding: '0px 6px', borderRadius: '5px', color: 'black', fontSize: '14px', fontWeight: '600'}}>
                                                {text.suspt}
                                            </div>
                                          }
                                          <div style={{ display: "flex", gap: '4px' }}>
                                              <Typography variant='p' sx={{ fontSize: "13px"}}>{text.nomd}:</Typography>
                                              <Typography variant='p' sx={{ fontSize: "13px"}}>{p.name}</Typography>
                                          </div>
                                          <div style={{ display: "flex", gap: '5px' }}>
                                              <Typography variant='p' sx={{ fontSize: "13px"}}>{text.langs}:</Typography>
                                              <Typography variant='p' sx={{ fontSize: "13px"}}>{p.language}</Typography>
                                          </div>
                                      </Box>
                                  </Grid>
                                  )
                              })}
                              </Grid>
                          </div>
                      </div>
                      {/* liste de fakeProfil */}
                      <div style={{width: "100%"}}>
                          <Stack sx={{flexDirection: "row", alignItems: "center", gap: "4px", marginBottom: "8px"}}>
                                <PeopleAltIcon sx={{ color: 'orange', fontSize: '25px' }} />
                                <Typography variant='h5' sx={{color: "orange"}}>
                                    {text.chxV}:
                                </Typography>
                          </Stack>
                          <div className='scroll' style={{ height: '210px', border: '2px solid #ccc', borderRadius: '8px', padding: '10px' }}>
                              <Grid container spacing={2}>
                              {profils?.fakeProfil.map((p)=>{
                                  const active = p.id == fauxId
                                  return(
                                    <Grid key={p.id} item sm={6} lg={3} sx={{ cursor: 'pointer' }} onClick={ p.bani ? ()=>{} : ( p.suspendu ? ()=>{} : () => { setBorder(); setFauxId(p.id); } )}>
                                      <Box sx={{ border: active ? '2px solid red' : '1px solid white', p: '10px', position: 'relative', opacity: p.bani ? '30%' : (p.suspendu ? '30%' : '100%') }}>
                                          { p.bani && 
                                            <div style={{ position: 'absolute', top: 4, right: 4, background: '#d50000', padding: '0px 6px', borderRadius: '5px', color: 'black', fontSize: '14px', fontWeight: '600' }}>
                                                {text.banit}
                                            </div>
                                          }
                                          { p.suspendu && 
                                            <div style={{ position: 'absolute', top: 4, right: 4, background: '#8e24aa', padding: '0px 6px', borderRadius: '5px', color: 'black', fontSize: '14px', fontWeight: '600'}}>
                                                {text.suspt}
                                            </div>
                                          }
                                          <div style={{ display: "flex", gap: '4px' }}>
                                              <Typography variant='p' sx={{ fontSize: "13px"}}>{text.nomd}:</Typography>
                                              <Typography variant='p' sx={{ fontSize: "13px"}}>{p.name}</Typography>
                                          </div>
                                          <div style={{ display: "flex", gap: '5px' }}>
                                              <Typography variant='p' sx={{ fontSize: "13px"}}>{text.langs}:</Typography>
                                              <Typography variant='p' sx={{ fontSize: "13px"}}>{p.language}</Typography>
                                          </div>
                                      </Box>
                                  </Grid>
                                  )
                              })}
                              </Grid>
                          </div>
                      </div>
                    </div>
                </div>

                {/* Affichage message */}
                <div style={{ width: "100%"}}>
                  <DisplayMessage idVp={vraiId} idFp={fauxId} error={displayError} setError={setDisplayError} setLastMess={setLastMess} />
                </div>

                {Error && 
                  <Typography sx={{ color: 'red', marginTop: '10px'}}>
                      {text.errdj}
                  </Typography>
                }

                {/* envoi des messages */}
                <div style={{width: "100%", opacity: displayError ? '20%' : '100%', pointerEvents: displayError ? 'none' : 'auto',}}>
                    <form onSubmit={SendMessage} style={{ display: 'flex', flexDirection: 'row', gap: '8px', marginTop: '10px'}}>
                      <textarea
                        defaultValue=""
                        value={newMessage}
                        onChange={(e) => setNewMessage(e.target.value)}
                        style={{ border: 'none', outline: 'none', padding: '8px', fontSize: '20px', fontWeight: 'bold', width: "100%", borderRadius: '8px'}}
                        placeholder={text.plch}
                        onKeyPress={(e) => {
                          if (e.key === 'Enter' && !e.shiftKey) {
                            SendMessage(e);
                          }
                        }}
                      />
                      <button
                        type='submit'
                        style={{ border: 'none', outline: 'none', padding: '8px', fontSize: '20px', fontWeight: 'bold', cursor: 'pointer', paddingLeft: "20px", paddingRight: "20px", borderRadius: '8px', background: "orange"}}
                      >
                        {loading? text.phload : text.phsend}
                      </button>
                    </form>
                </div>

              </div>
            </div>
          </div> 
        
      </div>
    )}else{
      return(
          <div style={{ maxWidth: "100%", height: "100vh", background: "black" }} >
              <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  height="100%"
              >
                  <CircularProgress />
              </Box>
          </div>
      )
    }
  }

export default SendMessage