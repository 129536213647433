import React, { useContext } from "react";
import { Button, Typography } from "@mui/material";
import { keyframes } from "@emotion/react";
import { ContactContext } from "./Main";

const slide = keyframes`
    0%, 9.9%, 90%, 100% { left: -108%; }

    10% { left: -108%; z-index: 1 }
    10.1%, 29.9% { z-index: 2 }
    30%, 49.9% { left: 0%; z-index: 1 }
    
    50% { left: -108%; z-index: 1 }
    50.1%, 69.9% { z-index: 2 }
    70%, 89.9% { left: 0%; z-index: 1 }

`

const CButton = ({children, onClick= () => {}}) => {
    const { setContactShow } = useContext(ContactContext)
    return <Button 
        onClick={() => setContactShow(true)}
        sx={{
            width: "fit-content",
            position: "relative",
            overflow: "hidden",
            background: "#dd791e",
            "&::before": {
                position: "absolute",
                height: "100%",
                width: "108%",
                top: "0",
                left: "0",
                background: "#dd791e",
                content: "''",
                animation: `${slide} 2s ease infinite`,
                animationDelay: "0.45s",
                clipPath: "polygon(0% 0%, 92% 0%, 100% 50%, 92% 100%, 0% 100%)",
            },
            "&::after": {
                position: "absolute",
                height: "100%",
                width: "108%",
                top: "0",
                left: "0",
                background: "#e8bd0d",
                content: "''",
                animation: `${slide} 2s ease infinite`,
                clipPath: "polygon(0% 0%, 92% 0%, 100% 50%, 92% 100%, 0% 100%)",
            },
        }}
    >
        <Typography variant="cbuttonttl" color="#000" textTransform="none" fontWeight="bold" zIndex={3}>{children}</Typography>
    </Button>
}

export default CButton