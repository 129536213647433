import React from "react";
import { Box, Stack, Typography } from "@mui/material";
import useLanguage from "../hooks/useLanguage";
import CButton from "./CButton";

const Apr = () => {
    const { text } = useLanguage()
    return <Stack id="apr" marginTop="95px">
        <Typography variant="hometitle" textAlign="right" fontWeight="bold" color="#fff" sx={{ WebkitTextStroke: "2px #d48b70", fontFamily: "PlayFairDisplay", zIndex: 2, paddingRight: "25px" }}>{text.apr}</Typography>
        <Stack alignItems="center" sx={{ transform: "translateY(-75px)" }}>
            <Box component="img" src="/images/landing/ab1.jpg" sx={{ width: "min(85%, 700px)" }}/>
        </Stack>
        <Stack sx={{ flexDirection: { xs: "column-reverse", md: "row" } }}>
            <Stack id="apr2" sx={{ width: {sm: "100%", md: "55%"}, paddingLeft: "20px" }} spacing={2}>
                <Typography>{text.aprtxt1}</Typography>
                <Typography>{text.aprtxt2}</Typography>
                <Typography>{text.aprtxt3}</Typography>
            </Stack>
            <Stack sx={{ width: {sm: "100%", md: "45%"}, transform: { xs: "translateY(-50px)", md: "translateY(-200px)"} }} alignItems="center" spacing={5}>
                <Box component="img" src="/images/landing/ab2.jpg" sx={{ width: "85%" }}/>
                <CButton>{text.rqstdmo}</CButton>
            </Stack>
        </Stack>
    </Stack>
}

export default Apr