import { Box, Button, IconButton, InputAdornment, Stack, TextField, Typography } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react'
import Language from '../Language/Language';
import useLanguage from '../hooks/useLanguage';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import axiosInstance from '../../axios';
import { AuthStateContext } from '../state/AuthStateContext';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

const LoginTest = () => {
  const { text } = useLanguage();
  const navigate = useNavigate();
  const { authState } = useContext(AuthStateContext)
  const { control, reset, handleSubmit, formState: {errors} } = useForm()

  
  const [showPassword, setShowPassword] = useState(false);
  const handleShowPassword = () => {
    setShowPassword((show) => !show)
  };

  // LOGIN
  const [loading, setLoading] = useState(false)
  const [ error, setError ] = useState()
  const handleLogin = data => {
    setLoading(true)
    axiosInstance.post('/interface/login', {
        "mail": data.email,
        "password": data.password,
        "password": data.password,
    })
    .then((response) => {
        console.log(response.data.token)
        localStorage.setItem('token', response.data.token);
        localStorage.setItem('userId', response.data.userId);
        setLoading(false)
        navigate('/home');
    })
    .catch((error) => {
        console.log(error)
        setError(error.response.data.message)
        setLoading(false)
    })
  }

  return (
    <Stack style={{ maxWidth: "100%", minHeight: "100vh", background: "black", color: "white"}} >
        <div style={{ display: "flex", width: "100%", justifyContent: "space-between", alignItems: "center", borderBottom: "1px solid gray"}}>
            <Stack maxWidth={50} sx={{ padding: "20px"}}>
                <img src="/images/HeaderImage/logo.png" alt="" height='50px'/>
            </Stack>
            <Typography variant='h4' style={{fontWeight: 'bold', color: "white"}}>
                {text.inter}
            </Typography>
            <Stack maxWidth={50} sx={{ padding: "20px"}}>
                <Language redirectRoute={'/pokemessage'} />
            </Stack>
        </div>
        <div style={{ width: "100%", minHeight: "89vh", display: "flex", justifyContent: "center", alignItems: "center"}}>
            <div style={{width: "70%", height: "400px", color: "black", background: "white", display: "flex"}}>
                <div style={{ 
                    width: "50%", 
                    height: "100%", 
                    backgroundImage: "url(/images/sitetest/bg3.jfif)",
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                    color: "white",
                }}>
                    <div style={{
                        width: "95%",
                        height: "100%",
                        display: "flex",
                        justifyContent: "center",
                        paddingLeft: "20px",
                        background: "rgba(0, 0, 0, 0.5)",
                        flexDirection: "column"
                    }}>
                        <Typography variant='p' sx={{ fontWeight: "600", color: "grey.400"}}>
                            {text.bienvTest}
                        </Typography>
                        <Typography variant='h1' sx={{ fontWeight: "600", fontSize: "60px", color: "orange"}}>
                            TextModing.
                        </Typography>
                    </div>
                </div>
                <div style={{ width: "50%", display: "flex", alignItems: "center", justifyContent: "center"}}>
                    <div>
                        <div style={{ marginBottom: "20px", display: "flex", flexDirection: "column"}}>
                            <Typography variant='h4' sx={{ fontWeight: "600"}}>
                                {text.logIn},
                            </Typography>
                            <Typography variant='p' sx={{ fontWeight: "500", color: "grey.700"}}>
                                {text.enterEP}
                            </Typography>
                            { error && 
                                <Typography variant='p' sx={{ fontWeight: "500", color: "red"}}>
                                    {error}
                                </Typography>
                            }
                        </div>
                        <div style={{ display: 'flex', flexDirection: "column", fontSize: "30px"}}>
                            <Controller
                                name="email"
                                control={control}
                                defaultValue=""
                                render={({ field: { onChange, value }, fieldState: { error } }) => (
                                    <TextField
                                        id="filled-basic"
                                        label={text.mail}
                                        variant="filled"
                                        type="text"
                                        value={value}
                                        onChange={onChange}
                                        error={!!error}
                                        helperText={error ? error.message : null}
                                        sx={{ width: '300px', marginBottom: '25px' }}
                                    />
                                )}
                            />
                            <Controller
                                name="password"
                                control={control}
                                defaultValue=""
                                render={({ field: { onChange, value }, fieldState: { error } }) => (
                                    <TextField
                                        id="standard-basic"
                                        label={text.password}
                                        variant="filled"
                                        type={showPassword ? 'text' : 'password'}
                                        control={control} 
                                        name="password" 
                                        value={value}
                                        onChange={onChange}
                                        error={!!error}
                                        helperText={error ? error.message : null}
                                        sx={{ width: "300px", marginBottom: "25px"}}
                                        InputProps={{
                                            endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleShowPassword}
                                                    edge="end"
                                                >
                                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            </InputAdornment>
                                            ),
                                        }}
                                    />
                                )}
                            />
                            <Button 
                                variant="contained" 
                                onClick={handleSubmit(handleLogin)}
                                sx={{
                                    fontWeight: "600",
                                    background: "black",
                                    color: "white",
                                    '&:hover': {
                                        background: "rgba(0, 0, 0, 0.9)"
                                    }
                                }}
                            >
                                { loading ? text.phload : text.login}
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Stack>
  )
}

export default LoginTest