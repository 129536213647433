import React from "react";
import { Stack, Typography, Divider } from "@mui/material";
import BasicHeader from "../Header/BasicHeader";
import useLanguage from "../hooks/useLanguage";
import { address, contactMail } from "../../helper/data";

const PrivacyPolicyAdmin = () => {
    const {text} = useLanguage()
    const count = 8

    return <Stack spacing={3} padding="20px" variant="maroongradientbg" height="100vh" boxSizing="border-box" sx={{ overflowY: "scroll" }}>
        <BasicHeader />
        <Stack alignItems="center" spacing={3} padding="10px">
            <Typography variant="h1" style={{textTransform: 'capitalize'}}>{text.tmppadm}</Typography>
            <Divider variant="primarybold" width='22%'/>
        </Stack>
        <Typography textAlign="center">{text.lstmj} : 08/11/2024</Typography>
        <Typography>{text.ppintadm}</Typography>

        {
            new Array(count).fill(null)
            .map((_, i) => <Stack key={i+1} spacing={2}>
                <Typography variant="pfsbold">{i+1} . {text[`pp${i+1}ttladm`]}</Typography>
                <Stack spacing={1}>
                    <Stack>
                        <Typography>{text[`pp${i+1}intadm`]}</Typography>
                        {
                            text[`pp${i+1}contadm`].split("\n").map((cont, j) =>
                            <Typography key={j*-1}>{cont}</Typography>)
                        }
                    </Stack>
                    <Typography>{text[`pp${i+1}outadm`]}</Typography>
                </Stack>
            </Stack>)
        }

        <Stack spacing={2}>
            <Typography variant="pfsbold">{count+1} . {text[`pp${count+1}ttladm`]}</Typography>
            <Stack spacing={1}>
                <Typography>{text[`pp${count+1}intadm`]}</Typography>
                <Stack>
                    <Typography>TextModing®</Typography>
                    <Typography>{text.addrss} : <b>{address}</b></Typography>
                    <Typography>{text.eml} : <b>{contactMail}</b></Typography>
                </Stack>
                <Typography>{text[`pp${count+1}outadm`]}</Typography>
            </Stack>
        </Stack>
    </Stack>
}

export default PrivacyPolicyAdmin