import { Stack, Typography, Button, DialogTitle, DialogActions, DialogContent, Pagination } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import useLanguage from "../hooks/useLanguage";
import BigContainerAccordion from "../tools/BigContainerAccordion";
import TableRow from "../tools/TableRow";
import useData from "../hooks/useData";
import SearchFilter from "../tools/SearchFilter";
import FormRow from "../tools/FormRow";
import {useForm} from 'react-hook-form'
import { AuthStateContext } from "../state/AuthStateContext";
import axiosInstance from "../../axios";
import CustomAlert from "../Admin/CustomAlert";
import CustomPopup from "../Admin/CustomPopup";
import { formatDate } from "../../helper/formatDate";
import { GifsContext } from "../state/gifContext";
import Title from "../Admin2/Comps/Title";
import Pusher from 'pusher-js'
import { CLUSTER, PUSHER_SECRET, apiURL, baseURL } from "../../helper/conf";

const TeaserMessages = () =>{
    const { globalFilter } = useContext(GifsContext)
    const {text} = useLanguage()
    const { languagesList,languagesgifList} = useData()
    const { authState } = useContext(AuthStateContext)
    const [ alerto, setAlerto ] = useState(false)
    const [ loading, setLoading ] = useState(false)
    const [ alertContenu, setAlertContenu] = useState("")

    const [filter, setFilter] = useState(() => ({
        ...globalFilter
    }))

    const [filter1, setFilter1] = useState(() => ({
        ...globalFilter
    }))

    const { control, handleSubmit, watch, reset, formState: {errors} } = useForm()

    // GET ALL TEASER
    const [ teaser, setTeaser ] = useState([])
    useEffect(() => {
        axiosInstance.get('/teaser', {
            headers: {
                'Authorization': `Bearer ${authState.token}`,
            },
        })
        .then((response) => {
            console.log(response.data.data)
            setTeaser(response.data.data)
        })
        .catch((error) => {
            console.log(error)
        })
    }, [])

    // PUSHER RECEIVE REPONSE TEASER
    useEffect(()=>{
        const pusher = new Pusher(PUSHER_SECRET,{cluster:CLUSTER,channelAuthorization:{endpoint:`${apiURL}/pusher/user-auth`,transport:'jsonp'}})
        const channel = pusher.subscribe("private-teaser")
        channel.bind(`add-${authState.userId}`, (data) => {
            console.log(data)
            setTeaser((prev) => prev.some((d) => d.id === data.id) ? 
                prev.map((d) => (d.id === data.id ? data : d))
                : 
                [...prev, data]
            );
        })
        return()=>{
            channel.unbind()
            pusher.unsubscribe("private-teaser")
        }
    },[])

    //LISTE TEASER PAS ENCORE ENVOYER
    const [ teaserNotSendMessages, setNotSendTeaserMessages ] = useState()
    const [ paginationHold, setPaginationHold ] = useState()
    const [pageHold, setPageHold] = useState(1);
    const handleChangeHold = (event, value) => {
        setPageHold(value);
    };
    useEffect(() => {
        axiosInstance.get(`/teasermessage/NotSendYet?year=${filter1?.year}&month=${filter1?.month}&date=${formatDate(filter1?.date).split('-')[0]}&startingDate=${formatDate(filter1?.startingDate)}&closingDate=${formatDate(filter1?.closingDate)}&platforms=${filter1?.platforms}&agents=${filter1?.agents}&language=${filter1?.language}&page=${pageHold}`,{
            headers: {
                'Authorization': `Bearer ${authState.token}`,
            },
        })
        .then((response) => {
            setNotSendTeaserMessages(response.data.data)
            setPaginationHold(response.data.pagination)
        })
        .catch((error) => {
            console.log(error)
        })
    }, [pageHold, filter1])
    
    //ADD TEASER
    const addTeaser = (data) => {
        setLoading(true)
        axiosInstance.post("/teasermessage/add", {
            "platformId": data.platform,
            "content": data.teasermessage,
            "language": data.language,
        }, {
            headers: {
                'Authorization': `Bearer ${authState.token}`,
            },
        })
        .then((response) => {
            console.log(response.data)
            setAlertContenu(response.data.message)
            setLoading(false)
            setNotSendTeaserMessages((last)=>[...last, response.data.data])
            reset()
            setAlerto(true)
        })
        .catch((error) => {
            console.log(error)
            setLoading(false)
        })
    }

    //UPDATE TEASER
    const [ teaserToUpdate, setTeaserToUpdate ] = useState()
    const [ openUpdate, setOpenUpdate ] = useState(false)
    const [ newContent, setNewContent ] = useState()
    const handleUpdate = () => {
        setLoading(true)
        axiosInstance.post('/teasermessage/update',{
            "teaserMessageId": teaserToUpdate?.id,
            "newContent": newContent,
        },{
            headers: { 
                'Authorization': `Bearer ${authState.token}`,
            },
        })
        .then((response) => {
            console.log(response.data)
            setAlertContenu(text.updtdsucc)
            setAlerto(true)
            setLoading(false)
            setNotSendTeaserMessages((last)=>last.map((t)=> t.id==teaserToUpdate?.id ? {...t, content: newContent} : t))
            setOpenUpdate(false)
        })
        .catch((error) => {
            console.log(error)
            setLoading(false)
        })
    }

    //DELETE TEASER
    const [ teaserToDelete, setTeaserToDelete ] = useState()
    const [ openDelete, setOpenDelete ] = useState(false)
    const handleDelete = () => {
        setLoading(true)
        axiosInstance.delete(`/teasermessage/delete/${teaserToDelete?.id}`,{
            headers: { 
                'Authorization': `Bearer ${authState.token}`,
            },
        })
        .then((response) => {
            console.log(response.data)
            setAlertContenu(text.delalert)
            setAlerto(true)
            setLoading(false)
            setNotSendTeaserMessages((last)=>last.filter((t)=> t.id !== teaserToDelete?.id))
            setOpenDelete(false)
        })
        .catch((error) => {
            console.log(error)
            setLoading(false)
        })
    }


    return(
        <Stack spacing={2} style={{ overflowY: "scroll" }}>
            <CustomAlert
                open={alerto}
                autoHideDuration={1500}
                onClose={() => setAlerto(false)}
                severity="success"
                message={alertContenu}
            />
            {/* pop up delete */}
            <CustomPopup open={openDelete} onClose={() => setOpenDelete(false)}>
                <div style={{ padding: "18px 30px"}}>
                    <DialogTitle id="alert-dialog-title" sx={{textAlign: "center"}}>
                        <p style={{ fontWeight: "800", fontSize: "22px"}}>
                            {text.dlttsrmess}
                        </p>
                    </DialogTitle>
                    <DialogActions>
                        <Button
                            variant='contained' 
                            sx={{ 
                                fontWeight: "700", 
                                bgcolor: "error.main",
                                color: "white",
                                "&:hover": {
                                    bgcolor: "error.dark",
                                } 
                            }} 
                            onClick={() => setOpenDelete(false)}
                        >
                            {text.annuler}
                        </Button>
                        <Button 
                            variant='contained' 
                            sx={{ 
                                fontWeight: "700", 
                                bgcolor: "success.main", 
                                color: "white",
                                "&:hover": {
                                    bgcolor: "success.dark",
                                } 
                            }} 
                            onClick={()=> handleDelete()}
                        >
                            {loading ? text.phload : text.vld}
                        </Button>
                    </DialogActions>
                </div>
            </CustomPopup>
            {/* pop up update */}
            <CustomPopup open={openUpdate} onClose={() => setOpenUpdate(false)}>
                <div style={{ padding: "18px 30px"}}>
                    <DialogTitle id="alert-dialog-title" sx={{textAlign: "center"}}>
                        <p style={{ fontWeight: "800", fontSize: "22px"}}>
                            {text.updttsrmess} :
                        </p>
                    </DialogTitle>
                    <DialogContent>
                        <div style={{ alignItems: 'center' }}>
                            <input
                                style={{fontSize: "22px", fontWeight: "500", color: "#333333", padding: '10px', width: '100%'}}
                                defaultValue={teaserToUpdate?.content}
                                onChange={(e)=>setNewContent(e.target.value)}
                            />
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            variant='contained' 
                            sx={{ 
                                fontWeight: "700", 
                                bgcolor: "error.main",
                                color: "white",
                                "&:hover": {
                                    bgcolor: "error.dark",
                                } 
                            }} 
                            onClick={() => setOpenUpdate(false)}
                        >
                            {text.annuler}
                        </Button>
                        <Button 
                            variant='contained' 
                            sx={{ 
                                fontWeight: "700", 
                                bgcolor: "success.main", 
                                color: "white",
                                "&:hover": {
                                    bgcolor: "success.dark",
                                } 
                            }} 
                            onClick={()=> handleUpdate()}
                        >
                            {loading ? text.phload : text.vld}
                        </Button>
                    </DialogActions>
                </div>
            </CustomPopup>
            <Stack style={{display: 'flex', alignItems: 'center', gap: '15px', justifyContent: 'center', flexDirection: 'row'}}>
                {/* <Typography variant="h1" alignSelf='center' style={{fontFamily: 'Fascinate Regular', fontWeight: 'bold', color: '#ff3131', WebkitTextStroke: '2px #ffaeae'}}
                >{text.teasermessages}</Typography> */}
                <Title>{text.teasermessages}</Title>
                {/* <img className="card-img-resize-2" src={"/images/supervisor/5tea.png"} alt="" height='50px' width='50px'/> */}
            </Stack>
            <BigContainerAccordion 
                transparent
                summaryBg="#ff9b45"
                summary={text.lsttsrmsg}
                details={
                    <Stack spacing={2}>
                        <SearchFilter
                            selectedDate={filter.date}
                            onDateChange={{
                                year: y => setFilter({...filter, year: y}),
                                month: m => setFilter({...filter, month: m}),
                                date: d => setFilter({...filter, date: d}),
                            }}
                            items= {[
                                { label: text.startingdate , comp: 'DatePicker', selected: filter.startingDate, onChange: d => setFilter({...filter, startingDate: d})},
                                { label: text.closingdate , comp: 'DatePicker', selected: filter.closingDate, onChange: d => setFilter({...filter, closingDate: d})},
                                { label: text.platform , comp: 'TextInput', onChange: e => setFilter({...filter, platforms: e.target.value}) },
                                { label: text.language, comp: 'Select', choices: languagesList, onChange: e => setFilter({ ...filter, language: e.target.value }) },
                            ]}
                        />
                        <Stack spacing={'10px'}>
                            <TableRow header columns={[
                                { xs: 1.9,  text: "N:"},
                                { xs: 1.9,  text: text.recep},
                                { xs: 1.9, text: text.exp },
                                { xs: 1.9,  text: text.dtofdlvr},
                                { xs: 1.9,  text: text.rsptostp2},
                                { xs: 1.9,  text: text.respVp},
                            ]}/>
                            {
                                teaser.map((sm, key) =>
                                    <TableRow key={key} columns={[
                                        { xs: 1.9,  text: key+1},
                                        { xs: 1.9,  text: sm.vprofil},
                                        { xs: 1.9, text: sm.fprofil},
                                        { xs: 1.9,  text: sm.date},
                                        { xs: 1.9,  text: sm.response.length > 80 ? `${sm.response.substring(0, 80)} ...` : (sm.response == "pas encore." ? text.pasEn : sm.response)},
                                        { xs: 1.9,  text: sm.réresponse.length > 80 ? `${sm.réresponse.substring(0, 80)} ...` : (sm.réresponse == "pas encore." ? text.pasEn : sm.réresponse)},
                                    ]}/>
                                )
                            }
                        </Stack>
                    </Stack>
                }
            />
            <BigContainerAccordion 
                transparent
                summaryBg="#ff9b45"
                summary={text.teaserPh}
                details={
                    <Stack spacing={2}>
                        <SearchFilter
                            selectedDate={filter1.date}
                            onDateChange={{
                                year: y => setFilter1({...filter1, year: y}),
                                month: m => setFilter1({...filter1, month: m}),
                                date: d => setFilter1({...filter1, date: d}),
                            }}
                            items= {[
                                { label: text.startingdate , comp: 'DatePicker', selected: filter1.startingDate, onChange: d => setFilter({...filter1, startingDate: d})},
                                { label: text.closingdate , comp: 'DatePicker', selected: filter1.closingDate, onChange: d => setFilter({...filter1, closingDate: d})},
                                { label: text.platform , comp: 'TextInput', onChange: e => setFilter1({...filter1, platforms: e.target.value}) },
                                { label: text.language, comp: 'Select', choices: languagesList, onChange: e => setFilter1({ ...filter1, language: e.target.value }) },
                            ]}
                        />
                        <Stack spacing={'10px'}>
                            <TableRow header columns={[
                                { xs: 1.9,  text: text.date},
                                { xs: 1.9,  text: text.platform},
                                { xs: 1.9,  text: text.language},
                                { xs: 1.9,  text: text.idpkmsg},
                                { xs: 1.9,  text: text.pkmsg},
                                { xs: 1.9,  text: text.actions},
                            ]}/>
                            {
                                teaserNotSendMessages?.map((pm, i) =>
                                    <TableRow key={'pm' + i} columns={[
                                        { xs: 1.9,  text: pm.created_at},
                                        { xs: 1.9,  text: pm.platform.name},
                                        { xs: 1.9, text: pm.language},
                                        { xs: 1.9,  text: pm.id},
                                        { xs: 1.9,  text: pm.content},
                                        {
                                            xs: 1.9,
                                            customContent: true,
                                            content: <Stack style={{width: '100%'}}>
                                                <Button 
                                                    style={{width: '100%', height: '100%', textTransform: 'capitalize', color: '#000'}}
                                                    onClick={()=>{ setTeaserToUpdate(pm); setOpenUpdate(true)}}
                                                >
                                                    <Typography style={{fontFamily: 'Delm'}}>{text.edit}</Typography>
                                                </Button>
                                                <Button 
                                                    style={{width: '100%', height: '100%', textTransform: 'capitalize', color: '#000'}}
                                                    onClick={()=>{ setTeaserToDelete(pm); setOpenDelete(true)}}
                                                >
                                                    <Typography style={{fontFamily: 'Delm'}}>{text.dlt}</Typography>
                                                </Button>
                                            </Stack>
                                        },
                                        
                                    ]}/>
                                )
                            }
                        </Stack>
                        <Stack justifyItems="center" alignItems="center" >
                            <Pagination 
                                page={pageHold}
                                onChange={handleChangeHold}
                                count={paginationHold?.total_pages}
                                variant="outlined" 
                                shape="rounded" 
                                color="secondary"
                            />
                        </Stack>
                    </Stack>
                }
            />
            <BigContainerAccordion 
                transparent
                summaryBg="#cb6ce6"
                summary={text.crttsrmsg}
                details={
                    <Stack spacing={2}>
                        {/* <FormRow label={text.idtsrmsg} control={control} name="teasermessageid" error={errors.teasermessageid} /> */}
                        <FormRow label={text.platform} control={control} name="platform" error={errors.platform} />
                        <FormRow label={text.language} comp='Select' options={languagesgifList} control={control} name="language" error={errors.language} />
                        <FormRow label={text.tsrmsg} control={control} name="teasermessage" error={errors.teasermessage} />
                        <Stack style={{width: '85%', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end'}}>
                            <Button onClick={handleSubmit(addTeaser)} variant="formrow">
                                <Typography>
                                    { loading ? text.phload : text.crttsrmsg }
                                </Typography>
                            </Button>
                        </Stack>
                    </Stack>
                }
            />
        </Stack>
    )
}

export default TeaserMessages